import React, { useEffect, useState } from 'react'
import { useDataProvider, LoadingIndicator } from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    border: 0
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  imgContainer: {
    width: 120,
    height: 90,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
})

const SummaryCard = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const [carInstance, setCarInstance] = useState({})
  const [carInstanceLoading, setCarInstanceLoading] = useState(false)

  useEffect(() => {
    setCarInstanceLoading(true)
    dataProvider.getOne('car-instances', { id: props.record.carInstance })
      .then(({ data }) => {
        setCarInstance(data)
      })
      .finally(() => {
        setCarInstanceLoading(false)
      })
  }, [])

  if (carInstanceLoading) return <LoadingIndicator />

  const {
    brandName = '', modelName = '', generationName = '', modificationName = '', vin ,
  } = carInstance
  
  return (
    <Card className={classes.root} variant="outlined" raised={false}>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ width: '100%' }}
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" component="h2">
              {`${brandName} ${modelName} ${generationName}`}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {modificationName}
            </Typography>
            <Typography variant="body2" component="p">
              {vin}
            </Typography>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  )
}

export default SummaryCard