export default {
  'crm/worksheets': {
    name: 'Feuille de travail |||| Feuilles de travail',
    fields: {
      companyBranch: 'Branche de l\'entreprise',
      createdAt: 'Created at',
      createdBy: 'Créé par',
      customerPhone: 'Téléphone'
    },
    errors: {
      phoneOrEmailRequired: 'Téléphone ou email requis'
    },
    tabs: {
      summary: 'Propriétés',
      customer: 'Client',
    },
  },
}