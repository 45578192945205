import React, { useState } from 'react'
import { useDataProvider, useTranslate } from 'react-admin'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const SaveFilterButton = (props) => {

  const { filterValues, className } = props

  const dataProvider = useDataProvider()
  const translate = useTranslate()

  const [open, setOpen] = useState(false)
  const [filterSaving, setFilterSaving] = useState(false)
  const [newFilterName, setNewFilterName] = useState('')

  const createFilter = () => {
    setFilterSaving(true)
    dataProvider.create('crwl/filters', { data: { name: newFilterName, filter: JSON.stringify(filterValues) } })
      .then(({ data }) => {
        setOpen(false)
        setNewFilterName('')
        setFilterSaving(false)
      })
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        disabled={filterSaving}
        className={className}
      >
        {translate('resources.crwl/links.actions.saveFilter')}
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {translate('ra.action.create')} {translate('resources.crwl/filters.name', { smart_count: 1 })}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={translate('resources.crwl/filters.fields.name')}
            fullWidth
            onChange={event => setNewFilterName(event.target.value)}
            variant="filled"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {translate('ra.action.cancel')}
          </Button>
          <Button onClick={() => createFilter()} color="primary" disabled={filterSaving}>
            {translate('ra.action.create')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default SaveFilterButton