import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import VersionSelector from './VersionSelector'
import { useDataProvider } from 'react-admin'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
}))

const StrictResolverContainer = ({ versions, onResolved }) => {

  const classes = useStyles()
  const dataProvider = useDataProvider();

  const logAndProceed = (versionId) => {
    dataProvider.create('commands/bc/log', {
      data: {
        version: versionId,
        source: 'appraisal'
      }
    })
      .finally(
        () => {
          onResolved(versionId)
        }
      )
  }

  return (
    <Paper m={3} className={classes.paper}>
      <Typography component="div">
        <VersionSelector versions={versions} onVersionFound={(id) => logAndProceed(id)}/>
      </Typography>
    </Paper>
  )
}

export default StrictResolverContainer