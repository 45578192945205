export default {
  'workflow/workflows': {
    name: 'Процесс |||| Процессы',
    fields: {
      company: 'Компания',
      name: 'Наименование',
      type: 'Тип',
      initial_place: 'Начало',
      enabled: 'Доступен'
    },
    tabs: {
      summary: 'Данные процесса',
      participants: 'Участники',
      places: 'Состояния',
      transitions: 'Переходы',
      dump: 'Граф'
    },
    buttons: {
      create: 'Добавить процесс',
      copy: 'Скопировать процесс'
    },
    dialogs: {
      assignee: {
        title: 'Назначить ответственного',
        assign: 'Назначить и отправить',
        notSelected: 'Выберите сотрудника',
        submit: 'Отправить',
        noAssignees: 'Нажмите кнопку "Отправить".',
        confirm: 'Подтвердите назначение'
      }
    }
  },
}
