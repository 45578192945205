export default {
  'comments': {
    name: 'Комментарий |||| Комментарии',
    fields: {
      comment: 'Комментарий'
    },
    buttons: {
      add: 'Добавить комментарий'
    },
    dialogs: {
      create: {
        title: 'Новый комментарий'
      }
    },
    notifications: {
      deleted: 'Комментарий удален'
    }

  },
}