import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
  {
    clearIcon: {
      height: 20,
      width: 20,
    },
    clearButton: {
      height: 26,
      width: 26,
      padding: 0,
    },
  },
)

const ResettableTextField = (props) => {

  const { onChange, label, value } = props

  const translate = useTranslate()
  const classes = useStyles()

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  const clean = () => {
    onChange('')
  }

  return (
    <TextField
      label={translate(label)}
      variant="outlined"
      {...props}
      onChange={handleChange}
      InputProps={{
        endAdornment: !!value ? <InputAdornment position="end">
          <IconButton
            onClick={clean}
            className={classes.clearButton}
          >
            <ClearIcon className={classes.clearIcon}/>
          </IconButton>
        </InputAdornment> : <div/>,
      }}
    />
  )
}

ResettableTextField.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.array,
  label: PropTypes.string
}

export default ResettableTextField