import Worksheet from './Worksheet/ru'
import WorksheetFinance from './WorksheetFinance/ru'
import WorksheetInsurance from './WorksheetInsurance/ru'
import WorksheetNew from './WorksheetNew/ru'
import WorksheetSearch from './WorksheetSearch/ru'
import WorksheetTradeIn from './WorksheetTradeIn/ru'
import WorksheetUsed from './WorksheetUsed/ru'

export default {
  ...Worksheet,
  ...WorksheetFinance,
  ...WorksheetInsurance,
  ...WorksheetNew,
  ...WorksheetSearch,
  ...WorksheetTradeIn,
  ...WorksheetUsed
}