import React from 'react'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import { withStyles } from '@material-ui/core/styles'
import { Button, translate } from 'react-admin'

const styles = {
  button: {
    marginTop: '1em'
  }
}

const AddDepartmentButton = ({ classes, record, translate }) => (
  <Button
    className={classes.button}
    component={Link}
    to={`/departments/create?company=${record.id}`}
    label={translate('resources.departments.add')}
    title={translate('resources.departments.add')}
  >
    <AddIcon/>
  </Button>
)

export default translate(withStyles(styles)(AddDepartmentButton))