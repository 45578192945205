export default {
  employees: {
    add: 'Mitarbeiter hinzufügen',
    name: 'Mitarbeiter |||| Mitarbeiter',
    fields: {
      'companyBranch__company': 'Firma',
      company: 'Firma',
      company_branch: 'Branch',
      department: 'Abteilung',
      job_position: 'Position',
      first_name: 'Vorname',
      last_name: 'Nachname',
      personal_code: 'Personal Nummer',
      cost_center: 'Kostenstelle',
      email_address: 'E-Mail',
      enabled: 'aktiviert',
      phone: 'Telefonnummer',
      gender: 'Geschlecht',
      dob: 'Geburtsdatum'
    },
    tabs: {
      summary: 'Zusammenfassung',
      stats: 'Statistik',
      performance: 'Leistung',
      communication: 'Leistung'
    },
    actions: {
      requestNewPassword: {
        button: 'Neues Passwort',
        message: 'Neues Passwort wurde an die Mitarbeiter-E-Mail gesendet'
      }
    }
  },
}