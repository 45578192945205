import React, { useEffect, useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CustomerContainer from './CustomerContainer'
import { LoadingIndicator, useDataProvider } from 'react-admin'
import PhotosContainer, { rakursList } from '../CarInstance/PhotosContainer'
import CheckContainer from '../CarInstance/Check/CheckContainer'
import CarInstanceContainer from './CarInstanceContainer'
import BeeCarsContainer from './BeeCarsContainer'
import PriceOffers from './PriceOffers'
import Appraisal from './Appraisal'
import DoneIcon from '@material-ui/icons/CheckCircle';
import NotDoneIcon from '@material-ui/icons/RadioButtonUnchecked';
import DocumentsContainer from './Documents/DocumentsContainer'


function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const WorksheetTabs = (props) => {

  const { record } = props

  const dataProvider = useDataProvider()

  const [wsbInterface, setWsbInterface] = React.useState({})
  const [wsbInterfaceLoading, setWsbInterfaceLoading] = React.useState(false)
  const [tab, setTab] = React.useState(0)
  const [carInstance, setCarInstance] = useState({})
  const [carInstanceLoading, setCarInstanceLoading] = useState(false)
  const [priceOffer, setPriceOffer] = useState(0)

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  useEffect(() => {
    setWsbInterfaceLoading(true)
    if (record.interface) {
      dataProvider.getOne('worksheet/worksheet-buy-interfaces', {
        id: record.interface
      })
        .then(({ data }) => {
          setWsbInterface(data)
        })
        .finally(() => {
          setWsbInterfaceLoading(false)
        })

    } else {
      dataProvider.create('worksheet/worksheet-buy-interfaces', {
        data: {
          worksheet: record.id,
          customerTabActive: true
        }
      })
        .then(({ data }) => {
          setWsbInterface(data)
        })
        .finally(() => {
          setWsbInterfaceLoading(false)
        })
    }
  }, [])

  useEffect(() => {
    setCarInstanceLoading(true)
    dataProvider.getOne('car-instances', { id: record.carInstance })
      .then(({ data }) => {
        setCarInstance(data)
      })
      .finally(() => {
        setCarInstanceLoading(false)
      })
  }, [])

  return (
    wsbInterfaceLoading ?
      <LoadingIndicator/> :
      <React.Fragment>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Клиент" icon={wsbInterface.customerCompleted ? <DoneIcon /> : <NotDoneIcon />} disabled={!wsbInterface.customerTabActive}/>
          <Tab label="Документы" icon={wsbInterface.carCompleted ? <DoneIcon /> : <NotDoneIcon />} disabled={!wsbInterface.carTabActive}/>
          <Tab label="Автомобиль" icon={wsbInterface.carCompleted ? <DoneIcon /> : <NotDoneIcon />} disabled={!wsbInterface.carTabActive}/>
          <Tab label="Диагностика" icon={wsbInterface.diagnosticsCompleted ? <DoneIcon /> : <NotDoneIcon />} disabled={!wsbInterface.diagnosticsTabActive}/>
          <Tab label="Фото" icon={wsbInterface.diagnosticsCompleted ? <DoneIcon /> : <NotDoneIcon />} disabled={!wsbInterface.diagnosticsTabActive}/>
          <Tab label="Оценка" icon={wsbInterface.appraisalCompleted ? <DoneIcon /> : <NotDoneIcon />} disabled={!wsbInterface.appraisalTabActive}/>
        </Tabs>
        <TabPanel value={tab} index={0}>
          <CustomerContainer {...props} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <DocumentsContainer carInstance={carInstance} />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          {
            carInstanceLoading ?
              <LoadingIndicator/> :
              <React.Fragment>
                <CarInstanceContainer carInstance={carInstance} onSave={(record) => {setCarInstance(record)}}/>
                <BeeCarsContainer carInstance={carInstance}/>
              </React.Fragment>

          }
        </TabPanel>
        <TabPanel value={tab} index={3}>
          {
            carInstanceLoading ?
              <LoadingIndicator/> :
              <CheckContainer carInstance={carInstance}/>
          }
        </TabPanel>
        <TabPanel value={tab} index={4}>
          {
            carInstanceLoading ?
              <LoadingIndicator/> :
              <PhotosContainer
                carInstance={carInstance}
                filter={(photos) => photos.filter(p => !p.deleted && p.type <= 42)}
                types={rakursList.filter(r => r.type <= 42)}
              />
          }
        </TabPanel>
        <TabPanel value={tab} index={5}>
          <PriceOffers onChange={setPriceOffer} record={record}/>
          <Appraisal priceOffer={priceOffer} record={record}/>
        </TabPanel>
      </React.Fragment>

  )
}

export default WorksheetTabs