import React, { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import PhotoGallery from './PhotoGallery'

const Photos = (props) => {

  const { record } = props

  const dataProvider = useDataProvider()

  const [photos, setPhotos] = useState([])
  const [isPhotosLoading, setPhotosLoading] = useState(false)

  useEffect(() => {
    if (!isPhotosLoading && !photos.length) {
      setPhotosLoading(true)

      dataProvider.getList('crwl/photos', {
        filter: { link: record.id },
        pagination: { perPage: 300 },
        sort: { field: 'id', order: 'ASC' }
      })
        .then(data => {
          if ('auto.ru' === record.src) {
            setPhotos(data.data.map(p => {
              return {
                preview: p.url,
                photo: p.url.replace('small', '1200x900')
              }
            }))
          } else if ('avito.ru' === record.src) {
            setPhotos(data.data.map(p => {
              return {
                preview: p.url.replace('640x480', '75x55'),
                photo: p.url.replace('640x480', '1280x960')
              }
            }))
          } else {
            setPhotos(data.data.map(p => {
              return {
                preview: p.url,
                photo: p.url
              }
            }))
          }
        })
        .catch(e => {
          console.log('e', e)
        })
    }
  })

  return (
    <PhotoGallery photos={photos}/>
  )
}

export default Photos