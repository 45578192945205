export default {
  'workflow/types': {
    name: 'Type de workflow |||| Types de flux de travail',
    fields: {
      name: 'Titre',
      subjectType: 'Objet',
    },
    tabs: {
      summary: 'Propriétés',
      actions: 'Actions'
    },
  },
}