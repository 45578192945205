import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, LoadingIndicator, Query, translate } from 'react-admin'

const WorksheetBuyTaskLink = (props) => {

  const { subjectResourceName, subjectId, tab, translate } = props
  const [url, setUrl] = useState('')
  const [redirect, setRedirect] = useState(false)

  console.log("props", props);
  
  return (
    redirect ? <Redirect to={url} push/> :
      <Query type="getOne" resource={subjectResourceName} payload={{ id: `/${subjectResourceName}/${subjectId}` }}>
        {({ data, loading, error }) => {
          if (loading) {
            return <LoadingIndicator/>
          }
          if (error) {
            return <p>ERROR</p>
          }
          setUrl(`/worksheet/worksheet-buys/${encodeURIComponent(data.id)}/show`)
          return <Button color="primary"
                         onClick={() => setRedirect(true)}
                         label={'Перейти: ' + translate(`resources.${subjectResourceName}.name`, { smart_count: 1 })}/>
        }}
      </Query>
  )

}

export default translate(WorksheetBuyTaskLink)