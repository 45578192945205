export default {
  companies: {
    name: 'Дилер |||| Дилеры',
    fields: {
      name: 'Название',
      enabled: 'Активный',
      blocked: 'Заблокирован',
      is_provider: 'Провайдер',
      type: 'Тип',
      logo: 'Логотип',
      'company-files': 'Документы'
    },
    tabs: {
      summary: 'Данные компании',
      branches: 'Филиалы',
      departments: 'Отделы',
      grades: 'Грейды',
      positions: 'Должности',
      inventory: 'Доступные автомобили',
      files: 'Файлы',
      workflows: 'Процессы',
      surveys: 'Опросы'
    }
  },
}