export default {
  'workflow/transitions': {
    add: 'Beziehung hinzufügen',
    name: 'Beziehung |||| Beziehungen',
    fields: {
      workflow: 'Arbeitsablauf',
      code: 'Code',
      name: 'Titel',
      toPlace: 'Zu',
      jobPositions: 'Berechtigte Positionen',
      fromPlaces: 'Von',
      participant: 'Gegenpartei',
      allowed_for_creator: 'Zulässig für den Ersteller',
      allowed_for_employee: 'Zulässig für den Mitarbeiter in der Anwendung',
      allowed_for_any_previous: 'Zulässig für vorgängigen Berechtigten',
      actions: 'Actions',
      comment_required: 'Kommentar erforderlich',
      document_required: 'Dokument erforderlich'
    },
    alerts: {
      comment_required: 'Bitte fügen Sie dem Antrag den erforderlichen Kommentar hinzu, bevor Sie fortfahren',
      document_required: 'Bitte fügen Sie dem Antrag das erforderliche Dokument hinzu, bevor Sie fortfahren',
    },
    buttons: {
      actionsWithObject: 'Aktionen',
      noActionsAvailable: 'Keine Aktionen verfügbar'
    },
    confirmDialog: {
      title: 'Bitte bestätigen Sie die Aktion',
      body: 'Dies ist der letzte Workflow-Schritt. Bitte bestätigen Sie Ihre Aktion.'
    }
  },
}