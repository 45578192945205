import React, { useEffect, useState } from 'react'
import { useTranslate, useDataProvider } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'

import { StorageFactory } from '../../utils/StorageFactory'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: 600,
  },
  formControl: {
    width: '100%',
    minWidth: 120,
  },
  selectorCell: {
    padding: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  button: {
    margin: 0
  },
}))

const GeoSelect = (props) => {

  const { className, label, options, value, onChange } = props

  return (
    <FormControl className={className}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
      >
        {
          options.map(o => {
            return (
              <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  )

}

const GeoSelectorContainer = (props) => {

  const { handler } = props

  const classes = useStyles()
  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const [region, setRegion] = useState(parseInt(StorageFactory().getItem('geo_region')))
  const [city, setCity] = useState(parseInt(StorageFactory().getItem('geo_city')))
  const [lat, setLat] = useState(StorageFactory().getItem('geo_lat'))
  const [lon, setLon] = useState(StorageFactory().getItem('geo_lon'))
  const [radius, setRadius] = useState(parseInt(StorageFactory().getItem('geo_radius')))

  const [regions, setRegions] = useState([])
  const [cities, setCities] = useState([])

  useEffect(() => {
    dataProvider.getList('geo/regions', {
      filter: {},
      pagination: { perPage: 500 },
      sort: { field: 'name', order: 'ASC' }
    })
      .then(data => {
        setRegions(data.data)
      })

  }, [])

  const selectRegion = (region) => {
    const r = regions.find(r => r.originId === region)
    setRegion(region)
    setCity(null)
    setLat(r['lat'])
    setLon(r['lon'])

    StorageFactory().setItem('geo_region', region)
    StorageFactory().setItem('geo_city', null)
    StorageFactory().setItem('geo_lat', r['lat'])
    StorageFactory().setItem('geo_lon', r['lon'])
  }

  useEffect(() => {
    if (region > 0 && regions.length > 0) {
      const r = regions.find(r => r.originId === region)
      dataProvider.getList('geo/cities', {
        filter: { region: r['id'] },
        pagination: { perPage: 300 },
        sort: { field: 'name', order: 'ASC' }
      })
        .then(data => {
          setCities(data.data)
        })
    }
  }, [region, regions])

  const selectCity = (city) => {
    const c = cities.find(c => c.originId === city)
    setCity(city)
    setLat(c['lat'])
    setLon(c['lon'])

    StorageFactory().setItem('geo_city', city)
    StorageFactory().setItem('geo_lat', c['lat'])
    StorageFactory().setItem('geo_lon', c['lon'])
  }

  const selectRadius = (radius) => {
    setRadius(radius)
    StorageFactory().setItem('geo_radius', radius)
  }

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.root}
    >
      <Grid item md={6} xs={12} className={classes.selectorCell}>
        <GeoSelect
          className={classes.formControl}
          label={translate('resources.crwl/links.fields.region')}
          value={region}
          options={regions.map(r => ({ id: r.originId, name: r.name }))}
          onChange={(event) => selectRegion(event.target.value)}
        />
      </Grid>
      <Grid item md={6} xs={12} className={classes.selectorCell}>

        <GeoSelect
          className={classes.formControl}
          label={translate('resources.crwl/links.fields.city')}
          value={city}
          options={cities.map(r => ({ id: r.originId, name: r.name }))}
          onChange={(event) => selectCity(event.target.value)}
        />
      </Grid>
      <Grid item md={6} xs={12} className={classes.selectorCell}>
        <GeoSelect
          className={classes.formControl}
          label={translate('resources.crwl/links.fields.radius')}
          value={radius}
          options={[100, 200, 300, 400, 500, 1000].map(r => ({
            id: r,
            name: `+${r} ${translate('resources.crwl/links.measure.mileage')}`
          }))}
          onChange={(event) => selectRadius(event.target.value)}
        />
      </Grid>
      <Grid item md={6} xs={12} className={classes.selectorCell}>
        <FormControl>
          <Button variant="outlined" className={classes.formControl} onClick={() => {
            handler({ lat, lon, radius })
          }}>
            {translate('ra.action.refresh')}
          </Button>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default GeoSelectorContainer
