export default {
  'workflow/actions': {
    add: 'Добавить действие',
    name: 'Действие |||| Действия',
    fields: {
      workflowType: 'Тип',
      name: 'Название',
      commandName: 'Генерируемая комманда',
      workflowEventName: 'На какое событие срабатывает'
    }
  },
}