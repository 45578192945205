import React from 'react'
import { MenuItemLink, useTranslate } from 'react-admin'

import { isGranted, ROLE_EMPLOYEE } from '../../utils/AuthUtils'
import CalendarIcon from './CalendarIcon'

//@TODO сделать через mercury
const Menu = (props) => {

  const translate = useTranslate()

  return (
    isGranted(ROLE_EMPLOYEE) &&
    <MenuItemLink
      to={'/calendar-events/events'}
      primaryText={translate('menu.calendar.name')}
      leftIcon={<CalendarIcon/>}
    />
  )
}

export default Menu