import React from 'react'
import {
  Edit,
  required,
  SimpleForm,
  SelectInput,
  TextInput,
  translate
} from 'react-admin'

const Title = ({ record }) => {
  return <>{record ? record.name : ''}</>
}

export const FilterEdit = translate(({ translate, ...props }) => (
  <Edit title={<Title/>} {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={required()}
      />
      <SelectInput source="notificationPeriod" choices={[
        { id: 'hourly', name: translate('resources.crwl/filters.notificationPeriods.hourly') },
        { id: '4hourly', name: translate('resources.crwl/filters.notificationPeriods.4hourly') },
        { id: 'daily', name: translate('resources.crwl/filters.notificationPeriods.daily') },
        { id: 'weekly', name: translate('resources.crwl/filters.notificationPeriods.weekly') },
        { id: 'never', name: translate('resources.crwl/filters.notificationPeriods.never') },
      ]}/>

    </SimpleForm>
  </Edit>
))