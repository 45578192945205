import React from 'react'
import { compose } from 'recompose'
import { List } from 'react-admin'
import CalendarView from './CalendarView'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  calendarContainer: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

})

const EventList = (props) => {
  const { classes, history } = props

  return (
    <List {...props} exporter={false} pagination={false}>
      <div className={classes.calendarContainer}>
        <CalendarView/>
      </div>
    </List>
  )
}

export default compose(
  withStyles(styles)
)(EventList)