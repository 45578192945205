export default {
  'dimples': {
    name: 'Повреждение |||| Повреждения',
    fields: {},
    buttons: {
      add: 'Добавить комментарий'
    },
    dialogs: {
      delete: {
        title: 'Удалить повреждение?',
        message: 'Восстановить удаленное повреждение нельзя'
      }
    },
    notifications: {
      deleted: 'Комментарий удален'
    }

  },
}