import React from 'react'
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin'
import { parse } from 'query-string'

//https://symfony.com/doc/current/workflow.html#using-events

export const ActionCreate = props => {
  const { type: type_id_string } = parse(props.location.search)
  const type = type_id_string
  const redirect = type ? `/workflow/types/${encodeURIComponent(type)}/actions` : false

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ workflowType: type }}
        redirect={redirect}
      >
        <TextInput source="name"/>
        <SelectInput source="workflowEventName" choices={[
          { id: 'workflow.guard', name: 'Guard event' },
          { id: 'workflow.leave', name: 'Leave event' },
          { id: 'workflow.transition', name: 'Transition event' },
          { id: 'workflow.enter', name: 'Enter event' },
          { id: 'workflow.entered', name: 'Entered event' },
          { id: 'workflow.completed', name: 'Completed event' },
        ]}/>
        <TextInput source="commandName"/>
      </SimpleForm>
    </Create>
  )
}