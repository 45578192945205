export default {
  'postal-addresses': {
    name: 'Адрес',
    fields: {
      addressCountry: 'Страна',
      addressLocality: 'Город',
      addressRegion: 'Регион',
      postalCode: 'Индекс',
      streetAddress: 'Улица, дом, офис'
    }
  },
}