import React from 'react'
import { ReferenceInput, Create, SimpleForm, TextInput, Toolbar, SaveButton, SelectInput } from 'react-admin'

const EventTypeTitle = ({ record }) => {
  return <span>{record ? record.title : ''}</span>
}

const EventTypeCreate = props => {

  return (
    <Create {...props} title={<EventTypeTitle/>}>
      <SimpleForm redirect="list">
        <ReferenceInput source="subjectType" reference="workflow/subject-types">
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <TextInput source="name"/>
      </SimpleForm>
    </Create>
  )
}

export default EventTypeCreate