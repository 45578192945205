import React from 'react'
import { Resource } from 'react-admin'
import { isGranted } from '../../utils/AuthUtils'
import { TypeList } from './TypeList'
import { TypeCreate } from './TypeCreate'
import TypeEdit from './TypeEdit'
import { WorkflowList } from './WorkflowList'
import { WorkflowCreate } from './WorkflowCreate'
import WorkflowEdit from './WorkflowEdit'
import { SubjectTypeList } from './SubjectTypeList'
import { SubjectTypeCreate } from './SubjectTypeCreate'
import { SubjectTypeEdit } from './SubjectTypeEdit'
import { ParticipantList } from './ParticipantList'
import { ParticipantCreate } from './ParticipantCreate'
import ParticipantEdit from './ParticipantEdit'
import { PlaceList } from './PlaceList'
import { PlaceCreate } from './PlaceCreate'
import { PlaceEdit } from './PlaceEdit'
import { TransitionList } from './TransitionList'
import { TransitionCreate } from './TransitionCreate'
import { TransitionEdit } from './TransitionEdit'
import { ActionList } from './ActionList'
import { ActionCreate } from './ActionCreate'
import { ActionEdit } from './ActionEdit'
import { RequestWorkflowCreate } from './RequestCopyWorkflowCreate'

export default permissions => [
  <Resource
    name="workflow/actions"
    list={isGranted('ROLE_ADMIN', permissions) ? ActionList : null}
    create={isGranted('ROLE_ADMIN', permissions) ? ActionCreate : null}
    edit={isGranted('ROLE_ADMIN', permissions) ? ActionEdit : null}
  />,

  <Resource
    name="workflow/subject-types"
    list={isGranted('ROLE_ADMIN', permissions) ? SubjectTypeList : null}
    create={isGranted('ROLE_ADMIN', permissions) ? SubjectTypeCreate : null}
    edit={isGranted('ROLE_ADMIN', permissions) ? SubjectTypeEdit : null}
  />,

  <Resource
    name="workflow/types"
    list={isGranted('ROLE_ADMIN', permissions) ? TypeList : null}
    create={isGranted('ROLE_ADMIN', permissions) ? TypeCreate : null}
    edit={isGranted('ROLE_ADMIN', permissions) ? TypeEdit : null}
  />,

  <Resource
    name="workflow/workflows"
    list={isGranted('ROLE_COMPANY_ADMIN', permissions) ? WorkflowList : null}
    create={isGranted('ROLE_COMPANY_ADMIN', permissions) ? WorkflowCreate : null}
    edit={isGranted('ROLE_COMPANY_ADMIN', permissions) ? WorkflowEdit : null}
  />,

  <Resource
    name="workflow/participants"
    list={isGranted('ROLE_COMPANY_ADMIN', permissions) ? ParticipantList : null}
    create={isGranted('ROLE_COMPANY_ADMIN', permissions) ? ParticipantCreate : null}
    edit={isGranted('ROLE_COMPANY_ADMIN', permissions) ? ParticipantEdit : null}
  />,

  <Resource
    name="workflow/places"
    list={isGranted('ROLE_COMPANY_ADMIN', permissions) ? PlaceList : null}
    create={isGranted('ROLE_COMPANY_ADMIN', permissions) ? PlaceCreate : null}
    edit={isGranted('ROLE_COMPANY_ADMIN', permissions) ? PlaceEdit : null}
  />,

  <Resource
    name="workflow/transitions"
    list={isGranted('ROLE_COMPANY_ADMIN', permissions) ? TransitionList : null}
    create={isGranted('ROLE_COMPANY_ADMIN', permissions) ? TransitionCreate : null}
    edit={isGranted('ROLE_COMPANY_ADMIN', permissions) ? TransitionEdit : null}
  />,

  <Resource
    name="workflow/request-copy-workflow"
    create={RequestWorkflowCreate}
  />

];