import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Fade from '@material-ui/core/Fade'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    maxWidth: '100vw',
    maxHeight: '100vh',
    height: 'inherit !important',
    cursor: 'pointer'
  },
  fullImg: {
    top: '50%',
    left: '50%',
    marginRight: '50%'
  },
  fitContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  pinchContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      zIndex: 100,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    position: 'fixed',
    right: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 1200,
  }
})

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Fade direction="up" ref={ref} {...props} />
})

const FullScreenPhoto = (props) => {
  const { classes, open, url, onClose } = props

  const [isOrigSize, setOrigSize] = useState(false)

  const handleFullScreen = () => {
    setOrigSize(!isOrigSize)
  }

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition} className={classes.root}>
      <IconButton className={classes.close} onClick={onClose}>
        <CloseIcon fontSize="large"/>
      </IconButton>
      {!isOrigSize ?
        <div className={classes.fitContainer}>
          <img
            src={url}
            className={classes.img}
            onClick={handleFullScreen}
            alt={''}
          />
        </div>
        :
        <div className={classes.pinchContainer}>
          <img src={url} onClick={handleFullScreen}/>
        </div>}
    </Dialog>
  )
}

export default withStyles(styles)(FullScreenPhoto)