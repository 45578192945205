export default {
  'questionnaire/questionnaires': {
    name: 'Questionnaire |||| Questionnaires',
    fields: {
      name: 'Titre',
      enabled: 'activé',
      createdAt: 'Créé à',
      numberOfQuestions: 'Nombre de questions',
    },
    tabs: {
      summary: 'Données générales',
      questions: 'Des questions',
    }
  },
}