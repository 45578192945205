export default {
  'postal-addresses': {
    name: 'Адрес',
    fields: {
      addressCountry: 'Pays',
      addressLocality: 'City',
      addressRegion: 'Région',
      postalCode: 'Code postal',
      streetAddress: 'Adresse postale'
    }
  },
}