import React from 'react'
import { BooleanField, Datagrid, DeleteButton, ReferenceManyField, TextField, Pagination } from 'react-admin'

export const PlaceList = props => {
  return (
    <ReferenceManyField
      reference="workflow/places"
      target="workflow"
      addLabel={false}
      pagination={<Pagination/>}
      sort={{ field: 'code', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="code"/>
        <TextField source="name"/>
        <BooleanField source="isInitial"/>
        <BooleanField source="isSuccess"/>
        <BooleanField source="isFail"/>
        <TextField source="maxMinutesInPlace"/>
        <DeleteButton label="" redirect={false}/>
      </Datagrid>
    </ReferenceManyField>
  )
}