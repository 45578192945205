import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import UserIcon from '@material-ui/icons/PermIdentity'
import { getSecuredFileURL } from '../../utils/FilesUtils'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'

const AssigneeItem = (props) => {
  const { id, user, firstName, lastName, emailAddress, avatar_id, jobPosition, company, branches, department, onSelect, selected } = props

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar src={avatar_id && getSecuredFileURL(`/avatar/${avatar_id}`)}>
          <UserIcon/>
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={<a href={`mailto: ${emailAddress}`}>{`${firstName} ${lastName}`}</a>}
                    secondary={
                      <React.Fragment>
                        <Typography variant="subtitle2">{company} ({branches.join(', ')})</Typography>
                        <Typography variant="subtitle2">{jobPosition} ({department})</Typography>
                      </React.Fragment>
                    }/>
      <ListItemSecondaryAction>
        <Checkbox
          edge="end"
          onChange={() => onSelect(user, `${firstName} ${lastName}`)}
          checked={selected}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )

}

AssigneeItem.propTypes = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  jobPosition: PropTypes.string,
  emailAddress: PropTypes.string,
  avatar_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default AssigneeItem