import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'
import blue from '@material-ui/core/colors/blue'

const rowStyle = (record, index, defaultStyle = {}) => {
  const isOverDue = record.due ? (new Date()) > (new Date(record.due)) : false
  const isNew = record.isNew
  const isCompleted = record.completed
  let style = defaultStyle

  if (isOverDue && !isCompleted)
    style = {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
      backgroundColor: red[50],
    }

  if (isNew)
    style = {
      ...style,
      borderLeftColor: blue[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }

  if (isNew && !isOverDue) {
    style = {
      ...style,
      backgroundColor: blue[50],
    }
  }

  return style
}

export default rowStyle