export default {
  'calendar-events/events': {
    name: 'Событие  |||| События',
    fields: {
      createdAt: 'Создано',
      createdBy: 'Автор',
      type: 'Тип события',
      description: 'Описание',
      startAt: 'Начало',
      finishAt: 'Окончание',
      period: 'Время события'
    },
    buttons: {
      add: 'Запланировать событие',
      delete: 'Удалить',
      showCalendar: 'Показать календарь',
      hideCalendar: 'Скрыть календарь'
    },
    dialogs: {
      create: {
        title: 'Запланировать событие'
      },
    },
    tabs: {},
    errors: {
      periodRequired: 'Укажите время события',
      finishEarlieStart: 'Время окончания раньше времени начала',
      typeRequired: 'Укажите тип события'
    }
  },
}