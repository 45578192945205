export default {
  'questionnaire/groups': {
    add: 'Добавить группу вопросов',
    name: 'Группа |||| Группы',
    fields: {
      name: 'Название группы',
      questionnaire: 'Опрос',
      ord: 'Порядковый номер',
      enabled: 'Активная',
      numberOfQuestions: 'Вопросы',
    }
  }
}