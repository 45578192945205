export default {
  'job-positions': {
    add: 'Add job position',
    name: 'Position |||| Positions',
    fields: {
      name: 'Position\'s title',
      company: 'Company',
      department: 'Department',
      jobGrade: 'Grade',
      roles: 'Role',
      applicable_for_the_car: 'Allowed for the car',
      enabled: 'Enabled',
    }
  },
}