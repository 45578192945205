import React from 'react'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import { withStyles } from '@material-ui/core/styles'
import { Button, translate } from 'react-admin'

const styles = {
  button: {
    marginTop: '1em'
  }
}

const AddWorkflowButton = ({ classes, record, translate }) => (
  <React.Fragment>
    <Button
      className={classes.button}
      component={Link}
      to={`/workflow/request-copy-workflow/create?company=${record.id}`}
      label={translate('resources.workflow/request-copy-workflow.buttons.create')}
      title={translate('resources.workflow/request-copy-workflow.buttons.create')}
    >
      <AddIcon/>
    </Button>
  </React.Fragment>
)

export default translate(withStyles(styles)(AddWorkflowButton))