import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { blue } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionActions from '@material-ui/core/AccordionActions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LinkSearchField from './Fields/LinkSearchField'
import BrandField from './Fields/BrandField'
import ModelField from './Fields/ModelField'
import GenerationField from './Fields/GenerationField'
import BodyTypeField from './Fields/BodyTypeField'
import GearboxTypeField from './Fields/GearboxTypeField'
import EngineTypeField from './Fields/EngineTypeField'
import DriveTypeField from './Fields/DriveTypeField'
import EngineSizeField from './Fields/EngineSizeField'
import YearField from './Fields/YearField'
import RegionField from './Fields/RegionField'
import CityField from './Fields/CityField'
import DistanceField from './Fields/DistanceField'
import SrcField from './Fields/SrcField'
import MileageField from './Fields/MileageField'
import PriceField from './Fields/PriceField'
import SaveFilterButton from './SaveFilterButton'
import { StorageFactory } from '../../../utils/StorageFactory'
import TagField from './Fields/TagField'

const useStyles = makeStyles(theme => createStyles({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  actions: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button: {
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  }
}))

const Filter = (props) => {

  const { context, setFilters, filterValues } = props

  const classes = useStyles()
  const translate = useTranslate()

  const [clearedSrcId, setSrcCleared] = useState(false)

  const [values, setValues] = useState(filterValues.hasOwnProperty('crwlFilter') ? JSON.parse(filterValues['crwlFilter']) : {})
  const [expanded, setExpanded] = useState(true)

  const [yearFrom, setYearFrom] = useState(new Date().getFullYear() - 20);
  const [yearTo, setYearTo]= useState(new Date().getFullYear());

  const getYears = (yearFrom, yearTo) => {
    return new Array(yearTo - yearFrom + 1)
      .fill(1)
      .reduce((obj, item, index) => {
        obj[yearTo - index] = yearTo - index
        return obj
      }, {});
  }

  const resetYears = () => {
    setYearFrom(new Date().getFullYear() - 20)
    setYearTo(new Date().getFullYear())
  }

  const setValue = (key, value) => {
    if ((Array.isArray(value) && !value.length) || '' === value || !value) {
      const newValues = Object.keys(values).reduce((obj, item) =>
          (item === key) ? obj : { ...obj, [item]: values[item] }
        , {})
      setValues(newValues)
    } else {
      setValues({ ...values, [key]: value })
    }
  }

  const getValue = (key, nullValue) => {
    return values.hasOwnProperty(key) ? values[key] : nullValue
  }

  const applyFilter = () => {
    const lat = StorageFactory().getItem('geo_lat')
    const lon = StorageFactory().getItem('geo_lon')
    const radius = StorageFactory().getItem('geo_radius') ? StorageFactory().getItem('geo_radius') : 100
    const spatialFilter = 'null' !== lat ? { lat, lon, radius } : {}
    setFilters({ crwlFilter: JSON.stringify(values), sellerPoint: JSON.stringify(spatialFilter) })
  }

  const clearFilter = () => {
    setValues({})
    setFilters({})
  }

  const onLinksSearchSubmit = (linkId) => {
    setValues({})
    setFilters({ srcId: linkId })
  }

  useEffect(() => {
    if ('form' === context && filterValues.hasOwnProperty('srcId') && !clearedSrcId) {
      clearFilter()
    }
    if ('form' === context) {
      setSrcCleared(true)
    }
  }, [context])

  return 'form' === context ? (
    <div className={classes.root}>

      <LinkSearchField onSubmit={onLinksSearchSubmit}/>

      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}><Box
            color="info.main">{translate('resources.crwl/links.filter.title')}</Box></Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6} sm={2}>
                <SrcField
                  onChange={(value) => setValue('src', value)}
                  initialValues={getValue('src', [])}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <BrandField
                  onChange={(value) => {
                    setValues({
                      ...values, ...{
                        'brand': value,
                        'model': null,
                        'generation': null
                      }
                    })
                    resetYears()
                  }}
                  initialValue={getValue('brand', '')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ModelField
                  onChange={(value) => {
                    setValues({
                      ...values, ...{
                        'model': value,
                        'generation': null
                      }
                    })
                    resetYears()
                  }}
                  brand={values['brand']}
                  initialValue={getValue('model', '')}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <GenerationField
                  onChange={(value) => setValue('generation', value)}
                  brand={values['brand']}
                  model={values['model']}
                  initialValue={getValue('generation', '')}
                  onOptionSelected={(generation) => {
                    if (generation) {
                      setYearFrom(generation.yearFrom)
                      setYearTo(generation.yearTo)
                    }
                    else {
                      resetYears();
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6} sm={2}>
                <BodyTypeField
                  onChange={(value) => setValue('bodyType', value)}
                  initialValues={getValue('bodyType', [])}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <GearboxTypeField
                  onChange={(value) => setValue('gearbox', value)}
                  initialValues={getValue('gearbox', [])}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <EngineTypeField
                  onChange={(value) => setValue('engineType', value)}
                  initialValues={getValue('engineType', [])}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <DriveTypeField
                  onChange={(value) => setValue('driveType', value)}
                  initialValues={getValue('driveType', [])}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <EngineSizeField
                  onChange={(value) => setValue('engineSizeFrom', value)}
                  type='from'
                  initialValue={getValue('engineSizeFrom', '')}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <EngineSizeField
                  onChange={(value) => setValue('engineSizeTo', value)}
                  type='to'
                  initialValue={getValue('engineSizeTo', '')}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <YearField
                  onChange={(value) => setValue('yearFrom', value)}
                  type='from'
                  years={getYears(yearFrom, yearTo)}
                  initialValue={getValue('yearFrom', '')}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <YearField
                  onChange={(value) => setValue('yearTo', value)}
                  type='to'
                  years={getYears(yearFrom, yearTo)}
                  initialValue={getValue('yearTo', '')}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <MileageField
                  onChange={(value) => setValue('mileageFrom', value)}
                  type='from'
                  initialValue={getValue('mileageFrom', '')}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <MileageField
                  onChange={(value) => setValue('mileageTo', value)}
                  type='to'
                  initialValue={getValue('mileageTo', '')}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <PriceField
                  onChange={(value) => setValue('priceFrom', value)}
                  type='from'
                  initialValue={getValue('priceFrom', '')}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <PriceField
                  onChange={(value) => setValue('priceTo', value)}
                  type='to'
                  initialValue={getValue('priceTo', '')}
                />
              </Grid>

            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} sm={4}>
                <RegionField onChange={(value) => setValue('region', value)}/>
              </Grid>

              <Grid item xs={12} sm={4}>
                <CityField region={getValue('region', StorageFactory().getItem('geo_region'))}/>
              </Grid>

              <Grid item xs={6} sm={2}>
                <DistanceField/>
              </Grid>

              <Grid item xs={6} sm={2}>
                <TagField
                  onChange={(value) => setValue('tags', value)}
                  initialValues={getValue('tags', [])}
                />
              </Grid>
            </Grid>
          </Grid>


        </AccordionDetails>


        <AccordionActions className={classes.actions}>
          <Button variant="contained" color="primary" className={classes.button} onClick={applyFilter}>
            {translate('resources.crwl/links.filter.button')}
          </Button>

          {
            Object.keys(values).length > 0 &&
            <Button variant="contained" color="secondary" className={classes.button}
                    onClick={clearFilter}>
              {translate('resources.crwl/links.actions.clearFilter')}
            </Button>
          }

          {
            Object.keys(values).length > 0 &&
            <SaveFilterButton filterValues={values} className={classes.button}/>
          }
        </AccordionActions>
      </Accordion>
    </div>
  ) : <div/>
}

export default Filter