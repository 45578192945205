import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { useTranslate } from 'react-admin'

const SingleSelectField = (props) => {

  const { initialValue, onChange, options, label, error = false, variant = 'outlined' } = props

  const translate = useTranslate()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const id = `multipleSelectFieldLabel${Math.random() * 1000}`

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl
      fullWidth
      variant={variant}
      error={error}
    >
      <InputLabel ref={inputLabel} id={id}>
        {translate(label)}
      </InputLabel>
      <Select
        labelId={id}
        id={`${id}sel`}
        value={initialValue}
        onChange={handleChange}
        renderValue={val => translate(options[val])}
        labelWidth={labelWidth}
      >
        <MenuItem value={null} />
        {Object.keys(options).map(code => (
          <MenuItem key={code} value={code}>{options[code]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SingleSelectField.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.array,
  options: PropTypes.object,
  label: PropTypes.string
}

export default SingleSelectField