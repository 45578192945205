export default {
  'questionnaire/questionnaires': {
    name: 'Опрос |||| Опросы',
    fields: {
      name: 'Название',
      enabled: 'Активный',
      createdAt: 'Создан',
      numberOfQuestions: 'Вопросы',
    },
    tabs: {
      summary: 'Данные опроса',
      questions: 'Вопросы',
    }
  },
}