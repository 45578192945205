export default {
  'app.auth.form.title.login': 'Sign in',
  'app.auth.form.title.register': 'Register',
  'app.auth.form.title.password-recovery': 'Password recovery',
  'app.auth.form.description.password-recovery': 'We have sent a code to your email. Fill the code and a new password.',
  'app.auth.form.description.passwordless.code': 'We have sent a code to your email. Fill it in.',
  'app.auth.form.link.register': 'Register',
  'app.auth.form.link.forgot-password': 'Forgot password?',
  'app.auth.form.link.email': 'One time code sign in',
  'app.auth.form.link.login': 'Password sign in',
  'app.auth.form.link.logout': 'Logout',
  'app.auth.form.email': 'Email',
  'app.auth.form.code': 'Code from the email',
  'app.auth.form.firstName': 'First name',
  'app.auth.form.lastName': 'Last name',
  'app.auth.form.password': 'Password',
  'app.auth.form.password.new': 'New password',
  'app.auth.form.password.confirm': 'Confirm password',
  'app.auth.form.password.changed': 'Password habe been changed',
  'app.auth.form.signin': 'Sign in',
  'app.auth.form.validation.invalid.email': 'Invalid email',
  'app.auth.form.email.not.found': 'Email not found',
  'app.auth.form.back': 'Back',
  'app.auth.form.register': 'Register',
  'app.auth.error.bad.credentials': 'Invalid email or password',
  'app.form.error.passwords.do.not.match': 'Passwords doesn\'t match',
  'app.form.error.passwords.empty': 'Password can\'t be empty',
  'app.form.error.code.empty': 'Code can\'t be empty',
  'app.form.error.code': 'Invalid code',
}