export default {
  'company-branches': {
    add: 'Branche hinzufügen',
    name: 'Branche |||| Branchen',
    fields: {
      name: 'Branche Titel',
      company: 'Firma',
      enabled: 'aktiviert',
      blocked: 'Gesperrt',
      postalAddress: 'Adresse',
    }
  },
}