import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import { DashboardMenuItem, } from 'react-admin'

import SettingsMenu from './Menu/Settings'
import TasksMenu from './Menu/Tasks'
import CalendarMenu from './Menu/Calendar'
import MarketMenu from './Menu/Market'
import WorksheetBuyMenu from './Menu/WorksheetBuy'

const Menu = ({ onMenuClick, logout }) => {

  const [submenus, setSubmenus] = useState({})

  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)

  const handleToggle = (item) => {

    if (submenus[item]) {
      const newSubmenus = { ...submenus }
      delete newSubmenus[item]
      setSubmenus(newSubmenus)
    } else {
      setSubmenus({ ...submenus, [item]: true })
    }
  }

  return (
    <div>
      {' '}
      <DashboardMenuItem onClick={onMenuClick}/>

      <WorksheetBuyMenu/>

      <MarketMenu
        isSidebarOpen={open}
        onMenuItemClick={onMenuClick}
        isSubmenuOpen={submenus['market']}
        toggleSubMenu={() => handleToggle('market')}
      />

      <TasksMenu/>
      <CalendarMenu/>

      <SettingsMenu
        isSidebarOpen={open}
        onMenuItemClick={onMenuClick}
        isSubmenuOpen={submenus['settings']}
        toggleSubMenu={() => handleToggle('settings')}
      />

      {isXSmall && logout}
    </div>
  )
}

const enhance = compose(
  withRouter,
)

export default enhance(Menu)