export default {
  'crwl/filters': {
    name: 'Фильтр |||| Фильтры',
    fields: {
      name: 'Название',
      notificationPeriod: 'Получать уведомления',
    },
    notificationPeriods: {
      hourly: 'Каждый час',
      '4hourly': 'Каждые 4 часа',
      daily: 'Раз в день',
      weekly: 'Раз в неделю',
      never: 'Не получать уведомления'
    },
    button: 'Параметры поиска',
    hide: 'Скрыть'
  },
}