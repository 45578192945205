export default {
  'workflow/subject-types': {
    name: 'Тип объекта |||| Типы объектов',
    fields: {
      name: 'Название',
      className: 'Класс объекта',
      resourceName: 'Имя ресурса',
      eventTypes: 'Типы календарных событий',
    }
  },
}