import React, { useEffect, useState } from 'react'
import {
  AutocompleteInput,
  Create,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  required,
  SimpleForm,
  useDataProvider,
} from 'react-admin'
import { parse } from 'query-string'

const WorksheetCreate = props => {

  const { carInstance: carInstance } = parse(props.location.search)

  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        {
          carInstance &&
          <ReferenceInput
            source="carInstance"
            reference="car-instances"
            filter={carInstance ? { id: carInstance } : {}}
            validate={required()}
          >
            <SelectInput optionText={
              carInstance => `${carInstance.brandName} ${carInstance.modelName}`
            }/>
          </ReferenceInput>
        }
        <ReferenceInput
          source="company"
          reference="companies"
          validate={required()}
          filter={{ isProvider: false }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText="name" allowEmpty={false}/>
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            <ReferenceInput
              source="companyBranch"
              reference="company-branches"
              validate={required()}
              filter={{ company: formData.company }}
              sort={{ field: 'name', order: 'ASC' }}
              perPage={500}
              {...rest}
            >
              <AutocompleteInput optionText="name" allowEmpty={false}/>
            </ReferenceInput>
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            <ReferenceInput
              source="workflow"
              reference="workflow/workflows"
              validate={required()}
              filter={{ enabled: true, company: formData.company || 0, 'type.subjectType.resourceName': 'worksheet/worksheet-buys' }}
              sort={{ field: 'name', order: 'ASC' }}
              {...rest}
            >
              <SelectInput optionText="name"/>
            </ReferenceInput>
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default WorksheetCreate