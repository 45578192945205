export default {
  'app.auth.form.title.login': 'Connecter',
  'app.auth.form.title.register': 'Enregistrer',
  'app.auth.form.title.password-recovery': 'Réinitialiser le mot de passe',
  'app.auth.form.description.password-recovery': 'Nous avons envoyé un code à votre email. Remplissez le code et un nouveau mot de passe.',
  'app.auth.form.description.passwordless.code': 'Nous avons envoyé un code à votre email. Remplissez le code',
  'app.auth.form.link.register': 'Enregistrer',
  'app.auth.form.link.forgot-password': 'Oublié mot de passe',
  'app.auth.form.link.email': 'Code à usage unique pour se connecter',
  'app.auth.form.link.login': 'Mot de passe de connexion',
  'app.auth.form.link.logout': 'Vous déconnecter',
  'app.auth.form.email': 'E-mail',
  'app.auth.form.code': 'Code d\' e-mail',
  'app.auth.form.firstName': 'Prénom',
  'app.auth.form.lastName': 'Nom de famille',
  'app.auth.form.password': 'Mot de passe',
  'app.auth.form.password.new': 'Nouveau mot de passe',
  'app.auth.form.password.confirm': 'Confirmer le mot de passe',
  'app.auth.form.password.changed': 'Le mot de passe a été changé',
  'app.auth.form.signin': 'Connecter',
  'app.auth.form.validation.invalid.email': 'E-Mail invalide',
  'app.auth.form.email.not.found': 'E-mail non trouvé',
  'app.auth.form.back': 'Retour',
  'app.auth.form.register': 'Enregistrer',
  'app.auth.error.bad.credentials': 'E-mail ou mot de passe invalide',
  'app.form.error.passwords.do.not.match': 'Le mot de passe est faux',
  'app.form.error.passwords.empty': 'Le mot de passe ne peut pas être vide',
  'app.form.error.code.empty': 'Le code ne peut pas être vide',
  'app.form.error.code': 'Code invalide',
}