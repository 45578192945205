import React, { useState } from 'react'
import { useTranslate, useDataProvider, LoadingIndicator, linkToRecord } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 140,
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  btn: {
    marginBottom: theme.spacing(),
  }

}))

const CreateFromCRWLButton = (props) => {

  const classes = useStyles()

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const [loading, setLoading] = useState(false)
  const { link, history } = props

  const handleClick = () => {
    setLoading(true)
    dataProvider.create('car-instances', {
      data: {
        'revision': 0,
        'vin': link.vin ?? '',
        'productionYear': link.year,
        'brandId': 0,
        'brandName': link.brand,
        'modelName': link.model,
        'generationName': link.generation || '',
        'modificationName': link.version,
        'complectationName': '',
        'bodyType': link.bodyType,
        'engineSize': link.engineSize,
        'hp': link.hp,
        'driveType': link.driveType,
        'engineType': link.engineType,
        'gearbox': link.gearbox,
        'steeringWheelPosition': link.steeringWheel,
        'color': link.color,
        'isColorMetallic': false,
        'firstRegistrationYear': 0,
        'numOwners': link.ownersNumber,
        'mileage': link.mileage,
        'crwlId': link.originId
      }
    })
      .then(response => {
        const record = response.data
        dataProvider.create('worksheet/worksheet-buys', {
          data: {
            carInstance: record.id
          }})
          .then(response => {
            const record = response.data
            history.push(linkToRecord('/worksheet/worksheet-buys', record.id, 'show'));
          })

      })
      .catch(error => {
        console.log('error', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className={classes.btn}>
      <React.Fragment>
        {
          loading ? <LoadingIndicator/> :
            <Button size="small" color="primary" onClick={handleClick}>
              <AddIcon className={classes.leftIcon}/>
              {translate('resources.worksheet/worksheet-buys.buttons.add')}
            </Button>
        }

      </React.Fragment>
    </div>
  )
}

export default CreateFromCRWLButton