import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

const Question = ({ question, onYes, onNo, onDontKnow }) => {

  const classes = useStyles()

  if (null === question) {
    return <div/>
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Укажите установлено ли следующее заводское оборудование
        </Typography>
        <Typography variant="h5" component="h2">
          {question.question}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onYes}>Установлено</Button>
        <Button size="small" onClick={onNo}>Не установлено</Button>
        <Button size="small" onClick={onDontKnow}>Не знаю</Button>
      </CardActions>
    </Card>
  )
}

export default Question