import React, { useEffect, useState } from 'react'
import {
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  DeleteButton,
  useLocale,
  useTranslate,
  TextField
} from 'react-admin'
import LinksDataGrid from './LinksDataGrid'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { color, formatDate } from './LinksList'
import { makeStyles } from '@material-ui/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import FavoritesExpander from './FavoritesExpander'
import DeleteIcon from '@material-ui/icons/Favorite'

const useStyles = makeStyles(theme => ({
  listItemRoot: {
    paddingLeft: 0,
  },
  inline: {
    display: 'inline',
  },
  rightAligned: {
    textAlign: 'right'
  },
  avatar: {
    border: `1px solid ${theme.palette.grey['200']}`,
    width: '24px',
    height: '24px'
  },
  imgContainer: {
    width: 120,
    height: 90,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
}))

const LinksList = (props) => {

  const classes = useStyles()
  const translate = useTranslate()
  const locale = useLocale()

  return (
    <List
      bulkActionButtons={false}
      exporter={false}
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid expand={<FavoritesExpander/>} rowClick={'expand'}>

        <FunctionField
          source="linkData"
          label=""
          render={record => {
            return record.linkData.photoUrls.length ?
              <div className={classes.imgContainer}
                   style={{ backgroundImage: `url(${record.linkData.photoUrls[0]})` }}
              /> : <div/>
          }}
        />

        <FunctionField
          label="resources.crwl/links.fields.createdAt"
          sortBy="id"
          render={record => (
            <ListItem alignItems="flex-start" className={classes.listItemRoot}>
              <ListItemText
                primary={`${record.linkData.src}`}
                secondary={formatDate(record.createdAt, locale, translate)}
                secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
              />
            </ListItem>
          )}
        />

        <FunctionField
          label="resources.crwl/links.fields.brand"
          render={record => (
            <ListItem alignItems="flex-start" className={classes.listItemRoot}>
              <ListItemText
                primary={`${record.linkData.brand} ${record.linkData.model}`}
                secondary={`${record.linkData.generation || ''}`}
                secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
              />
            </ListItem>
          )}
        />

        <TextField label="resources.crwl/links.fields.version" source='linkData.version' sortable={false}/>

        <FunctionField
          label="resources.crwl/links.fields.color"
          sortable={false}
          render={record =>
            <Tooltip title={record.linkData.color} placement="left">
              <Avatar sizes={'s,a'}
                      className={classes.avatar}
                      style={{ backgroundColor: color(record.linkData.color) }}
              >
                <Typography/>
              </Avatar>
            </Tooltip>
          }
        />

        <TextField label="resources.crwl/links.fields.year" source='linkData.year'/>

        <FunctionField
          label="resources.crwl/links.fields.mileage"
          headerClassName={classes.rightAligned}
          textAlign={'right'}
          render={record => `${(new Intl.NumberFormat(locale).format(record.linkData.mileage))} ${translate('resources.crwl/links.measure.mileage')}`}
        />

        <FunctionField
          sortable={false}
          label="resources.crwl/links.fields.sellerName"
          render={record => (
            <ListItem alignItems="flex-start" className={classes.listItemRoot}>
              <ListItemText
                secondary={`${record.linkData.sellerType ? record.linkData.sellerType.toLowerCase() !== 'private' ?
                  translate('resources.crwl/links.measure.sellerCompany') :
                  translate('resources.crwl/links.measure.sellerPrivate') :
                  ''}`}
                primary={`${record.linkData.sellerLocationName}`}
                secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
              />
            </ListItem>
          )}
        />

        <FunctionField
          headerClassName={classes.rightAligned}
          textAlign={'right'}
          label="resources.crwl/links.fields.price"
          render={record =>
            <Typography variant='button'>
              {
                new Intl.NumberFormat(locale, {
                  minimumFractionDigits: 0,
                  style: 'currency',
                  currency: translate('resources.crwl/links.measure.currency'),
                  currencyDisplay: 'symbol'
                }).format(record.linkData.price)
              }
            </Typography>
          }
        />

        <DeleteButton label="" icon={<DeleteIcon/>}/>

      </Datagrid>
    </List>
  )
}

export default LinksList