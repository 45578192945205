export default {
  menu: {
    dashboard: {
      name: 'Главная'
    },
    settings: {
      name: 'Настройки',
      menu: {
        countries: {
          name: 'Страны'
        },
        companies: {
          name: 'Дилеры'
        },
        employees: {
          name: 'Сотрудники'
        },
        workflow_subject_types: {
          name: 'Типы объектов'
        },
        workflow_types: {
          name: 'Типы процессов'
        },
        workflows: {
          name: 'Процессы'
        },
        calendar_events_types: {
          name: 'Типы событий'
        },
        beecars: {
          name: 'BeeCars'
        },
        tags: {
          name: 'Метки'
        },
      }
    },
    tasks: {
      name: 'Задачи',
    },
    calendar: {
      name: 'Календарь',
    },
    'worksheets-buy': {
      name: 'Выкуп',
    },
    questionnaires: {
      name: 'Анкетирование',
      menu: {
        settings: {
          name: 'Настройки'
        },
        processing: {
          name: 'Анкеты'
        },
        reports: {
          name: 'Результаты'
        },
      }
    },
    market: {
      name: 'Рынок',
      menu: {
        offers: {
          name: 'Все объявления'
        },
        favorites: {
          name: 'Избранное'
        },
        estimation: {
          name: 'Оценка'
        },
        filters: {
          name: 'Мои фильтры'
        },
      }
    }
  }
}