export default {
  'workflow/transitions': {
    add: 'Добавить переход',
    name: 'Переход |||| Переходы',
    fields: {
      workflow: 'Процесс',
      name: 'Название',
      code: 'Код',
      toPlace: 'Куда',
      jobPositions: 'Разрешен для должностей',
      fromPlaces: 'Откуда',
      participant: 'Участник',
      allowed_for_creator: 'Доступно для владельца',
      allowed_for_employee: 'Доступно для сотрудника в заявке',
      allowed_for_any_previous: 'Доступно для любого предыдущего участника',
      actions: 'Действия',
      comment_required: 'Требуется комментарий',
      document_required: 'Требуется документ'
    },
    alerts: {
      comment_required: 'Пожалуйста, добавьте необходимый комментарий прежде чем перейти к следующему шагу',
      document_required: 'Пожалуйста, добавьте необходимый документ прежде чем перейти к следующему шагу',
    },
    buttons: {
      actionsWithObject: 'Действия',
      noActionsAvailable: 'Нет доступных действий'
    },
    confirmDialog: {
      title: 'Подтвердите действие',
      body: 'Это последний шаг процесса. Подтвердите, что хотите завершить процесс'
    }
  },
}