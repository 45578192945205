export default {
  api: process.env.REACT_APP_API_ENTRYPOINT,
  publicImagesRoot: process.env.REACT_APP_IMAGES_ROOT,
  protectedImagesRoot: process.env.REACT_APP_PROTECTED_ROOT,
  APP_STORAGE_ADAPTER: process.env.STORAGE_ADAPTER,
  APP_NAME: process.env.APP_NAME,
  APP_VERSION: process.env.APP_VERSION,
  languages: [
    { id: 'ru', name: 'Русский' },
  ],
  themeConfig: require(`../theme/${process.env.REACT_APP_THEME}/config`).default,
}