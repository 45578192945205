import React from 'react'
import {
  BooleanField,
  FunctionField,
  ImageField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useTranslate
} from 'react-admin'
import AddCompanyBranchButton from './AddCompanyBranchButton'
import AddJobPositionButton from './AddJobPositionButton'
import AddDepartmentButton from './AddDepartmentButton'
import CompanyBranchList from './CompanyBranchList'
import DepartmentList from './DepartmentList'
import JobPositionList from './JobPositionList'
import JobGradeList from './JobGradeList'
import AddJobGradeButton from './AddJobGradeButton'
import { isGranted, ROLE_COMPANY_ADMIN } from '../../utils/AuthUtils'
import withStyles from '@material-ui/core/styles/withStyles'
import AddCompanyFileButton from './AddCompanyFileButton'
import config from '../../config/config'
import WorkflowList from './WorkflowList'
import AddWorkflowButton from './AddWorkflowButton'
import CompanyFileList from './CompanyFileList'

export const styles = {
  enabled: { display: 'inline-block' },
  blocked: { display: 'inline-block', marginLeft: 32 },
  docs: { width: 644, marginTop: 0 },
}

const CompanyTitle = ({ record }) => {
  return <span>{record ? record.name : ''}</span>
}

const CompanyShow = ({ classes, ...props }) => {

  const translate = useTranslate()

  return (
    <Show {...props} title={<CompanyTitle/>}>
      <TabbedShowLayout
        scrollable="true"
      >
        <Tab label={translate('resources.companies.tabs.summary')}>
          <TextField source="name"/>
          <ReferenceField source="type" reference="company-types" link={false}>
            <TextField source="name"/>
          </ReferenceField>
          <BooleanField source="enabled" className={classes.enabled}/>
          <BooleanField source="blocked" className={classes.blocked}/>

          <ReferenceField source="logo" reference="logos">
            <FunctionField source="contentUrl" render={record =>
              <ImageField source="src" record={{ src: `${config.publicImagesRoot}${record.contentUrl}` }}/>
            }/>

          </ReferenceField>

          {/*
                    <Typography variant="headline" gutterBottom>
                        {translate('resources.company-files.header')}
                    </Typography>

                    <ReferenceManyField
                        reference="company-files"
                        target="company"
                        label=""
                        className={classes.docs}
                        pagination={<Pagination/>}
                        sort={{field: 'createdAt', order: 'DESC'}}
                    >
                        <Datagrid>
                            <DateField source="createdAt" showTime/>
                            <FunctionField
                                source="name"
                                render={record =>
                                    <a href={`${config.api}${record.contentUrl}`} target="_blank">{record.name}</a>
                                }
                            />
                            {
                                isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
                                <DeleteButton label="" redirect={false} />
                            }
                        </Datagrid>
                    </ReferenceManyField>
                    {
                        isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
                            <AddCompanyFileButton />
                    }
                    */}
        </Tab>

        <Tab label={translate('resources.companies.tabs.branches')} path="branches">
          <CompanyBranchList/>
          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <AddCompanyBranchButton/>
          }
        </Tab>


        <Tab label={translate('resources.companies.tabs.departments')} path="departments">
          <DepartmentList/>
          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <AddDepartmentButton/>
          }
        </Tab>


        <Tab label={translate('resources.companies.tabs.positions')} path="positions">
          <JobPositionList/>
          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <AddJobPositionButton/>
          }
        </Tab>

        <Tab label={translate('resources.companies.tabs.files')} path="company-files">
          <CompanyFileList fullWidth/>
          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <AddCompanyFileButton/>
          }
        </Tab>


        <Tab label={translate('resources.companies.tabs.workflows')} path="workflows">
          <WorkflowList/>
          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <AddWorkflowButton/>
          }
        </Tab>

      </TabbedShowLayout>
    </Show>
  )
}

export default withStyles(styles)(CompanyShow)