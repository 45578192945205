import React from 'react'
import {
  FunctionField,
  ReferenceField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  useLocale,
  useTranslate
} from 'react-admin'
import calendarResources from './calendarResources'
import Typography from '@material-ui/core/Typography'

const EventShow = (props) => {

  const locale = useLocale()
  const translate = useTranslate()

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <FunctionField label="" source="subjectResourceName" render={record =>
          calendarResources.find(r => r.name === record.subjectResourceName).showLink(record)
        }/>
        <ReferenceField
          source="type"
          reference="calendar-events/event-types"
          sort={{ field: 'name', order: 'ASC' }}
          linkType={false}
        >
          <TextField source="name"/>
        </ReferenceField>
        <FunctionField source="period" render={record =>
          <Typography>
            {
              record['period'] &&
              `
                                ${new Date(record['period'][0]).toLocaleDateString()}
                                ${new Date(record['period'][0]).toLocaleTimeString(locale, {
                hour: '2-digit',
                minute: '2-digit'
              })}
                                -
                                ${new Date(record['period'][1]).toLocaleTimeString(locale, {
                hour: '2-digit',
                minute: '2-digit'
              })}
                                ${new Date(record['period'][1]).toLocaleDateString()}
                            `
            }
          </Typography>
        }/>
        <RichTextField source="description"/>

      </SimpleShowLayout>
    </Show>
  )

}

export default EventShow