import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@material-ui/core/colors'

const getStyles = (color, theme) => ({
  color: theme.palette.getContrastText(color[500]),
  backgroundColor: color[500],
  '&:hover': {
    backgroundColor: color[300],
  },
})

export default (theme) => ({
  red: getStyles(red, theme),
  pink: getStyles(pink, theme),
  purple: getStyles(purple, theme),
  deepPurple: getStyles(deepPurple, theme),
  indigo: getStyles(indigo, theme),
  blue: getStyles(blue, theme),
  lightBlue: getStyles(lightBlue, theme),
  cyan: getStyles(cyan, theme),
  teal: getStyles(teal, theme),
  green: getStyles(green, theme),
  lightGreen: getStyles(lightGreen, theme),
  lime: getStyles(lime, theme),
  yellow: getStyles(yellow, theme),
  amber: getStyles(amber, theme),
  orange: getStyles(orange, theme),
  deepOrange: getStyles(deepOrange, theme),
  brown: getStyles(brown, theme),
  grey: getStyles(grey, theme),
  blueGrey: getStyles(blueGrey, theme),
});
