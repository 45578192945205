import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { Title, useDataProvider, useNotify } from 'react-admin'

import ModelMenu from './Beecars/ModelMenu'
import ModelSelector from './Beecars/ModelSelector'
import { getCleanHydraId } from '../../utils/HydraUtils'
import MappingsContainer from './Beecars/MappingsContainer'

const BeeCarsMappings = (props) => {

  const [brand, setBrand] = useState(null)
  const [brands, setBrands] = useState([])
  const [brandsLoading, setBrandsLoading] = useState(false)
  const [modelsLoading, setModelsLoading] = useState(false)
  const [model, setModel] = useState(null)
  const [models, setModels] = useState([])

  const [selectedMenuItem, setSelectedMenuItem] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)
  const [mapping, setMapping] = useState([])

  const dataProvider = useDataProvider()
  const notify = useNotify()

  useEffect(() => {
    setBrandsLoading(true)
    dataProvider.getList('b-c-brands', {
      filter: { is_virtual: 0 },
      pagination: { perPage: 300 },
      sort: { field: 'name', order: 'ASC' }
    })
      .then(data => {
        setBrands(data.data)
        setBrandsLoading(false)
      })
      .catch(e => {
        notify(e, 'warning')
        setBrandsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!brand) {
      return
    }
    setModelsLoading(true)
    dataProvider.getList('b-c-models', {
      filter: { brand: brand },
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'name', order: 'ASC' },
    })
      .then((data) => {
        setModels(data.data)
        setModelsLoading(false)
      })
      .catch((e) => {
        notify(e, 'warning')
        setModelsLoading(false)
      })
  }, [brand])

  useEffect(() => {
    if (!model) {
      setMapping([])
      return
    }
    setDataLoading(true)
    Promise.all([
      dataProvider.getList('b-c-auto-ru-mappings', {
        filter: { modelId: getCleanHydraId(model) },
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'id', order: 'ASC' }
      })
    ])
      .then(values => {
        setMapping(values[0].data)
      })
      .catch((e) => {
        notify(e, 'warning')
      })
      .finally(() => {
        setDataLoading(false)
      })
  }, [brand, model])

  function selectBrand (id) {
    setBrand(id)
  }

  function selectModel (id) {
    setModel(id)
  }

  return (
    <Card>
      <Title title="Расширение BC"/>
      <CardContent>
        <ModelSelector
          models={models}
          brands={brands}
          brandsLoading={brandsLoading}
          modelsLoading={modelsLoading}
          selectedBrand={brand}
          selectedModel={model}
          onBrandSelect={selectBrand}
          onModelSelect={selectModel}
        />

        <Grid container spacing={8}>
          <Grid item xs={3}>
            {
              model &&
              <ModelMenu
                selectedItem={selectedMenuItem}
                selectItem={(item) => () => setSelectedMenuItem(item)}
                mapping={mapping.length}
                loading={dataLoading}
              />
            }
          </Grid>
          <Grid item xs={9}>
            {/*
                            (!dataLoading && 'photo' === selectedMenuItem) &&
                            <PhotosContainer
                                model={models.find(m => m.id === model)}
                                data={photos}
                                onPhotoUploaded={(photo) => setPhotos([...photos,photo])}
                                onPhotoDeleted={(photo) => setPhotos(photos.filter(p => p.id !== photo.id))}
                            />
                        */}
            {/*
                            (!dataLoading && 'video' === selectedMenuItem) &&
                            <VideosContainer
                                model={models.find(m => m.id === model)}
                                data={videos}
                            />
                        */}
            {/*
                            (!dataLoading && 'description' === selectedMenuItem) &&
                            <DescriptionsContainer
                                model={models.find(m => m.id === model)}
                                data={descriptions}
                            />
                        */}
            {
              //(!dataLoading && 'mapping' === selectedMenuItem) &&
              <MappingsContainer
                model={models.find(m => m.id === model)}
                data={mapping}
              />
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

export default BeeCarsMappings