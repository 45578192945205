import React from 'react'
import { Edit, required, SimpleForm, TextInput, ReferenceInput, SelectInput, translate } from 'react-admin'

const Title = ({ record }) => {
  return <span>{record ? record.name : ''}</span>
}

const ParticipantEdit = props => {
  const redirect = (basePath, id, data) => `/workflow/workflows/${encodeURIComponent(data.workflow)}/participants`
  return (
    <Edit {...props} title={<Title/>}>
      <SimpleForm
        redirect={redirect}
      >
        <ReferenceInput
          source="company"
          reference="companies"
          allowEmpty
          validate={required()}
          sort={{ field: 'name', order: 'ASC' }}
          filter={{ enabled: true }}
          perPage={1000}
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>

        <TextInput source="role"/>
      </SimpleForm>
    </Edit>
  )
}

export default ParticipantEdit