export default {
  'workflow/places': {
    add: 'Ajouter un état',
    name: 'L\' état |||| L\' état',
    fields: {
      workflow: 'flux de travaux',
      name: 'Titre',
      code: 'Code',
      isInitial: 'Départ',
      isSuccess: 'Succès',
      isFail: 'Manquer',
      maxMinutesInPlace: 'Max minutes en place',
      overdueJobPositions: 'Avertir en retard'
    },
    timeleft: 'Temps restant',
    overdue: 'Il était nécessaire de poursuivre l\'action jusqu\'à l\'heure indiquée. L\'état actuel a une limite sur le temps maximum passé dedans. Vous n\'avez pas franchi la prochaine étape dans le temps.'
  },
}