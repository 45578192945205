import React from 'react'
import PropTypes from 'prop-types'
import SingleSelectField from './SingleSelectField'
import { useTranslate } from 'react-admin'

const EngineSizeField = ({ type, ...props }) => {
  const translate = useTranslate()
  return (
    <SingleSelectField
      options={options.reduce((obj, item) => ({
        ...obj,
        [item]: `${item} ${translate('resources.crwl/links.measure.engineSize')}`
      }), {})}
      label={'resources.crwl/links.fields.' + ('to' === type ? 'engineSizeTo' : 'engineSizeFrom')}
      {...props}
    />
  )
}

const options = ['0.2', '0.4', '0.6', '0.8', '1.0', '1.2', '1.4', '1.6', '1.8', '2.0', '2.2', '2.4', '2.6', '2.8', '3.0', '3.5', '4.0', '4.5', '5.0', '5.5', '6.0', '7.0', '8.0', '9.0', '10.0']

EngineSizeField.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  type: PropTypes.oneOf(['from', 'to']).isRequired
}

export default EngineSizeField