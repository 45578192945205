import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { grey } from '@material-ui/core/colors'
import AutoRuSelector from './AutoRuSelector'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  bigAvatar: {
    border: `1px solid ${grey[100]}`
  },
}))

const MappingsContainer = ({ data, model }) => {

  const classes = useStyles()

  if (!model) return <div/>

  return (
    <Grid container spacing={16} className={classes.root} alignItems="center">
      <Grid item xs={2}>
        {
          <img src={`https://files.beecars.ru/auto/menu/${model && model.previewFile}`} className={classes.bigAvatar}/>

        }
      </Grid>
      <Grid item xs={10}>
        <Typography variant={'h5'}>{model && model.name}</Typography>
      </Grid>

      {
        !!data.length &&
        <Grid item xs={12}>
          <Typography
            variant={'subtitle2'}>{`Связь: ${data[0].brand} ${data[0].model} ${data[0].generation} ${data[0].body}`}</Typography>
        </Grid>
      }


      {
        (!!data.length > 0 && (data[0].generation1 !== '' && data[0].generation1 !== null)) &&
        <Grid item xs={12}>
          <Typography
            variant={'subtitle2'}>{`Связь: ${data[0].brand} ${data[0].model} ${data[0].generation1} ${data[0].body}`}</Typography>
        </Grid>
      }

      <Grid item xs={12}>
        <AutoRuSelector mapping={data} model={model}/>
      </Grid>

    </Grid>
  )

}

export default MappingsContainer