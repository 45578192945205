import React from 'react'
import {
  BooleanField,
  ChipField,
  Datagrid,
  DeleteButton,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField
} from 'react-admin'

export const TransitionList = props => {
  return (
    <ReferenceManyField
      reference="workflow/transitions"
      target="workflow"
      addLabel={false}
      pagination={<Pagination/>}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="code"/>
        <TextField source="name"/>

        <ReferenceArrayField
          source="fromPlaces"
          reference="workflow/places"
        >
          <SingleFieldList>
            <ChipField source="codeName"/>
          </SingleFieldList>
        </ReferenceArrayField>

        <ReferenceField
          source="toPlace"
          reference="workflow/places"
        >
          <ChipField source="codeName"/>
        </ReferenceField>

        <ReferenceField
          source="participant"
          reference="workflow/participants"
          linkType={false}
        >
          <TextField source="name"/>
        </ReferenceField>

        <ReferenceArrayField
          source="jobPositions"
          reference="job-positions"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SingleFieldList>
            <ChipField source="name"/>
          </SingleFieldList>
        </ReferenceArrayField>
        <DeleteButton label="" redirect={false}/>
      </Datagrid>
    </ReferenceManyField>
  )
}