export default {
  'questionnaire/questions': {
    add: 'Add question',
    name: 'Question |||| Questionss',
    fields: {
      questionnaire: 'Questionnaire',
      ord: 'Order',
      companyTypes: 'Allowed company types',
      questionType: 'Question type',
      questionText: 'Question text',
      isMust: 'Is must',
      questionGroup: 'Group',
    },
    types: {
      QUESTION_TYPE_YES_NO: 'Yes or no',
      QUESTION_TYPE_RADIO_GROUP: 'Multiple choice questions with one correct answer',
      QUESTION_TYPE_CHECKBOX_GROUP: 'Multiple choice questions with more than one correct answer',
      QUESTION_TYPE_RADIO_GROUP_FREETEXT: 'Multiple choice questions with more than one correct answer & free text field',
      QUESTION_TYPE_FREETEXT: 'Freetext field',
    },
    tabs: {
      question: 'Question',
      answers: 'Answers'
    },
    actions: {
      backToQuestionnaire: 'Back to questionnaire'
    }
  }
}