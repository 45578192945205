import React, { useEffect, useState } from 'react'
import {
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  TextInput,
  useDataProvider,
  useTranslate,
  BooleanInput,
  SaveContextProvider
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PhotosContainer, { rakursList } from '../../CarInstance/PhotosContainer'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  width: '100%',
  inlineField: {
    display: 'inline-block',
    width: '30%',
  },
  first: { display: 'inline-block' },
  second: { display: 'inline-block', marginLeft: 32 },
});


const DocumentsContainer = props => {

  const { carInstance } = props
  const dataProvider = useDataProvider()
  const classes = useStyles();

  const [document, setDocument] = useState(null);

  useEffect(() => {
    dataProvider.getOne('car-document', {id: carInstance.carDocument})
      .then(({data}) => {
        setDocument(data);
      })
  }, carInstance.carDocument)

  const handleSave = (record) => {
    console.log("record", record);
    dataProvider.update('car-documents', { id: document.id, data: record, previousData: document })
      .then(({ data }) => {
        //onSave(data)
      })
  }

  return (
    <SimpleForm record={document} resource={'car-documents'} save={handleSave}>
      <Typography variant={'h5'} fullWidth={true}>Документы</Typography>
      <TextInput
        source="stsRegNumber"
        label="Гос. номер"
      />
      <Typography variant="subtitle2" fullWidth={true}>ПТС</Typography>
      <BooleanInput label="Оригинал ПТС" source="ptsIsOriginal" />
      <TextInput
        source="ptsSeries"
        label="Серия ПТС"
        formClassName={classes.first}
      />
      <TextInput
        source="ptsNumber"
        label="Номер ПТС"
        formClassName={classes.second}
      />
      <Typography variant="subtitle2" fullWidth={true}>СТС</Typography>
      <TextInput
        source="stsSeries"
        label="Серия СТС"
        formClassName={classes.first}
      />
      <TextInput
        source="stsNumber"
        label="Номер СТС"
        formClassName={classes.second}
      />
      <Typography />
      <TextInput
        source="stsDate"
        label="Дата выдачи СТС"
        formClassName={classes.first}
      />
      <TextInput
        source="stsIssuer"
        label="Кем выдан СТС"
        formClassName={classes.second}
      />

      {/*
      <PhotosContainer
        carInstance={carInstance}
        showTitle={true}
        filter={(photos) => photos.filter(p => !p.deleted && p.type <= 42)}
        types={rakursList.filter(r => r.type >= 100 && r.type <= 150)}
      />
      */}

    </SimpleForm>
  )
}

export default DocumentsContainer