import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'

const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: 600,
  },
  card: {
    maxWidth: 600,
    boxShadow: 'none'
  },
  media: {
    minHeight: 400,
  },
  cardSelected: {
    opacity: 0.7
  },
  cardPreview: {
    width: '100%',
    maxWidth: 100,
    border: `1px solid ${theme.palette.grey['400']}`,
    boxShadow: 'none',
    cursor: 'pointer'

  },
  mediaPreview: {
    minHeight: 70,
  },
})

const PhotoGallery = (props) => {

  const { photos, classes } = props
  const [selectedPhoto, setSelectedPhoto] = useState(0)

  if (!photos.length) return <div/>

  const handlePhotoSelect = (index) => () => {
    setSelectedPhoto(index)
  }

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={0}
        className={classes.root}
      >
        <Grid item xs={12}>
          <Card className={classes.card} square={true}>
            <CardMedia
              image={photos[selectedPhoto].photo}
              className={classes.media}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={0}
        className={classes.root}
      >

        {
          photos.map((p, index) => (
              <Grid key={index} item xs={2} className={index === selectedPhoto ? classes.cardSelected : ''}>
                <Card className={classes.cardPreview} square={true} onClick={handlePhotoSelect(index)}>
                  <CardMedia
                    image={p.preview}
                    className={classes.mediaPreview}
                  />
                </Card>
              </Grid>
            )
          )
        }

        {
          photos.length < 6 && (new Array(6 - photos.length)).fill(1).map((i, index) => (
            <Grid key={index} item xs={2}/>
          ))
        }

      </Grid>
    </React.Fragment>
  )

}

PhotoGallery.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      preview: PropTypes.string
    })
  )
}

PhotoGallery.defaultProps = {
  photos: []
}

export default withStyles(styles)(PhotoGallery)