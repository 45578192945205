export default {
  'company-files': {
    add: 'Add document',
    header: 'Documents',
    name: 'Document |||| Documents',
    fields: {
      createdAt: 'Created at',
      name: 'Title',
      file: 'File',
      company: 'Company'
    }
  },
}