import React from 'react'
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  Pagination,
  ReferenceManyField,
  TextField
} from 'react-admin'
import { isGranted, ROLE_COMPANY_ADMIN } from '../../utils/AuthUtils'

const CompanyBranchList = (props) => {

  return (
    <ReferenceManyField
      reference="company-branches"
      target="company"
      addLabel={false}
      pagination={<Pagination/>}
      sort={{ field: 'name', order: 'ASC' }}
      fullWidth
      {...props}
    >
      <Datagrid>
        <TextField source="name"/>
        <BooleanField source="enabled"/>
        <BooleanField source="blocked"/>
        {
          isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
          <EditButton label=""/>
        }
        {
          isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
          <DeleteButton label="" redirect={false}/>
        }
      </Datagrid>
    </ReferenceManyField>
  )

}

export default CompanyBranchList