import React, { useState } from 'react'
import { compose } from 'recompose'
import { useDispatch } from 'react-redux'

import {
  BooleanInput,
  Button,
  fetchEnd,
  fetchStart,
  FormDataConsumer,
  TextInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  useNotify,
  SimpleForm,
  useTranslate,
  useDataProvider,
  useLocale,
  Toolbar
} from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { DateTimeInput } from '../Inputs/ra-admin-date-inputs'

const styles = theme => ({
  firstInput: {
    marginTop: 0,
  },
  first: {
    display: 'inline-block'
  },
  second: {
    display: 'inline-block', marginLeft: 32
  },
  heading: {
    marginTop: theme.spacing(2),
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  content: {
    padding: 0,
    width: '100%'
  }
})

const AddCommentDialog = (props) => {

  const { open, onClose, onCreate, classes, fullScreen, resource, subjectId } = props

  const translate = useTranslate()
  const locale = useLocale()
  const dataProvider = useDataProvider()
  const dispatch = useDispatch()
  const notify = useNotify()
  const [saving, setSaving] = useState(false)

  const formName = 'comment-quick-create'

  const handleSubmit = values => {

    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart())
    setSaving(true)

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider.create('comments', { data: { subjectResourceName: resource, subjectId: `${subjectId}`, ...values } })
      .then(({ data }) => {
        onCreate(data)
        //reset(formName);

      })
      .catch(error => {
        notify(error.message, 'error')
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        dispatch(fetchEnd())
        setSaving(false)
        onClose()
      })
  }

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-label="Create comment"
    >
      <DialogTitle>{translate('resources.comments.dialogs.create.title')}</DialogTitle>
      <DialogContent className={classes.content}>
        <SimpleForm
          resource="comments"
          toolbar={<AddCommentDialogToolbar onClose={onClose} isSaving={saving}/>}
          undoable={false}
          redirect={false}
          save={handleSubmit}

        >
          <TextInput multiline fullWidth source="comment"/>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )
}

const AddCommentDialogToolbar = (props) => {
  const { onClose, isSaving } = props
  return (
    <Toolbar {...props} >
      <SaveButton
        redirect={false}
        submitOnEnter={true}
        disabled={isSaving}
      />
      <Button label="ra.action.cancel" onClick={onClose} disabled={isSaving}>
        <IconCancel/>
      </Button>
    </Toolbar>
  )

}

export default compose(
  withStyles(styles),
  withMobileDialog(),
)(AddCommentDialog)