import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CheckedIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import { green } from '@material-ui/core/colors'
import { red } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  table: {
    width: 'auto'
  },
  tableContainer: {
    width: '100%',
    padding: theme.spacing.unit * 1,
    overflowX: 'auto',
  },
  green: {
    color: green['600'],
  },
  red: {
    color: red['600']
  }
}))

const PriceOfferList = ({ offers, onDelete }) => {

  const classes = useStyles()

  const [isOpen, setOpen] = useState(false);

  return (
    <div className={classes.tableContainer}>
      <Button color="primary" onClick={() => setOpen(!isOpen)}>{isOpen ? 'Скрыть предложения' : 'Показать предложения'}</Button>
      {
        isOpen && <Table className={classes.table}>
          <TableHead>
            <TableCell align="left">Предложение</TableCell>
            <TableCell align="right">Дата</TableCell>
            <TableCell align="right">Согласовано</TableCell>
            <TableCell align="right" />
          </TableHead>
          <TableBody>
            {
              offers.map(r => {
                return (
                  <TableRow key={r.id}>
                    <TableCell align="right"
                               className={r.isConfirmed === true ? classes.green : r.isConfirmed === false ? classes.red : ''}>
                      {
                        new Intl.NumberFormat('ru-RU', {
                          minimumFractionDigits: 0,
                          style: 'currency',
                          currency: 'RUB',
                          currencyDisplay: 'symbol'
                        }).format(r.offer)
                      }
                    </TableCell>
                    <TableCell align="right">
                      {
                        new Intl.DateTimeFormat('ru-RU', {
                          year: 'numeric', month: 'numeric', day: 'numeric',
                          hour: 'numeric', minute: 'numeric', second: 'numeric',
                        }).format(new Date(r.createdAt))

                      }
                    </TableCell>
                    <TableCell align="center"
                               className={r.isConfirmed === true ? classes.green : r.isConfirmed === false ? classes.red : ''}>
                      {

                        r.isConfirmed ? <CheckedIcon/> : ' - '

                      }
                    </TableCell>
                    <TableCell align="right">
                      {

                        !r.isConfirmed &&
                        <IconButton color="primary" component="span" onClick={() => onDelete(r.id)}>
                          <DeleteIcon />
                        </IconButton>

                      }
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      }
    </div>
  )

}

export default PriceOfferList