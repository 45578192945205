import Actions from './Action/fr'
import Participant from './Participant/fr'
import Place from './Place/fr'
import RequestToCopy from './RequestToCopy/fr'
import SubjectType from './SubjectType/fr'
import Transition from './Transition/fr'
import Type from './Type/fr'
import Workflow from './Workflow/fr'

export default {
  ...Actions,
  ...Participant,
  ...Place,
  ...RequestToCopy,
  ...SubjectType,
  ...Transition,
  ...Type,
  ...Workflow,
}