import React from 'react'
import {
  ReferenceArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SelectArrayInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import { ROLE_ADMIN, ROLE_COMPANY_ADMIN, rolesAvailable } from '../../config/roles'

const JobPositionTitle = ({ record }) => {
  return <span>{record ? record.title : ''}</span>
}

const JobPositionEdit = props => {

  const redirect = (basePath, id, data) => `/companies/${encodeURIComponent(data.company)}/positions`

  return (
    <Edit {...props} title={<JobPositionTitle/>}>
      <SimpleForm
        redirect={redirect}
      >
        <TextInput source="name"/>

        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              return (
                <ReferenceInput
                  source="department"
                  reference="departments"
                  filter={{ company: formData.company }}
                  sort={{ field: 'name', order: 'ASC' }}
                  {...rest}
                >
                  <SelectInput optionText="name"/>
                </ReferenceInput>
              )
            }

          }

        </FormDataConsumer>


        {
          <SelectArrayInput
            source="roles"
            choices={
              Object
                .keys(rolesAvailable)
                .reduce((acc, role) => {
                  if ((ROLE_ADMIN === role && ROLE_ADMIN === props.permissions) || ROLE_ADMIN !== role) {
                    acc.push({
                      id: role,
                      name: rolesAvailable[role].name
                    })
                  }
                  return acc
                }, [])
            }
          />
        }

        <BooleanInput source="enabled"/>

      </SimpleForm>
    </Edit>
  )
}

export default JobPositionEdit