export default {
  'workflow/participants': {
    add: 'Gegenpartei hinzufügen',
    name: 'Gegenpartei |||| Gegenpartei',
    fields: {
      workflow: 'Arbeitsablauf',
      role: 'Rolle',
      company: 'Gegenpartei'
    }
  },
}