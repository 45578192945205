import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { LoadingIndicator, useDataProvider, useLocale } from 'react-admin'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import grey from '@material-ui/core/colors/grey'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  selectedVersion: {
    backgroundColor: grey[200]
  }
}))

const CompareTable = ({ versions, selectedVersion, year }) => {

  const classes = useStyles()
  const dataProvider = useDataProvider()
  const locale = useLocale()

  const [table, setTable] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [difference, setDifference] = useState(false)

  const [options, setOptions] = useState({});

  const [rows, setRows] = useState([])

  useEffect(() => {
    setTableLoading(true)
    dataProvider.create('commands/bc/compare-table', {
      data: {
        versions: versions.map(v => v.id),
        year: parseInt(year)
      }
    })
      .then(({ data }) => {
        setTable(data.result)
      })
      .finally(() => {
        setTableLoading(false)
      })
  }, [])

  const prepareTableData = () => {
    if (!table.versions) return
  }

  const checkOption = (versionId, optionId, price) => {
    let newOptions = [...options];
    if (options[versionId] && options[versionId][optionId]) {
      newOptions = newOptions.filter((el, ind, arr) => !el[versionId] && !el[versionId][optionId])
    }
    else {
      newOptions[versionId][optionId] = price;
    }
    setOptions(newOptions);
  }

  return (
    <Paper m={3} className={classes.paper}>
      <Typography component="div">
        {
          tableLoading ?
            <LoadingIndicator/> :
            table.versions &&
            <TableContainer component={Paper}>
              <Table  stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {
                      table.versions.map(v =>
                        <TableCell align="center" key={'v_name' + v.id}
                                   className={v.id === selectedVersion ? classes.selectedVersion : ''}>{v.name}</TableCell>
                      )
                    }
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {
                        difference ?
                          <Button variant="contained" color="primary" onClick={() => setDifference(false)}>Все опции</Button> :
                          <Button variant="contained" color="primary" onClick={() => setDifference(true)}>Только различия</Button>
                      }
                    </TableCell>
                    {
                      table.versions.map(v =>
                        <TableCell align="center" key={'v_price' + v.id}
                                   className={v.id === selectedVersion ? classes.selectedVersion : ''}>
                          {
                            new Intl.NumberFormat(locale, {
                              minimumFractionDigits: 0,
                              currency: 'RUB',
                              style: 'currency'
                            }).format(v.priceFrom)
                          }
                          {
                            v.priceFrom !== v.priceTo && ' - '
                          }
                          {
                            v.priceFrom !== v.priceTo && (
                              new Intl.NumberFormat(locale, {
                                minimumFractionDigits: 0,
                                currency: 'RUB',
                                style: 'currency'
                              }).format(v.priceTo))
                          }
                        </TableCell>
                      )
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    table.equipmentTable.blocks.map(b => {
                      return (
                        <React.Fragment>
                          <TableRow key={'block_' + b.id}>
                            <TableCell colSpan={table.versions.length + 1}>
                              <Typography variant={'h5'}>{b.name}</Typography>
                            </TableCell>
                          </TableRow>
                          {
                            b.items.map(item => {
                              const includes = table.versions.filter(v => {
                                return v.equipment.includes("" + item.id)
                              })

                              const includesOptional = table.versions.filter(v => {
                                return v.options.find((el, ind, arr) => {
                                  return el[0] === "" + item.id;
                                }) !== undefined;
                              })

                              if (includes.length > 0 && (difference && includes.length !== table.versions.length || !difference)) {
                                return (
                                  <TableRow key={'item_' + item.id} hover={true}>
                                    <TableCell>{item.name}</TableCell>
                                    {
                                      table.versions.map(v => {
                                        return (
                                          <TableCell align="center" className={v.id === selectedVersion ? classes.selectedVersion : ''}>
                                            {
                                              v.equipment.includes("" + item.id) ? <CheckBoxIcon /> :
                                                v.options.find((el, ind, arr) => {
                                                  return el[0] === "" + item.id;
                                                }) !== undefined ? (
                                                  <React.Fragment>
                                                    <CheckBoxOutlineBlankIcon />
                                                    <Typography>
                                                      {
                                                        new Intl.NumberFormat(locale, {
                                                          minimumFractionDigits: 0,
                                                          currency: 'RUB',
                                                          style: 'currency'
                                                        }).format(v.options.find((el, ind, arr) => {
                                                          return el[0] === "" + item.id;
                                                        })[1])
                                                      }
                                                    </Typography>
                                                  </React.Fragment>) : <RemoveIcon />
                                            }
                                          </TableCell>
                                        )
                                      })
                                    }
                                  </TableRow>
                                )
                              }
                            })
                          }
                        </React.Fragment>
                      )
                    })
                  }
                  <React.Fragment>
                    <TableRow>
                      <TableCell colSpan={table.versions.length + 1}>
                        <Typography variant={'h5'}>Пакеты оборудования</Typography>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>

                  {
                    table.equipmentTable.packages.map(p => {
                      return (
                        <React.Fragment>
                          <TableRow key={'pack_' + p.id}>
                            <TableCell>
                              <Typography>{p.name}</Typography>
                              {
                                p.equipment.map(e => {
                                        return (
                                          <Typography key={'ose_' + p.id + e[0]} variant="caption" display="block" gutterBottom>
                                            {
                                              e[1]
                                            }
                                          </Typography>
                                        )
                                })
                              }
                            </TableCell>
                            {
                              table.versions.map(v => {
                                const available = p.versions.includes(parseInt(v.id));

                                return (
                                  <TableCell key={'set_v_' + v.id}  align="center"  className={v.id === selectedVersion ? classes.selectedVersion : ''}>
                                    {
                                      available ?
                                        <React.Fragment>
                                          <CheckBoxOutlineBlankIcon />
                                          <Typography>
                                            {
                                              new Intl.NumberFormat(locale, {
                                                minimumFractionDigits: 0,
                                                currency: 'RUB',
                                                style: 'currency'
                                              }).format(p.price)
                                            }
                                          </Typography>
                                        </React.Fragment> : <RemoveIcon />
                                    }
                                  </TableCell>
                                  )
                              })
                            }
                          </TableRow>
                        </React.Fragment>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
        }
      </Typography>
    </Paper>
  )
}

export default CompareTable