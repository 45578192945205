import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'

import CheckEngineIcon from '../../../assets/lamps/check-engine.png'
import OilEngineIcon from '../../../assets/lamps/engine-oil.png'
import DieselGlowIcon from '../../../assets/lamps/diesel-glow-plug.png'
import BatteryIcon from '../../../assets/lamps/battery.png'
import ABSIcon from '../../../assets/lamps/abs.png'
import AirbagIcon from '../../../assets/lamps/airbag.png'

import CheckLamp from './CheckLamp'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px'
  },

  header: {
    textAlign: 'center',
  },

  headerLabel: {
    fontSize: '10px',
    textTransform: 'uppercase'
  }
}))

const lamps = {
  controlLampsCheckengine: {
    icon: CheckEngineIcon,
    text: 'Check Engine'
  },
  controlLampsOil: {
    icon: OilEngineIcon,
    text: 'Моторное масло'
  },
  controlLampsDiesel: {
    icon: DieselGlowIcon,
    text: 'Дизель',
    condition: (carInstance) => 'DIESEL' === carInstance.engineType
  },
  controlLampsBattery: {
    icon: BatteryIcon,
    text: 'АКБ'
  },
  controlLampsAbs: {
    icon: ABSIcon,
    text: 'АБС'
  },
  controlLampsAirbag: {
    icon: AirbagIcon,
    text: 'Подушки безопасности'
  }
}

const CheckLamps = (props) => {

  const { diagnostics, carInstance, existTitle, onTitle, offTitle, onChange } = props

  const classes = useStyles();

  const handleLamp = (name) => {
    return (value) => {
      onChange(name, value);
    }
  }

  return (
    <List dense className={classes.root}>
      <ListItem button style={{ paddingTop: '0px' }}>
        <Grid container spacing={0}>
          <Grid item xs={3}/>
          <Grid item xs={3} className={classes.header}>
            <Typography variant="body2" className={classes.headerLabel}>{existTitle}</Typography>
          </Grid>
          <Grid item xs={3} className={classes.header}>
            <Typography variant="body2" className={classes.headerLabel}>{onTitle}</Typography>
          </Grid>
          <Grid item xs={3} className={classes.header}>
            <Typography variant="body2" className={classes.headerLabel}>{offTitle}</Typography>
          </Grid>
        </Grid>
      </ListItem>

      {
        Object.keys(lamps).map(lamp => {
          if (!lamps[lamp].condition || lamps[lamp].condition(carInstance)) {
            return (
              <CheckLamp
                key={lamp}
                icon={lamps[lamp].icon}
                text={lamps[lamp].text}
                value={diagnostics[lamp]}
                handler={handleLamp(lamp)}
              />
            )
          }
        })
      }

      {/*Object.entries(values).map(lamp => (
        <CheckLamp
          key={lamp[0]}
          icon={lamps[lamp[0]].icon}
          text={lamps[lamp[0]].text}
          value={lamp[1]}
          handler={handleLamp(lamp[0])}
        />
      ))*/}
    </List>
  )
}

CheckLamps.propTypes = {
  diagnostics: PropTypes.object,
  carInstance: PropTypes.object,
  existTitle: PropTypes.string,
  onTitle: PropTypes.string,
  offTitle: PropTypes.string
}

CheckLamps.defaultProps = {
  existTitle: 'Есть',
  onTitle: 'Вкл.',
  offTitle: 'Погасла',
}

export default CheckLamps;