import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import { ReferenceField, TextField } from 'react-admin'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: 0
  }
})

const TasksList = (props) => {

  const { classes, tasks, onSelect } = props

  const [checked, setChecked] = useState([])

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
    onSelect(value)
  }

  return (
    <div className={classes.root}>
      <List>
        {tasks.map(task => (
          <React.Fragment>
            <ListItem
              key={task.id}
              role={undefined}
              dense
              button
              className={classes.listItem}
            >

              <ListItemText primary={
                <ReferenceField label="" linkType="edit" source="id" reference="tasks" record={task}
                                basePath={'/tasks'}>
                  <TextField source="title"/>
                </ReferenceField>
              } secondary={task.description}/>
              <Checkbox
                checked={checked.indexOf(task.id) !== -1}
                tabIndex={-1}
                disableRipple
                onChange={handleToggle(task.id)}
              />
            </ListItem>
            <Divider/>
          </React.Fragment>
        ))}
      </List>
    </div>
  )
}

export default withStyles(styles)(TasksList)