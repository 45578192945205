export default {
  'crm/worksheets': {
    name: 'Whorksheet |||| Worksheets',
    fields: {
      companyBranch: 'Company branch',
      createdAt: 'Created at',
      createdBy: 'Created by',
      customerPhone: 'Phone'
    },
    errors: {
      phoneOrEmailRequired: 'Phone or Email required'
    },
    tabs: {
      summary: 'Summary',
      customer: 'Customer',
      new: 'New car',
      used: 'Used car',
      tradeIn: 'Trade in',
      finance: 'Finance',
      insurance: 'Insurance',
      search: 'Search'
    },
  },
}