import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import validator from 'validator'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useTranslate } from 'react-admin'
import { COMPONENT_STATE_SUBMITTING } from './AuthRoot'

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  cell: {
    padding: 0,
    textAlign: 'center',
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
    padding: '10px'
  }
})

const required = (value) => {
  if (null == value || !value.toString().trim().length) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return 'app.form.required'
  }
  return true
}

const email = (value) => {
  if (null == value || !validator.isEmail(value)) {
    return 'app.auth.form.validation.invalid.email'
  }
  return true
}

const AuthPasswordlessForm = (props) => {

  const {
    credentials,
    errors,
    onError,
    onChange,
    onSubmit,
    componentState,
    classes
  } = props

  const translate = useTranslate()

  const isEmailValid = () => {
    const r = required(credentials.email)
    const e = email(credentials.email)
    if (r !== true || e !== true) {
      onError('email', r === true ? e : r)
      return false
    }
    return true
  }

  const handleChange = (field) => {
    return (event) => {
      onChange(field, event.target.value)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (isEmailValid()) {
      onSubmit()
    }
  }

  const formLocked = COMPONENT_STATE_SUBMITTING === componentState

  return (
    <React.Fragment>
      <Grid container spacing={0} direction="column" alignItems="stretch">
        <Grid item xs={12} className={classes.cell}>
          <TextField
            required
            error={null != errors.email}
            label={null == errors.email ? translate('app.auth.form.email') : translate(errors.email)}
            className={classes.textField}
            margin="normal"
            onChange={handleChange('email')}
            value={credentials.email}
            disabled={formLocked}
          />
        </Grid>
        <Grid item xs={12} className={classes.cell}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
            onClick={handleSubmit}
            disabled={formLocked}
          >
            {translate('app.auth.form.signin')}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )

}

AuthPasswordlessForm.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object,
  credentials: PropTypes.object,
  componentState: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func
}

export default withStyles(styles)(AuthPasswordlessForm)