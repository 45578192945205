import React from 'react'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import { withStyles } from '@material-ui/core/styles'
import { Button, translate } from 'react-admin'

const styles = {
  button: {
    marginTop: '1em'
  }
}

const AddTransitionButton = ({ classes, record, translate }) => (
  <Button
    className={classes.button}
    component={Link}
    to={`/workflow/transitions/create?workflow=${record.id}`}
    label={translate('resources.workflow/transitions.add')}
    title={translate('resources.workflow/transitions.add')}
  >
    <AddIcon/>
  </Button>
)

export default translate(withStyles(styles)(AddTransitionButton))