import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useDataProvider, useTranslate } from 'react-admin'
import { StorageFactory } from '../../../../utils/StorageFactory'

const RegionField = (props) => {

  const [regions, setRegions] = useState([])
  const [loading, setLoading] = useState(false)

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const [selectedRegion, setSelectedRegion] = useState(StorageFactory().getItem('geo_region'))

  useEffect(() => {
    setLoading(true)
    dataProvider.getList('geo/regions', {
      filter: {},
      pagination: { perPage: 300 },
      sort: { field: 'name', order: 'ASC' }
    })
      .then(data => {
        setRegions(data.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const onChange = (event, value) => {
    const region = value
    const { onChange } = props
    if (region) {
      StorageFactory().setItem('geo_region', region['id'])
      StorageFactory().setItem('geo_city', null)
      StorageFactory().setItem('geo_lat', region['lat'])
      StorageFactory().setItem('geo_lon', region['lon'])
      setSelectedRegion(region['id'])
      onChange(region['id'])
    } else {
      StorageFactory().setItem('geo_region', null)
      StorageFactory().setItem('geo_city', null)
      StorageFactory().setItem('geo_lat', null)
      StorageFactory().setItem('geo_lon', null)
      setSelectedRegion(null)
      onChange(null)
    }
  }

  const getById = (id) => {
    if (regions.length) {
      return regions.find((element) => `${element['id']}` === `${id}`)
    }
    return ''
  }

  return (
    <Autocomplete
      value={getById(selectedRegion)}
      options={regions}
      getOptionLabel={option => option.name}
      onChange={onChange}
      loading={loading}
      loadingText={translate('ra.page.loading')}
      renderInput={params => (
        <TextField
          {...params}
          label={translate('resources.crwl/links.fields.region')}
          variant="outlined"
          fullWidth
        />
      )}
    />
  )
}

RegionField.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string
}

export default RegionField