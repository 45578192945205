export default {
  'crwl/request-appraisal': {
    name: 'Оценка стоимости автомобиля с пробегом',
    fields: {
      brand: 'Марка',
      model: 'Модель',
      year: 'Год',
      generation: 'Поколение',
      modification: 'Модификация'
    },
    action: {
      create: 'Оценить'
    }
  },
}