import React from 'react'
import { Resource } from 'react-admin'
import LinksList from './LinksList'
import FilterList from './FilterList'
import { FilterEdit } from './FilterEdit'
import LinkShow from './LinkShow'
import FavoritesList from './FavoritesList'
import RequestAppraisalCreate from './RequestAppraisalCreate'
import ResourceGuesser from '@api-platform/admin/lib/ResourceGuesser'

export default permissions => [
  <Resource
    name="crwl/links"
    list={LinksList}
    show={LinkShow}
  />,

  <Resource
    name="crwl/filters"
    list={FilterList}
    edit={FilterEdit}
  />,

  <Resource
    name="crwl/favorites"
    list={FavoritesList}
  />,

  <Resource
    name="crwl/tagged-ads"
  />,

  <Resource
    name="crwl/request-appraisal"
    create={RequestAppraisalCreate}
  />,

  <Resource
    name="crwl/photos"
  />,

  <Resource
    name="reference/brands"
  />,

  <Resource
    name="reference/models"
  />,

  <Resource
    name="reference/generations"
  />,

  <Resource
    name="reference/complectations"
  />,

  <Resource
    name="reference/modifications"
  />,

  <Resource
    name="crwl/request-links-by-modification"
  />,

  <Resource
    name="commands/bc/versions-for-modification"
  />
]