import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import { useDataProvider, useTranslate, useLocale, LoadingIndicator } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'inline-block'
  },
  button: {
    marginRight: theme.spacing(),
  },
  input: {
    fontSize: theme.typography.fontSize * 2,
    width: '100%'
  },
}))

const ChangePriceButton = (props) => {

  const { id, isSold, initialPrice } = props

  const classes = useStyles()
  const dataProvider = useDataProvider()
  const translate = useTranslate()
  const locale = useLocale()

  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(initialPrice)
  const [displayValue, setDisplayValue] = useState(new Intl.NumberFormat(locale, { minimumFractionDigits: 0 }).format(initialPrice))
  const [isSaving, setIsSaving] = useState(false)

  function handleClickOpen () {
    setOpen(true)
  }

  function handleClose () {
    setOpen(false)
  }

  const handleChange = () => event => {
    const val = event.target.value.replace(/[^\d]/g, '')
    const displayVal = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0
    }).format(val)

    setValue(val)
    setDisplayValue(displayVal)
  }

  async function handleSave () {
    setIsSaving(true)
    await dataProvider.update('crwl/links', { id: id, data: { price: parseInt(value) } })
    setIsSaving(false)
    setOpen(false)
  }

  return (
    <div className={classes.container}>
      {!isSold && <Button color='primary' className={classes.button} onClick={handleClickOpen}>
        {
          translate('resources.crwl/links.actions.changePrice')
        }
      </Button>}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{translate('resources.crwl/links.dialog.changePrice.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              translate('resources.crwl/links.dialog.changePrice.description', { src: props.src })
            }
          </DialogContentText>
          <FormControl>
            <Input
              id="adornment-amount"
              endAdornment={<InputAdornment position="end"><Typography variant={'h6'}>₽</Typography></InputAdornment>}
              fullWidth
              margin="dense"
              autoFocus
              value={displayValue}
              onChange={handleChange()}
              className={classes.input}
            />
          </FormControl>
        </DialogContent>
        {
          isSaving ? <LoadingIndicator/> :
            <DialogActions>
              <Button onClick={handleSave} color="primary">
                {translate('ra.action.save')}
              </Button>
              <Button onClick={handleClose} color="primary">
                {translate('ra.action.cancel')}
              </Button>
            </DialogActions>
        }

      </Dialog>
    </div>
  )

}

ChangePriceButton.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string,
  isSold: PropTypes.bool,
  initialPrice: PropTypes.number
}

export default ChangePriceButton