export default {
  'company-files': {
    add: 'Ajouter documents',
    header: 'Documents',
    name: 'Document |||| Documents',
    fields: {
      createdAt: 'Créé à',
      name: 'Titre',
      file: 'fichier',
      company: 'Société'
    }
  },
}