import authForm from './authForm/en'
import menu from './menu/en'
import resources from './resoures/en'

export default {
  'app.loading': 'Loading',
  'app.welcome': 'Welcome',
  'app.form.required': 'Required field',
  'app.form.submit': 'Submit',
  show: 'Show',
  hide: 'Hide',

  roles: {
    ROLE_ANONYMOUS: 'Anonymous user',
    ROLE_ADMIN: 'Administrator',
    ROLE_EMPLOYEE: 'Employee',
    ROLE_COMPANY_ADMIN: 'Company administrator',
    ROLE_COMPANY_MANAGER: 'Company manager',
    ROLE_BRANCH_MANAGER: 'Branch manager',
    ROLE_DEALER: 'Dealer',
  },

  time: {
    days: '%{smart_count} Day |||| %{smart_count} Days',
    hours: '%{smart_count} Hour |||| %{smart_count} Hours',
    minutes: '%{smart_count} Minute |||| %{smart_count} Minutes',
    seconds: '%{smart_count} Second |||| %{smart_count} Seconds',
  },

  ...authForm,
  ...menu,
  ...resources

}