import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, LoadingIndicator, Query, translate } from 'react-admin'

const LinkTaskLink = (props) => {

  const { resource, id, translate } = props
  const [url, setUrl] = useState('')
  const [redirect, setRedirect] = useState(false)

  return (
    redirect ? <Redirect to={url} push/> :
      <Query type="getOne" resource={resource} payload={{ id: `/${resource}/${id}` }}>
        {({ data, loading, error }) => {
          if (loading) {
            return <LoadingIndicator/>
          }
          if (error) {
            return <p>ERROR</p>
          }
          setUrl(`/crwl/links/${encodeURIComponent(data.id)}/show`)
          return <Button color="primary"
                         onClick={() => setRedirect(true)}
                         label={translate(`resources.${resource}.name`, { smart_count: 1 })}/>
        }}
      </Query>
  )

}

export default translate(LinkTaskLink)