import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useDataProvider, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'

const GenerationField = (props) => {

  const { brand, model, initialValue, onOptionSelected = (generation) => {} } = props
  const [loading, setLoading] = useState(false)
  const [generations, setGenerations] = useState([])

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (model) {
      setLoading(true)
      dataProvider.getList('reference/generations', {
        filter: { model: model },
        pagination: { perPage: 300 },
        sort: { field: 'name', order: 'ASC' }
      })
        .then(data => {
          setGenerations(data.data)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setGenerations([])
    }

  }, [brand, model])

  const onChange = (event, value) => {
    const { onChange } = props
    onChange(value ? value['id'] : undefined)
    onOptionSelected(value)
  }

  const getById = (id) => {
    if (generations.length) {
      return generations.find((element) => element['id'] === id)
    }
    return ''
  }

  return (
    <Autocomplete
      options={generations}
      getOptionLabel={option => option ? `${option.name} [${option.yearFrom} - ${option.yearTo}]` : ''}
      onChange={onChange}
      value={getById(initialValue)}
      loading={loading}
      loadingText={translate('ra.page.loading')}
      renderInput={params => (
        <TextField {...params} label={translate('resources.crwl/links.fields.generation')} variant="outlined"
                   fullWidth/>
      )}
    />
  )
}

GenerationField.propTypes = {
  onChange: PropTypes.func,
  brand: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  model: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default GenerationField