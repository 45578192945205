import React from 'react'
import { Datagrid, DeleteButton, FunctionField, Pagination, ReferenceManyField, DateField } from 'react-admin'
import { isGranted, ROLE_ADMIN, ROLE_COMPANY_ADMIN } from '../../utils/AuthUtils'
import Link from '@material-ui/core/Link'
import config from '../../config/config'

const CompanyFileList = (props) =>
  <ReferenceManyField
    reference="company-files"
    target="company"
    addLabel={false}
    sort={{ field: 'name', order: 'ASC' }}
    pagination={<Pagination/>}
    {...props}
  >
    <Datagrid>
      <FunctionField
        source="name"
        render={record =>
          <Link href={`${config.protectedImagesRoot}/files${record.id}`} target="_blank">{record.name}</Link>}
      />

      <DateField source="createdAt" showTime/>
      {
        (isGranted(ROLE_ADMIN) || isGranted(ROLE_COMPANY_ADMIN)) && <DeleteButton label="" redirect={false}/>
      }
    </Datagrid>
  </ReferenceManyField>

export default CompanyFileList