export default {
  'questionnaire/questions': {
    add: 'Ajouter une question',
    name: 'Question |||| Des questions',
    fields: {
      questionnaire: 'Questionnaire',
      ord: 'Commande',
      companyTypes: 'Types de société autorisés',
      questionType: 'Type de question',
      questionText: 'Texte de la question',
      isMust: 'est indispensable',
      questionGroup: 'Group',
    },
    types: {
      QUESTION_TYPE_YES_NO: 'Oui ou non',
      QUESTION_TYPE_RADIO_GROUP: 'Questions à choix multiples avec une réponse correcte',
      QUESTION_TYPE_CHECKBOX_GROUP: 'Questions à choix multiples avec plusieurs réponses correctes',
      QUESTION_TYPE_RADIO_GROUP_FREETEXT: 'Questions à choix multiples avec plusieurs réponses correctes et champ de texte libre',
      QUESTION_TYPE_FREETEXT: 'Champ Freetext',
    },
    tabs: {
      question: 'Question',
      answers: 'Réponses'
    },
    actions: {
      backToQuestionnaire: 'Retour au questionnaire'
    }
  }
}