export default {
  'crm/worksheets': {
    name: 'Рабочий лист |||| Рабочие листы',
    fields: {
      companyBranch: 'Автосалон',
      createdAt: 'Создан',
      employee: 'Автор',
      customer: 'Клиент',
      isClosed: 'Закрыт',
      type: 'Тип',

      customerLastName: 'Фамилия',
      customerFirstName: 'Имя',
      customerThirdName: 'Отчество',
      customerPhone: 'Телефон',
      customerEmail: 'Электронная почта',

      request: 'Потребности',
      isNewCarPurchase: 'Продажа нового',
      isUsedCarPurchase: 'Продажа с пробегом',
      isTradeIn: 'Выкуп',
      isFinance: 'Кредит',
      isInsurance: 'Страхование',
      isSearch: 'Автоподбор'
    },
    errors: {
      phoneOrEmailRequired: 'Необходимо указать телефон или e-mail',
      subWorksheetNotInitialized: 'Рабочий лист %{resource} не найден'
    },
    tabs: {
      summary: 'Свойства',
      customer: 'Клиент',
      new: 'Продажа нового',
      used: 'Продажа Б/У',
      tradeIn: 'Выкуп',
      finance: 'Кредит',
      insurance: 'Страхование',
      search: 'Подбор'
    },
    dialogs: {
      existingCustomerDialog: {
        loading: 'Проверяем есть ли клиент с такими данными...',
        title: 'Найден клиент с указанными данными',
        buttons: {
          createNew: 'Создать нового клиента',
          select: 'Выбрать'
        }
      }
    },
    buttons: {
      transitions: 'Дальнейшие действия с рабочим листом'
    },
  },
}