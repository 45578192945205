import React, { useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import ResettableTextField from './ResettableTextField'

const PriceField = (props) => {

  const { initialValue, onChange, type } = props

  const translate = useTranslate()

  const handleChange = (value) => onChange(value.replace(/[^\d]/g, ''))

  return (
    <ResettableTextField
      resettable={true}
      value={initialValue}
      onChange={handleChange}
      variant='outlined'
      fullWidth
      label={`${translate('resources.crwl/links.fields.' + ('to' === type ? 'priceTo' : 'priceFrom'))}, ${translate('resources.crwl/links.measure.currency')}`}
    />
  )
}

export default PriceField