export default {
  companies: {
    name: 'Customer |||| Customers',
    fields: {
      name: 'Title',
      enabled: 'Enabled',
      blocked: 'Blocked',
      is_provider: 'Provider',
      type: 'Type',
      logo: 'Logo'
    },
    tabs: {
      summary: 'General data',
      branches: 'Branches',
      departments: 'Departments',
      grades: 'Grades',
      positions: 'Positions',
      inventory: 'Cars to order',
      files: 'Documents',
      workflows: 'Workflows',
      surveys: 'Surveys'
    }
  },
}