import * as React from "react";
import SelectWorkflowForm from '../Primitives/SelectWorkflowForm/SelectWorkflowForm'
import Grid from '@material-ui/core/Grid'
import WorkflowTimeline from './WorkflowTimeline'
import WorksheetTabs from './WorksheetTabs'

const WorksheetBuyPropertiesField = (props) => {

  const {record, resource} = props;

  if (!record.workflow) {
    return (
      <SelectWorkflowForm record={record} resource={resource} />
    )
  }
  else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <WorkflowTimeline {...props} />
        </Grid>
        <Grid item xs={12}>
          <WorksheetTabs {...props} />
        </Grid>
      </Grid>
    );
  }

}

export default WorksheetBuyPropertiesField;