export default {
  employees: {
    add: 'Ajouter employé',
    name: 'employé |||| employé',
    fields: {
      'companyBranch__company': 'Société',
      company: 'Société',
      company_branch: 'Branch',
      department: 'Département',
      job_position: 'Position',
      first_name: 'Prénom',
      last_name: 'Nom de famille',
      personal_code: 'Numéro de personnel',
      cost_center: 'Section de frais',
      email_address: 'E-Mail',
      enabled: 'activé',
      phone: 'numéro de téléphone',
      gender: 'Gender',
      dob: 'date de naissance'
    },
    tabs: {
      summary: 'Résumé',
      stats: 'Statistiques',
      performance: 'Performance',
      communication: 'Communication'
    },
    actions: {
      requestNewPassword: {
        button: 'Nouveau mot de passe',
        message: 'Un nouveau mot de passe a été envoyé aux employés \' email'
      }
    }
  },
}