import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useDataProvider, useTranslate, LoadingIndicator } from 'react-admin'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import QuestionsContainer from './QuestionsContainer'

const VersionSelector = (props) => {

  const { onVersionFound, autoRuModification: { year, gearboxCode, driveType, hp, engineSize }, bcModel } = props

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const [versions, setVersions] = useState([])
  const [reducedVersions, setReducedVersions] = useState([])
  const [loading, setLoading] = useState(false)

  const isVersion4WD = ({ name }) => {
    return name.indexOf('4WD') > 0 ||
      name.indexOf('4MATIC') > 0 ||
      name.indexOf('Quattro') > 0 ||
      name.indexOf('xDrive') > 0
  }

  
  useEffect(() => {
    setLoading(true)
    dataProvider.getList('b-c-versions', {
      filter: { model: bcModel['@id'] },
      pagination: { perPage: 300 },
      sort: { field: 'ord', order: 'ASC' }
    })
      .then(({ data }) => {
        //для некоторых внедорожников никогда не указывают 4wd ни в одной комплектации
        const isGlobal4WD = data.find(version => {
          return isVersion4WD(version)
        })

        const versions = data.filter(version => {
          const startYear = (new Date(version.tsCreate)).getFullYear()
          const endYear = (new Date(version.tsArchive)).getFullYear()

          const isYear = (isNaN(startYear) || startYear <= year) && (isNaN(endYear) || endYear >= year)
          const isTransmission =
            ('AUTOMATIC' === gearboxCode && (48 === version.transmissionType || 121 === version.transmissionType)) ||
            ('MECHANICAL' === gearboxCode && 21 === version.transmissionType) ||
            ('ROBOT' === gearboxCode && (119 === version.transmissionType || 121 === version.transmissionType)) ||
            ('VARIATOR' === gearboxCode && (120 === version.transmissionType || 121 === version.transmissionType))


          const isDriveType =
            ('ALL_WHEEL_DRIVE' === driveType && (!isGlobal4WD || isVersion4WD(version))) ||
            ('ALL_WHEEL_DRIVE' !== driveType && !isVersion4WD(version))


          const isHp = (version.name.indexOf(`HP)`) > 0 &&
            (
              version.name.indexOf(`(${hp}HP)`) > 0 ||
              version.name.indexOf(`(${hp + 1}HP)`) > 0 ||
              version.name.indexOf(`(${hp - 1}HP)`) > 0 ||
              version.name.indexOf(`(${hp + 2}HP)`) > 0 ||
              version.name.indexOf(`(${hp - 2}HP)`) > 0
            )) ||
            (version.name.indexOf(`HP)`) === -1)

          const isEngineSize = (version.name.indexOf(engineSize) === 0)

          return isYear&& isTransmission  && isDriveType && isHp && isEngineSize
        })
        
        setVersions(versions)
        setReducedVersions(versions.map(version => version.originId))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [bcModel])

  const reduceVersions = (questions) => {
    dataProvider.create('b-c-request-versions', {
      data: { inVersions: versions.map(version => version.originId), prevQuestions: questions }
    }).then(({ data: { outVersions = [] } }) => {
      if (outVersions.length === 1) {
        onVersionFound(outVersions[0])
      }
      setReducedVersions(outVersions)
    })
  }

  if (loading) {
    return <LoadingIndicator/>
  }

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12} spacing={3}>
        <List>
          <ListItem>
            <ListItemText primary={`Найдено ${reducedVersions.length} потенциальных комплектации`}/>
          </ListItem>
        </List>
      </Grid>
      <Grid container item xs={12} md={4} spacing={3}>
        <List>
          {
            versions.filter(v => reducedVersions.includes(v.originId)).map(version => {
              return (
                <ListItem key={version.id}>
                  <ListItemText secondary={version.name.replace(/\(\d{4}\)\s/, '')}/>
                </ListItem>
              )
            })
          }
        </List>
      </Grid>
      <Grid container item xs={12} md={8} spacing={3}>
        <QuestionsContainer onNullQuestion={() => onVersionFound(reducedVersions[0])} versionsIds={reducedVersions}
                            onAnswer={reduceVersions}/>
      </Grid>
    </Grid>
  )
}

export default VersionSelector