import React from 'react'
import PropTypes from 'prop-types'
import MultipleSelectField from './MultipleSelectField'

const options = {
  ALL_WHEEL_DRIVE: 'resources.crwl/links.measure.driveType.ALL_WHEEL_DRIVE',
  FORWARD_CONTROL: 'resources.crwl/links.measure.driveType.FORWARD_CONTROL',
  REAR_DRIVE: 'resources.crwl/links.measure.driveType.REAR_DRIVE'
}

const DriveTypeField = (props) =>
  <MultipleSelectField
    options={options}
    label={'resources.crwl/links.fields.driveType'}
    {...props}
  />

DriveTypeField.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.array
}

export default DriveTypeField