export default {
  'workflow/transitions': {
    add: 'Add transition',
    name: 'Transition |||| Transitions',
    fields: {
      workflow: 'Workflow',
      code: 'Code',
      name: 'Title',
      toPlace: 'To',
      jobPositions: 'Allowed for positions',
      fromPlaces: 'From',
      participant: 'Counterparty',
      allowed_for_creator: 'Allowed for creator',
      allowed_for_employee: 'Allowed for employee in application',
      allowed_for_any_previous: 'Allowed for any previous assignee',
      actions: 'Actions',
      comment_required: 'Comment required',
      document_required: 'Document required'
    },
    alerts: {
      comment_required: 'Please add required comment to the application before proceeding further',
      document_required: 'Please add required document to the application before proceeding further',
    },
    buttons: {
      actionsWithObject: 'Actions',
      noActionsAvailable: 'No actions available'
    },
    confirmDialog: {
      title: 'Please confirm the action',
      body: 'This is the last workflow step. Please confirm your action.'
    }
  },
}