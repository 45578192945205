import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslate } from 'react-admin'

export const ConfirmableButton = ({
  size,
  color,
  onClick,
  dialogTitle,
  dialogMessage,
  children
}) => {

  const [showDialog, setShowDialog] = useState(false)
  const translate = useTranslate()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleOk = () => {
    setShowDialog(false)
    onClick()
  }

  const handleCancel = () => {
    setShowDialog(false)
  }

  return (
    <>
      <Button size={size} color={color} onClick={handleClick}>
        {children}
      </Button>
      <Dialog
        open={showDialog}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate(dialogTitle)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate(dialogMessage)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary" autoFocus>
            {translate('ra.action.undo')}
          </Button>
          <Button onClick={handleOk} color="primary">
            {translate('ra.action.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}