import * as React from 'react'
import { FunctionField, ReferenceField, Show, SimpleShowLayout, TextField, useGetOne } from 'react-admin'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import WorksheetBuyPropertiesField from './WorksheetBuyPropertiesField'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const useStyles = makeStyles(theme => ({
  inlineField: {
    display: 'inline-block',
    width: '20%',
    '& span': {
      width: '100%'
    }
  },
}))

const WorksheetTitle = (props) => {
  const {record} = props;
  const { data, loading, error } = useGetOne('car-instances', record.carInstance);
  if (loading) { return <span />; }
  if (error) { return <span />; }
  return <span>Выкуп автомобиля {data.brandName} {data.modelName} {data.productionYear}</span>
}

const WorksheetShowNew = (props) => {

  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <React.Fragment>
      <Show title={<WorksheetTitle/>} {...props}>
        <SimpleShowLayout>
          <FunctionField
            className={classes.inlineField}
            label='Рабочий лист'
            render={record => `ID: ${record.originId}, ${(new Date(record.createdAt)).toLocaleString()}`}
          />
          {
            matches &&
            <ReferenceField source="companyBranch" reference="company-branches" link={false} className={classes.inlineField}>
              <FunctionField source="name" render={record => `${record.companyName} ${record.name}`} />
            </ReferenceField>
          }
          {
            matches &&
            <TextField source="creatorEmployee.fullName" className={classes.inlineField}/>
          }
          {
            matches &&
            <FunctionField
              source="assigneeEmployee.fullName"
              render={record => record.assigneeEmployee ? record.assigneeEmployee.fullName : ''}
              className={classes.inlineField}
            />
          }
          <WorksheetBuyPropertiesField />
        </SimpleShowLayout>
      </Show>

    </React.Fragment>
  )
}

export default WorksheetShowNew