import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  useTranslate
} from 'react-admin'

const ModelSelector = ({ brandsLoading, modelsLoading, models, brands, selectedBrand, selectedModel, onBrandSelect, onModelSelect }) => {

  const translate = useTranslate()

  const getById = (data, id) => {
    if (data.length) {
      return data.find((element) => element['id'] === id)
    }
    return ''
  }

  return (
    <Grid container spacing={8}>
      <Grid item xs={4}>
        <Autocomplete
          value={getById(brands, selectedBrand)}
          options={brands.filter(b => '-' !== b.name)}
          getOptionLabel={option => option.name}
          onChange={(event, value) => {
            onBrandSelect(value ? value['id'] : undefined)
          }}
          loading={brandsLoading}
          loadingText={translate('ra.page.loading')}
          renderInput={params => (
            <TextField
              {...params}
              label={translate('resources.crwl/links.fields.brand')}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          value={getById(models.filter(m => 0 === m.isArchive), selectedModel)}
          options={models.filter(m => 0 === m.isArchive)}
          getOptionLabel={option => option.name}
          onChange={(event, value) => {
            onModelSelect(value ? value['id'] : undefined)
          }}
          loading={modelsLoading}
          loadingText={translate('ra.page.loading')}
          renderInput={params => (
            <TextField
              {...params}
              label={'Актуальные модели'}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Autocomplete
          value={getById(models.filter(m => 1 === m.isArchive), selectedModel)}
          options={models.filter(m => 1 === m.isArchive)}
          getOptionLabel={option => option.name}
          onChange={(event, value) => {
            onModelSelect(value ? value['id'] : undefined)
          }}
          loading={modelsLoading}
          loadingText={translate('ra.page.loading')}
          renderInput={params => (
            <TextField
              {...params}
              label={'Архивные модели'}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default ModelSelector