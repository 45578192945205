import React from 'react'
import { BooleanInput, Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin'
import { parse } from 'query-string'

const JobGradeCreate = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { company: company_id_string } = parse(props.location.search)

  const company = company_id_string
  const redirect = company ? `/companies/${encodeURIComponent(company)}/grades` : false

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ company }}
        redirect={redirect}
      >
        <ReferenceInput
          source="company"
          reference="companies"
          allowEmpty
          validate={required()}
        >
          <SelectInput optionText="name" disabled/>
        </ReferenceInput>
        <TextInput source="name"/>
        <BooleanInput source="enabled"/>
      </SimpleForm>
    </Create>
  )
}

export default JobGradeCreate