import React, { useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import ResettableTextField from './ResettableTextField'

const MileageField = (props) => {

  const { initialValue, onChange, type } = props

  const translate = useTranslate()

  const handleChange = (value) => onChange(value.replace(/[^\d]/g, ''))

  return (
    <ResettableTextField
      resettable={true}
      value={initialValue}
      onChange={handleChange}
      variant='outlined'
      fullWidth
      label={translate('resources.crwl/links.fields.' + ('to' === type ? 'mileageTo' : 'from' === type ? 'mileageFrom' : 'mileage'))}
    />
  )
}

export default MileageField