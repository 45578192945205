import React, { Fragment } from 'react'
import compose from 'recompose/compose'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import { withStyles } from '@material-ui/core/styles'

import { MenuItemLink, translate } from 'react-admin'

const styles = theme => ({
  listItemText: {
    paddingLeft: 0,
    fontSize: '1rem',
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
})

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  classes,
  children,
  to
}) => (
  <Fragment>
    <MenuItemLink
      to={'..'}
      primaryText={name}
      leftIcon={isOpen ? <ExpandMore/> : icon}
      onClick={handleToggle}
    />
    <Collapse in={!!isOpen} timeout="auto" unmountOnExit>
      <List
        component="div"
        disablePadding
        className={
          sidebarIsOpen
            ? classes.sidebarIsOpen
            : classes.sidebarIsClosed
        }
      >
        {children}
      </List>
      <Divider/>
    </Collapse>
  </Fragment>
)

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(SubMenu)