import React from 'react'
import { BooleanInput, Create, ReferenceInput, SelectInput, SimpleForm, TextInput, required } from 'react-admin'

export const CompanyCreate = ({ classes, ...props }) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={required()}
      />
      <ReferenceInput
        source="type"
        reference="company-types"
        validate={required()}
      >
        <SelectInput optionText="name"/>
      </ReferenceInput>
      <BooleanInput
        source="enabled"
      />
    </SimpleForm>
  </Create>
)

export default CompanyCreate