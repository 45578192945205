export default {
  'questionnaire/questions': {
    add: 'Добавить вопрос',
    name: 'Вопрос |||| Вопросы',
    fields: {
      questionnaire: 'Опрос',
      ord: 'Порядковый номер',
      companyTypes: 'Доступно для компаний',
      questionType: 'Тип вопроса',
      questionText: 'Текст вопроса',
      isMust: 'Обязательный',
      questionGroup: 'Группа',
      enabled: 'Активный',
    },
    types: {
      QUESTION_TYPE_YES_NO: 'Да/Нет',
      QUESTION_TYPE_RADIO_GROUP: 'Радиогруппа',
      QUESTION_TYPE_CHECKBOX_GROUP: 'Чекбоксгруппа',
      QUESTION_TYPE_RADIO_GROUP_FREETEXT: 'Радиогруппа + свой вариант',
      QUESTION_TYPE_FREETEXT: 'Текст',
    },
    tabs: {
      question: 'Вопрос',
      answers: 'Ответы'
    },
    actions: {
      backToQuestionnaire: 'Назад к опросу'
    }
  }
}