import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

const CompanyTypeCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name"/>
        <TextInput source="code"/>
      </SimpleForm>
    </Create>
  )
}

export default CompanyTypeCreate