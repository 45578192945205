import Group from './Group/en'
import Options from './Options/en'
import Question from './Question/en'
import Questionnaire from './Questionnaire/en'
import Survey from './Survey/en'

export default {
  ...Group,
  ...Options,
  ...Question,
  ...Questionnaire,
  ...Survey,
}