import React from 'react'
import { BooleanInput, Edit, SimpleForm, TextInput } from 'react-admin'

const JobGradeTitle = ({ record }) => {
  return <span>{record ? record.title : ''}</span>
}

const JobGradeEdit = props => {

  const redirect = (basePath, id, data) => `/companies/${encodeURIComponent(data.company)}/grades`

  return (
    <Edit {...props} title={<JobGradeTitle/>}>
      <SimpleForm
        redirect={redirect}
      >
        <TextInput source="name"/>
        <BooleanInput source="enabled"/>
      </SimpleForm>
    </Edit>
  )
}

export default JobGradeEdit