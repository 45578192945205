import React from 'react'
import {
  BooleanField,
  Datagrid,
  EditButton,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  TextField
} from 'react-admin'
import { isGranted, ROLE_COMPANY_ADMIN } from '../../utils/AuthUtils'
import { Route } from 'react-router'
import { Drawer } from '@material-ui/core'
import { RequestWorkflowCreate } from '../Workflow/RequestCopyWorkflowCreate'

const WorkflowList = (props) => {

  return (
    <React.Fragment>
      <ReferenceManyField
        reference="workflow/workflows"
        target="company"
        addLabel={false}
        sort={{ field: 'name', order: 'ASC' }}
        pagination={<Pagination/>}
        {...props}
      >
        <Datagrid>
          <TextField source="name"/>
          <ReferenceField
            source="type"
            reference="workflow/types"
          >
            <TextField source="name"/>
          </ReferenceField>
          <ReferenceField
            source="company"
            reference="companies"
            linkType={false}
          >
            <TextField source="name"/>
          </ReferenceField>
          <BooleanField source="enabled"/>

          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <EditButton label=""/>
          }
        </Datagrid>
      </ReferenceManyField>

      <Route
        path="/workflow/request-copy-workflow/create"
        render={() => {
          console.log('route works')
          return (
            <RequestWorkflowCreate {...props} />
          )
        }}
      />
    </React.Fragment>
  )

}

export default WorkflowList