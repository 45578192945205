import Worksheet from './Worksheet/fr'
import WorksheetFinance from './WorksheetFinance/fr'
import WorksheetInsurance from './WorksheetInsurance/fr'
import WorksheetNew from './WorksheetNew/fr'
import WorksheetSearch from './WorksheetSearch/fr'
import WorksheetTradeIn from './WorksheetTradeIn/fr'
import WorksheetUsed from './WorksheetUsed/fr'

export default {
  ...Worksheet,
  ...WorksheetFinance,
  ...WorksheetInsurance,
  ...WorksheetNew,
  ...WorksheetSearch,
  ...WorksheetTradeIn,
  ...WorksheetUsed
}