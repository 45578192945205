import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import PhotoIcon from '@material-ui/icons/Image'
import DescriptionIcon from '@material-ui/icons/Notes'
import VideoIcon from '@material-ui/icons/VideoLibrary'
import Badge from '@material-ui/core/Badge'

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
})

const ModelMenu = ({ classes, selectedItem, selectItem, photos, videos, descriptions, mapping, loading }) => {

  return (
    <div className={classes.root}>
      <List component="nav">
        <ListItem button selected={'photo' === selectedItem} onClick={selectItem('photo')}>
          <ListItemIcon>
            <PhotoIcon/>
          </ListItemIcon>
          <ListItemText primary="Фото"/>
          {
            !loading ?
              <Badge badgeContent={photos} color="primary"><ListItemSecondaryAction/></Badge> :
              <ListItemSecondaryAction><CircularProgress size={20}/></ListItemSecondaryAction>
          }
        </ListItem>
        <ListItem button selected={'video' === selectedItem} onClick={selectItem('video')}>
          <ListItemIcon>
            <VideoIcon/>
          </ListItemIcon>
          <ListItemText primary="Видео"/>
          {
            !loading ?
              <Badge badgeContent={videos} color="primary"><ListItemSecondaryAction/></Badge> :
              <ListItemSecondaryAction><CircularProgress size={20}/></ListItemSecondaryAction>
          }
        </ListItem>
        <ListItem button selected={'description' === selectedItem} onClick={selectItem('description')}>
          <ListItemIcon>
            <DescriptionIcon/>
          </ListItemIcon>
          <ListItemText primary="Описание"/>
          {
            !loading ?
              <Badge badgeContent={descriptions} color="primary"><ListItemSecondaryAction/></Badge> :
              <ListItemSecondaryAction><CircularProgress size={20}/></ListItemSecondaryAction>
          }
        </ListItem>
      </List>
      <Divider/>
      <List component="nav">
        <ListItem button selected={'mapping' === selectedItem} onClick={selectItem('mapping')}>
          <ListItemText primary="auto.ru"/>
          {
            !loading ?
              <Badge color={!!mapping ? 'primary' : 'default'}><ListItemSecondaryAction/></Badge> :
              <ListItemSecondaryAction><CircularProgress size={20}/></ListItemSecondaryAction>
          }
        </ListItem>
      </List>
    </div>
  )

}

export default withStyles(styles)(ModelMenu)