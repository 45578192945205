import React, { useState, useEffect } from 'react'
import Badge from '@material-ui/core/Badge'

import Icon from '@material-ui/icons/Event'

//@TODO сделать через mercury
const CalendarIcon = (props) => {

  const [numEvents, setNumEvents] = useState(0)

  const getEvents = () => {

  }

  useEffect(() => {
    getEvents()
    const checker = setInterval(getEvents, 50000)
    return () => clearInterval(checker)
  }, [])

  return (
    numEvents > 0 ? <Badge badgeContent={numEvents} color="primary"> <Icon/> </Badge> : <Icon/>
  )
}

export default CalendarIcon