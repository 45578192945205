import { StorageFactory } from './StorageFactory'
import * as JWTUtils from './JWTUtils'
import config from '../config/config'

const ACCESS_TOKEN_NAME = 'ACCESS_TOKEN'
const ACCESS_TOKEN_EXP = 'ACCESS_TOKEN_EXP'
const ACCESS_TOKEN_ROLE = 'ACCESS_TOKEN_ROLE'
const REFRESH_TOKEN_NAME = 'REFRESH_TOKEN'
const REFRESH_TOKEN_EXP = 'REFRESH_TOKEN_EXP'

export const ROLE_USER = 'ROLE_USER'
export const ROLE_CUSTOMER = 'ROLE_CUSTOMER'
export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE'
export const ROLE_BRANCH_MANAGER = 'ROLE_BRANCH_MANAGER'
export const ROLE_COMPANY_MANAGER = 'ROLE_COMPANY_MANAGER'
export const ROLE_COMPANY_ADMIN = 'ROLE_COMPANY_ADMIN'
export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_DEALER = 'ROLE_DEALER'

export const getAccessToken = () => StorageFactory().getItem(ACCESS_TOKEN_NAME)
export const getRefreshToken = () => StorageFactory().getItem(REFRESH_TOKEN_NAME)
export const getAccessTokenFromRequest = (req) => {
  if (ACCESS_TOKEN_NAME in req.cookies) {
    return req.cookies[ACCESS_TOKEN_NAME]
  }
  return null
}

export const setToken = (access_token, refresh_token) => {
  StorageFactory().setItem(ACCESS_TOKEN_NAME, access_token)
  StorageFactory().setItem(ACCESS_TOKEN_EXP, JWTUtils.getTokenExpTimestamp(access_token))
  StorageFactory().setItem(REFRESH_TOKEN_NAME, refresh_token)
}

export const deleteToken = () => {
  StorageFactory().removeItem(ACCESS_TOKEN_NAME)
  StorageFactory().removeItem(ACCESS_TOKEN_EXP)
  StorageFactory().removeItem(REFRESH_TOKEN_NAME)
}

export const setRole = (access_token) => {
  StorageFactory().setItem(ACCESS_TOKEN_ROLE, getTokenClaim(access_token, 'roles'))
}

export const deleteRole = () => {
  StorageFactory().removeItem(ACCESS_TOKEN_ROLE)
}

export const getRole = () => {
  return StorageFactory().getItem(ACCESS_TOKEN_ROLE)
}

export const isGranted = (role, permissions = null) => {
  const rolesAvailable = [
    ROLE_USER,
    ROLE_CUSTOMER,
    ROLE_EMPLOYEE,
    ROLE_BRANCH_MANAGER,
    ROLE_COMPANY_MANAGER,
    ROLE_COMPANY_ADMIN,
    ROLE_ADMIN
  ]
  try {
    let result = false
    const roles = JSON.parse(permissions || getRole())
    if (ROLE_DEALER !== role) {
      const minKey = rolesAvailable.indexOf(role)

      if (Array.isArray(roles)) {
        roles.forEach(r => {
          if (rolesAvailable.indexOf(r) >= minKey) {
            result = true
          }
        })
      }
    } else {
      result = roles.indexOf(role) > -1
    }
    return result
  } catch (e) {
    return false
  }
}

export const isTokenValid = (token) => {
  return !JWTUtils.isTokenExpired(token)
}

export const getTokenClaim = (token, claim) => {
  return JWTUtils.getTokenPayload(token)[claim]
}

export const getTokenPayload = (token) => {
  return JWTUtils.getTokenPayload(token)
}

export const isLoggedIn = (isLoggedIn) => {
  return (
    isLoggedIn === 1 ||
    isLoggedIn === true ||
    isLoggedIn === 'yes' ||
    isLoggedIn === 'true'
  )
}

export const refreshToken = () => {
  const refreshToken = getRefreshToken()

  return fetch(`${config.api}/token/refresh`, {
    method: 'POST',
    body: JSON.stringify({ refresh_token: refreshToken }),
    headers: { 'Content-Type': 'application/json' }
  })
    .then(async (response) => {
      if (200 !== response.status) {
        throw(new Error())
      }
      console.log('refreshToken then', response)
    })
    .catch((e) => {
      throw(new Error())
    })
}