import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

const LinkSearchField = ({ onSubmit }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const [value, setValue] = useState('')

  const onChange = event => {
    const res = (event.target.value.match(/\d{9,11}/i))
    try {
      setValue(null !== res ? res[0] : '')
    } catch (e) {
      setValue(event.target.value)
    }
  }

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={translate('resources.crwl/links.filter.linkPlaceholder')}
        inputProps={{ 'aria-label': translate('resources.crwl/links.filter.linkPlaceholder') }}
        onChange={onChange}
      />

      <Divider className={classes.divider} orientation="vertical"/>
      <IconButton className={classes.iconButton} aria-label="search" onClick={() => onSubmit(value)}>
        <SearchIcon/>
      </IconButton>
    </Paper>
  )
}

export default LinkSearchField