import React from 'react'
import { Resource } from 'react-admin'

export default permissions => [
  <Resource
    name="car-instances"
  />,
  <Resource
    name="car-photos"
  />,
  <Resource
    name="reference/dimple-locations"
  />,
  <Resource
    name="reference/dimple-types"
  />,
  <Resource
    name="dimples"
  />,
  <Resource
    name="diagnostics"
  />,
  <Resource
    name="reference/check-points"
  />,
  <Resource
    name="reference/check-point-blocks"
  />,
  <Resource
    name="reference/check-point-levels"
  />,
  <Resource
    name="car-documents"
  />,
]