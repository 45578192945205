export default {
  'app.auth.form.title.login': 'Einloggen',
  'app.auth.form.title.register': 'Registrieren',
  'app.auth.form.title.password-recovery': 'Password zurücksetzen',
  'app.auth.form.description.password-recovery': 'Wir haben ihnen ein E-Mail geschickt. Füllen sie den gesendeten Code ein und setzen sie ein neues Passwort.',
  'app.auth.form.description.passwordless.code': 'Wir haben ihnen einen Login Code per E-Mail zugestellt',
  'app.auth.form.link.register': 'Registrieren',
  'app.auth.form.link.forgot-password': 'Passwort vergessen',
  'app.auth.form.link.email': 'Einmalcode zum einloggen',
  'app.auth.form.link.login': 'Passwort einloggen',
  'app.auth.form.link.logout': 'Ausloggen',
  'app.auth.form.email': 'E-Mail',
  'app.auth.form.code': 'Code vom E-Mail',
  'app.auth.form.firstName': 'Vorname',
  'app.auth.form.lastName': 'Nachname',
  'app.auth.form.password': 'Passwort',
  'app.auth.form.password.new': 'Neues Passwort',
  'app.auth.form.password.confirm': 'Passwort bestätigen',
  'app.auth.form.password.changed': 'Passwort wurde geändert',
  'app.auth.form.signin': 'Einloggen',
  'app.auth.form.validation.invalid.email': 'Ungültiges E-Mail',
  'app.auth.form.email.not.found': 'E-Mail nicht gefunden',
  'app.auth.form.back': 'Zurück',
  'app.auth.form.register': 'Registrieren',
  'app.auth.error.bad.credentials': 'Ungültiges E-Mail oder Passwort',
  'app.form.error.passwords.do.not.match': 'Passwort stimmt nicht ',
  'app.form.error.passwords.empty': 'Passwortfeld kann nicht leer sein',
  'app.form.error.code.empty': 'Code kann nicht leer sein',
  'app.form.error.code': 'Ungültiger Code',
}