import React from 'react'
import { Resource } from 'react-admin'
import TagsList from './List'
import TagEdit from './Edit'
import TagCreate from './Create'

export default permissions => [

  <Resource
    name="crwl/tags"
    list={TagsList}
    edit={TagEdit}
    create={TagCreate}
  />,

]