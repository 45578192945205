import Group from './Group/fr'
import Options from './Options/fr'
import Question from './Question/fr'
import Questionnaire from './Questionnaire/fr'
import Survey from './Survey/fr'

export default {
  ...Group,
  ...Options,
  ...Question,
  ...Questionnaire,
  ...Survey,
}