import React from 'react'
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DeleteButton,
  Filter,
  List,
  NullableBooleanInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'

const ListFilter = (props) =>
  <Filter {...props}>
    <TextInput source="name" alwaysOn/>
    <NullableBooleanInput source="enabled" alwaysOn/>
    <ReferenceInput
      source="type"
      reference="workflow/types"
      alwaysOn
      perPage={1000}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput helperText="" optionText="name" allowEmpty emptyValue={null}/>
    </ReferenceInput>

    <ReferenceInput
      source="company"
      reference="companies"
      alwaysOn
      sort={{ field: 'name', order: 'ASC' }}
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput helperText="" optionText="name" allowEmpty emptyValue={null}/>
    </ReferenceInput>
  </Filter>

export const WorkflowList = props => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }} filters={<ListFilter/>} bulkActionButtons={false}
        exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source="name"/>
      <ReferenceField
        source="type"
        reference="workflow/types"
        linkType={false}
      >
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField
        source="company"
        reference="companies"
      >
        <TextField source="name"/>
      </ReferenceField>
      <BooleanField source="enabled"/>
      <DeleteButton label="" redirect={false}/>z
    </Datagrid>
  </List>
)