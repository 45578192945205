import Actions from './Action/en'
import Participant from './Participant/en'
import Place from './Place/en'
import RequestToCopy from './RequestToCopy/en'
import SubjectType from './SubjectType/en'
import Transition from './Transition/en'
import Type from './Type/en'
import Workflow from './Workflow/en'

export default {
  ...Actions,
  ...Participant,
  ...Place,
  ...RequestToCopy,
  ...SubjectType,
  ...Transition,
  ...Type,
  ...Workflow,
}