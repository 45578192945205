// in src/posts.js
import React from 'react'
import { List, Datagrid, TextField, FunctionField, EditButton, DeleteButton } from 'react-admin'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import colors from './colors'
import TagIcon from '@material-ui/icons/LocalOffer'

const useStyles = makeStyles(colors)

const TagsList = (props) => {

  const classes = useStyles()

  return (
    <List sort={{ field: 'title', order: 'ASC' }} bulkActionButtons={false} exporter={false} {...props}>
      <Datagrid>
        <TextField source="title"/>
        <FunctionField
          source="color"
          render={record => <Avatar className={classes[record.color]}><TagIcon/></Avatar>}
        />
        <EditButton label=""/>
        <DeleteButton label=""/>
      </Datagrid>
    </List>
  )
}

export default TagsList