import CRM from './CRM/ru'
import General from './General/ru'
import Organization from './Organization/ru'
import Person from './Person/ru'
import Questionnaire from './Questionnaire/ru'
import CarInstance from './CarInstance/ru'
import CalendarEvents from './CalendarEvents/ru'
import Tags from './Tags/ru'
import Comments from './Comments/ru'
import Tasks from './Tasks/ru'
import User from './User/ru'
import Workflow from './Workflow/ru'
import WorksheetsBuy from './WorksheetsBuy/ru'
import CRWL from './CRWL/ru'
import Dimples from './Dimples/ru'

export default {
  resources: {
    ...CarInstance,
    ...CRM,
    ...General,
    ...Organization,
    ...Person,
    ...Questionnaire,
    ...CalendarEvents,
    ...Tags,
    ...Comments,
    ...Tasks,
    ...Workflow,
    ...WorksheetsBuy,
    ...User,
    ...CRWL,
    ...Dimples,
  },
  navigation: {
    first: 'Начало'
  }
}
