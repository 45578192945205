import React from 'react'
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin'

const Title = ({ record }) => {
  return <span>{record ? record.name : ''}</span>
}

export const ActionEdit = props => {
  const redirect = (basePath, id, data) => `/workflow/types/${encodeURIComponent(data.workflowType)}/actions`

  return (
    <Edit {...props} title={<Title/>}>
      <SimpleForm
        redirect={redirect}
      >
        <TextInput source="name"/>
        <SelectInput source="workflowEventName" choices={[
          { id: 'workflow.guard', name: 'Guard event' },
          { id: 'workflow.leave', name: 'Leave event' },
          { id: 'workflow.transition', name: 'Transition event' },
          { id: 'workflow.enter', name: 'Enter event' },
          { id: 'workflow.entered', name: 'Entered event' },
          { id: 'workflow.completed', name: 'Completed event' },
        ]}/>
        <TextInput source="commandName"/>
      </SimpleForm>
    </Edit>
  )
}