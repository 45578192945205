import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useDataProvider, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import { StorageFactory } from '../../../../utils/StorageFactory'

const CityField = (props) => {

  const { region } = props

  const [cities, setCities] = useState([])
  const [loading, setLoading] = useState(false)

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const [selectedCity, setSelectedCity] = useState(StorageFactory().getItem('geo_city'))

  useEffect(() => {
    if (null !== region) {
      //setSelectedCity(null);
      setLoading(true)
      dataProvider.getList('geo/cities', {
        filter: { region: region },
        pagination: { perPage: 300 },
        sort: { field: 'name', order: 'ASC' }
      })
        .then(data => {
          setCities(data.data)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setCities([])
      setSelectedCity(null)
    }
  }, [region])

  const onChange = (event, value) => {
    const city = value
    if (city) {
      StorageFactory().setItem('geo_city', city['id'])
      StorageFactory().setItem('geo_lat', city['lat'])
      StorageFactory().setItem('geo_lon', city['lon'])
      setSelectedCity(city['id'])
    } else {
      StorageFactory().setItem('geo_city', null)
      setSelectedCity(null)
    }
  }

  const getById = (id) => {
    if (cities.length) {
      return cities.find((element) => `${element['id']}` === `${id}`)
    }
    return ''
  }

  return (
    <Autocomplete
      options={cities}
      getOptionLabel={option => option.name}
      onChange={onChange}
      value={getById(selectedCity)}
      loading={loading}
      loadingText={translate('ra.page.loading')}
      renderInput={params => (
        <TextField {...params} label={translate('resources.crwl/links.fields.city')} variant="outlined"
                   fullWidth/>
      )}
    />
  )
}

CityField.propTypes = {
  onChange: PropTypes.func,
  region: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default CityField