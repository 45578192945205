export default {
  'crwl/tags': {
    name: 'Метка  |||| Метки',
    fields: {
      title: 'Метка',
      color: 'Цвет'
    },
    buttons: {
      add: 'Добавить метку',
      create: 'Создать новую метку'
    },
    dialogs: {
      create: {
        title: 'Создать новую метку'
      }
    }
  },
}