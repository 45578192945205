export default {
  'questionnaire/questions': {
    add: 'Frage hinzufügen',
    name: 'Frage |||| Fragen',
    fields: {
      questionnaire: 'Fragebogen',
      ord: 'Bestellung',
      companyTypes: 'Zulässige Firmentypen',
      questionType: 'Fragentyp',
      questionText: 'Fragetext',
      isMust: 'Muss',
      questionGroup: 'Gruppe',
    },
    types: {
      QUESTION_TYPE_YES_NO: 'Ja oder Nein',
      QUESTION_TYPE_RADIO_GROUP: 'Multiple-Choice-Fragen mit einer richtigen Antwort',
      QUESTION_TYPE_CHECKBOX_GROUP: 'Multiple-Choice-Fragen mit mehr als einer richtigen Antwort',
      QUESTION_TYPE_RADIO_GROUP_FREETEXT: 'Multiple-Choice-Fragen mit mehr als einem richtigen Antwort- und Freitextfeld',
      QUESTION_TYPE_FREETEXT: 'Freetext-Feld',
    },
    tabs: {
      question: 'Frage',
      answers: 'Antworten'
    },
    actions: {
      backToQuestionnaire: 'Zurück zum Fragebogen'
    }
  }
}