import React from 'react'
import {
  Edit,
  FormDataConsumer,
  FunctionField,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  useTranslate
} from 'react-admin'
import PeriodInput from '../Inputs/DateInput/PeriodInput'

import calendarResources from './calendarResources'

const EventEdit = (props) => {

  const translate = useTranslate()

  const validate = (values) => {
    const errors = {}

    if ((new Date(values.period[0]) > new Date(values.period[1]))) {
      errors.period = translate('resources.calendar-events/events.errors.finishEarlieStart')
    }

    if (!values['type']) {
      errors.type = translate('resources.calendar-events/events.errors.typeRequired')
    }

    return errors
  }

  return (
    <Edit {...props}>
      <SimpleForm redirect={false} validate={validate}>
        <FunctionField label="" source="subjectResourceName" render={record =>
          calendarResources.find(r => r.name === record.subjectResourceName).showLink(record)
        }/>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            <ReferenceInput
              source="type"
              reference="calendar-events/event-types"
              filter={{ 'subjectType.resourceName': formData.subjectResourceName }}
              sort={{ field: 'name', order: 'ASC' }}
              {...rest}
            >
              <SelectInput optionText="name"/>
            </ReferenceInput>
          }
        </FormDataConsumer>
        <TextInput multiline fullWidth source="description"/>
        <PeriodInput source="period"/>
      </SimpleForm>
    </Edit>
  )

}

export default EventEdit