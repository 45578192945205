export default {
  companies: {
    name: 'Kunde |||| Kunden',
    fields: {
      name: 'Titel',
      enabled: 'aktiviert',
      blocked: 'Gesperrt',
      is_provider: 'Anbieter',
      type: 'Type',
      logo: 'Logo'
    },
    tabs: {
      summary: 'Allgemeine Daten',
      branches: 'Branche',
      departments: 'Abteilung',
      grades: 'Job Level',
      positions: 'Positionen',
      inventory: 'Bestellbare Fahrzeuge',
      files: 'Dokumente',
      workflows: 'Arbeitsabläufe',
      surveys: 'Umfragen'
    }
  },
}