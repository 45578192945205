import React, { useEffect, useState } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { sheetsManager } from '@material-ui/core/es/styles/withStyles'
import sma from 'simple-moving-avg'

const styles = theme => ({
  tooltipRoot: {
    padding: theme.spacing()
  }
})

const TitsPlot = ({ classes, data, referencePrice = null }) => {

  const [points, setPoints] = useState([]);

  useEffect(() => {
    let step = 0

    const minPriceYear = data.year.data.reduce((accumulator, currentValue) => {
      return currentValue.price < accumulator ? currentValue.price : accumulator
    }, 1000000000000)

    const maxPriceYear = data.year.data.reduce((accumulator, currentValue) => {
      return currentValue.price > accumulator ? currentValue.price : accumulator
    }, 0)

    const minPriceNextYear = data.nextYear.data.reduce((accumulator, currentValue) => {
      return currentValue.price < accumulator ? currentValue.price : accumulator
    }, 1000000000000)

    const maxPriceNextYear = data.nextYear.data.reduce((accumulator, currentValue) => {
      return currentValue.price > accumulator ? currentValue.price : accumulator
    }, 0)

    const minPrice = Math.min(minPriceYear, minPriceNextYear)
    const maxPrice = Math.max(maxPriceYear, maxPriceNextYear)

    if (referencePrice < 500000) {
      step = 10000
    }
    if (referencePrice >= 500000 && referencePrice < 1000000) {
      step = 20000
    }
    if (referencePrice >= 1000000 && referencePrice < 2000000) {
      step = 50000
    }
    if (referencePrice > 2000000) {
      step = 100000
    }

    const points = []
    for (let i = minPriceYear; i <= maxPriceYear; i = i + step) {
      points.push({
        from: i,
        to: i + step,
        year: data.year.data.filter(v => v.price >= i && v.price < i + step).length,
        nextYear: data.nextYear.data.filter(v => v.price >= i && v.price < i + step).length,
      })
    }

    const smaYear = sma(points.map(p => p.year), 3, false)
    const smaNextYear = sma(points.map(p => p.nextYear), 3, false)

    points.forEach((currentValue, index, array) => {
      currentValue.smaYear = Math.ceil(smaYear[index])
      currentValue.smaNextYear = Math.ceil(smaNextYear[index])
    })

    setPoints(points);
  }, [data])

  return points.length ? (
    <div>
      <Typography variant='h6'>Распределение предложений на рынке</Typography>

      <Typography variant='body1' paragraph={false}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={points}
            margin={{ top: 20, right: 30, left: 10, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="from" type="number" domain={['dataMin', 'dataMax']}
                   tickFormatter={(val) => new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0 }).format(val)}/>
            <YAxis/>
            <Tooltip content={<CustomTooltip className={classes.tooltipRoot}/>}/>
            <Legend />
            <Line type="monotone" dataKey="year" name={`${data.year.year} г.`} stroke="#8884d8"/>
            <Line type="monotone" dataKey="nextYear" name={`${data.nextYear.year} г.`} stroke="#82ca9d"/>
            {/*<Line type="monotone" dataKey="smaNextYear" name={`${data.nextYear.year} г.`} stroke="#82ca9d"/>*/}
            <ReferenceLine x={referencePrice} stroke="red"/>

          </LineChart>
        </ResponsiveContainer>
      </Typography>
    </div>
  ) : <Typography variant={'body1'}>Объявления не найдены</Typography>
}

const CustomTooltip = (props) => {
  const { active, payload, label, classes, className } = props

  if (active) {
    return (
      <Paper className={className}>
        <Typography variant={'body1'}>
          Количество предложений в диапазоне<br/>{`от 
                    ${new Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: 0,
          currency: 'RUB',
          style: 'currency'
        }).format(payload[0].payload.from)} до
                    ${new Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: 0,
          currency: 'RUB',
          style: 'currency'
        }).format(payload[0].payload.to)}`}
        </Typography>
        <Typography style={{ color: payload[0].stroke }}>{`${payload[0].name} - ${payload[0].value} шт.`}</Typography>
        <Typography style={{ color: payload[1].stroke }}>{`${payload[1].name} - ${payload[1].value} шт.`}</Typography>
      </Paper>
    )
  }

  return null
}

export default withStyles(styles)(TitsPlot)