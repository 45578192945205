import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useTranslate } from 'react-admin'
import { COMPONENT_STATE_SUBMITTING, COMPONENT_STATE_SUCCESS } from './AuthRoot'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  cell: {
    padding: 0,
    textAlign: 'center'
  },
  textField: {
    width: '100%'
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
    padding: '10px'
  }
})

const required = (value) => {
  if (null == value || !value.toString().trim().length) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return 'app.form.required'
  }
  return true
}

const AuthPasswordlessCodeForm = (props) => {

  const {
    credentials,
    errors,
    onError,
    onChange,
    onSubmit,
    classes,
    componentState
  } = props

  const translate = useTranslate()

  const isCodeValid = () => {
    const r = required(credentials.code)
    if (r !== true) {
      onError('code', r)
      return false
    }
    return true
  }

  const handleChange = (field) => {
    return (event) => {
      onChange(field, event.target.value)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (isCodeValid()) {
      onSubmit()
    }
  }

  const formLocked = COMPONENT_STATE_SUBMITTING === componentState

  return (
    <React.Fragment>
      {
        COMPONENT_STATE_SUCCESS !== componentState &&
        <Grid container spacing={0}>
          <Grid item xs={12} className={classes.cell}>
            <Typography>{translate('app.auth.form.description.passwordless.code')}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.cell}>
            <TextField
              required
              error={null != errors.code}
              label={null == errors.code ? translate('app.auth.form.code') : translate(errors.code)}
              className={classes.textField}
              margin="normal"
              onChange={handleChange('code')}
              value={credentials.code}
              disabled={formLocked}
            />
          </Grid>
          <Grid item xs={12} className={classes.cell}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.submit}
              onClick={handleSubmit}
              disabled={formLocked}
            >
              {translate('app.auth.form.signin')}
            </Button>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  )
}

AuthPasswordlessCodeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object,
  credentials: PropTypes.object,
  componentState: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func
}

export default withStyles(styles)(AuthPasswordlessCodeForm)