export default {
  'job-positions': {
    add: 'Добавить должность',
    name: 'Должность |||| Должности',
    fields: {
      name: 'Название должности',
      company: 'Дилер',
      department: 'Отдел',
      jobGrade: 'Грейд',
      roles: 'Роль в системе',
      applicable_for_the_car: 'Имеет право на авто',
      enabled: 'Активный',
    }
  },
}