import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import VersionSelector from './VersionSelector'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
}))

const FoundVersion = ({ versionName, onUnlink }) => {

  const classes = useStyles()

  return (
    <Paper m={3} className={classes.paper}>
      <Typography component="div">
        {versionName}
      </Typography>
      <Button variant="contained" onClick={onUnlink}>Изменить комплектацию</Button>
    </Paper>
  )
}

export default FoundVersion