import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LoadingIndicator, useDataProvider } from 'react-admin'
import UnlinkButton from './UnlinkButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'
import VersionLevelContainer from './VersionLevelContainer'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const EquipmentDataContainer = (props) => {

  const { beeCarsVersionId, unlink, year } = props
  const dataProvider = useDataProvider()
  const [blocks, setBlocks] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const classes = useStyles()

  useEffect(() => {
    dataProvider.create('b-c-request-equipment-datas', {
      data: { versionId: beeCarsVersionId }
    }).then(({ data }) => {
      setData(data.data.data.sort(sortByOrd))
      setBlocks(data.data.blocks.sort(sortByOrd))
      setLoading(false)
    })
  }, [beeCarsVersionId])

  function sortByOrd (a, b) {
    if (a.ord < b.ord) {
      return -1
    }
    if (a.ord > b.ord) {
      return 1
    }
    // a должно быть равным b
    return 0
  }

  if (loading) {
    return <LoadingIndicator/>
  }

  return (
    <div>
      <VersionLevelContainer bcVersionId={beeCarsVersionId} year={year}/>
      <UnlinkButton unlink={unlink}/>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          {
            blocks.map(block => {
                if (data.find(d => d.block === block.id)) {
                  return (
                    <React.Fragment>
                      <TableHead key={block.id}>
                        <TableRow>
                          <TableCell component="th">
                            <Typography variant={'h4'}>{block.name}</Typography>
                          </TableCell>
                          <TableCell component="th"/>
                          <TableCell component="th"/>
                        </TableRow>
                      </TableHead>
                      {
                        data.filter(d => d.block === block.id).map(d => {
                          return (
                            <TableRow key={d.id}>
                              <TableCell scope="row">
                                {d.name}
                              </TableCell></TableRow>
                          )
                        })
                      }
                    </React.Fragment>
                  )
                }
              }
            )
          }
        </Table>
      </TableContainer>
    </div>
  )

}

export default EquipmentDataContainer