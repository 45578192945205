export default {
  'company-files': {
    add: 'Добавить файл',
    header: 'Документы',
    name: 'Файл |||| Файлы',
    fields: {
      createdAt: 'Создан',
      name: 'Название',
      file: 'Файл',
      company: 'Компания'
    }
  }
}