export default {
  'workflow/places': {
    add: 'Добавить состояние',
    name: 'Состояние |||| Состояния',
    fields: {
      workflow: 'Процесс',
      name: 'Название',
      code: 'Код',
      isInitial: 'Начало',
      isSuccess: 'Успешно',
      isFail: 'Не успешно',
      maxMinutesInPlace: 'Время на этап (минуты)',
      overdueJobPositions: 'Уведомить при просрочке'
    },
    timeleft: 'Осталось',
    overdue: 'Необходимо было сделать дальнейшее действие до указанного времени. Текущее состояние имеет ограничение максимального времени пребывания в нем. Вы не перешли на следующий шаг во время.'
  },
}