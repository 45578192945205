export const getStartOfTheDay = (date) => {
  const d = new Date(date.valueOf())
  d.setHours(0, 0, 0)
  return d
}

export const getEndOfTheDay = (date) => {
  const d = new Date(date.valueOf())
  d.setHours(24, 59, 59)
  return d
}

export const getFirstDateOfMonth = (date) => {
  const d = new Date(date.valueOf())
  d.setDate(1)
  d.setHours(0, 0, 0)
  return d
}

export const getLastDateOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
}

export const getRoundedDate = (d = new Date()) => (minutes) => {
  const ms = 1000 * 60 * minutes // convert minutes to ms
  return new Date(Math.round(d.getTime() / ms) * ms)
}