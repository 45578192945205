import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  EditButton
} from 'react-admin'

export const CompanyTypeList = props => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={false} exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source="name"/>
      <TextField source="code"/>
      <EditButton label={''}/>
    </Datagrid>
  </List>
)