import React from 'react'
import {
  FunctionField,
  BooleanField,
  Datagrid,
  Filter,
  List,
  NullableBooleanInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput
} from 'react-admin'
import Avatar from '@material-ui/core/Avatar'
import DealerIcon from '@material-ui/icons/Business'
import config from '../../config/config'

const CompanyFilter = (props) =>
  <Filter {...props}>
    <TextInput source="name" alwaysOn/>
    <NullableBooleanInput source="enabled" alwaysOn/>
    <NullableBooleanInput source="blocked" alwaysOn/>
    <ReferenceInput
      source="type"
      reference="company-types"
      alwaysOn
      perPage={100}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput source="name"/>
    </ReferenceInput>
  </Filter>

export const CompanyList = props => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }} filters={<CompanyFilter/>} bulkActionButtons={false}
        exporter={false}>
    <Datagrid rowClick="show">
      <ReferenceField reference="logos" source="logo" allowEmpty={true}>
        <FunctionField source="contentUrl" render={record => {
          console.log('record.contentUrl', `${config.publicImagesRoot}${record.contentUrl}`)
          return (
            <Avatar src={`${config.publicImagesRoot}${record.contentUrl}`} style={props.style}>
              <DealerIcon/>
            </Avatar>
          )
        }}/>
      </ReferenceField>

      <TextField source="name"/>
      <ReferenceField
        source="type"
        reference="company-types"
        linkType={false}
      >
        <TextField source="name"/>
      </ReferenceField>
      <BooleanField source="enabled"/>
      <BooleanField source="blocked"/>
    </Datagrid>
  </List>
)