import React from 'react'
import {
  Create,
  FileField,
  FileInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar
} from 'react-admin'
import { parse } from 'query-string'

const CompanyFileCreate = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { company: company_id_string } = parse(props.location.search)

  const company = company_id_string
  const redirect = company ? `/companies/${encodeURIComponent(company)}/show/company-files` : false

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ company }}
        redirect={redirect}
      >
        <ReferenceInput
          source="company"
          reference="companies"
          allowEmpty
          validate={required()}
        >
          <SelectInput optionText="name" optionValue="id" disabled/>
        </ReferenceInput>
        <TextInput source="name" validate={required()}/>
        <FileInput source="file" validate={required()}>
          <FileField source="src" title="title" target="_blank"/>
        </FileInput>
      </SimpleForm>
    </Create>
  )
}

export default CompanyFileCreate