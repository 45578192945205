import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'

const CompanyTypeEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name"/>
        <TextInput source="code" disabled/>
      </SimpleForm>
    </Edit>
  )
}

export default CompanyTypeEdit