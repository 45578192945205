import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LoadingIndicator, useDataProvider, useNotify } from 'react-admin'
import TitsPlot from './TitsPlot'

const TitsContainer = (props) => {

  const {
    brand,
    model,
    generation,
    bodyType,
    doorsCount,
    gearbox,
    engineType,
    engineSize,
    driveType,
    year,
    hp,
    lat,
    lon,
    radius,
    referencePrice
  } = props

  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const requestPayload = {
    brand: brand,
    model: model,
    generation: generation,
    bodyType: bodyType,
    doorsCount: doorsCount,
    transmission: gearbox,
    engineType: engineType,
    engineSize: engineSize,
    driveType: driveType,
    year: year,
    hp: hp,
    lat: lat,
    lon: lon,
    radius: radius
  }

  useEffect(() => {
    setLoading(true)

    Promise.all([
      dataProvider.create('crwl/request-similar-cars', { data: requestPayload }),
      dataProvider.create('crwl/request-similar-cars', { data: { ...requestPayload, year: year + 1 } })
    ])
      .then(data => {
        setData({
          year: {
            year: year,
            data: data[0].data.result
          },
          nextYear: {
            year: year + 1,
            data: data[1].data.result
          }
        })
      })
      .finally(() => setLoading(false))
  }, [lat, lon, radius])

  return (!data || loading) ? <LoadingIndicator/> : <TitsPlot data={data} referencePrice={referencePrice}/>

}

TitsContainer.propTypes = {
  brand: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  generation: PropTypes.string,
  bodyType: PropTypes.string.isRequired,
  doorsCount: PropTypes.number,
  gearbox: PropTypes.string.isRequired,
  engineType: PropTypes.string.isRequired,
  engineSize: PropTypes.string.isRequired,
  driveType: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  hp: PropTypes.number,
  referencePrice: PropTypes.number
}

export default TitsContainer