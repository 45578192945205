import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CardMedia from '@material-ui/core/CardMedia'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import home from './home.png'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    width: 1000,
    marginLeft: '50px'
  },
  card: {
    width: 1000,
    boxShadow: 'none'
  },
  media: {
    height: 563,
  },
  buttonContainer: {
    marginLeft: 380
  }
}))

const Dashboard = (props) => {

  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={0}
      className={classes.root}
    >
      <Grid item xs={12}>
        <Card className={classes.card} square={true}>
          <CardMedia
            image={home}
            className={classes.media}
          />
          <div className={classes.buttonContainer}>
            <Button color="primary" variant={'contained'} component={RouterLink} to={'/crwl/links'}>
              Перейти к объявлениям
            </Button>
          </div>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Dashboard