import ru from './ru'
import en from './en'
import de from './de'
import fr from './fr'

export default {
  ru,
  en,
  fr,
  de
}