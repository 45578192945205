import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { translate } from 'react-admin'
import { Field } from 'react-final-form'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DateIcon from '@material-ui/icons/Event'
import Typography from '@material-ui/core/Typography'
import 'react-day-picker/lib/style.css'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import DayPicker from 'react-day-picker'
// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/ru'
import TimeSelector from './TimeSelector'
import red from '@material-ui/core/colors/red'
import { getRoundedDate } from '../../../utils/dateUtils'

const styles = theme => {
  return {
    root: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
      display: 'flex'
    },
    button: {
    },
    icon: {
      fontSize: 36,
      color: theme.palette.grey[600],
      flex: 'none'
    },
    card: {
      minWidth: 275,
    },
    typography: {
      padding: theme.spacing(2),
    },
    popper: {
      zIndex: theme.zIndex.modal + 1,
    },
    popperPaper: {
      borderRadius: 0,
    },
    red: {
      color: red[600]
    }
  }
}

const FieldComponent = translate(withStyles(styles)((props) => {
  const { input: { value, onChange }, meta: { error }, classes, locale, translate } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [openPopper, setOpenPopper] = useState(false)
  const [startDate, setStartDate] = useState(getRoundedDate(value ? new Date(value[0]) : new Date())(15))
  const [finishDate, setFinishDate] = useState(getRoundedDate(value ? new Date(value[1]) : new Date())(15))
  const [popperComponent, setPopperComponent] = useState(<div/>)

  const handleClick = (component) => event => {
    const { currentTarget } = event
    setPopperComponent(component)
    setAnchorEl(currentTarget)
    setOpenPopper(prev => anchorEl !== currentTarget || !prev)
  }

  useEffect(() => {
    onChange([startDate.toISOString(), finishDate.toISOString()])
  }, [startDate, finishDate])

  const handleStartDateClick = (day, { selected }) => {

    const newDate = new Date(startDate.getTime())
    newDate.setDate(day.getDate())
    newDate.setMonth(day.getMonth())
    newDate.setFullYear(day.getFullYear())

    setFinishDate(new Date(newDate))
    setStartDate(new Date(newDate))
    setOpenPopper(false)
  }

  const handleFinishDateClick = (day, { selected }) => {
    if (day < startDate && day.toLocaleDateString() !== startDate.toLocaleDateString()) return

    const newDate = finishDate
    newDate.setDate(day.getDate())
    newDate.setMonth(day.getMonth())
    newDate.setFullYear(day.getFullYear())

    setFinishDate(newDate)
    setOpenPopper(false)
  }

  const handleStartTimeClick = ({ hours, minutes }) => {
    const newDate = startDate
    newDate.setHours(hours)
    newDate.setMinutes(minutes)

    setStartDate(newDate)
    if (finishDate < newDate) {
      setFinishDate(newDate)
    }

    setOpenPopper(false)
  }

  const handleFinishTimeClick = ({ hours, minutes }) => {
    const newDate = finishDate
    newDate.setHours(hours)
    newDate.setMinutes(minutes)

    setFinishDate(newDate)
    setOpenPopper(false)
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Popper open={openPopper} anchorEl={anchorEl} placement='bottom-start' className={classes.popper}>
          <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
            <Paper className={classes.popperPaper}>
              <Typography>
                {
                  popperComponent
                }

              </Typography>
            </Paper>
          </ClickAwayListener>
        </Popper>

        <DateIcon className={classnames(classes.icon, error ? classes.red : '')}/>
        <Button
          className={classnames(classes.button, error ? classes.red : '')}
          size={'small'}
          onClick={
            handleClick(<DayPicker
              showOutsideDays
              localeUtils={MomentLocaleUtils}
              locale={locale}
              onDayClick={handleStartDateClick}
              selectedDays={startDate}
              disabledDays={[
                {
                  before: new Date(),
                },
              ]}
            />)
          }
        >
          {startDate.toLocaleDateString()}
        </Button>
        <Button
          className={classnames(classes.button, error ? classes.red : '')}
          size={'small'}
          onClick={handleClick(
            <TimeSelector
              onSelect={handleStartTimeClick}
              value={startDate}
            />)}
        >
          {startDate.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })}
        </Button>

        <Typography variant="h6" className={error ? classes.red : ''}>–</Typography>

        <Button
          className={classnames(classes.button, error ? classes.red : '')}
          size={'small'}
          onClick={handleClick(
            <TimeSelector
              onSelect={handleFinishTimeClick}
              value={finishDate}
              startFrom={
                startDate.getDate() === finishDate.getDate() &&
                startDate.getMonth() === finishDate.getMonth() &&
                startDate.getYear() === finishDate.getYear() ?
                  startDate : null
              }
            />)}
        >
          {finishDate.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })}
        </Button>

        <Button
          className={classnames(classes.button, error ? classes.red : '')}
          size={'small'}
          onClick={
            handleClick(<DayPicker
              showOutsideDays
              localeUtils={MomentLocaleUtils}
              locale={locale}
              onDayClick={handleFinishDateClick}
              selectedDays={finishDate}
              disabledDays={[
                {
                  before: startDate,
                },
              ]}
            />)
          }
        >
          {finishDate.toLocaleDateString()}
        </Button>

      </div>
      {
        error &&
        <Typography variant={'caption'} className={error ? classes.red : ''}>{error}</Typography>
      }
    </React.Fragment>

  )
}))

const PeriodInput = (props) => {
  const { id } = props

  return (
    <Field name={id} component={FieldComponent}/>
  )
}

export default PeriodInput
