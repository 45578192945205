export default {
  'job-grades': {
    add: 'Ajouter job leve',
    name: 'Level |||| Level',
    fields: {
      name: 'Titre',
      company: 'Société',
      enabled: 'activé',
    }
  },
}