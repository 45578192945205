export default {
  departments: {
    add: 'Добавить отдел',
    name: 'Отдел |||| Отделы',
    fields: {
      name: 'Название отдела',
      company: 'Дилер',
      enabled: 'Активный',
    }
  }
}