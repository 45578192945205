import React from 'react'
import {
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Pagination,
  ReferenceManyField,
  required,
  SimpleForm,
  TextField,
  TextInput,
  translate
} from 'react-admin'

const Title = ({ record }) => {
  return <>{record ? record.name : ''}</>
}

export const SubjectTypeEdit = translate(({ translate, ...props }) => (
  <Edit title={<Title/>} {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={required()}
      />
      <TextInput
        source="resourceName"
        validate={required()}
      />
      <TextInput
        multiline
        source="className"
      />

    </SimpleForm>
  </Edit>
))