import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import SingleSelectField from '../../CRWL/LinksListFilter/Fields/SingleSelectField'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '24px'
  },
  media: {
    height: 140,
  },
})

const NewDimpleForm = ({ onClose, onSave, type, types, locations }) => {
  const classes = useStyles()

  const [dimpleType, setDimpleType] = useState(null)
  const [dimpleSide, setDimpleSide] = useState(null)
  const [dimpleNode, setDimpleNode] = useState(null)
  const [dimplePart, setDimplePart] = useState(null)
  const [comment, setComment] = useState(null)
  const [error, setError] = useState(false)

  const doSave = () => {
    if (dimplePart) {
      onSave({ dimpleType, dimplePart, comment })
    } else {
      setError(true)
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Новое повреждение {type === 'interior' ? 'интерьера' : 'экстерьера'}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <SingleSelectField
              variant="filled"
              options={types.reduce((obj, item, index) => {
                obj[item.id] = item.type
                return obj
              }, {})}
              onChange={setDimpleType}
              label={'Тип повреждения'}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SingleSelectField
              variant="filled"
              options={locations.reduce((obj, item, index) => {
                if (item.type === type) {
                  obj[item.side] = item.side
                }
                return obj
              }, {})}
              onChange={setDimpleSide}
              label={'Место повреждения'}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SingleSelectField
              variant="filled"
              options={locations.reduce((obj, item, index) => {
                if (item.type === type && item.side === dimpleSide) {
                  obj[item.node] = item.node
                }
                return obj
              }, {})}
              onChange={setDimpleNode}
              label={'Поврежденный узел'}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SingleSelectField
              variant="filled"
              options={locations.reduce((obj, item, index) => {
                if (item.type === type && item.side === dimpleSide && item.node === dimpleNode) {
                  obj[item.id] = item.part
                }
                return obj
              }, {})}
              onChange={setDimplePart}
              label={'Поврежденный элемент'}
              error={error}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="Комментарий"
                multiline
                rowsMax="15"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                margin="normal"
                variant="filled"
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={doSave}>
          Сохранить
        </Button>
        <Button size="small" color="primary" onClick={onClose}>
          Отмена
        </Button>
      </CardActions>
    </Card>
  )
}

export default NewDimpleForm