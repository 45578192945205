import React from 'react'
import { Resource } from 'react-admin'
import { TaskList } from './TaskList'
import TaskEdit from './TaskEdit'
import TaskShow from './TaskShow'

export default permissions => [

  <Resource
    name="tasks"
    list={TaskList}
    show={TaskShow}
    edit={TaskEdit}
  />
]