import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'

import { ReferenceField, translate, FunctionField } from 'react-admin'

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: 0
  }
})

const CalendarEventsList = (props) => {

  const { classes, events, onSelect, locale } = props

  const [checked, setChecked] = useState([])

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
    onSelect(value)
  }

  const options = { weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }

  return (
    <div className={classes.root}>
      <List>
        {events.map(event => (
          <React.Fragment>
            <ListItem
              key={event.id}
              role={undefined}
              dense
              button
              onClick={() => console.log(event.id)}
              className={classes.listItem}
            >

              <ListItemText primary={
                <ReferenceField label="" linkType="edit" source="id" reference="tasks" record={event}
                                basePath={'/calendar-events/events'}>
                  <FunctionField
                    label="Name"
                    render={record =>
                      `${(new Date(record.startAt)).toLocaleString(locale, options)} – ${(new Date(record.finishAt)).toLocaleString(locale, options)}`
                    }
                  />
                </ReferenceField>
              } secondary={<div><Typography variant={'body1'}>{event.title}</Typography><Typography
                variant={'caption'}>{event.description}</Typography></div>}/>
              <Checkbox
                checked={checked.indexOf(event.id) !== -1}
                tabIndex={-1}
                disableRipple
                onChange={handleToggle(event.id)}
              />
            </ListItem>
            <Divider/>
          </React.Fragment>
        ))}
      </List>
    </div>
  )
}

export default translate(withStyles(styles)(CalendarEventsList))