export default {
  'job-grades': {
    add: 'Job Level hinzufügen',
    name: 'Level |||| Level',
    fields: {
      name: 'Titel',
      company: 'Firma',
      enabled: 'aktiviert',
    }
  },
}