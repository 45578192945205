export default {
  'company-branches': {
    add: 'Добавить филиал',
    name: 'Филиал |||| Филиалы',
    fields: {
      name: 'Название филиала',
      company: 'Дилер',
      enabled: 'Активный',
      blocked: 'Заблокирован',
      postalAddress: 'Адрес',
    }
  },
}