import React, { useState } from 'react'
import { compose } from 'recompose'
import { useTranslate } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import AddTaskDialog from './AddTaskDialog'

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(),
  },
})

const AddTaskButton = (props) => {

  const { classes, resource, id, onCreate } = props

  const translate = useTranslate()
  const [showForm, setShowForm] = useState(false)

  return (
    <React.Fragment>
      <Button size="small" color="primary" onClick={() => setShowForm(true)}>
        <AddIcon className={classes.leftIcon}/>
        {translate('resources.tasks.buttons.add')}
      </Button>
      {
        showForm &&
        <AddTaskDialog
          open={showForm}
          onClose={() => setShowForm(false)}
          onCreate={onCreate}
          resource={resource}
          id={id}
        />
      }

    </React.Fragment>

  )
}

export default compose(
  withStyles(styles),
)(AddTaskButton)