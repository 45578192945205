import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import { Resource } from 'react-admin'
import AddressCreate from './AddressCreate'
import AddressEdit from './AddressEdit'

export default permissions => [

  <ResourceGuesser
    name="countries"
  />,

  <Resource
    name="postal-addresses"
    create={AddressCreate}
    edit={AddressEdit}
  />
]