export default {
  'company-branches': {
    add: 'Ajouter branche',
    name: 'Branche |||| Branche',
    fields: {
      name: 'Titre de branche',
      company: 'Société',
      enabled: 'activé',
      blocked: 'bloqué',
      postalAddress: 'Adresse',
    }
  },
}