import React from 'react'
import { MenuItemLink, useTranslate, } from 'react-admin'

import WorksheetIcon from '@material-ui/icons/Assignment'
import { isGranted, ROLE_EMPLOYEE } from '../../utils/AuthUtils'

const Menu = () => {
  const translate = useTranslate()

  return (
    isGranted(ROLE_EMPLOYEE) &&
    <MenuItemLink
      to={'/worksheet/worksheet-buys'}
      primaryText={translate('menu.worksheets-buy.name')}
      leftIcon={<WorksheetIcon/>}
    />
  )
}

export default Menu