export default {
  'crwl/links': {
    name: 'Объявление |||| Объявления',
    fields: {
      soldAt: 'Дата продажи',
      isSold: 'Продан',
      createdAt: 'Добавлен',
      src: 'Источник',
      url: 'Ссылка',
      srcId: 'ID источника',
      vin: 'VIN',
      year: 'Год выпуска',
      yearFrom: 'Год от',
      yearTo: 'Год до',
      color: 'Цвет',
      brand: 'Марка',
      model: 'Модель',
      generation: 'Поколение',
      version: 'Версия',
      engineSize: 'Объем двигателя',
      engineSizeFrom: 'Объем, от',
      engineSizeTo: 'Объем, до',
      engineType: 'Тип двигателя',
      gearbox: 'Тип КПП',
      doorsCount: 'Количество дверей',
      bodyTypeCode: 'Код типа кузова',
      bodyType: 'Тип кузова',
      driveType: 'Привод',
      hp: 'Мощность, л.с.',
      hpFrom: 'Мощность от, л.с.',
      hpTo: 'Мощность до, л.с.',
      steeringWheel: 'Положение руля',
      srcCreateTs: 'Дата добавления',
      ownersNumber: 'Количество владельцев',
      ptsOriginal: 'ПТС оригинальный',
      price: 'Цена',
      priceFrom: 'Цена от',
      priceTo: 'Цена до',
      mileage: 'Пробег, км',
      mileageFrom: 'Пробег от, км',
      mileageTo: 'Пробег до, км',
      sellerType: 'Тип продавца',
      sellerName: 'Продавец',
      sellerLocationName: 'Нахождение продавца',
      sellerLocationLat: 'Широта',
      sellerLocationLon: 'Долгота',
      sellerPoint: 'Геоточка',
      photos: 'Фотографии',
      region: 'Регион',
      city: 'Город',
      radius: 'Расстояние',
      tags: 'Метки',
    },
    filter: {
      title: 'Фильтры',
      button: 'Показать предложения',
      linkPlaceholder: 'Скопируйте и вставьте сюда ссылку с auto.ru или avito.ru',
    },
    measure: {
      mileage: 'км',
      sellerPrivate: 'Частник',
      sellerCompany: 'Компания',
      currency: 'RUB',
      today: 'Сегодня',
      engineSize: 'л',
      engineType: {
        ELECTRO: 'Электро',
        DIESEL: 'Дизель',
        GASOLINE: 'Бензин',
        HYBRID: 'Гибрид',
        LPG: 'Газ'
      },
      gearbox: {
        AUTOMATIC: 'Автоматическая',
        ROBOT: 'Роботизированная',
        VARIATOR: 'Вариатор',
        MECHANICAL: 'Механическая'
      },
      driveType: {
        ALL_WHEEL_DRIVE: 'Полный',
        FORWARD_CONTROL: 'Передний',
        REAR_DRIVE: 'Задний'
      }
    },
    actions: {
      showButton: 'Перейти к объявлению',
      saveFilter: 'Сохранить фильтр',
      clearFilter: 'Сбросить фильтр',
      deleteFilter: 'Удалить фильтр',
      carSold: 'Автомобиль продан',
      soldAt: 'Продан %{soldAt}',
      changePrice: 'В объявлении другая цена'
    },
    dialog: {
      sold: {
        title: 'Автомобиль продан?',
        description: 'Если в объявлении на сайте %{src} указано, что автомобиль продан, нажмите "Да".'
      },
      changePrice: {
        title: 'В объявлении другая цена?',
        description: 'Если в объявлении на сайте %{src} указана другая цена, пожалуйста, укажите эту цену.'
      }
    },
    tabs: {
      actions: 'Действия',
      analytics: 'Анализ цены',
      similar: 'Похожие объявления',
      tech: 'Характеристики',
      equipment: 'Комплектация'
    }
  },
}