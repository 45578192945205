import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { useLocale, useDataProvider } from 'react-admin'
import { Calendar, messages, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'moment/locale/ru'
import 'moment/locale/de'
import 'moment/locale/fr'
import ru from '../../i18n/calendar/ru'
import { getFirstDateOfMonth, getLastDateOfMonth } from '../../utils/dateUtils'
import calendarResources from './calendarResources'
import { withRouter } from 'react-router'

const localizer = momentLocalizer(moment)

const CalendarView = (props) => {
  const locale = useLocale()
  const dataProvider = useDataProvider()

  const { history, defaultView = 'week', scrollToTime = new Date() } = props
  const [messages, setMessages] = useState(null)

  const [startAt, setStartAt] = useState(getFirstDateOfMonth(scrollToTime))
  const [endAt, setEndAt] = useState(getLastDateOfMonth(scrollToTime))
  const [events, setEvents] = useState([])

  const setPeriod = (period) => {
    if (Array.isArray(period)) {
      setStartAt(getFirstDateOfMonth(period[0]))
      setEndAt(getLastDateOfMonth(period[period.length - 1]))
    } else {
      setStartAt(getFirstDateOfMonth(period['start']))
      setEndAt(getLastDateOfMonth(period['end']))
    }
  }

  useEffect(() => {
    moment.locale(locale)
    switch (locale) {
      case 'ru':
        setMessages(ru)
        break
    }
  }, [locale])

  useEffect(() => {
    dataProvider.getList('calendar-events/events', {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'createdAt', order: 'ASC' },
      filter: {
        'startAt[after]': startAt.toISOString(),
        'startAt[before]': endAt.toISOString()
      }
    })
      .then(({ data }) => {
        setEvents(data.map(d => ({
          title: d.title,
          description: d.description,
          start: new Date(d.startAt),
          end: new Date(d.finishAt),
          allDay: false,
          resourceName: d.subjectResourceName,
          resourceId: d.subjectId,
          id: d.id,
        })))
      })
  }, [startAt, endAt])

  return (
    <Calendar
      localizer={localizer}
      defaultView={defaultView}
      onSelectEvent={e => {
        e.end > new Date() ?
          calendarResources
            .find(r => r.name === e.resourceName)
            .link({ record: e, dataProvider })
            .then(l => history.push(l)) :
          history.push(`/calendar-events/events/${encodeURIComponent(e.id)}/show`)
      }}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      messages={messages}
      onRangeChange={setPeriod}
      scrollToTime={scrollToTime}
      defaultDate={scrollToTime}
      step={15}
    />
  )
}

export default compose(
  withRouter
)(CalendarView)