import CRM from './CRM/en'
import General from './General/en'
import Organization from './Organization/en'
import Person from './Person/en'
import Questionnaire from './Questionnaire/en'
import CalendarEvents from './CalendarEvents/en'
import Tags from './Tags/en'
import Comments from './Comments/en'
import Tasks from './Tasks/en'
import Workflow from './Workflow/en'
import WorksheetsBuy from './WorksheetsBuy/en'
import CRWL from './CRWL/en'

export default {
  resources: {
    ...CRM,
    ...General,
    ...Organization,
    ...Person,
    ...Questionnaire,
    ...CalendarEvents,
    ...Tags,
    ...Comments,
    ...Tasks,
    ...Workflow,
    ...WorksheetsBuy,
    ...CRWL,
  },
  navigation: {
    first: 'First'
  }
}
