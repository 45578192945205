import React from 'react'
import {
  BooleanInput,
  Edit,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  LoadingIndicator,
  Query,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  useTranslate,
  ReferenceField
} from 'react-admin'
import AddCompanyBranchButton from './AddCompanyBranchButton'
import AddJobPositionButton from './AddJobPositionButton'
import AddDepartmentButton from './AddDepartmentButton'
import CompanyBranchList from './CompanyBranchList'
import DepartmentList from './DepartmentList'
import JobPositionList from './JobPositionList'
import config from '../../config/config'
import JobGradeList from './JobGradeList'
import AddJobGradeButton from './AddJobGradeButton'
import { isGranted, ROLE_ADMIN, ROLE_COMPANY_ADMIN } from '../../utils/AuthUtils'
import withStyles from '@material-ui/core/styles/withStyles'
import WorkflowList from './WorkflowList'
import AddWorkflowButton from './AddWorkflowButton'
import AddCompanyFileButton from './AddCompanyFileButton'
import CompanyFileList from './CompanyFileList'

export const styles = {
  enabled: { display: 'inline-block' },
  blocked: { display: 'inline-block', marginLeft: 32 },
}

const CompanyTitle = ({ record }) => {
  return <span>{record ? record.name : ''}</span>
}

const CompanyEdit = ({ classes, ...props }) => {

  const translate = useTranslate()

  return (
    <Edit {...props} title={<CompanyTitle/>}>
      <TabbedForm
        scrollable="true"
        redirect={'edit'}
      >
        <FormTab label={translate('resources.companies.tabs.summary')}>
          <TextInput
            source="name"
            validate={required()}
          />
          <ReferenceInput
            source="type"
            reference="company-types"
            validate={required()}
          >
            <SelectInput optionText="name"/>
          </ReferenceInput>
          <BooleanInput
            source="enabled"
            validate={required()}
            formClassName={classes.enabled}
          />

          {
            isGranted(ROLE_ADMIN, props.permissions) &&
            <BooleanInput
              source="blocked"
              formClassName={classes.enabled}
            />
          }

          <ImageInput source="logo" accept="image/*">
            <ImageField source="contentUrl" title="title"/>
          </ImageInput>

          <ReferenceField source="logo" reference="logos" label="">
            <FunctionField source="contentUrl" render={record =>
              <ImageField source="src" record={{ src: `${config.publicImagesRoot}${record.contentUrl}` }}/>
            }/>
          </ReferenceField>
        </FormTab>

        <FormTab label={translate('resources.companies.tabs.branches')} path="branches">
          <CompanyBranchList fullWidth/>
          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <AddCompanyBranchButton/>
          }
        </FormTab>


        <FormTab label={translate('resources.companies.tabs.departments')} path="departments">
          <DepartmentList fullWidth/>
          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <AddDepartmentButton/>
          }
        </FormTab>

        <FormTab label={translate('resources.companies.tabs.positions')} path="positions">
          <JobPositionList fullWidth/>
          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <AddJobPositionButton/>
          }
        </FormTab>

        <FormTab label={translate('resources.companies.tabs.files')} path="company-files">
          <CompanyFileList fullWidth/>
          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <AddCompanyFileButton/>
          }
        </FormTab>

        <FormTab label={translate('resources.companies.tabs.workflows')} path="workflows">
          <WorkflowList fullWidth/>
          {
            isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
            <AddWorkflowButton/>
          }
        </FormTab>

      </TabbedForm>
    </Edit>
  )
}

const LogoField = (props) => {

  const { record, src } = props

  if (typeof record === 'object' && record.hasOwnProperty('rawFile')) {
    return <ImageField source='logo' record={{ logo: record['undefined'], rawFile: record.rawFile }}/>
  } else {
    return <ImageField source='logo' record={{ logo: src }}/>
  }
}

export default withStyles(styles)(CompanyEdit)