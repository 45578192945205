import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import colors from './colors'
import DoneIcon from '@material-ui/icons/Done'
import Button from '@material-ui/core/Button'

import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import CommentIcon from '@material-ui/icons/Comment'

const styles = theme => ({
  ...colors(theme),
  container: {
    maxWidth: '500'
  },
  margin: {
    marginTop: theme.spacing(),
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  label: {
    justifyContent: 'start'
  }
})

const TagSelect = ({ tags, onLinkTag, onUnlinkTag, classes }) => {

  const handleTagClick = (tagId) => () => {
    const tag = tags.find(t => t.id === tagId)
    !tag.selected ?
      onLinkTag(tag.id) : onUnlinkTag(tag.id)
  }

  return (
    <div className={classes.container}>
      {
        tags.map(tag => {
          return (
            <Button
              variant="contained"
              color="primary"
              className={classNames(classes.margin, classes[tag.color])}
              classes={{ label: classes.label }}
              onClick={handleTagClick(tag.id)}
              fullWidth
            >
              {
                tag.selected && <DoneIcon className={classes.leftIcon}/>
              }
              {`${tag.title}`}
            </Button>
          )
        })
      }
    </div>
  )
}

TagSelect.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
    title: PropTypes.string,
    selected: PropTypes.bool,
  })),
  selectedTags: PropTypes.arrayOf(PropTypes.number),
  onLinkTag: PropTypes.func.isRequired,
  onUnlinkTag: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles)
)(TagSelect)