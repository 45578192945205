import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'

import { useTranslate } from 'react-admin'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const MultipleSelectField = (props) => {

  const { initialValues, onChange, options, label } = props

  const translate = useTranslate()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const id = `multipleSelectFieldLabel${Math.random() * 1000}`

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl
      fullWidth
      variant="outlined"
    >
      <InputLabel ref={inputLabel} id={id}>
        {translate(label)}
      </InputLabel>
      <Select
        labelId={id}
        id={`${id}sel`}
        multiple
        value={initialValues}
        onChange={handleChange}
        renderValue={selected => selected.map(val => translate(options[val])).join(', ')}
        MenuProps={MenuProps}
        labelWidth={labelWidth}
      >
        {Object.keys(options).map(code => (
          <MenuItem key={code} value={code}>
            <Checkbox checked={initialValues.indexOf(code) > -1}/>
            <ListItemText primary={translate(options[code])}/>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

MultipleSelectField.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.array,
  options: PropTypes.object,
  label: PropTypes.string
}

export default MultipleSelectField

