export default {
  'job-positions': {
    add: 'Position hinzufügen',
    name: 'Position |||| Positionen',
    fields: {
      name: 'Position Titel',
      company: 'Firma',
      department: 'Abteilung',
      jobGrade: 'Level',
      roles: 'Rolle',
      applicable_for_the_car: 'Berechtigt für ein Fahrzeug',
      enabled: 'aktiviert',
    }
  },
}