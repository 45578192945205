export default {
  'questionnaire/surveys': {
    add: 'Créer une enquête',
    name: 'Enquête |||| Enquêtes',
    fields: {
      createdAt: 'Créé à',
      company: 'Dealer',
      questionnaire: 'Questionnaire',
      isCompleted: 'Completed',
      isOpen: 'Ouvert',
      workflow: 'Flux de travail',
    },
    tabs: {
      summary: 'Résumé',
      results: 'Résultats',
      questions: 'Des questions',
    },
    header: 'Question %{current_question} de %{questions_num}',
    buttons: {
      prev: 'Question précédente',
      next: 'Question suivante',
      complete: 'Complete survey',
      save: 'Sauver',
      transitions: 'Actions de sondage'
    },
    inputs: {
      freeText: 'Votre réponse',
      yes: 'Oui'
    },
    messages: {
      answerSaved: 'Réponse enregistrée',
      answerError: 'Une erreur s\'est produite lors de l\'enregistrement de la réponse'
    }
  }
}