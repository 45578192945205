import React, { useEffect, useState } from 'react'
import { Show, SimpleShowLayout, useLocale, useTranslate, useDataProvider } from 'react-admin'

import Grid from '@material-ui/core/Grid'
import CardActions from '@material-ui/core/CardActions'
import CarSoldButton from './CarSoldButton'
import ChangePriceButton from './ChangePriceButton'
import FavoritesButton from './FavoritesButton'
import { makeStyles } from '@material-ui/core/styles'
import Photos from './Photos'
import LinkTags from './LinkTags'
import CalendarEventsCard from '../CalendarEvents/CalendarEventsCard'
import TasksCard from '../Tasks/TasksCard'
import CarCommonData from './CarCommonData'
import { formatDate } from './LinksList'
import Typography from '@material-ui/core/Typography'
import CommentsCard from '../Comments/CommentsCard'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import MarketDataContainer from './MarketDataContainer'
import SimilarLinksList from './SimilarLinksList'
import TechDataContainer from './BeeCars/TechDataContainer'
import EquipmentDataContainer from './BeeCars/EquipmentDataContainer'
import ResolverContainer from './BeeCars/ResolverContainer'
import CreateFromCRWLButton from '../WorksheetBuy/CreateFromCRWL/CreateFromCRWLButton'

const useStyles = makeStyles(theme => {
  return ({

    actions: {},

    legal: {
      marginBottom: theme.spacing(1)
    },

    cardContainer: {
      padding: theme.spacing(3)
    },

    commonDataContainer: {
      marginTop: theme.spacing(1.5),
      paddingLeft: '30px !important'
    }

  })
})

const ShowActions = ({ data, id, resource, ...props }) => {
  const classes = useStyles()

  return data ?
    <CardActions className={classes.actions}>
      <CarSoldButton id={id} src={data.src} isSold={data.isSold} soldAt={data.soldAt}/>
      <ChangePriceButton id={id} src={data.src} isSold={data.isSold} initialPrice={data.price}/>
      <FavoritesButton id={id} src={data.src} isSold={data.isSold}/>
    </CardActions> :
    <React.Fragment/>
}

const ShowTitle = ({ record }) => {
  return `${record.brand} ${record.model}, ${record.year} ${record.version}`
}

const engineType = (type) => {
  switch (type) {
    case 'Бензин' :
    case 'GASOLINE' :
      return 'Бензин'
    case 'Газ' :
    case 'LPG' :
      return 'Газ'
    case 'Гибрид' :
    case 'HYBRID' :
      return 'Гибрид'
    case 'Дизель' :
    case 'DIESEL' :
      return 'Дизель'
    case 'Электро' :
    case 'ELECTRO' :
      return 'Электро'
  }
  return ''
}

const gearboxType = (type) => {
  switch (type) {
    case 'Автомат' :
    case  'AUTOMATIC' :
      return 'Автомат'
    case 'Вариатор' :
    case  'VARIATOR' :
      return 'Вариатор'
    case 'Механика' :
    case  'MECHANICAL' :
      return 'Механика'
    case 'Робот' :
    case  'ROBOT' :
      return 'Механика'
  }
  return ''
}

const driveType = (type) => {
  switch (type) {
    case 'Задний' :
    case 'REAR_DRIVE' :
      return 'Задний'
    case 'Передний' :
    case 'FORWARD_CONTROL' :
      return 'Передний'
    case 'Полный' :
    case 'ALL_WHEEL_DRIVE' :
      return 'Полный'
  }
  return ''
}

const wheelType = (type) => {
  switch (type) {
    case 'Левый' :
    case 'LEFT' :
      return 'Левый'
    case 'Правый' :
    case 'RIGHT' :
      return 'Правый'
  }
  return ''
}

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

const CustomShowLayout = (props) => {
  const { record, basePath, resource, history } = props

  const locale = useLocale()
  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const classes = useStyles()

  const [tab, setTab] = useState('actions')
  const [modificationLoading, setModificationLoading] = useState(false)
  const [modification, setModification] = useState(null)
  const [beeCarsVersionId, setBeeCarsVersionId] = useState(null)

  const [vinData, setVinData] = useState(null)
  const [contactData, setContactData] = useState(null)
  const [isLogged, setIsLogged] = useState(false)

  const handleTab = (event, newValue) => {
    setTab(newValue)
  }

  let url = record.url
  if ('avito.ru' === record.src) {
    url = url.replace('m.avito.ru', 'avito.ru')
  }

  useEffect(() => {
    dataProvider.create('crwl/request-modification-for-links', {
      data: {
        brandName: record.brand,
        modelName: record.model,
        generationName: record.generation,
        year: record.year,
        bodyType: record.bodyType,
        engineSize: record.engineSize,
        gearboxCode: record.gearbox,
        hp: record.hp,
        driveType: record.driveType,
        engineType: record.engineType
      }
    })
      .then(({ data }) => {
        setModification(data)
      })
      .finally(() => {

      })

  }, [])

  useEffect(() => {
    getBCVersionId()
    getVin()
    getContact()
  }, [])

  const getBCVersionId = () => {
    dataProvider.getList('link-maps', {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'ASC' },
      filter: { crwlId: record.originId }
    })
      .then(({ data }) => {
        if (data.length) {
          setBeeCarsVersionId(data[0]['bcId'])
        }
      })
  }

  const getVin = () => {
    dataProvider.getList('crwl/vins', {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'ASC' },
      filter: { link: record.originId }
    })
      .then(({ data }) => {
        if (data.length) {
          setVinData(data[0])
        }
      })
  }

  const saveVin = ({ vin, regNumber }) => {
    if (null === vinData) {
      dataProvider.create('crwl/vins', {
        data: {
          vin, regNumber, link: record.originId
        }
      }).then(({ data }) => setVinData(data))
    } else {
      dataProvider.update('crwl/vins', {
        id: vinData.id,
        data: {
          vin, regNumber
        },
        previousData: {}
      }).then(({ data }) => setVinData(data))
    }
  }

  const getContact = () => {
    dataProvider.getList('crwl/contacts', {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'ASC' },
      filter: { link: record.originId }
    })
      .then(({ data }) => {
        if (data.length) {
          setContactData(data[0])
        }
      })
  }

  const saveContact = ({ name, phone }) => {
    if (null === contactData) {
      dataProvider.create('crwl/contacts', {
        data: {
          name, phone, link: record.originId
        }
      }).then(({ data }) => {
        setContactData(data)
      })
    } else {
      dataProvider.update('crwl/contacts', {
        id: contactData.id,
        data: {
          phone, name
        },
        previousData: {}
      }).then(({ data }) => {
        setContactData(data)
      })
    }
  }

  const linkBCVersion = (versionId) => {
    dataProvider.create('link-maps', { data: { bcId: versionId, crwlId: record.originId } })
      .then(({ data }) => {
        //console.log("linkBCVersion", data);
      })
    setBeeCarsVersionId(versionId)
  }

  const unlinkBCVersionId = () => {
    if (beeCarsVersionId) {
      dataProvider.getList('link-maps', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
        filter: { crwlId: record.originId }
      })
        .then(({ data }) => {
          if (data.length) {
            dataProvider.delete('link-maps', {
              id: data[0].id, previousData: data[0]
            })
              .then(() => {
                setBeeCarsVersionId(null)
              })
          }

        })
    }
  }

  const logAndProceed = (versionId) => {
    if (!isLogged) {
      dataProvider.create('commands/bc/log', {
        data: {
          version: versionId,
          source: 'classifieds'
        }
      })
        .finally(
          () => {
            linkBCVersion(versionId)
            setIsLogged(true)
          }
        )
    }
    else {
      linkBCVersion(versionId)
    }

  }

  return (
    <SimpleShowLayout {...props}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ width: '100%' }}
        spacing={2}
      >
        <Grid item md={6} xs={12}>
          <Photos {...props} />
        </Grid>

        <Grid item md={6} xs={12} className={classes.commonDataContainer}>

          <Typography variant={'body2'}>
            <a href={url} target="_blank">  {url}   </a>
          </Typography>

          <CarCommonData
            date={formatDate(record.createdAt, locale, translate)}
            brand={record.brand}
            model={record.model}
            generation={record.generation}
            year={record.year}
            version={record.version}
            engineType={engineType(record.engineType)}
            gearbox={gearboxType(record.gearbox)}
            driveType={driveType(record.driveType)}
            steeringWheel={wheelType(record.steeringWheel)}
            vin={null !== vinData ? vinData['vin'] : record.vin}
            regNumber={null !== vinData ? vinData['regNumber'] : ''}
            doors={record.doorsCount}
            color={record.color}
            mileage={`${Intl.NumberFormat(locale).format(record.mileage)} ${translate('resources.crwl/links.measure.mileage')}`}
            body={record.bodyTypeCode}
            location={record.sellerLocationName}
            sellerName={null !== contactData ? contactData['name'] || record.sellerName : record.sellerName}
            sellerType={record.sellerType ? record.sellerType.toLowerCase() !== 'private' ? 'Компания' : 'Частник' : ''}
            ptsOwners={record.ownersNumber}
            saveVin={saveVin}
            saveContact={saveContact}
            contact={contactData}
          />

          {
            record.src === 'auto.ru' ?
              <Typography variant='caption' className={classes.legal}>
                <a href='https://www.auto.ru' target='_blank'>AUTO.RU
                  – Автомобили в России</a>
              </Typography> : <div/>
          }


          <Typography variant={'h5'} gutterBottom>
            {
              new Intl.NumberFormat(locale, {
                minimumFractionDigits: 0,
                style: 'currency',
                currency: translate('resources.crwl/links.measure.currency'),
                currencyDisplay: 'symbol'
              }).format(record.price)
            }
          </Typography>
        </Grid>
      </Grid>


      <AppBar position="static" color={'colorSecondary'}>
        <Tabs
          value={tab}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={handleTab}
        >
          <Tab value="actions" label={translate('resources.crwl/links.tabs.actions')}/>
          <Tab value="analytics" label={translate('resources.crwl/links.tabs.analytics')}/>
          <Tab value="similar" label={translate('resources.crwl/links.tabs.similar')}/>
          <Tab value="tech" label={translate('resources.crwl/links.tabs.tech')}/>
          <Tab value="equipment" label={translate('resources.crwl/links.tabs.equipment')}/>
        </Tabs>
      </AppBar>

      <TabPanel value={tab} index="actions">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ width: '100%' }}
          spacing={2}
        >
          <Grid item md={6} xs={12}>
            <LinkTags linkId={record['originId']}/>
            <CommentsCard resource={resource} subjectId={record['originId']} record={record}
                          basePath={basePath}/>
          </Grid>

          <Grid item md={6} xs={12}>
            <CreateFromCRWLButton link={record} history={history}/>
            <CalendarEventsCard resource={resource} id={record['originId']} record={record}
                                basePath={basePath}/>
            <TasksCard resource={resource} id={record['originId']} record={record} basePath={basePath}/>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index="analytics">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ width: '100%' }}
          spacing={2}
        >
          <Grid item xs={12}>
            <MarketDataContainer record={record} basePath={basePath}/>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index="similar">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ width: '100%' }}
          spacing={2}
        >
          <Grid item xs={12}>
            <SimilarLinksList record={record} basePath={basePath} modification={modification} {...props} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index="tech">
        {
          beeCarsVersionId ? <TechDataContainer beeCarsVersionId={beeCarsVersionId} unlink={unlinkBCVersionId}/> :
            <ResolverContainer onVersionFound={logAndProceed} autoRuModification={modification} year={record.year}/>
        }
      </TabPanel>
      <TabPanel value={tab} index="equipment">
        {
          beeCarsVersionId ? <EquipmentDataContainer beeCarsVersionId={beeCarsVersionId} unlink={unlinkBCVersionId}
                                                     year={record.year}/> : <ResolverContainer
            onVersionFound={logAndProceed} autoRuModification={modification} year={record.year}/>
        }
      </TabPanel>

    </SimpleShowLayout>
  )
}

const LinkShow = (props) => {

  return (
    <Show {...props} actions={<ShowActions {...props} />} title={<ShowTitle/>}>
      <CustomShowLayout history={props.history}/>
    </Show>
  )

}

export default LinkShow