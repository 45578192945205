import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ModelSelector from './ModelSelector'
import VersionSelector from './VersionSelector'
import { LoadingIndicator } from 'react-admin'

const ResolverContainer = (props) => {

  const { autoRuModification, onVersionFound } = props

  const [bcModel, setBcModel] = useState()

  useEffect(() => {
    autoRuModification && setBcModel(autoRuModification['bcModel'])
  }, [autoRuModification])

  if (!autoRuModification) {
    return <LoadingIndicator/>
  }

  return (
    <Grid container spacing={3}>
      {
        !bcModel &&
        <Grid item xs={12}>
          <Typography variant={'body1'}>Для данного автомобиля нет возможности определить комплектацию</Typography>
        </Grid>

      }

      {
        bcModel &&
        <Grid item xs={12}>
          <Typography variant={'body1'}>Для определения комплектации, ответьте на несколько
            вопросов.</Typography>
        </Grid>
      }

      {
        bcModel &&
        <Grid item xs={12}>
          <VersionSelector onVersionFound={onVersionFound} autoRuModification={autoRuModification} bcModel={bcModel}/>
        </Grid>
      }
    </Grid>
  )

}

ResolverContainer.propTypes = {
  autoRuModification: PropTypes.object
}

export default ResolverContainer