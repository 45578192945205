import React, { useState } from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  required,
  SelectInput,
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  translate
} from 'react-admin'
import AddTransitionButton from './AddTransitionButton'
import AddPlaceButton from './AddPlaceButton'
import AddParticipantButton from './AddParticipantButton'
import { ParticipantList } from './ParticipantList'
import { PlaceList } from './PlaceList'
import { TransitionList } from './TransitionList'
import Button from '@material-ui/core/Button'
import DOTField from './DOTField'
import { TopToolbar } from 'react-admin'
import CopyWorkflowButton from './CopyWorkflowButton'

const Title = ({ record }) => {
  return <>{record ? record.name : ''}</>
}

const WorkflowActions = ({ basePath, data, resource, ...props }) => {

  return (
    <TopToolbar>
      {
        data && <CopyWorkflowButton record={data}/>
      }
    </TopToolbar>
  )
}

const WorkflowEdit = props => {

  const { translate } = props
  const [showGraph, setShowGraph] = useState(false)

  return (
    <Edit title={<Title/>} actions={<WorkflowActions/>} {...props}>
      <TabbedForm>
        <FormTab label={translate('resources.workflow/workflows.tabs.summary')}>
          <ReferenceInput
            source="company"
            reference="companies"
            allowEmpty
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name: searchText })}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>

          <ReferenceInput
            source="type"
            reference="workflow/types"
            allowEmpty
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name"/>
          </ReferenceInput>

          <TextInput source="name" fullWidth validate={required()}/>

          <BooleanInput source="enabled"/>
        </FormTab>
        <FormTab label={props.translate('resources.workflow/workflows.tabs.participants')} path="participants">
          <ParticipantList fullWidth/>
          <AddParticipantButton/>
        </FormTab>

        <FormTab label={props.translate('resources.workflow/workflows.tabs.places')} path="places">
          <PlaceList fullWidth/>
          <AddPlaceButton/>
        </FormTab>

        <FormTab label={props.translate('resources.workflow/workflows.tabs.transitions')} path="transitions">
          <TransitionList fullWidth/>
          <AddTransitionButton/>
        </FormTab>

        <FormTab label={props.translate('resources.workflow/workflows.tabs.dump')}>
          <Button
            variant={'text'}
            color={'primary'}
            onClick={showGraph ? () => setShowGraph(false) : () => setShowGraph(true)}
          >
            {showGraph ? translate('hide') : translate('show')}
          </Button>
          {
            showGraph && <DOTField source={'dump'} graphOptions={{ width: '100%', zoom: true }}/>
          }
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default translate(WorkflowEdit)