export default {
  'company-branches': {
    add: 'Add branch',
    name: 'Branch |||| Branches',
    fields: {
      name: 'Branch title',
      company: 'Company',
      enabled: 'Enabled',
      blocked: 'Blocked',
      postalAddress: 'Address',
    }
  },
}