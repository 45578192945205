export default {
  'questionnaire/groups': {
    add: 'Add question\'s group',
    name: 'Group |||| Groups',
    fields: {
      name: 'Group title',
      questionnaire: 'Questionnaire',
      ord: 'Order',
      enabled: 'Enabled',
      numberOfQuestions: 'Number of questions',
    }
  }
}