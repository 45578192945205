import React, { useEffect, useState } from 'react'
import {
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  TextInput,
  useDataProvider,
  useTranslate,
  BooleanInput,
  SaveContextProvider
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PhoneInput from '../PhoneInput'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  width: '100%',
  inlineField: {
    display: 'inline-block',
    width: '30%',
  },
  first: { display: 'inline-block' },
  second: { display: 'inline-block', marginLeft: 32 },
})

const CustomerContainer = props => {

  //record - workshetbuy
  const { record } = props

  const classes = useStyles()
  const dataProvider = useDataProvider()
  const translate = useTranslate()

  const [customer, setCustomer] = useState({});

  useEffect(() => {
    dataProvider.getOne('crm/customers', { id : record.customer} )
      .then(({data}) => {
        setCustomer(data);
      });
  }, [])

  const handleSave = (record) => {
    dataProvider.update('crm/customers', { id: customer.id, data: record, previousData: customer })
      .then(({ data }) => {
        //onSave(data)
      })
  }

  const phoneFormatter = v => {
    // v is a `Date` object
    if (!(v instanceof Date) || isNaN(v)) return;
    const pad = '00';
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
  };

  const phoneParser = v => {
    // v is a string of "YYYY-MM-DD" format
    const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
    if (match === null) return;
    const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
    if (isNaN(d)) return;
    return d;
  };

  return (
    <SimpleForm record={customer} resource={'crm/customers'} save={handleSave}>
      <Typography variant={'h5'} fullWidth={true}>Данные клиента</Typography>
      <TextInput
        source="person.firstName"
        label="Имя"
        formClassName={classes.first}
      />
      <TextInput
        source="person.lastName"
        label="Фамилия"
        formClassName={classes.second}
      />
      <TextInput
        source="person.thirdName"
        label="Отчество"
        formClassName={classes.second}
      />
      <Typography />
      <ArrayInput source="person.phones" label="Телефоны">
        <SimpleFormIterator>
          <TextInput
            source="number"
            label="Номер"
            variant="filled"
            formClassName={classes.first}
          />
          <BooleanInput
            source="isWhatsApp"
            label="WhatsApp"
            formClassName={classes.second}
          />
          <BooleanInput
            source="isViber"
            label="Viber"
            formClassName={classes.second}
          />
          <BooleanInput
            source="isTelegram"
            label="Telegram"
            formClassName={classes.second}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput
        source="person.email"
        label="Электронная почта"
      />
    </SimpleForm>
  )
}

export default CustomerContainer