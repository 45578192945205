import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { LoadingIndicator, useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import withMobileDialog from '@material-ui/core/withMobileDialog'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TagCreate from './TagCreate'
import TagSelect from './TagSelect'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(),
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
}))

const AddTagButton = ({ tags, onLinkTag, onUnlinkTag, onCreate, fullScreen }) => {

  const classes = useStyles()
  const translate = useTranslate()

  const [open, setOpen] = useState(false)
  const [component, setComponent] = useState('list')
  const [title, setTitle] = useState('')

  const [isCreating, setIsCreating] = useState(false)
  const [newTagData, setNewTagData] = useState(null)

  const handleClose = () => {
    setOpen(!open)
  }

  const handleCreateNewTagClick = () => {
    setComponent('create')
  }

  const handleSaveNewTagClick = () => {
    if (!isCreating) {
      setIsCreating(true)
      const creatingPromise = onCreate(newTagData)
      creatingPromise.then(data => {
        setIsCreating(false)
        setComponent('list')
      })
        .catch(e => {
          setIsCreating(false)
        })
      //setComponent('list');
    }

  }

  useEffect(() => {
    'list' === component ?
      setTitle(translate('resources.crwl/tags.name', { smart_count: 2 })) :
      setTitle(translate('resources.crwl/tags.dialogs.create.title'))
  }, [component])

  return (
    <React.Fragment>
      <Button size="small" color="primary" onClick={handleClose}>
        <AddIcon className={classes.leftIcon}/>
        {translate('resources.crwl/tags.buttons.add')}
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >

        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              'list' === component &&
              <TagSelect tags={tags} onLinkTag={onLinkTag} onUnlinkTag={onUnlinkTag}/>
            }
            {
              'create' === component &&
              <TagCreate onChange={setNewTagData}/>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            'list' === component &&
            <Button onClick={handleCreateNewTagClick} color="primary">
              {translate('resources.crwl/tags.buttons.create')}
            </Button>
          }
          {
            'list' === component &&
            <Button
              onClick={handleClose} color="primary" autoFocus>
              {translate('ra.action.save')}
            </Button>
          }
          {
            'create' === component &&
            <Button
              onClick={handleSaveNewTagClick} color="primary" autoFocus>
              {isCreating ? <LoadingIndicator/> : translate('ra.action.save')}
            </Button>
          }
          {
            'list' !== component &&
            <Button
              onClick={() => setComponent('list')} color="primary" autoFocus>
              {translate('ra.action.cancel')}
            </Button>
          }
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

AddTagButton.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
    title: PropTypes.string,
    selected: PropTypes.bool,
  })),
  onLinkTag: PropTypes.func.isRequired,
  onUnlinkTag: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
}

export default compose(
  withMobileDialog(),
)(AddTagButton)