import React, { useState } from 'react'
import { connect } from 'react-redux'

import {
  AutocompleteInput,
  BooleanField,
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NullableBooleanInput,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin'

const EmployeesFilter = (props) => {

  let companyFilter = { company: '0' }
  if (props.filterValues.hasOwnProperty('company')) {
    companyFilter = { company: props.filterValues.company }
  }

  return (
    <Filter {...props}>
      <NullableBooleanInput source="enabled" alwaysOn/>
      <ReferenceInput
        source="company"
        reference="companies"
        perPage={500}
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOn
        filter={companyFilter}
      >
        <AutocompleteInput
          optionText="name"
        />
      </ReferenceInput>

      <ReferenceInput
        source="companyBranches"
        reference="company-branches"
        alwaysOn
        filter={companyFilter}
        sort={{ field: 'name', order: 'ASC' }}
        {...props}
      >
        <SelectInput
          optionText="name"
        />
      </ReferenceInput>

      <ReferenceInput
        source="jobPosition"
        reference="job-positions"
        filter={companyFilter}
        sort={{ field: 'name', order: 'ASC' }}
        {...props}
      >
        <AutocompleteInput
          optionText="name"
        />
      </ReferenceInput>

      <TextInput source="person.lastName"/>
      <TextInput source="person.firstName"/>
    </Filter>
  )

}

const EmployeesList = props => (
  <List {...props} filters={<EmployeesFilter dispatch={props.dispatch}/>} bulkActionButtons={false} exporter={false}>
    <Datagrid rowClick="show">
      <ReferenceField
        source="company"
        reference="companies"
        linkType={false}
        sortBy="company.name"
      >
        <TextField source="name"/>
      </ReferenceField>

      <ReferenceArrayField
        source="companyBranches"
        reference="company-branches"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ReferenceArrayField>

      <ReferenceField
        source="jobPosition"
        reference="job-positions"
        sortBy="jobPosition.name"
        linkType={false}
      >
        <TextField source="name"/>
      </ReferenceField>

      <FunctionField source="person" label="Name" render={record => `${record.person.firstName} ${record.person.lastName}`}/>

      <TextField source="account.email"/>

      <BooleanField source="enabled"/>
    </Datagrid>
  </List>
)

export default connect()(EmployeesList)