import * as React from "react";
import { SaveButton, Toolbar } from 'react-admin';

const SelectWorkflowFormToolbar = props => (
  <Toolbar {...props} >
    <SaveButton
      label="Сохранить и продолжить"
      redirect="show"
      submitOnEnter={true}
    />
  </Toolbar>
);

export default SelectWorkflowFormToolbar;