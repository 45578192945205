export default {
  'workflow/workflows': {
    name: 'flux de travaux |||| flux de travaux',
    fields: {
      company: 'Société',
      title: 'Titre',
      type: 'Type',
      initial_place: 'Initial status',
      enabled: 'activé'
    },
    tabs: {
      summary: 'données flux de travaux',
      participants: 'Contreparties',
      places: 'état',
      transitions: 'Transition',
      dump: 'Dump'
    },
    buttons: {
      create: 'Ajouter un flux de travail',
      copy: 'Copier le flux de travail'
    },
    dialogs: {
      assignee: {
        title: 'Attribuer',
        assign: 'Attribuer et soumettre',
        notSelected: 'Sélectionnez l\'employé',
        submit: 'Soumettre',
        noAssignees: 'Appuyez sur le bouton Soumettre.',
        confirm: 'Confirmer l\'affectation'
      }
    }
  },
}