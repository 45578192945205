import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { LoadingIndicator, useDataProvider, useRedirect } from 'react-admin'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import WorkflowActionButton from '../Workflow/WorkflowActionButton'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  buttonGroup: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
}))

const WorkflowTimeline = (props) => {
  const { record, resource } = props

  const classes = useStyles()
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [log, setLog] = useState([])
  const [nextTransitions, setNextTransitions] = useState([])
  const [expanded, setExpanded] = React.useState(true)

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    dataProvider.create('workflow/request-allowed-transitions', {
      data: {
        resourceName: resource,
        resourceId: record.originId
      }
    })
      .then(({ data }) => {
        setNextTransitions(data)
        setLoading(false)
      })
      .catch(error => {
        setError(error)
        setLoading(false)
      })
  }, [])

  const mergeData = (data) => {
    setLog(prev => data.reverse().concat(prev))
  }

  const hasMore = () => {
    return log.length < total;
  }

  useEffect(() => {
    dataProvider.getList(
      'worksheet/worksheet-buy-logs',
      {
        pagination: { page: page, perPage: 3 },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: { worksheet: record.id }
      }
    )
      .then(({ data, total }) => {
        setTotal(total)
        mergeData(data)
        setLoading(false)
      })
      .catch(error => {
        setError(error)
        setLoading(false)
      })
  }, [page])

  if (error) { return <p>Ошибка загрузки данных</p> }

  return (
    <Box m={2} alignContent='center'>
      <Typography variant='h6'>Процесс <IconButton
        className={clsx(classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <ExpandLessIcon/>
      </IconButton></Typography>

      <Collapse in={expanded} timeout="auto">
        {
          loading && <LoadingIndicator />
        }
        <Timeline align="alternate">
          {
            hasMore() &&
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Button startIcon={<ExpandLessIcon />} onClick={() => setPage(prev => prev + 1)}>еще</Button>
              </TimelineContent>
            </TimelineItem>
          }
          {
            log.map(item => {
              const meta = JSON.parse(item.meta)
              if ('transition' === meta['type']) {
                return (
                  <TimelineItem key={item.id}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {(new Date(item.createdAt)).toLocaleString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {meta['executor']}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary"/>
                      <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography>{item.data}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                )
              }
            })
          }

          {
            nextTransitions?.transitions?.map(transition => {
              return (
                <TimelineItem key={transition.id}>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">

                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="grey"/>
                    <TimelineConnector/>
                  </TimelineSeparator>
                  <TimelineContent>
                    <WorkflowActionButton transition={transition} resourceName={resource} resourceId={record.originId} />
                  </TimelineContent>
                </TimelineItem>
              )
            })
          }
        </Timeline>
      </Collapse>
      <Collapse in={!expanded} timeout="auto">
        <div className={classes.buttonGroup}>
          {
            nextTransitions?.transitions?.map(transition => {
              return (
                <WorkflowActionButton key={transition.id} transition={transition} resourceName={resource} resourceId={record.originId} />
              )
            })
          }
        </div>
      </Collapse>
    </Box>
  )

}

export default WorkflowTimeline