import React, { useState } from 'react'
import AuthWrapper from './AuthWrapper'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { userLogin, hideNotification, useTranslate, useLogin } from 'react-admin'
import { useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import config from '../../config/config'

import AuthChangePasswordForm from './AuthChangePasswordForm'
import AuthForgotPasswordForm from './AuthForgotPasswordForm'
import AuthLoginPasswordForm from './AuthLoginPasswordForm'
import AuthPasswordlessCodeForm from './AuthPasswordlessCodeForm'
import AuthPasswordlessForm from './AuthPasswordlessForm'

export const COMPONENT_STATE_DISPLAY = 'COMPONENT_STATE_DISPLAY'
export const COMPONENT_STATE_SUBMITTING = 'COMPONENT_STATE_SUBMITTING'
export const COMPONENT_STATE_SUCCESS = 'COMPONENT_STATE_SUCCESS'
export const COMPONENT_STATE_ERROR = 'COMPONENT_STATE_ERROR'

export const COMPONENT_LOGIN_PASSWORD_FORM = 'COMPONENT_LOGIN_PASSWORD_FORM'
export const COMPONENT_FORGOT_PASSWORD_FORM = 'COMPONENT_FORGOT_PASSWORD_FORM'
export const COMPONENT_PASSWORD_CHANGE_FORM = 'COMPONENT_PASSWORD_CHANGE_FORM'
export const COMPONENT_PASSWORDLESS_CODE_REQUEST_FORM = 'COMPONENT_PASSWORDLESS_CODE_REQUEST_FORM'
export const COMPONENT_PASSWORDLESS_CODE_PROVIDE_FORM = 'COMPONENT_PASSWORDLESS_CODE_PROVIDE_FORM'
export const COMPONENT_REGISTRATION_FORM = 'COMPONENT_REGISTRATION_FORM'
export const COMPONENT_REGISTRATION_CONFIRMATION_FORM = 'COMPONENT_REGISTRATION_CONFIRMATION_FORM'

const AuthRoot = (props) => {

  const [component, setComponent] = useState(COMPONENT_LOGIN_PASSWORD_FORM)
  const [componentState, setComponentState] = useState(COMPONENT_STATE_DISPLAY)
  const [credentials, setCredentials] = useState({})
  const [errors, setErrors] = useState({})

  const dispatch = useDispatch()
  const translate = useTranslate()
  const login = useLogin()

  const showForgotPasswordForm = () => {
    setComponent(COMPONENT_FORGOT_PASSWORD_FORM)
    setComponentState(COMPONENT_STATE_DISPLAY)
    setErrors({})
  }

  const showLoginPasswordForm = () => {
    setComponent(COMPONENT_LOGIN_PASSWORD_FORM)
    setComponentState(COMPONENT_STATE_DISPLAY)
    setErrors({})
  }

  const showPasswordChangeForm = () => {
    setComponent(COMPONENT_PASSWORD_CHANGE_FORM)
    setComponentState(COMPONENT_STATE_DISPLAY)
    setErrors({})
  }

  const showPasswordLessCodeProvideForm = () => {
    setComponent(COMPONENT_PASSWORDLESS_CODE_PROVIDE_FORM)
    setComponentState(COMPONENT_STATE_DISPLAY)
    setErrors({})
  }

  const showPasswordLessCodeRequestForm = () => {
    setComponent(COMPONENT_PASSWORDLESS_CODE_REQUEST_FORM)
    setComponentState(COMPONENT_STATE_DISPLAY)
    setErrors({})
  }

  const doRequestEmailToken = () => {
    const { email } = credentials
    const data = { email }

    setComponentState(COMPONENT_STATE_SUBMITTING)
    fetch(`${config.api}/request_email_token`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(async (response) => {
        if (202 !== response.status) {
          throw(new Error())
        }
        showPasswordLessCodeProvideForm()
      })
      .catch((e) => {
        handleError('email', 'app.auth.error.bad.credentials')
      })
  }

  const doRequestPasswordResetToken = () => {
    const { email } = credentials
    const data = { email }
    setComponentState(COMPONENT_STATE_SUBMITTING)
    fetch(`${config.api}/request_password_reset_token`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(async (response) => {
        if (202 !== response.status) {
          throw(new Error())
        }
        showPasswordChangeForm()
      })
      .catch((e) => {
        handleError('email', 'app.auth.form.validation.invalid.email')
      })
  }

  const doRequestPasswordReset = () => {
    const { email, code, password } = credentials
    const data = {
      email: email,
      token: code,
      newPassword: password,
    }
    setComponentState(COMPONENT_STATE_SUBMITTING)
    fetch(`${config.api}/request_password_reset`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(async (response) => {
        if (202 !== response.status) {
          throw(new Error())
        }

        setComponentState(COMPONENT_STATE_SUCCESS)
      })
      .catch((e) => {
        handleError('code', 'app.form.error.code')
      })
  }

  const doLogin = () => {
    const { email, password } = credentials
    const data = {
      username: email,
      password: password,
    }
    setComponentState(COMPONENT_STATE_SUBMITTING)
    fetch(`${config.api}/login`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        withCredentials: true
      }
    })
      .then(async (response) => {
        const json = await response.json()
        if (200 !== response.status) {
          throw(new Error(json.error))
        }
        dispatch(hideNotification()) // очищаем ошибки авторизации
        await login({ token: json.token, refresh_token: json.refreshToken }, '/')
      })
      .catch((e) => {
        handleError('email', 'app.auth.error.bad.credentials')
      })
  }

  const doLoginByEmailToken = () => {
    const { email, code } = credentials
    const data = {
      username: email,
      password: code,
    }
    setComponentState(COMPONENT_STATE_SUBMITTING)
    fetch(`${config.api}/login_email`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(async (response) => {
        const json = await response.json()
        if (200 !== response.status) {
          throw(new Error(json.error))
        }
        await login({ token: json.token, refresh_token: json.refresh_token }, '/')
      })
      .catch((e) => {
        handleError('code', 'app.form.error.code')
      })
  }

  const handleSubmit = () => {
    switch (component) {
      case COMPONENT_FORGOT_PASSWORD_FORM:
        doRequestPasswordResetToken()
        break

      case COMPONENT_PASSWORD_CHANGE_FORM:
        doRequestPasswordReset()
        break

      case COMPONENT_PASSWORDLESS_CODE_REQUEST_FORM:
        doRequestEmailToken()
        break

      case COMPONENT_PASSWORDLESS_CODE_PROVIDE_FORM:
        doLoginByEmailToken()
        break

      default:
        doLogin()
        break
    }
  }

  const handleCredentialsChange = (key, value) => {
    setCredentials(Object.assign({}, credentials, { [key]: value }))
    setErrors({})
  }

  const handleError = (field, error) => {
    setComponentState(COMPONENT_STATE_ERROR)
    setErrors(Object.assign({}, errors, { [field]: error }))
  }

  let Component = undefined
  let title = ''

  switch (component) {
    case COMPONENT_FORGOT_PASSWORD_FORM:
      Component = AuthForgotPasswordForm
      title = 'app.auth.form.title.password-recovery'
      break

    case COMPONENT_PASSWORD_CHANGE_FORM:
      Component = AuthChangePasswordForm
      title = 'app.auth.form.title.password-recovery'
      break

    case COMPONENT_PASSWORDLESS_CODE_REQUEST_FORM:
      Component = AuthPasswordlessForm
      title = 'app.auth.form.title.login'
      break

    case COMPONENT_PASSWORDLESS_CODE_PROVIDE_FORM:
      Component = AuthPasswordlessCodeForm
      title = 'app.auth.form.title.login'
      break

    default:
      Component = AuthLoginPasswordForm
      title = 'app.auth.form.title.login'
      break
  }

  return (
    <MuiThemeProvider theme={props.theme}>
      <AuthWrapper {...props} title={title}>

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
        >
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            style={{ marginTop: 12 }}
          >
            <Component
              onChange={handleCredentialsChange}
              componentState={componentState}
              credentials={credentials}
              errors={errors}
              onError={handleError}
              onSubmit={handleSubmit}
            />
          </Grid>

          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            style={{ marginTop: 12 }}
          >
            {
              COMPONENT_FORGOT_PASSWORD_FORM !== component &&
              COMPONENT_PASSWORD_CHANGE_FORM !== component &&
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  onClick={() => showForgotPasswordForm()}
                >
                  {translate('app.auth.form.link.forgot-password')}
                </Button>
              </Grid>
            }
            {
              COMPONENT_LOGIN_PASSWORD_FORM !== component &&
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  onClick={() => showLoginPasswordForm()}
                >
                  {translate('app.auth.form.link.login')}
                </Button>
              </Grid>
            }
            {
              COMPONENT_PASSWORDLESS_CODE_REQUEST_FORM !== component &&
              COMPONENT_PASSWORDLESS_CODE_PROVIDE_FORM !== component &&
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  onClick={() => showPasswordLessCodeRequestForm()}
                >
                  {translate('app.auth.form.link.email')}
                </Button>
              </Grid>
            }

            {/*
                            <Grid item xs={12}>
                                <Button
                                    color="secondary"
                                >
                                    {translate('app.auth.form.link.register')}
                                </Button>
                            </Grid>
                            */}

          </Grid>
        </Grid>
      </AuthWrapper>

    </MuiThemeProvider>
  )

}

export default AuthRoot