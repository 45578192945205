import React from 'react'
import LinkExpander from './LinkExpander'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { color, formatDate } from './LinksList'
import { makeStyles } from '@material-ui/styles'

import {
  Datagrid,
  FunctionField,
  TextField,
  useTranslate,
  useLocale
} from 'react-admin'

export const useStyles = makeStyles(theme => ({
  listItemRoot: {
    paddingLeft: 0,
  },
  inline: {
    display: 'inline',
  },
  rightAligned: {
    textAlign: 'right'
  },
  avatar: {
    border: `1px solid ${theme.palette.grey['200']}`,
    width: '24px',
    height: '24px'
  },
  imgContainer: {
    width: 120,
    height: 90,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
}))

const LinksDataGrid = (props) => {

  const classes = useStyles()
  const translate = useTranslate()
  const locale = useLocale()

  const { history } = props;

  return (
    <Datagrid expand={<LinkExpander history={history} />} rowClick={'expand'} {...props}>

      <FunctionField
        source="photosUrl"
        label=""
        render={record => {
          return record.photoUrls.length ?
            <div className={classes.imgContainer}
                 style={{ backgroundImage: `url(${record.photoUrls[0]})` }}
            /> : <div/>
        }}
      />

      <FunctionField
        source="createdAt"
        sortBy="id"
        render={record => (
          <ListItem alignItems="flex-start" className={classes.listItemRoot}>
            <ListItemText
              primary={`${record.src}`}
              secondary={formatDate(record.createdAt, locale, translate)}
              secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
            />
          </ListItem>
        )}
      />

      <FunctionField
        source="brand"
        render={record => (
          <ListItem alignItems="flex-start" className={classes.listItemRoot}>
            <ListItemText
              primary={`${record.brand} ${record.model}`}
              secondary={`${record.generation || ''}`}
              secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
            />
          </ListItem>
        )}
      />
      <TextField source='version' sortable={false}/>
      <FunctionField
        source='color'
        sortable={false}
        render={record =>
          <Tooltip title={record.color} placement="left">
            <Avatar sizes={'s,a'}
                    className={classes.avatar}
                    style={{ backgroundColor: color(record.color) }}
            >
              <Typography/>
            </Avatar>
          </Tooltip>
        }
      />
      <TextField source='year'/>
      <FunctionField
        source="mileage"
        headerClassName={classes.rightAligned}
        textAlign={'right'}
        render={record => `${(new Intl.NumberFormat(locale).format(record.mileage))} ${translate('resources.crwl/links.measure.mileage')}`}
      />
      <FunctionField
        source="sellerType"
        sortable={false}
        label="resources.crwl/links.fields.sellerName"
        render={record => (
          <ListItem alignItems="flex-start" className={classes.listItemRoot}>
            <ListItemText
              secondary={`${record.sellerType ? record.sellerType.toLowerCase() !== 'private' ?
                translate('resources.crwl/links.measure.sellerCompany') :
                translate('resources.crwl/links.measure.sellerPrivate') :
                ''}`}
              primary={`${record.sellerLocationName}`}
              secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
            />
          </ListItem>
        )}
      />
      <FunctionField
        headerClassName={classes.rightAligned}
        textAlign={'right'}
        source="price"
        render={record =>
          <Typography variant='button'>
            {
              new Intl.NumberFormat(locale, {
                minimumFractionDigits: 0,
                style: 'currency',
                currency: translate('resources.crwl/links.measure.currency'),
                currencyDisplay: 'symbol'
              }).format(record.price)
            }
          </Typography>
        }
      />

    </Datagrid>
  )
}

export default LinksDataGrid