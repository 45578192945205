// in src/comments.js
import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/core/Avatar'
import { List, TextField, DateField, ReferenceField, EditButton } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const cardStyle = theme => ({
  card: {
    width: `100%`,
    minHeight: 200,
    margin: 0,
    display: 'inline-block',
    verticalAlign: 'top',
    border: 0
  },
  media: {
    width: '50%',
    height: 0,
    paddingTop: '30%',
  },
})
const MobileGrid = ({ ids, data, classes }) => {

  return (
    <div style={{ margin: '0' }}>
      {ids.map(id =>
        <Card key={id} className={classes.card} variant={'outlined'} square={true}>
          <CardHeader
            title={`${data[id].brand} ${data[id].model}`}
            titleTypographyProps={{ variant: 'overline' }}
            subheader={`${data[id].version}, ${data[id].year}`}
            subheaderTypographyProps={{ variant: 'caption' }}
          />
          {
            data[id].photoUrls.length &&
            <CardMedia
              className={classes.media}
              image={data[id].photoUrls[0]}
            />
          }
        </Card>
      )}
    </div>
  )
}

MobileGrid.defaultProps = {
  data: {},
  ids: [],
}

export default withStyles(cardStyle)(MobileGrid)