export default {
  'workflow/types': {
    name: 'Workflow type |||| Workflow types',
    fields: {
      name: 'Title',
      subjectType: 'Subject type',
    },
    tabs: {
      summary: 'Properties',
      actions: 'Actions'
    },
  },
}