export default {
  'workflow/request-copy-workflow': {
    name: 'Copy workflow',
    fields: {
      workflowToCopy: 'Flux de travail à copier',
      newWorkflowCompany: 'Nouvelle société du flux de travail',
      newWorkflowName: 'Nouveau nom du workflow',
    },
    buttons: {
      create: 'Ajouter un flux de travail',
      copy: 'Copier le flux de travail'
    }
  },
}