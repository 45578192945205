import Link from './Link/de'
import Filter from './Filter/de'
import Favorites from './Favorites/de'
import RequestAppraisal from './RequestAppraisal/de'

export default {
  ...Link,
  ...Filter,
  ...Favorites,
  ...RequestAppraisal,
}