import React from 'react'
import SubWorksheetTaskLink from '../Worksheet/SubWorksheetTaskLink'
import LinkTaskLink from '../CRWL/LinkTaskLink'
import WorksheetBuyTaskLink from '../WorksheetBuy/WorksheetBuyTaskLink'

const subWorkSheetLink = (tab) => (record) =>
  <SubWorksheetTaskLink id={record.subjectId} resource={record.subjectResourceName} tab={tab}/>

const crwlLink = (record) =>
  <LinkTaskLink id={record.subjectId} resource={record.subjectResourceName}/>

const worksheetBuy = (record) =>
  <WorksheetBuyTaskLink id={record.subjectId} resource={record.subjectResourceName}/>

export default [
  {
    name: 'crm/worksheet-finances',
    link: subWorkSheetLink('finance')
  },
  {
    name: 'crm/worksheet-insurances',
    link: subWorkSheetLink('insurance')
  },
  {
    name: 'crm/worksheet-news',
    link: subWorkSheetLink('newCarPurchase')
  },
  {
    name: 'crm/worksheet-searches',
    link: subWorkSheetLink('search')
  },
  {
    name: 'crm/worksheet-trade-ins',
    link: subWorkSheetLink('tradeIn')
  },
  {
    name: 'crm/worksheet-useds',
    link: subWorkSheetLink('usedCarPurchase')
  },
  {
    name: 'crwl/links',
    link: crwlLink
  },
  {
    name: 'worksheet/worksheet-buys',
    link: WorksheetBuyTaskLink
  },
  //
]