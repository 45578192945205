export default {
  'car-instances': {
    name: 'Автомобиль',
    fields: {
      originId: 'ID',
      brand: 'Марка',
      model: 'Модель',
      generation: 'Поколение',
      modification: 'Модификация',
      vin: 'VIN',
      mileage: 'Пробег',
      numOwners: 'Количество владельцев',
      productionYear: 'Год выпуска'
    },
  },
}