export default {
  companies: {
    name: 'Client |||| Client',
    fields: {
      name: 'Titre',
      enabled: 'activé',
      blocked: 'bloqué',
      is_provider: 'fournisseur',
      type: 'Type',
      logo: 'Logo'
    },
    tabs: {
      summary: 'Données générales',
      branches: 'Branche',
      departments: 'Département',
      grades: 'Level',
      positions: 'Positions',
      inventory: 'Véhicules commandables',
      files: 'Documents',
      workflows: 'Flux de travaux',
      surveys: 'Enquêtes'
    }
  },
}