import React from 'react'
import { Link } from 'react-router-dom'
import {
  SelectField,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
  FunctionField,
  translate
} from 'react-admin'

const FilterList = ({ translate, ...props }) => {

  const rowClick = (id, basePath, record) => {
    const filter = { crwlFilter: JSON.stringify(JSON.parse(record.filter)) }
    return `/crwl/links?filter=${encodeURI(JSON.stringify(filter))}`
  }

  return (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={false} exporter={false}>
      <Datagrid rowClick={rowClick}>
        <TextField source="name"/>
        <SelectField source="notificationPeriod" choices={[
          { id: 'hourly', name: translate('resources.crwl/filters.notificationPeriods.hourly') },
          { id: '4hourly', name: translate('resources.crwl/filters.notificationPeriods.4hourly') },
          { id: 'daily', name: translate('resources.crwl/filters.notificationPeriods.daily') },
          { id: 'weekly', name: translate('resources.crwl/filters.notificationPeriods.weekly') },
          { id: 'never', name: translate('resources.crwl/filters.notificationPeriods.never') },
        ]}/>
        <DeleteButton label="" redirect={false}/>
        <EditButton label="" redirect={false}/>
      </Datagrid>
    </List>
  )
}

export default translate(FilterList)