import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 3,
    },
    input: {
        fontSize: theme.typography.fontSize * 2,
    },
    button: {
        marginLeft: theme.spacing.unit * 3,
    }
}))

const PriceOfferInput = (props) => {

    const {currentOffer, onChange } = props;
    
    const classes = useStyles();

    const [value, setValue] = useState(0);
    const [displayValue, setDisplayValue] = useState(0);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        parseValue(`${currentOffer}`);
    }, [currentOffer]);

    const doSubmit = () => {
        onChange(parseInt(value));
        setIsChanged(false);
    };

    const parseValue = (value) => {
        const val = value.replace(/[^\d]/g, '');
        const displayVal = new Intl.NumberFormat('ru-RU', {
            minimumFractionDigits: 0
        }).format(val);

        setValue(val);
        setDisplayValue(displayVal);
    }

    const handleChange = () => event => {
        parseValue(event.target.value);
        setIsChanged(true);
    };

    return (
        <div className={classes.container}>
            <FormControl variant="filled">
                <InputLabel htmlFor="adornment-amount">{isChanged ? 'Новое' : 'Последнее'} предложение</InputLabel>
                <FilledInput
                    id="adornment-amount"
                    value={value > 0 ? displayValue : ''}
                    onChange={handleChange()}
                    endAdornment={<InputAdornment position="end"><Typography variant={"h6"}>₽</Typography></InputAdornment>}
                    className={classes.input}
                />
            </FormControl>
            {
                isChanged &&
                <Button variant={"contained"} color={"secondary"} className={classes.button} onClick={doSubmit}>Отправить</Button>
            }
        </div>
    )

};

export default PriceOfferInput;