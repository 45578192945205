import authForm from './authForm/fr'
import menu from './menu/fr'
import resources from './resoures/fr'

export default {
  'app.loading': 'Chargement',
  'app.welcome': 'Bienvenue',
  'app.form.required': 'Champ obligatoire',
  'app.form.submit': 'Transmettre',
  show: 'Afficher',
  hide: 'Cacher',

  roles: {
    ROLE_ANONYMOUS: 'Utilisateur anonyme',
    ROLE_ADMIN: 'Administrateur',
    ROLE_EMPLOYEE: 'Employé',
    ROLE_COMPANY_ADMIN: 'Administrateur de société',
    ROLE_COMPANY_MANAGER: 'Manager d\'entreprise',
    ROLE_BRANCH_MANAGER: 'Manager de branche',
    ROLE_DEALER: 'Revendeur',
  },

  ...authForm,
  ...menu,
  ...resources

}