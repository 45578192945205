import React from 'react'
//import {change} from 'redux-form';
import { useForm } from 'react-final-form'

import {
  BooleanInput,
  Create,
  FormDataConsumer,
  LoadingIndicator,
  NumberInput,
  Query,
  REDUX_FORM_NAME,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import { parse } from 'query-string'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  first: { display: 'inline-block' },
  second: { display: 'inline-block', marginLeft: 32 },
}

export const PlaceCreate = (withStyles(styles))(props => {
  const { workflow: workflow_id_string } = parse(props.location.search)
  const workflow = workflow_id_string
  const redirect = workflow ? `/workflow/workflows/${encodeURIComponent(workflow)}/places` : false

  const { classes } = props

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ workflow }}
        redirect={redirect}
      >
        <TextInput source="code" />
        <TextInput source="name"/>
        <NumberInput source="maxMinutesInPlace" formClassName={classes.first}/>
        <FormDataConsumer formClassName={classes.second}>
          {
            ({ formData, ...rest }) => {
              return formData.maxMinutesInPlace > 0 && (
                <Query
                  type="GET_LIST"
                  resource="workflow/participants"
                  payload={{
                    pagination: { page: 1, perPage: 10 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: {
                      workflow: rest.record.workflow
                    }
                  }}
                >
                  {({ data, loading, error }) => {
                    if (loading) { return <LoadingIndicator/> }
                    if (error) { return <p>ERROR</p> }

                    const companies = data.map(p => p.company)

                    return (
                      <ReferenceArrayInput
                        source="overdueJobPositions"
                        reference="job-positions"
                        filter={{ company: companies }}
                        sort={{ field: 'name', order: 'ASC' }}
                        {...rest}
                      >
                        <SelectArrayInput optionText="name"/>
                      </ReferenceArrayInput>
                    )
                  }}
                </Query>
              )
            }
          }
        </FormDataConsumer>
        <BooleanInput source="isInitial"/>
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => (
            <BooleanInput
              source="isSuccess"
              onChange={(value, checked) => {
                //checked && dispatch(change(REDUX_FORM_NAME, 'isFail', false))
              }}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => (
            <BooleanInput
              source="isFail"
              onChange={(value, checked) => {
                //checked && dispatch(change(REDUX_FORM_NAME, 'isSuccess', false))
              }}
              {...rest}
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
})