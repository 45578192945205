import React, { useState } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import { MenuItemLink, useTranslate, } from 'react-admin'

import SubMenu from '../SubMenu'

import SettingsIcon from '@material-ui/icons/Settings'
import DealerIcon from '@material-ui/icons/Business'
import EmployeesIcon from '@material-ui/icons/People'
import WorkflowIcon from '@material-ui/icons/Share'
import CountriesIcon from '@material-ui/icons/Public'
import CalendarIcon from '@material-ui/icons/Event'
import CodeIcon from '@material-ui/icons/Code'
import TagIcon from '@material-ui/icons/LocalOffer'

import { isGranted, ROLE_ADMIN, ROLE_BRANCH_MANAGER, ROLE_COMPANY_ADMIN, ROLE_EMPLOYEE } from '../../utils/AuthUtils'

const Menu = ({ onMenuItemClick, toggleSubMenu, isSidebarOpen, isSubmenuOpen, location }) => {

  const translate = useTranslate()

  const Items = [
    /*
    {
        url : '/countries',
        hiddenUrls : [],
        name : translate('menu.settings.menu.countries.name'),
        icon : <CountriesIcon />,
        role : ROLE_ADMIN
    },
     */
    {
      url: '/company-types',
      hiddenUrls: [],
      name: translate('resources.company-types.name', { smart_count: 2 }),
      icon: <DealerIcon/>,
      role: ROLE_ADMIN
    },
    {
      url: '/companies',
      hiddenUrls: ['/company-branches', '/company-files', '/departments', '/job-positions', '/job-grades'],
      name: translate('menu.settings.menu.companies.name'),
      icon: <DealerIcon/>,
      role: ROLE_EMPLOYEE
    },
    {
      url: '/employees',
      hiddenUrls: [],
      name: translate('menu.settings.menu.employees.name'),
      icon: <EmployeesIcon/>,
      role: ROLE_EMPLOYEE
    },
    {
      url: '/workflow/subject-types',
      hiddenUrls: [],
      name: translate('menu.settings.menu.workflow_subject_types.name'),
      icon: <WorkflowIcon/>,
      role: ROLE_ADMIN
    },
    {
      url: '/workflow/types',
      hiddenUrls: ['/workflow/actions'],
      name: translate('menu.settings.menu.workflow_types.name'),
      icon: <WorkflowIcon/>,
      role: ROLE_ADMIN
    },
    {
      url: '/workflow/workflows',
      hiddenUrls: ['/workflow/participants', '/workflow/places', '/workflow/transitions', '/workflow/request-copy-workflow'],
      name: translate('menu.settings.menu.workflows.name'),
      icon: <WorkflowIcon/>,
      role: ROLE_COMPANY_ADMIN
    },
    {
      url: '/calendar-events/event-types',
      hiddenUrls: [],
      name: translate('menu.settings.menu.calendar_events_types.name'),
      icon: <CalendarIcon/>,
      role: ROLE_ADMIN
    },
    {
      url: '/beecars',
      hiddenUrls: [],
      name: translate('menu.settings.menu.beecars.name'),
      icon: <CodeIcon/>,
      role: ROLE_ADMIN
    },
    {
      url: '/crwl/tags',
      hiddenUrls: [],
      name: translate('menu.settings.menu.tags.name'),
      icon: <TagIcon/>,
      role: ROLE_EMPLOYEE
    }
  ]

  const byLocationOpen = Items
    .reduce((acc, i) => acc.concat(i.url).concat(i.hiddenUrls), [])
    .filter(url => location.pathname.indexOf(url) > -1)
    .length

  return (
    isGranted(ROLE_EMPLOYEE) ?
      <SubMenu
        handleToggle={toggleSubMenu}
        isOpen={isSubmenuOpen || byLocationOpen}
        sidebarIsOpen={isSidebarOpen}
        name={translate('menu.settings.name')}
        icon={<SettingsIcon/>}
        to={Items[0].url}
      >
        {
          Items.map((i, index) =>
            isGranted(i.role) &&
            <MenuItemLink
              key={index}
              to={i.url}
              primaryText={i.name}
              leftIcon={i.icon}
              onClick={onMenuItemClick}
            />
          )
        }
      </SubMenu> : <div/>
  )
}

Menu.propTypes = {
  onMenuItemClick: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  isSubmenuOpen: PropTypes.bool,
  translate: PropTypes.func,
  toggleSubMenu: PropTypes.func,
}

const enhance = compose(
  withRouter,
)

export default enhance(Menu)