export const ROLE_ANONYMOUS = 'ROLE_ANONYMOUS'
export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE'
export const ROLE_COMPANY_ADMIN = 'ROLE_COMPANY_ADMIN'
export const ROLE_COMPANY_MANAGER = 'ROLE_COMPANY_MANAGER'
export const ROLE_BRANCH_MANAGER = 'ROLE_BRANCH_MANAGER'
export const ROLE_DEALER = 'ROLE_DEALER'

export const rolesAvailable = {

  [ROLE_ANONYMOUS]: {
    name: 'roles.ROLE_ANONYMOUS'
  },
  [ROLE_EMPLOYEE]: {
    name: 'roles.ROLE_EMPLOYEE'
  },
  [ROLE_COMPANY_MANAGER]: {
    name: 'roles.ROLE_COMPANY_MANAGER'
  },
  [ROLE_BRANCH_MANAGER]: {
    name: 'roles.ROLE_BRANCH_MANAGER'
  },
  [ROLE_COMPANY_ADMIN]: {
    name: 'roles.ROLE_COMPANY_ADMIN'
  },
  [ROLE_ADMIN]: {
    name: 'roles.ROLE_ADMIN'
  },
  [ROLE_DEALER]: {
    name: 'roles.ROLE_DEALER'
  },
}