import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckLamps from './CheckLamps'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import { useQueryWithStore, Loading, Error } from 'react-admin';
import CheckPoint from './CheckPoint'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

const DiagnosticsContainer = ({ data, carInstance }) => {

  const classes = useStyles()
  const [ state, setState ] = useState(data);
  const [ showProblems, setShowProblems ] = useState(false);

  const [checkPoints, setCheckPoints] = useState([]);
  const [checkPointLevels, setCheckPointLevels] = useState([]);
  const [checkPointBlocks, setCheckPointBlocks] = useState([]);

  const checkPointLevelsCall = useQueryWithStore({
    type: 'getList',
    resource: 'reference/check-point-levels',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'asc'},
      filter: {}
    }
  });

  const checkPointBlocksCall = useQueryWithStore({
    type: 'getList',
    resource: 'reference/check-point-blocks',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'asc'},
      filter: {}
    }
  });

  const checkPointsCall = useQueryWithStore({
    type: 'getList',
    resource: 'reference/check-points',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'asc'},
      filter: {}
    }
  });
  
  useEffect(() => {
    checkPointLevelsCall.data && setCheckPointLevels(checkPointLevelsCall.data)
  }, [checkPointLevelsCall.loaded])

  useEffect(() => {
    checkPointBlocksCall.data && setCheckPointBlocks(checkPointBlocksCall.data)
  }, [checkPointBlocksCall.loaded])

  useEffect(() => {
    checkPointsCall.data && setCheckPoints(checkPointsCall.data)
  }, [checkPointsCall.loaded])

  const updateState = (key, value) => {
    setState({...state, [key] : value});
  }

  const handleShowProblems = (event) => {
    setShowProblems(event.target.checked);
  };
  
  const handleCheckPoint = (id) => (result) => {
    const newResults = [...state.checkPointResults];
    const currResult = newResults.find(r => r.checkPoint === id);
    if (currResult) {
      currResult.result = result;
    }
    else {
      newResults.push({
        checkPoint: id,
        comment: "",
        estimationCost: null,
        isFixed: false,
        repairCost: null,
        result: result
      })
    }
    updateState('checkPointResults', newResults);
    console.log("newResults", newResults);
  }

  return (
    <div className={classes.root}>
      <CheckLamps diagnostics={state} carInstance={carInstance} onChange={updateState} />
      <FormControlLabel
        control={
          <Switch
            checked={showProblems}
            onChange={handleShowProblems}
            color="primary"
          />
        }
        label="Только проблемы"
      />

      {
        checkPointLevels.map(level => {
          return (
            <>
              <Typography variant={'h5'} key={level.id}>{ level.name }</Typography>
              {
                checkPointBlocks.map(block => {
                  const points = checkPoints.filter(p => {
                    const blCondition = p.block === block.id && p.level === level.id
                    let gearboxCond = true;
                    if ('MT' === p.gearbox) {
                      gearboxCond = 'MT' === carInstance.gearbox;
                    }
                    if ('AT' === p.gearbox) {
                      gearboxCond = 'MT' !== carInstance.gearbox;
                    }

                    return blCondition && gearboxCond;
                  })

                  if (0 === points.length) {
                    return null;
                  }
                  return (
                    <>
                      <Typography variant={'h6'} key={block.id}>{ block.name }</Typography>
                      {
                        points.map(p => {
                          const result = state.checkPointResults.find(r => r.checkPoint === p.id);
                          const value = result ? result.result : 0;
                          if (showProblems && (value === 0 || value === 1)) return;
                          return (
                            <CheckPoint handler={handleCheckPoint(p.id)} value={value} text={p.name} textNegative={p.nameNegative} />
                          )
                        })
                      }
                    </>
                  )
                })
              }
            </>
          )
        })
      }

    </div>
  )
}

export default DiagnosticsContainer;