import React from 'react'
import { useDataProvider } from 'react-admin'
import LinkTaskLink from '../CRWL/LinkTaskLink'
import SubWorksheetTaskLink from '../Worksheet/SubWorksheetTaskLink'

const subWorkSheetLink = (tab) => ({ record, dataProvider }) => {
  return dataProvider.getOne(record.resourceName, { id: `/${record.resourceName}/${record.resourceId}` })
    .then(({ data }) => `/crm/worksheets/${encodeURIComponent(data.worksheet)}/${tab}`)
}

const crwlLink = ({ record, dataProvider }) => {
  return dataProvider.getOne(record.resourceName, { id: `/${record.resourceName}/${record.resourceId}` })
    .then(({ data }) => `/crwl/links/${encodeURIComponent(data.id)}/show`)
}

const subWorkSheetShowLink = (tab) => (record) =>
  <SubWorksheetTaskLink id={record.subjectId} resource={record.subjectResourceName} tab={tab}/>

const crwlShowLink = (record) =>
  <LinkTaskLink id={record.subjectId} resource={record.subjectResourceName}/>

export default [
  {
    name: 'crm/worksheet-finances',
    link: subWorkSheetLink('finance'),
    showLink: subWorkSheetShowLink('finance'),
  },
  {
    name: 'crm/worksheet-insurances',
    link: subWorkSheetLink('insurance'),
    showLink: subWorkSheetShowLink('insurance'),
  },
  {
    name: 'crm/worksheet-news',
    link: subWorkSheetLink('newCarPurchase'),
    showLink: subWorkSheetShowLink('newCarPurchase'),
  },
  {
    name: 'crm/worksheet-searches',
    link: subWorkSheetLink('search'),
    showLink: subWorkSheetShowLink('search'),
  },
  {
    name: 'crm/worksheet-trade-ins',
    link: subWorkSheetLink('tradeIn'),
    showLink: subWorkSheetShowLink('tradeIn'),
  },
  {
    name: 'crm/worksheet-useds',
    link: subWorkSheetLink('usedCarPurchase'),
    showLink: subWorkSheetShowLink('usedCarPurchase'),
  },
  {
    name: 'crwl/links',
    link: crwlLink,
    showLink: crwlShowLink
  },
]