export default {
  'job-positions': {
    add: 'Ajouter position',
    name: 'Position |||| Position',
    fields: {
      name: 'L\'intitulé position',
      company: 'Société',
      department: 'Département',
      jobGrade: 'Level',
      roles: 'Rôle',
      applicable_for_the_car: 'éligible pour un véhicule',
      enabled: 'activé',
    }
  },
}