export default {
  'postal-addresses': {
    name: 'Адрес',
    fields: {
      addressCountry: 'Land',
      addressLocality: 'Stadt',
      addressRegion: 'Region',
      postalCode: 'Postleitzahl',
      streetAddress: 'Straße'
    }
  },
}