import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LoadingIndicator, useDataProvider } from 'react-admin'
import SimilarLinksPlot from './SimilarLinksPlot'

const SimilarLinksContainer = (props) => {

  const {
    brand,
    model,
    generation,
    bodyType,
    doorsCount,
    gearbox,
    engineType,
    engineSize,
    driveType,
    year,
    hp,
    lat,
    lon,
    radius,
    referencePrice
  } = props

  const {
    basePath
  } = props

  const dataProvider = useDataProvider()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const requestPayload = {
    brand: brand,
    model: model,
    generation: generation,
    bodyType: bodyType,
    doorsCount: doorsCount,
    transmission: gearbox,
    engineType: engineType,
    engineSize: engineSize,
    driveType: driveType,
    year: year,
    hp: hp,
    lat: lat,
    lon: lon,
    radius: radius
  }

  useEffect(() => {
    setLoading(true)
    dataProvider.create('crwl/request-similar-cars', { data: requestPayload })
      .then(({ data }) => {
        setData(data.result)
        setLoading(false)
      })
  }, [lat, lon, radius])

  return (!data.length || loading) ?
    <LoadingIndicator/> :
    <SimilarLinksPlot data={data} referencePrice={referencePrice} basePath={basePath}/>

}

SimilarLinksContainer.propTypes = {
  brand: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  generation: PropTypes.string,
  bodyType: PropTypes.string.isRequired,
  doorsCount: PropTypes.number,
  gearbox: PropTypes.string.isRequired,
  engineType: PropTypes.string.isRequired,
  engineSize: PropTypes.string.isRequired,
  driveType: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  hp: PropTypes.number,
  referencePrice: PropTypes.number
}

export default SimilarLinksContainer