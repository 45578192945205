import React from 'react'
import { Resource } from 'react-admin'
import EventTypeEdit from './EventTypeEdit'
import { EventTypeList } from './EventTypeList'
import EventTypeCreate from './EventTypeCreate'
import EventList from './EventList'
import EventShow from './EventShow'
import EventEdit from './EventEdit'

export default permissions => [

  <Resource
    name="calendar-events/events"
    list={EventList}
    edit={EventEdit}
    show={EventShow}
  />,
  <Resource
    name="calendar-events/event-types"
    create={EventTypeCreate}
    list={EventTypeList}
    edit={EventTypeEdit}
  />
]