import React from 'react'
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  FunctionField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useTranslate
} from 'react-admin'
import { rolesAvailable } from '../../config/roles'
import Chip from '@material-ui/core/Chip'
import { isGranted, ROLE_COMPANY_ADMIN } from '../../utils/AuthUtils'

const JobPositionList = (props) => {

  const translate = useTranslate()

  return (
    <ReferenceManyField
      reference="job-positions"
      target="company"
      addLabel={false}
      sort={{ field: 'name', order: 'ASC' }}
      pagination={<Pagination/>}
      {...props}
    >
      <Datagrid>
        <TextField source="name"/>

        <ReferenceField
          reference="departments"
          source="department"
          sort={{ field: 'department.name' }}
          link={false}
        >
          <TextField source="name"/>
        </ReferenceField>

        <FunctionField
          source="roles"
          render={record => {
            return (
              <div>
                {
                  record.roles.map(role =>
                    <Chip label={translate(rolesAvailable[role]['name'])}/>
                  )
                }
              </div>
            )
          }}
        />
        <BooleanField source="enabled"/>
        {
          isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
          <EditButton label=""/>
        }
        {
          isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
          <DeleteButton label="" redirect={false}/>
        }
      </Datagrid>
    </ReferenceManyField>
  )

}

export default JobPositionList