import React from 'react'
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import colors from './colors'
import TagIcon from '@material-ui/icons/LocalOffer'

const useStyles = makeStyles(colors)

const TagTitle = ({ record }) => {
  return <span>{record ? record.title : ''}</span>
}

const colorNames = [
  'red',
  'pink',
  'purple',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'brown',
  'grey',
]

const colorItem = color => <Avatar className={color.className}><TagIcon/></Avatar>

const TagEdit = props => {

  const classes = useStyles()

  return (
    <Edit {...props} title={<TagTitle/>}>
      <SimpleForm redirect="list">
        <TextInput source="title"/>
        <SelectInput source="color" choices={colorNames.map(c => ({ id: c, className: classes[c] }))}
                     optionText={colorItem}/>
      </SimpleForm>
    </Edit>
  )
}

export default TagEdit