export default {
  menu: {
    dashboard: {
      name: 'Tableau de bord'
    },
    settings: {
      name: 'Paramètres',
      menu: {
        countries: {
          name: 'Des pays'
        },
        companies: {
          name: 'Concessionnaires'
        },
        employees: {
          name: 'Employé'
        },
        workflow_subject_types: {
          name: 'Sujets de workflow'
        },
        workflow_types: {
          name: 'Types de processus'
        },
        workflows: {
          name: 'Flux de travail'
        }
      }
    },
    tasks: {
      name: 'les tâches',
    },
    calendar: {
      name: 'Calendrier des événements',
    },
    worksheets: {
      name: 'Fiches de travail'
    },
    questionnaires: {
      name: 'Questionnaires',
      menu: {
        settings: {
          name: 'Paramètres'
        },
        processing: {
          name: 'Questionnaire'
        },
        reports: {
          name: 'Résultats'
        },
      }
    }
  }
}