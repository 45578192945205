import React, { Suspense, useEffect, useState } from 'react'
import {
  DateField,
  TextField,
  ReferenceField,
  EditButton,
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TopToolbar,
  useTranslate,
  useDataProvider,
  LoadingIndicator
} from 'react-admin'
import WorkflowActionsButtons from '../Workflow/WorkflowActionsButtons'
import Title from './Title'
import { makeStyles } from '@material-ui/core/styles'
import SummaryCard from './SummaryCard'

import WorkflowLog from './WorkflowLog'
import Appraisal from './Appraisal'
import CarInstanceContainer from './CarInstanceContainer'
import PhotosContainer, { rakursList } from '../CarInstance/PhotosContainer'
import CheckContainer from '../CarInstance/Check/CheckContainer'
import CustomerContainer from './CustomerContainer'
import PriceOffers from './PriceOffers'

export const useStyles = makeStyles(theme => ({
  inlineField: {
    display: 'inline-block',
    width: '20%',
    '& span': {
      width: '100%'
    }
  },
}))
const Actions = (props) => {

  const { basePath, data, resource, onTransitionComplete } = props

  return (
    <TopToolbar style={{ marginRight: 332 }}>
      {
        (undefined !== data && data.hasOwnProperty('originId')) &&
        <EditButton basePath={basePath} record={data}/>
      }
      {
        (undefined !== data && data.hasOwnProperty('originId')) &&
        <WorkflowActionsButtons
          resourceName={resource}
          resourceId={data.originId}
          onTransitionComplete={onTransitionComplete}
        />
      }
    </TopToolbar>
  )
}

const CustomShowLayout = props => {

  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const classes = useStyles()

  const [carInstance, setCarInstance] = useState({})
  const [carInstanceLoading, setCarInstanceLoading] = useState(false)

  const [customer, setCustomer] = useState({})
  const [customerLoading, setCustomerLoading] = useState(false)

  const [workflow, setWorkflow] = useState({})
  const [workflowLoading, setWorkflowLoading] = useState(false)

  const [priceOffer, setPriceOffer] = useState(0);

  useEffect(() => {
    setCarInstanceLoading(true)
    dataProvider.getOne('car-instances', { id: props.record.carInstance })
      .then(({ data }) => {
        setCarInstance(data)
      })
      .finally(() => {
        setCarInstanceLoading(false)
      })
  }, [])

  useEffect(() => {
    setWorkflowLoading(true)
    dataProvider.getOne('workflow/workflows', { id: props.record.workflow })
      .then(({ data }) => {
        setWorkflow(data)
      })
      .finally(() => {
        setWorkflowLoading(false)
      })
  }, [])

  useEffect(() => {
    setCustomerLoading(true)
    dataProvider.getOne('/crm/customers', { id: props.record.customer })
      .then(({ data }) => {
        setCustomer(data)
      })
      .finally(() => {
        setCustomerLoading(false)
      })
  }, [])

  return (
    <React.Fragment>
      {
        carInstanceLoading || workflowLoading ?
          <LoadingIndicator/> :
          <SummaryCard {...props} carInstance={carInstance} workflow={workflow}/>
      }
      <TabbedShowLayout {...props}>
        <Tab label={translate('resources.worksheet/worksheet-buys.tabs.form')}>
          <DateField source="createdAt" showTime className={classes.inlineField}/>
          <ReferenceField source="company" reference="companies" link={false} className={classes.inlineField}>
            <TextField source="name"/>
          </ReferenceField>
          <ReferenceField source="companyBranch" reference="company-branches" link={false}
                          className={classes.inlineField}>
            <TextField source="name"/>
          </ReferenceField>
          <TextField source="creatorEmployee.fullName" className={classes.inlineField}/>
          <FunctionField
            source="assigneeEmployee.fullName"
            render={record => record.assigneeEmployee ? record.assigneeEmployee.fullName : ''}
            className={classes.inlineField}
          />

          <CustomerContainer customer={customer} onSave={record => setCustomer(record)}/>
        </Tab>
        <Tab label={translate('resources.worksheet/worksheet-buys.tabs.summary')}>
          <CarInstanceContainer carInstance={carInstance} onSave={record => setCarInstance(record)}/>
        </Tab>
        <Tab label={translate('resources.worksheet/worksheet-buys.tabs.check')}>
          <CheckContainer carInstance={carInstance}/>
        </Tab>
        <Tab label={translate('resources.worksheet/worksheet-buys.tabs.photos')}>
            <PhotosContainer
              carInstance={carInstance}
              filter={(photos) => photos.filter(p => !p.deleted && p.type <= 42)}
              types={rakursList.filter(r => r.type <= 42)}
            />
        </Tab>
        <Tab label={translate('resources.worksheet/worksheet-buys.tabs.estimation')}>
          <PriceOffers onChange={setPriceOffer} />
          <Appraisal priceOffer={priceOffer} />
        </Tab>
        <Tab label={translate('resources.worksheet/worksheet-buys.tabs.log')}>
          <WorkflowLog/>
        </Tab>
      </TabbedShowLayout>
    </React.Fragment>
  )
}

const WorksheetShow = props => {

  const { history, basePath } = props

  const onTransitionComplete = (data) => {
    history.push(basePath)
  }

  return (
    <Show
      actions={<Actions onTransitionComplete={onTransitionComplete}/>}
      title={<Title/>}
      {...props}
    >
      <CustomShowLayout/>
    </Show>
  )
}

export default WorksheetShow