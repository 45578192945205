import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useTranslate } from 'react-admin'
import AddIcon from '@material-ui/icons/Add'
import AddCommentDialog from './AddCommentDialog'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(),
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
}))

const AddCommentButton = (props) => {

  const translate = useTranslate()
  const classes = useStyles()
  const [showForm, setShowForm] = useState(false)

  const { resource, subjectId, onCreate } = props

  return (
    <React.Fragment>
      <Button size="small" color="primary" onClick={() => setShowForm(true)}>
        <AddIcon className={classes.leftIcon}/>
        {translate('resources.comments.buttons.add')}
      </Button>
      {
        showForm &&
        <AddCommentDialog
          open={showForm}
          onClose={() => setShowForm(false)}
          onCreate={onCreate}
          resource={resource}
          subjectId={subjectId}
        />
      }

    </React.Fragment>
  )

}

AddCommentButton.propTypes = {
  resource: PropTypes.string,
  subjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default AddCommentButton