import React, { useEffect, useState } from 'react'
import {
  AutocompleteInput,
  Edit,
  FormDataConsumer,
  Query,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useTranslate,
  minLength,
  maxLength,
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  width: '100%',
  inlineField: {
    display: 'inline-block',
    width: '30%',
  },
  first: { display: 'inline-block' },
  second: { display: 'inline-block', marginLeft: 32 },
})

const CarInstanceContainer = props => {

  const { carInstance, onSave } = props

  const classes = useStyles()
  const dataProvider = useDataProvider()
  const translate = useTranslate()

  const currYear = new Date().getFullYear()
  const numYears = 30

  const handleSave = (record) => {
    dataProvider.update('car-instances', { id: carInstance.id, data: record, previousData: carInstance })
      .then(({ data }) => {
        onSave(data)
      })
  }

  return (
    <SimpleForm record={carInstance} resource={'car-instances'} save={handleSave}>
      <ReferenceInput
        source="brand"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={500}
        reference="reference/brands"
        formClassName={classes.first}
      >
        <AutocompleteInput optionText="name" allowEmpty={false}/>
      </ReferenceInput>

      <FormDataConsumer formClassName={classes.second}>
        {({ formData, ...rest }) =>
          <ReferenceInput
            source="model"
            reference="reference/models"
            validate={required()}
            filter={{ brand: formData.brand }}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={500}
            {...rest}
          >
            <AutocompleteInput optionText="name" allowEmpty={false}/>
          </ReferenceInput>
        }
      </FormDataConsumer>

      <FormDataConsumer formClassName={classes.second}>
        {({ formData, ...rest }) =>
          <ReferenceInput
            source="generation"
            reference="reference/generations"
            validate={required()}
            filter={{ model: formData.model }}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={500}
            {...rest}
          >
            <AutocompleteInput
              optionText={option => option ? `${option.name} [${option.yearFrom} - ${option.yearTo}]` : ''}
              allowEmpty={false}/>
          </ReferenceInput>
        }
      </FormDataConsumer>

      <FormDataConsumer formClassName={classes.second}>
        {({ formData, ...rest }) =>
          <ReferenceInput
            source="modification"
            reference="reference/modifications"
            validate={required()}
            filter={{ generation: formData.generation }}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={500}
            {...rest}
          >
            <AutocompleteInput optionText="name" allowEmpty={false}/>
          </ReferenceInput>
        }
      </FormDataConsumer>
      <Typography />
      <TextInput
        source="vin"
        validate={[
          minLength(17),
          maxLength(17),
        ]}
        formClassName={classes.first}
        inputProps={{maxLength: 17}}
      />
      <TextInput
        source="mileage"
        format={(m) => new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0 }).format(m) }
        parse={(m) => parseInt(m.replace(/\D/g,''))}
        formClassName={classes.second}
        inputProps={{maxLength: 17}}
      />
      <TextInput
        source="numOwners"
        format={(v) => undefined !== v ? `${v}`.replace(/\D/g,''):''}
        formClassName={classes.second}
        parse={(v) => parseInt(v)}
        inputProps={{maxLength: 17}}
      />
      <SelectInput
        source="productionYear"
        formClassName={classes.second}
        choices={new Array(numYears)
          .fill(1)
          .map((obj, index) => ({id : currYear - index, name: currYear - index}))
        }
      />


    </SimpleForm>

  )
}

export default CarInstanceContainer