import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import colors from '../../../Tags/colors'
import TagIcon from '@material-ui/icons/LocalOffer'

import { useTranslate, useDataProvider } from 'react-admin'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useStyles = makeStyles(colors)

const TagField = (props) => {

  const { initialValues, onChange } = props

  const translate = useTranslate()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)
  const dataProvider = useDataProvider()
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    dataProvider.getList('crwl/tags', {
      filter: {},
      pagination: { perPage: 300 },
      sort: { field: 'color', order: 'ASC' }
    })
      .then(data => {
        setTags(data.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const id = `tagFieldLabel`

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl
      fullWidth
      variant="outlined"
    >
      <InputLabel ref={inputLabel} id={id}>
        {translate('resources.crwl/links.fields.tags')}
      </InputLabel>
      <Select
        labelId={id}
        id={`${id}sel`}
        multiple
        value={initialValues}
        onChange={handleChange}
        renderValue={selected => {
          return tags.filter(tag => selected.includes(tag.originId)).map(tag => tag.title).join(', ')
          //selected.map(val => translate(options[val])).join(', ')
        }}
        MenuProps={MenuProps}
        labelWidth={labelWidth}
      >
        {tags.map(tag => (
          <MenuItem key={tag.id} value={tag.originId}>
            <Avatar className={classes[tag.color]}><TagIcon/></Avatar>
            <Checkbox checked={initialValues.indexOf(tag.originId) > -1}/>
            <ListItemText primary={tag.title}/>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

TagField.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.array,
}

export default TagField

