export default {
  users: {
    fields: {
      email: 'Электронная почта',
      roles: 'Роли',
      enabled: 'Активный',
      password: 'Пароль',
      avatar: 'Ававтар'
    }
  },
}