import React, { useState } from 'react'
import {
  SimpleForm,
  ReferenceInput,
  useDataProvider,
  useTranslate,
  required,
  SelectInput,
} from 'react-admin'

import Typography from '@material-ui/core/Typography'
import SelectWorkflowFormToolbar from './SelectWorkflowFormToolbar'

const SelectWorkflowForm = props => {

  const { resource, record, onSaved } = props
  const [workflow, setWorkflow] = useState(null);

  const dataProvider = useDataProvider()
  const translate = useTranslate()

  const handleSave = (newRecord) => {
    dataProvider.update(resource, { id: record.id, data: newRecord , previousData: record });
  }

  return (
    <SimpleForm toolbar={<SelectWorkflowFormToolbar />} record={record} resource={resource} save={handleSave}>
      <Typography variant={'h5'} fullWidth={true}>Укажите процесс</Typography>
      <ReferenceInput
        source="workflow"
        reference="workflow/workflows"
        label="Процесс"
        validate={required()}
        filter={{
          enabled: true,
          company: record.company,
          'type.subjectType.resourceName': 'worksheet/worksheet-buys'
        }}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  )
}

export default SelectWorkflowForm