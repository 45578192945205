import React, { useState } from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  email,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  translate,
  ImageInput,
  FunctionField,
  Query,
  LoadingIndicator,
  ImageField

} from 'react-admin'

import { styles } from './EmployeeCreate'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import config from '../../config/config'

const Title = ({ record, ...rest }) => {

  return <span>{record ? `${record.fullName}` : ''}</span>
}

const EmployeeEdit = props => {
  const [company, setCompany] = useState(null)

  const { classes, translate } = props

  return (
    <Edit {...props} title={<Title/>}>
      <SimpleForm redirect="edit">
        <ReferenceInput
          source="company"
          reference="companies"
          validate={required()}
        >
          <AutocompleteInput
            optionText="name"
            onChange={value => {
              setCompany(value)
            }}
          />
        </ReferenceInput>

        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              return (
                formData.company &&
                <ReferenceArrayInput
                  source="companyBranches"
                  reference="company-branches"
                  validate={required()}
                  filter={{ company: formData.company }}
                  perPage={500}
                  {...rest}
                >
                  <SelectArrayInput
                    optionText="name"
                  />
                </ReferenceArrayInput>
              )
            }
          }
        </FormDataConsumer>

        <FormDataConsumer
          formClassName={classes.department}
        >
          {
            ({ formData, ...rest }) => {
              return (
                formData.company &&
                <ReferenceInput
                  source="department"
                  reference="departments"
                  validate={required()}
                  filter={{ company: formData.company }}
                  perPage={500}
                  {...rest}
                >
                  <SelectInput
                    optionText="name"
                  />
                </ReferenceInput>
              )
            }
          }
        </FormDataConsumer>

        <FormDataConsumer
          formClassName={classes.job_position}
        >
          {
            ({ formData, ...rest }) => {
              return (
                formData.department &&
                <ReferenceInput
                  source="jobPosition"
                  reference="job-positions"
                  validate={required()}
                  filter={{ department: formData.department }}
                  perPage={500}
                  {...rest}
                >
                  <SelectInput
                    optionText="name"
                  />
                </ReferenceInput>
              )
            }
          }
        </FormDataConsumer>

        <Typography/>

        <TextInput
          source="firstName"
          validate={required()}
          formClassName={classes.first_name}
          label={translate('resources.people.fields.firstName')}
        />
        <TextInput
          source="lastName"
          validate={required()}
          formClassName={classes.last_name}
          label={translate('resources.people.fields.lastName')}
        />
        <TextInput
          source="email"
          validate={[required(), email()]}
          type="email"
          fullWidth={true}
          formClassName={classes.email}
          label={translate('resources.users.fields.email')}
        />

        <BooleanInput source="enabled"/>

        <ImageInput source="avatar" accept="image/*">

          <FunctionField
            render={
              record => {
                if (record && typeof record === 'string') {
                  return (
                    <Query type="GET_ONE" resource="user-avatars" payload={{ id: record }}>
                      {({ data, loading, error }) => {
                        if (loading) {
                          return <LoadingIndicator/>
                        }
                        if (error) {
                          return <p>ERROR</p>
                        }

                        return (
                          <ImageField
                            source='src'
                            record={{ src: `${config.api}${data.contentUrl}` }}
                          />
                        )
                      }}
                    </Query>
                  )
                } else if (record && typeof record === 'object') {
                  return (
                    <ImageField source="src" record={{ src: record.rawFile.preview }}/>
                  )
                }
                return <div/>
              }
            }/>
        </ImageInput>
      </SimpleForm>
    </Edit>
  )
}

export default translate(withStyles(styles)(EmployeeEdit))