import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LoadingIndicator, useDataProvider } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import DimplesContainer from './DimplesContainer'
import DiagnosticsContainer from './DiagnosticsContainer'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps (index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    width: '100%'
  }
}))

const CheckContainer = ({ carInstance }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const [value, setValue] = useState(0)
  const [diagnostics, setDiagnostics] = useState(null)
  const [loading, setLoading] = useState(true)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  
  useEffect(() => {
    setLoading(true)
    if (!carInstance.hasOwnProperty('diagnostics')) {
      return
    }

    if (0 === carInstance.diagnostics.length) {
      //создаем диагностику
      dataProvider.create('diagnostics', { data: { carInstance: carInstance.id } })
        .then(({ data }) => {
          setDiagnostics(data)
          setLoading(false)
        })
    } else {
      dataProvider.getOne('diagnostics', { id: carInstance.diagnostics[0] })
        .then(({ data }) => {
          setDiagnostics(data)
          setLoading(false)
        })
    }
  }, [carInstance])

  return (
    loading ?
      <LoadingIndicator/> :
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Повреждения экстерьера" {...a11yProps(0)} />
          <Tab label="Повреждения интерьера" {...a11yProps(1)} />
          <Tab label="Диагностика" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0} className={classes.tab}>
          <DimplesContainer carInstance={carInstance} diagnostics={diagnostics} type={'exterior'}/>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tab}>
          <DimplesContainer carInstance={carInstance} diagnostics={diagnostics} type={'interior'}/>
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tab}>
          <DiagnosticsContainer data={diagnostics} carInstance={carInstance} />
        </TabPanel>
      </div>
  )
}

export default CheckContainer