import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { blue } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionActions from '@material-ui/core/AccordionActions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import BrandField from './Fields/BrandField'
import ModelField from './Fields/ModelField'
import GenerationField from './Fields/GenerationField'
import YearField from './Fields/YearField'
import RegionField from './Fields/RegionField'
import CityField from './Fields/CityField'
import DistanceField from './Fields/DistanceField'
import MileageField from './Fields/MileageField'
import { StorageFactory } from '../../../utils/StorageFactory'
import ModificationField from './Fields/ModificationField'

const useStyles = makeStyles(theme => createStyles({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  actions: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button: {
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  }
}))

const AppraisalFilter = (props) => {

  const { setFilters, onFilterApply } = props

  const classes = useStyles()
  const translate = useTranslate()

  const [values, setValues] = useState({})
  const [expanded, setExpanded] = useState(true)

  const [yearFrom, setYearFrom] = useState(new Date().getFullYear() - 20);
  const [yearTo, setYearTo]= useState(new Date().getFullYear());

  const getYears = (yearFrom, yearTo) => {
    return new Array(yearTo - yearFrom + 1)
      .fill(1)
      .reduce((obj, item, index) => {
        obj[yearTo - index] = yearTo - index
        return obj
      }, {});
  }

  const resetYears = () => {
    setYearFrom(new Date().getFullYear() - 20)
    setYearTo(new Date().getFullYear())
  }

  const getValue = (key, nullValue) => {
    return values.hasOwnProperty(key) ? values[key] : nullValue
  }

  const applyFilter = () => {
    const lat = StorageFactory().getItem('geo_lat')
    const lon = StorageFactory().getItem('geo_lon')
    const radius = null === lat ? null : StorageFactory().getItem('geo_radius') ? StorageFactory().getItem('geo_radius') : 10000

    setFilters({
      lat, lon, radius, ...values
    })
  }

  const clearFilter = () => {
    setValues({})
    setFilters({})
  }

  return (
    <div className={classes.root}>

      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}><Box
            color="info.main">{translate('resources.crwl/links.filter.title')}</Box></Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} sm={4}>
                <BrandField
                  onChange={(value) => {
                    setValues({
                      ...values, ...{
                        'brand': value,
                        'model': null,
                        'generation': null,
                        'modification': null,
                        'year': null
                      }
                    })
                    setFilters({})
                  }}
                  initialValue={getValue('brand', '')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ModelField
                  onChange={(value) => {
                    setValues({
                      ...values, ...{
                        'model': value,
                        'generation': null,
                        'modification': null,
                        'year': null
                      }
                    })
                    setFilters({})
                  }}
                  brand={values['brand']}
                  initialValue={getValue('model', '')}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <GenerationField
                  onChange={(value) => {
                    setValues({
                      ...values, ...{
                        'generation': value,
                        'modification': null,
                        'year': null
                      }
                    })
                    setFilters({})
                  }}
                  onOptionSelected={(generation) => {
                    if (generation) {
                      setYearFrom(generation.yearFrom)
                      setYearTo(generation.yearTo)
                    }
                    else {
                      resetYears();
                    }
                  }}
                  brand={values['brand']}
                  model={values['model']}
                  initialValue={getValue('generation', '')}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <ModificationField
                  onChange={(value) => {
                    setValues({
                      ...values, ...{
                        'modification': value
                      }
                    })
                    setFilters({})
                  }}
                  onOptionSelected={(modification) => {
                    if (modification) {
                      setYearFrom(modification.yearFrom)
                      setYearTo(modification.yearTo)
                    }
                    else {
                      resetYears();
                    }
                  }}
                  brand={getValue('brand', '')}
                  model={getValue('model', '')}
                  generation={getValue('generation', '')}
                  initialValue={getValue('modification', '')}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <YearField
                  onChange={(value) => {
                    setValues({
                      ...values, ...{
                        'year': value
                      }
                    })
                    setFilters({})
                  }}
                  years={getYears(yearFrom, yearTo)}
                  type="flat"
                  initialValue={getValue('year', '')}
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <MileageField
                  onChange={(value) => {
                    setValues({
                      ...values, ...{
                        'mileage': value
                      }
                    })
                    setFilters({})
                  }}
                  type="flat"
                  initialValue={getValue('mileage', '')}
                />
              </Grid>

            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} sm={4}>
                <RegionField onChange={(value) => {
                  setValues({
                    ...values, ...{
                      'region': value
                    }
                  })
                  setFilters({})
                }}/>
              </Grid>

              <Grid item xs={12} sm={4}>
                <CityField region={getValue('region', StorageFactory().getItem('geo_region'))}/>
              </Grid>

              <Grid item xs={12} sm={4}>
                <DistanceField/>
              </Grid>
            </Grid>
          </Grid>


        </AccordionDetails>


        <AccordionActions className={classes.actions}>
          {
            (values.hasOwnProperty('modification') && values.hasOwnProperty('year')) &&
            <Button variant="contained" color="primary" className={classes.button} onClick={applyFilter}>
              {translate('resources.crwl/request-appraisal.action.create')}
            </Button>
          }

          {
            Object.keys(values).length > 0 &&
            <Button variant="contained" color="secondary" className={classes.button}
                    onClick={clearFilter}>
              {translate('resources.crwl/links.actions.clearFilter')}
            </Button>
          }
        </AccordionActions>
      </Accordion>
    </div>
  )
}

export default AppraisalFilter