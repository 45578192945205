import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}))

const AnsweredQuestionsList = ({ questions, onRemove }) => {

  const classes = useStyles()

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Оборудование</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.map((q) => (
            <TableRow key={q.id}>
              <TableCell component="th" scope="row">
                {q.question}
              </TableCell>
              <TableCell
                align="right">{-1 === q.answer ? 'Не установлено' : 1 === q.answer ? 'Установлено' : 'Не знаю'}</TableCell>
              <TableCell align="right">
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon onClick={() => onRemove(q.id)}/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AnsweredQuestionsList