export default {
  'questionnaire/questionnaires': {
    name: 'Questionnaire |||| Questionnaires',
    fields: {
      name: 'Title',
      enabled: 'Enabled',
      createdAt: 'Created at',
      numberOfQuestions: 'Number of questions',
    },
    tabs: {
      summary: 'General data',
      questions: 'Questions',
    }
  },
}