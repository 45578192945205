import Worksheet from './Worksheet/en'
import WorksheetFinance from './WorksheetFinance/en'
import WorksheetInsurance from './WorksheetInsurance/en'
import WorksheetNew from './WorksheetNew/en'
import WorksheetSearch from './WorksheetSearch/en'
import WorksheetTradeIn from './WorksheetTradeIn/en'
import WorksheetUsed from './WorksheetUsed/en'

export default {
  ...Worksheet,
  ...WorksheetFinance,
  ...WorksheetInsurance,
  ...WorksheetNew,
  ...WorksheetSearch,
  ...WorksheetTradeIn,
  ...WorksheetUsed
}