export default {
  'questionnaire/groups': {
    add: 'Ajouter le groupe de la question',
    name: 'Groupe |||| Groupes',
    fields: {
      name: 'Groupe |||| Groupes',
      questionnaire: 'Questionnaire',
      ord: 'Commande',
      enabled: 'Enabled',
      numberOfQuestions: 'Nombre de questions',
    }
  }
}