import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import colors from './colors'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DoneIcon from '@material-ui/icons/Done'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles(theme => ({
  ...colors(theme),
  margin: {
    marginRight: theme.spacing(),
    marginTop: theme.spacing(),
  },
  textField: {
    minWidth: 300
  }
}))

const TagCreate = ({ onChange }) => {

  const translate = useTranslate()
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [color, setColor] = useState(null)

  useEffect(() => {
    onChange({ title, color })
  }, [title, color])

  return (
    <div>
      <TextField
        id="title"
        label={translate('resources.crwl/tags.fields.title')}
        className={classes.textField}
        value={title}
        onChange={event => setTitle(event.target.value)}
        margin="normal"
        fullWidth
      />

      {
        [
          'red',
          'pink',
          'purple',
          'indigo',
          'blue',
          'cyan',
          'teal',
          'green',
          'lime',
          'yellow',
          'amber',
          'orange',
          'brown',
          'grey',
        ].map(c => {
          return (
            <Button
              variant="contained"
              color="primary"
              className={classNames(classes.margin, classes[c])}
              onClick={() => setColor(c)}
            >
              {
                color === c ? <DoneIcon/> : ''

              }
            </Button>
          )
        })
      }
    </div>
  )
}

TagCreate.propTypes = {
  onChange: PropTypes.func
}

export default TagCreate