export default {
  'workflow/subject-types': {
    name: 'Workflow-Typ |||| Workflow-Typen',
    fields: {
      name: 'Titel',
      className: 'Subject class name',
      resourceName: 'Ressourcenname',
      eventTypes: 'Calendar event types',
    }
  },
}