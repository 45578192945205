import React, { useState, useEffect } from 'react'
import Badge from '@material-ui/core/Badge'
import { useDataProvider } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import TaskIcon from '@material-ui/icons/EventAvailable'
import { getAccessToken, getTokenClaim } from '../../utils/AuthUtils'

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge)

//@TODO сделать через mercury
const TasksIcon = () => {

  const [numTasks, setNumTasks] = useState(0)
  const dataProvider = useDataProvider()

  const getTasks = () => {

    /*
    dataProvider.getList('tasks',{
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: { isCompleted: false, assignee : getTokenClaim(getAccessToken(), 'id')},
    })
        .then(({data, total}) => {
            setNumTasks(total)
        });

     */

  }

  useEffect(() => {
    getTasks()
    const checker = setInterval(getTasks, 30000)
    return () => clearInterval(checker)
  }, [])

  return (
    numTasks > 0 ? <StyledBadge badgeContent={numTasks} color="primary"> <TaskIcon/> </StyledBadge> : <TaskIcon/>
  )
}

export default TasksIcon