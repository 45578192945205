import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslate } from 'react-admin'

const ConfirmDialog = (props) => {

  const { open, title, body, onAgree, onDisagree } = props

  const translate = useTranslate()

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onDisagree()} color="primary">
          {translate('ra.action.cancel')}
        </Button>
        <Button onClick={() => onAgree()} color="primary" autoFocus>
          {translate('ra.action.confirm')}
        </Button>
      </DialogActions>.
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  onAgree: PropTypes.func,
  onDisagree: PropTypes.func
}

export default ConfirmDialog