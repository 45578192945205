// in src/Dashboard.js
import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Title } from 'react-admin'
import Dashboard from '../components/Dashboard/Dashboard'

export default (props) => (
  <Card>
    <Title title="digidu"/>
    <CardContent>
      <Dashboard {...props} />
    </CardContent>
  </Card>
);