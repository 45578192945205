import React from 'react'
import {
  Show,
  Tab,
  FunctionField,
  RichTextField,
  TabbedShowLayout,
  DateField,
  BooleanField,
  useTranslate
} from 'react-admin'
import taskableResources from './taskableResources'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  firstInput: {
    marginTop: 0,
  },
  first: {
    display: 'inline-block'
  },
  second: {
    display: 'inline-block', marginLeft: 32
  },
  heading: {
    marginTop: theme.spacing(2),
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  content: {
    padding: 0
  }
})

const TaskShow = props => {

  const translate = useTranslate()

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label={translate('resources.tasks.tabs.summary')}>
          <RichTextField source="title"/>
          <RichTextField source="description"/>
          <DateField source="due" showTime/>
          <BooleanField source="isCompleted"/>
          <DateField source="completedAt" showTime/>

          <FunctionField label="" source="subjectResourceName" render={record => {
            const resource = taskableResources.find(r => r.name === record.subjectResourceName);
            return resource ? resource.link(record) : ''
          }}/>
        </Tab>

      </TabbedShowLayout>
    </Show>
  )
}

export default withStyles(styles)(TaskShow)