export default {
  'tasks': {
    name: 'Задача  |||| Задачи',
    fields: {
      createdAt: 'Создана',
      createdBy: 'Автор',
      creatorEmployee: 'Автор',
      task: 'Описание задачи',
      subject: 'Связанный объект',
      title: 'Заголовок',
      description: 'Описание задачи',
      due: 'Срок выполнения',
      assignee: 'Назначена на',
      isCompleted: 'Выполнена',
      completedAt: 'Время выполнения',
      isNew: 'Новая',
      subjectClassName: 'Тип привязанного объекта',
      subjectId: 'Связанный объект',
      notifications: 'Напоминания',

      properties: 'Свойства задачи',
      resource: 'Связанный объект',
      resourceName: 'Тип связанного объекта',
      resourceId: 'ID связанного объекта',
      assignment: 'Назначение',
      assignOnMe: 'Назначить на меня',
      company: 'Дилер',
      companyBranch: 'Автосалон',
      jobPosition: 'Должность',
      employee: 'Сотрудник'
    },
    buttons: {
      add: 'Добавить задачу',
      markAsComplete: 'Выполнено',
      markAsNew: 'Отметить как новую'
    },
    errors: {
      assignmentRequired: 'Необходимо указать на кого назначить задачу'
    },
    dialogs: {
      create: {
        title: 'Добавить задачу'
      },
      confirmComplete: {
        title: 'Отметить как задачу, как выполненную',
        description: 'Подтвердите выполнение задачи'
      }
    },
    tabs: {
      summary: 'Свойства задачи',
      log: 'История изменений'
    }
  },
}