import React, { Suspense, lazy } from 'react'
import PropTypes from 'prop-types'

const Graphviz = lazy(() => import('./Graphviz'))

class DOTField extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      showGraph: false
    }
    this.toggleGraph = this.toggleGraph.bind(this)
  }

  toggleGraph () {
    this.setState((state) => ({ showGraph: !state.showGraph }))
  }

  render () {
    const { source, record = {}, graphOptions = {} } = this.props
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Graphviz dot={record[source]} options={graphOptions}/>
      </Suspense>
    )
  }

}

DOTField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default DOTField
