export default {
  'workflow/participants': {
    add: 'Добавить участника',
    name: 'Участник |||| Участники',
    fields: {
      workflow: 'Процесс',
      role: 'Роль',
      company: 'Участник'
    }
  },
}