export default {
  'job-grades': {
    add: 'Add job grade',
    name: 'Grade |||| Grades',
    fields: {
      name: 'Title',
      company: 'Company',
      enabled: 'Enabled',
    }
  },
}