import React from 'react'
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  FormTab,
  FunctionField,
  TextInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  useTranslate,
  useLocale
} from 'react-admin'
import { DateTimeInput } from '../Inputs/ra-admin-date-inputs'
import taskableResources from './taskableResources'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  firstInput: {
    marginTop: 0,
  },
  first: {
    display: 'inline-block'
  },
  second: {
    display: 'inline-block', marginLeft: 32
  },
  heading: {
    marginTop: theme.spacing(2),
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  content: {
    padding: 0
  }
})

const TaskEdit = props => {

  const { classes } = props

  const locale = useLocale()
  const translate = useTranslate()

  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label={translate('resources.tasks.tabs.summary')}>
          <TextInput multiline source="title" fullWidth/>
          <TextInput multiline source="description" fullWidth/>
          <DateTimeInput source="due" locale={locale} options={{
            disablePast: true,
            ampm: false
          }}/>
          <BooleanInput source="isCompleted"/>
          <FunctionField label="" source="subjectResourceName" render={record =>
            taskableResources.find(r => r.name === record.subjectResourceName).link(record)
          }/>

          <Typography variant="subtitle1" className={classes.heading} color={'primary'}>
            {translate('resources.tasks.fields.assignment')}
          </Typography>

          <BooleanInput source="assignOnMe" defaultValue={true}/>
          <FormDataConsumer formClassName={classes.first}>
            {({ formData, ...rest }) =>
              !formData.assignOnMe &&
              <ReferenceInput
                source="company"
                reference="companies"
                formClassName={classes.first}
                {...rest}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
            }
          </FormDataConsumer>

          <FormDataConsumer formClassName={classes.second}>
            {({ formData, ...rest }) =>
              (!formData.assignOnMe && formData.company) &&
              <ReferenceInput
                source="companyBranch"
                reference="company-branches"
                formClassName={classes.second}
                filter={{ company: formData.company }}
                {...rest}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
            }
          </FormDataConsumer>
          <div/>
          <FormDataConsumer formClassName={classes.first}>
            {({ formData, ...rest }) =>
              (!formData.assignOnMe && formData.company) &&
              <ReferenceInput
                source="jobPosition"
                reference="job-positions"
                formClassName={classes.second}
                filter={{ company: formData.company }}
                {...rest}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
            }
          </FormDataConsumer>

          <FormDataConsumer formClassName={classes.second}>
            {({ formData, ...rest }) =>
              (!formData.assignOnMe && formData.companyBranch && formData.jobPosition) &&
              <ReferenceInput
                source="employee"
                reference="employees"
                formClassName={classes.second}
                filter={{ companyBranch: formData.companyBranch, jobPosition: formData.jobPosition }}
                {...rest}
              >
                <SelectInput optionText={r => {
                  return `${r.personData.lastName ? r.personData.lastName : ''} ${r.personData.firstName ? r.personData.firstName : ''} ${r.personData.thirdName ? r.personData.thirdName : ''}`
                }}/>
              </ReferenceInput>
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default withStyles(styles)(TaskEdit)