import Group from './Group/de'
import Options from './Options/de'
import Question from './Question/de'
import Questionnaire from './Questionnaire/de'
import Survey from './Survey/de'

export default {
  ...Group,
  ...Options,
  ...Question,
  ...Questionnaire,
  ...Survey,
}