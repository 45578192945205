import Actions from './Action/ru'
import Participant from './Participant/ru'
import Place from './Place/ru'
import RequestToCopy from './RequestToCopy/ru'
import SubjectType from './SubjectType/ru'
import Transition from './Transition/ru'
import Type from './Type/ru'
import Workflow from './Workflow/ru'

export default {
  ...Actions,
  ...Participant,
  ...Place,
  ...RequestToCopy,
  ...SubjectType,
  ...Transition,
  ...Type,
  ...Workflow,
}