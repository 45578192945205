import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'

import CardMedia from '@material-ui/core/CardMedia'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Close'
import LinearProgress from '@material-ui/core/LinearProgress'

const style = theme => ({
  card: {
    maxWidth: 345,
    position: 'relative'
  },
  media: {
    height: 140,
  },
  delete: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  input: {
    opacity: '0',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1200,
    cursor: 'pointer'
  },
})

const Thumbnail = ({ classes, url, showTitle=false, title='', onClick, onDelete, uploadable = false, uploadProgress = null, onUpload = () => { } }) => {

  const [hovered, setHovered] = useState(false)
  const [src, setSrc] = useState(url)

  const fileSelector = () => <input
    accept="image/*"
    className={classes.input}
    type="file"
    onChange={onChange}
  />

  const onChange = (event) => {
    const input = event.target
    const file = input.files[0]

    const reader = new FileReader()
    reader.onload = function (e) {
      setSrc(e.target.result)
    }
    reader.readAsDataURL(file)
    onUpload(file)
  }

  return (
    <Card className={classes.card} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {
        uploadable && fileSelector()
      }
      {
        uploadProgress && <LinearProgress variant="determinate" value={uploadProgress}/>
      }
      {
        showTitle &&
        <CardHeader
          subheader={title}
        />
      }
      <CardActionArea>

        <CardMedia
          className={classes.media}
          image={src ? src : url}
          onClick={onClick}
        />
        {
          (!!hovered && !!onDelete) &&
          <IconButton aria-label="Settings" className={classes.delete} onClick={onDelete}>
            <DeleteIcon color={'error'}/>
          </IconButton>
        }

      </CardActionArea>
    </Card>
  )

}

export default withStyles(style)(Thumbnail)

