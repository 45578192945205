import React, { useState } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import { MenuItemLink, useTranslate, } from 'react-admin'

import SubMenu from '../SubMenu'

import MarketIcon from '@material-ui/icons/CollectionsBookmark'
import CarsIcon from '@material-ui/icons/DirectionsCar'
import FavIcon from '@material-ui/icons/Favorite'
import CalcIcon from '@material-ui/icons/Keyboard'
import FilterIcon from '@material-ui/icons/Tune'

import { isGranted, ROLE_USER } from '../../utils/AuthUtils'

const Menu = ({ onMenuItemClick, toggleSubMenu, isSidebarOpen, isSubmenuOpen, location }) => {

  const translate = useTranslate()

  const Items = [
    {
      url: '/crwl/links',
      hiddenUrls: [],
      name: translate('menu.market.menu.offers.name'),
      icon: <CarsIcon/>,
      role: ROLE_USER
    },
    {
      url: '/crwl/favorites',
      hiddenUrls: [],
      name: translate('menu.market.menu.favorites.name'),
      icon: <FavIcon/>,
      role: ROLE_USER
    },
    {
      url: '/crwl/filters',
      hiddenUrls: [],
      name: translate('menu.market.menu.filters.name'),
      icon: <FilterIcon/>,
      role: ROLE_USER
    },
    {
      url: '/crwl/request-appraisal/create',
      hiddenUrls: [],
      name: translate('menu.market.menu.estimation.name'),
      icon: <CalcIcon/>,
      role: ROLE_USER
    }
  ]

  const byLocationOpen = Items
    .reduce((acc, i) => acc.concat(i.url).concat(i.hiddenUrls), [])
    .filter(url => location.pathname.indexOf(url) > -1)
    .length

  return (
    isGranted(ROLE_USER) ?
      <SubMenu
        handleToggle={toggleSubMenu}
        isOpen={isSubmenuOpen || byLocationOpen}
        sidebarIsOpen={isSidebarOpen}
        name={translate('menu.market.name')}
        icon={<MarketIcon/>}
        to={Items[0].url}
      >
        {
          Items.map((i, index) =>
            isGranted(i.role) &&
            <MenuItemLink
              key={index}
              to={i.url}
              primaryText={i.name}
              leftIcon={i.icon}
              onClick={onMenuItemClick}
            />
          )
        }
      </SubMenu> : <div/>
  )
}

Menu.propTypes = {
  onMenuItemClick: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  isSubmenuOpen: PropTypes.bool,
  translate: PropTypes.func,
  toggleSubMenu: PropTypes.func,
}

const enhance = compose(
  withRouter
)

export default enhance(Menu)