import React, { useEffect, useState } from 'react'
import { LoadingIndicator, useDataProvider } from 'react-admin'
import AddDimpleButton from './AddDimpleButton'
import { makeStyles } from '@material-ui/core/styles'
import Dimple from './Dimple'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

const DimplesContainer = ({ type, diagnostics, carInstance }) => {

  const classes = useStyles()
  const dataProvider = useDataProvider()

  const [isLoading, setLoading] = useState(false)
  const [dimpleLocations, setDimpleLocations] = useState([])
  const [dimpleTypes, setDimpleTypes] = useState([])
  const [dimples, setDimples] = useState([])

  useEffect(() => {
    setLoading(true)
    dataProvider.getList('reference/dimple-types', {
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'type', order: 'ASC' },
        filter: {}
      }
    ).then(({ data }) => setDimpleTypes(data))

    dataProvider.getList('reference/dimple-locations', {
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'type', order: 'ASC' },
        filter: { 'type': type }
      }
    ).then(({ data }) => setDimpleLocations(data))

    dataProvider.getList('dimples', {
      pagination: { page: 1, perPage: 200 },
      sort: { field: 'type', order: 'ASC' },
      filter: { 'diagnostics': diagnostics.id }
    }).then(({ data }) => {
      setDimples(data)
      setLoading(false)
    })

  }, [])

  const addDimple = (dimple) => {
    const newDimples = [...dimples]
    newDimples.unshift(dimple)
    setDimples(newDimples)
  }

  const updateDimple = (dimple, data) => {
    dataProvider.update('dimples', {
      id: dimple.id,
      data: { ...dimple, ...data },
      previousData: dimple
    })
      .then(({ data }) => {
        const newDimples = dimples
        newDimples.map(d => {
          return d.id === dimple.id ?
            { ...d, ...data } : d
        })
        setDimples(newDimples)
      })
  }

  const deleteDimple = (dimpleId) => {
    const newDimples = dimples.filter(d => d.id !== dimpleId)
    setDimples(newDimples)
    dataProvider.delete('dimples', { id: dimpleId })
  }

  const onPhotoUploaded = (dimple) => (photo) => {
    dataProvider.update('dimples', {
      id: dimple.id,
      data: { ...dimple, photos: dimple.photos.concat(`/car-photos/${photo.id}`) },
      previousData: dimple
    })
      .then(({ data }) => {
        const newDimples = dimples
        newDimples.map(d => {
          return d.id === dimple.id ?
            { ...d, photos: dimple.photos.concat(`/car-photos/${photo.id}`) } : d
        })
        setDimples(newDimples)
      })
  }

  const onPhotoDeleted = (dimple) => (photo) => {
    dataProvider.update('dimples', {
      id: dimple.id,
      data: { ...dimple, photos: dimple.photos.filter(p => p.id !== photo.id) },
      previousData: dimple
    })
      .then(({ data }) => {
        const newDimples = dimples
        newDimples.map(d => {
          return d.id === dimple.id ?
            { ...d, photos: dimple.photos.filter(p => p.id !== photo.id) } : d
        })
        setDimples(newDimples)
      })
  }

  return (
    <div className={classes.root}>
      <AddDimpleButton onAdd={addDimple} diagnostics={diagnostics} type={type} types={dimpleTypes}
                       locations={dimpleLocations}/>

      {
        isLoading && <LoadingIndicator/>
      }

      {
        (dimpleTypes && dimpleLocations && dimples) && dimples.filter(d => {
          const location = dimpleLocations.find(l => l.id === d.location)
          return location && location.type === type
        }).map(d =>
          <Dimple
            key={d.id}
            carInstance={carInstance}
            diagnostics={diagnostics}
            type={type}
            dimple={d}
            locations={dimpleLocations}
            types={dimpleTypes}
            onSave={updateDimple}
            onDelete={deleteDimple}
            onPhotoUploaded={onPhotoUploaded(d)}
            onPhotoDeleted={onPhotoDeleted(d)}
          />)
      }
    </div>
  )

}

export default DimplesContainer

