import Company from './Company/ru'
import CompanyBranch from './CompanyBranch/ru'
import CompanyFile from './CompanyFile/ru'
import CompanyType from './CompanyType/ru'
import Department from './Department/ru'
import Employee from './Employee/ru'
import JobGrade from './JobGrade/ru'
import JobPosition from './JobPosition/ru'

export default {
  ...Company,
  ...CompanyBranch,
  ...CompanyFile,
  ...CompanyType,
  ...Department,
  ...Employee,
  ...JobGrade,
  ...JobPosition
}