import Link from './Link/ru'
import Filter from './Filter/ru'
import Favorites from './Favorites/ru'
import RequestAppraisal from './RequestAppraisal/ru'

export default {
  ...Link,
  ...Filter,
  ...Favorites,
  ...RequestAppraisal,
}