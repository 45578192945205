export default {
  'workflow/request-copy-workflow': {
    name: 'Copy workflow',
    fields: {
      workflowToCopy: 'Zu kopierender Workflow',
      newWorkflowCompany: 'Unternehmen des neuen Workflows',
      newWorkflowName: 'Neuer Workflowname',
    },
    buttons: {
      create: 'Workflow hinzufügen',
      copy: 'Workflow kopieren'
    }
  },
}