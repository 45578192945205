import React from 'react'
import PropTypes from 'prop-types'
import MultipleSelectField from './MultipleSelectField'

const options = {
  ['auto.ru']: 'auto.ru',
  ['avito.ru']: 'avito.ru',
}

const SrcField = (props) =>
  <MultipleSelectField
    options={options}
    label={'resources.crwl/links.fields.src'}
    {...props}
  />

SrcField.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
}

export default SrcField