import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Error, LinearProgress, useDataProvider, useTranslate } from 'react-admin'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/es/ListItem/ListItem'
import AssigneeItem from './AssigneeItem'
import Typography from '@material-ui/core/Typography'

const AssigneeList = (props) => {

  const { resourceName, resourceId, transitionId, onSelect, selected } = props

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const dataProvider = useDataProvider()
  const translate = useTranslate()

  useEffect(() => {
    if (transitionId) {
      setLoading(true)
      dataProvider.create(
        'workflow/request-suggested-assignees',
        { data: { transition: transitionId, subject: resourceId } }
      )
        .then(({ data }) => {
          setData(data)
          setLoading(false)
        })
        .catch(error => {
          setError(error)
          setLoading(false)
        })

    }
  }, [transitionId, resourceId])

  if (loading) return <List><ListItem><LinearProgress/></ListItem></List>
  if (error) return <Error/>
  if (!data) return null

  if (!data.employees.length) {
    return <List style={{ padding: 28 }}><Typography
      variant="body1">{translate('resources.workflow/workflows.dialogs.assignee.noAssignees')}</Typography></List>
  }

  return (

    <List>
      {
        data.employees.map(employee => {
          return (
            <AssigneeItem
              key={employee.user}
              {...employee}
              onSelect={onSelect}
              selected={selected.indexOf(employee.user) > -1}
            />
          )
        })
      }
    </List>
  )
}

AssigneeList.propTypes = {
  transition: PropTypes.string,
  subject: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.array
}

export default AssigneeList