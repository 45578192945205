import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { useTranslate, useDataProvider } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'
import AddTaskButton from '../Tasks/AddTaskButton'
import TasksList from './TasksList'

const styles = theme => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 140,
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  btn: {
    marginBottom: theme.spacing(),
  }
})

const TasksCard = (props) => {

  const { classes, resource, id, basePath } = props

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const [tasks, setTasks] = useState([])
  const [selected, setSelected] = useState([])
  const [isCompleting, setIsCompleting] = useState(false)

  useEffect(() => {
    dataProvider.getList('tasks', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'due', order: 'ASC' },
      filter: {
        isCompleted: 'false',
        subjectId: id,
        subjectResourceName: resource
      }
    })
      .then(({ data }) => {
        setTasks(data)
      })
  }, [resource, id])

  const onSelect = (taskId) => {
    setSelected(selected.includes(taskId) ? selected.filter(t => t !== taskId) : selected.concat(taskId))
  }

  const onCreate = (task) => {
    setTasks(tasks.concat(task))
  }

  const onComplete = () => {
    setIsCompleting(true)
    Promise.all(
      selected.map(id =>
        dataProvider.update('tasks', { id, data: { isNew: false, isCompleted: true }, previousData: {} })
          .then((data) => id)
      )
    ).then((ids) => {
      setSelected([])
      setTasks(tasks.filter(t => !ids.includes(t.id)))
      setIsCompleting(false)
    })
  }

  return (
    tasks.length > 0 ?
      <Card className={classes.card} square={true} elevation={1}>
        <CardContent>
          <Typography gutterBottom variant="h5">
            {translate('resources.tasks.name', { smart_count: 2 })}
          </Typography>
          <TasksList tasks={tasks} onSelect={onSelect} basePath={basePath}/>
        </CardContent>
        <CardActions>
          {
            !!selected.length &&
            <Button size="small" color="primary" onClick={onComplete} disabled={isCompleting}>
              <DoneIcon className={classes.leftIcon}/>
              {translate('resources.tasks.buttons.markAsComplete')}
            </Button>
          }

          <AddTaskButton resource={resource} id={id} onCreate={onCreate}/>
        </CardActions>
      </Card> :
      <div className={classes.btn}>
        <AddTaskButton resource={resource} id={id} onCreate={onCreate}/>
      </div>
  )
}

export default compose(
  withStyles(styles),
)(TasksCard)