export default {
  'workflow/request-copy-workflow': {
    name: 'Copy workflow',
    fields: {
      workflowToCopy: 'Workflow to copy',
      newWorkflowCompany: 'New workflow\'s company',
      newWorkflowName: 'New workflow name',
    },
    buttons: {
      create: 'Add workflow',
      copy: 'Copy workflow'
    }
  },
}