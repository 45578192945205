import Worksheet from './Worksheet/de'
import WorksheetFinance from './WorksheetFinance/de'
import WorksheetInsurance from './WorksheetInsurance/de'
import WorksheetNew from './WorksheetNew/de'
import WorksheetSearch from './WorksheetSearch/de'
import WorksheetTradeIn from './WorksheetTradeIn/de'
import WorksheetUsed from './WorksheetUsed/de'

export default {
  ...Worksheet,
  ...WorksheetFinance,
  ...WorksheetInsurance,
  ...WorksheetNew,
  ...WorksheetSearch,
  ...WorksheetTradeIn,
  ...WorksheetUsed
}