export default {
  'questionnaire/surveys': {
    add: 'Create survey',
    name: 'Survey |||| Surveys',
    fields: {
      createdAt: 'Создан',
      company: 'Dealer',
      questionnaire: 'Questionnaire',
      isCompleted: 'Completed',
      isOpen: 'Open',
      workflow: 'Workflow',
    },
    tabs: {
      summary: 'Summary',
      results: 'Results',
      questions: 'Questions',
    },
    header: 'Question %{current_question} of %{questions_num}',
    buttons: {
      prev: 'Previous question',
      next: 'Next question',
      complete: 'Complete survey',
      save: 'Save',
      transitions: 'Survey actions'
    },
    inputs: {
      freeText: 'Your answer',
      yes: 'Yes'
    },
    messages: {
      answerSaved: 'Answer saved',
      answerError: 'An error occured while saving answer'
    }
  }
}