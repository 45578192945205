import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LoadingIndicator, useDataProvider } from 'react-admin'
import UnlinkButton from './UnlinkButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const TechDataContainer = (props) => {

  const { beeCarsVersionId, unlink } = props
  const dataProvider = useDataProvider()
  const [blocks, setBlocks] = useState([])
  const [descriptors, setDescriptors] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const classes = useStyles()

  useEffect(() => {
    dataProvider.create('b-c-request-tech-datas', {
      data: { versionId: beeCarsVersionId }
    }).then(({ data }) => {
      setData(data.data.data.sort(sortByOrd))
      setDescriptors(data.data.descriptors.sort(sortByOrd))
      setBlocks(data.data.blocks.sort(sortByOrd))
      setLoading(false)
    })
  }, [beeCarsVersionId])

  function sortByOrd (a, b) {
    if (a.ord < b.ord) {
      return -1
    }
    if (a.ord > b.ord) {
      return 1
    }
    // a должно быть равным b
    return 0
  }

  if (loading) {
    return <LoadingIndicator/>
  }

  return (
    <div>
      <UnlinkButton unlink={unlink}/>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          {
            blocks.map(block => {
              if (descriptors.find(d => d.block === block.id)) {
                return (
                  <React.Fragment>
                    <TableHead key={block.id}>
                      <TableRow>
                        <TableCell component="th">
                          <Typography variant={'h4'}>{block.name}</Typography>
                        </TableCell>
                        <TableCell component="th"/>
                        <TableCell component="th"/>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        descriptors.filter(descriptor => descriptor.block === block.id).map(descriptor => {
                          const row = data.find(d => d.descriptor === descriptor.id)

                          return (
                            <TableRow key={descriptor.id}>
                              <TableCell scope="row">
                                {descriptor.name}
                              </TableCell>
                              <TableCell scope="row">
                                {row.data}
                              </TableCell>
                              <TableCell scope="row" dangerouslySetInnerHTML={{ __html: descriptor.measure }}/>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </React.Fragment>
                )
              }
            })
          }
        </Table>
      </TableContainer>
    </div>
  )

}

TechDataContainer.propTypes = {
  autoRuModificationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default TechDataContainer