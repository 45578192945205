import React, { useEffect, useState } from 'react'
import { LoadingIndicator, useDataProvider, useLocale, useTranslate } from 'react-admin'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles({
  root: {
    marginBottom: 20
  },
  table: {
    minWidth: 650
  },

})

const VersionLevelContainer = ({ bcVersionId, year }) => {

  const dataProvider = useDataProvider()
  const classes = useStyles()
  const locale = useLocale()
  const translate = useTranslate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [currVersion, setCurrVersion] = useState({ name: '', rating: 0 })
  const [showFull, setShowFull] = useState(false)

  useEffect(() => {
    dataProvider.create('b-c-request-version-levels', { data: { version: bcVersionId, year: year } })
      .then(({ data }) => {
        if (data.result.length) {
          const foundVersion = data.result.find(r => r.curr === 1)
          if (foundVersion) {
            setCurrVersion(data.result.find(r => r.curr === 1))
          }
          setData(data.result)
        }
        setLoading(false)
      })
  }, [bcVersionId, year])

  return (
    loading ? <LoadingIndicator/> :
      showFull ?
        <div className={classes.root}>
          <Button color="primary" onClick={() => setShowFull(false)} size="small">Скрыть все комплектации</Button>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Комплектация</TableCell>
                  <TableCell align="right">мин. цена в {year} году</TableCell>
                  <TableCell align="right">макс. цена в {year} году</TableCell>
                  <TableCell align="right">Уровень комплектации</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.name} selected={row.curr === 1}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{
                      new Intl.NumberFormat(locale, {
                        minimumFractionDigits: 0,
                        style: 'currency',
                        currency: translate('resources.crwl/links.measure.currency'),
                        currencyDisplay: 'symbol'
                      }).format(row.minPrice)
                    }</TableCell>
                    <TableCell align="right">{
                      new Intl.NumberFormat(locale, {
                        minimumFractionDigits: 0,
                        style: 'currency',
                        currency: translate('resources.crwl/links.measure.currency'),
                        currencyDisplay: 'symbol'
                      }).format(row.maxPrice)
                    }</TableCell>
                    <TableCell align="right">
                      <Rating name="read-only" value={row.rating * 5} precision={0.01} readOnly/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </div> :

        <Box component="fieldset" mb={3} borderColor="transparent">
          <Typography component="legend">{currVersion.name}</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} md={2}>
              <Rating name="read-only" value={currVersion.rating * 5} precision={0.01} readOnly/>
            </Grid>
            <Grid item xs={6} md={2}>
              <Button color="primary" onClick={() => setShowFull(true)} size="small">Показать все комплектации</Button>
            </Grid>
          </Grid>
        </Box>
  )
}

export default VersionLevelContainer