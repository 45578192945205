import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { translate } from 'react-admin'
import grey from '@material-ui/core/colors/grey'

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    overflow: 'scroll',
    maxHeight: 300,
    backgroundColor: theme.palette.background.paper,
  },
  selected: {
    backgroundColor: grey[400]
  }
})

const getTimes = ({ startFrom = null, locale, value, ...props }) => {
  const date = (new Date())
  date.setHours(0, 0, 0, 0)
  const next24 = date.getTime() + 24 * 60 * 60 * 1000
  if (startFrom) {
    date.setHours(startFrom.getHours(), startFrom.getMinutes(), startFrom.getSeconds(), startFrom.getMilliseconds())
  }

  const times = []
  const formatter = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: locale === 'en_US'
  })

  while (date.getTime() < next24) {
    times.push({
      val: date.getTime(),
      text: formatter.format(date),
      selected: date.getHours() === value.getHours() && date.getMinutes() === value.getMinutes()
    })

    let prevTime = date.getTime()
    date.setTime(prevTime + 15 * 60 * 1000)
  }

  return times
}

const TimeSelector = (props) => {
  const { classes, locale, startFrom = null, onSelect, value } = props
  const times = getTimes({ startFrom: startFrom, locale, value })

  const handleSelect = (val) => () => {
    const d = new Date(val)
    onSelect({
      hours: d.getHours(),
      minutes: d.getMinutes()
    })
  }

  return (
    <div className={classes.root}>
      <List component="nav">
        {
          times.map(t => (
            <ListItem button onClick={handleSelect(t.val)} className={t.selected ? classes.selected : ''}>
              <ListItemText primary={t.text}/>
            </ListItem>
          ))
        }
      </List>
    </div>
  )
}

TimeSelector.propTypes = {
  value: PropTypes.object,
  startFrom: PropTypes.object,
  onSelect: PropTypes.func,
}

export default translate(withStyles(styles)(TimeSelector))