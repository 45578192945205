import React, { useState, useEffect } from 'react'
import Thumbnail from '../Photo/Thumbnail'
import { getPhotoUri } from '../../utils/PhotoUtils'

const PhotoThumbnail = ({ photo, showTitle=false, title='', onClick, onDeleted }) => {

  const [isLoading, setLoading] = useState(false)
  const [uri, setUri] = useState(null)

  
  useEffect(() => {
    if (typeof photo === 'string') {
      setUri(photo)
    } else {
      if (!isLoading && !uri) {
        setLoading(true)
        const uri = getPhotoUri(photo, 'small')()
        setUri(uri)
      }
    }
  })

  return (
    <Thumbnail url={uri} onClick={onClick} onDelete={onDeleted} title={title} showTitle={showTitle} />
  )

}

export default PhotoThumbnail

