import Company from './Company/fr'
import CompanyBranch from './CompanyBranch/fr'
import CompanyFile from './CompanyFile/fr'
import CompanyType from './CompanyType/fr'
import Department from './Department/fr'
import Employee from './Employee/fr'
import JobGrade from './JobGrade/fr'
import JobPosition from './JobPosition/fr'

export default {
  ...Company,
  ...CompanyBranch,
  ...CompanyFile,
  ...CompanyType,
  ...Department,
  ...Employee,
  ...JobGrade,
  ...JobPosition
}