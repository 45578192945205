export default {
  'workflow/workflows': {
    name: 'Arbeitsablauf |||| Arbeitsabläufe',
    fields: {
      company: 'Firma',
      title: 'Titel',
      type: 'Typ',
      initial_place: 'Prozessstart',
      enabled: 'aktiviert'
    },
    tabs: {
      summary: 'Daten Arbeitsablauf',
      participants: ' Gegenparteien',
      places: 'Stadien',
      transitions: 'Beziehungen',
      dump: 'Dump'
    },
    buttons: {
      create: 'Workflow hinzufügen',
      copy: 'Workflow kopieren'
    },
    dialogs: {
      assignee: {
        title: 'Zuordnen',
        assign: 'Zuweisen und einreichen',
        notSelected: 'Mitarbeiter auswählen',
        submit: 'Einreichen',
        noAssignees: 'Drücken Sie die Senden-Taste.',
        confirm: 'Zuordnung bestätigen'
      }
    }
  },
}