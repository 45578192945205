import React from 'react'
import { ReferenceInput, required, SelectInput, Create, SimpleForm, TextInput, AutocompleteInput } from 'react-admin'
import { parse } from 'query-string'

export const RequestWorkflowCreate = props => {
  const { company: company_id_string } = parse(props.location.search)
  const { workflow: workflow_id_string } = parse(props.location.search)

  const company = company_id_string
  const workflow = workflow_id_string

  const redirect = company ? `/companies/${encodeURIComponent(company)}/workflows` :
    workflow ? `/workflow/workflows/${encodeURIComponent(workflow)}` : false

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ newWorkflowCompany: company, workflowToCopy: workflow }}
        redirect={redirect}
      >
        <ReferenceInput
          source="newWorkflowCompany"
          reference="companies"
          validate={required()}
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ name: searchText })}
        >
          <AutocompleteInput
            optionText="name"
            disabled={!!company}
          />
        </ReferenceInput>

        <ReferenceInput
          source="workflowToCopy"
          reference="workflow/workflows"
          validate={required()}
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ name: searchText })}
        >
          <AutocompleteInput
            optionText="name"
            disabled={!!company}
          />
        </ReferenceInput>
        <TextInput source="newWorkflowName"/>
      </SimpleForm>
    </Create>
  )
}