import React from 'react'
import PropTypes from 'prop-types'
import MultipleSelectField from './MultipleSelectField'

const options = {
  GASOLINE: 'resources.crwl/links.measure.engineType.GASOLINE',
  DIESEL: 'resources.crwl/links.measure.engineType.DIESEL',
  HYBRID: 'resources.crwl/links.measure.engineType.HYBRID',
  LPG: 'resources.crwl/links.measure.engineType.LPG',
  ELECTRO: 'resources.crwl/links.measure.engineType.ELECTRO',
}

const EngineTypeField = (props) =>
  <MultipleSelectField
    options={options}
    label={'resources.crwl/links.fields.engineType'}
    {...props}
  />

EngineTypeField.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.array
}

export default EngineTypeField
