import React from 'react'
import { Datagrid, List, TextField, ReferenceField, Filter, ReferenceInput, SelectInput } from 'react-admin'

const EventTypeFilter = (props) =>
  <Filter {...props}>
    <ReferenceInput
      source="subjectType"
      reference="workflow/subject-types"
      alwaysOn
      perPage={100}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput source="name"/>
    </ReferenceInput>
  </Filter>

export const EventTypeList = props => {
  return (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={false} exporter={false}
          filters={<EventTypeFilter/>}>
      <Datagrid rowClick="edit">
        <TextField source="name"/>
        <ReferenceField source="subjectType" reference="workflow/subject-types" linkType={false}>
          <TextField source="name"/>
        </ReferenceField>
      </Datagrid>
    </List>
  )
}