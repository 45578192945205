import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useDataProvider, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'

const ModelField = (props) => {

  const { brand, initialValue } = props
  const [models, setModels] = useState([])
  const [loading, setLoading] = useState(false)

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (brand) {
      setLoading(true)
      dataProvider.getList('reference/models', {
        filter: { brand: brand },
        pagination: { perPage: 300 },
        sort: { field: 'name', order: 'ASC' }
      })
        .then(data => {
          setModels(data.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }

  }, [brand])

  const onChange = (event, value) => {
    const { onChange } = props
    onChange(value ? value['id'] : undefined)
  }

  const getById = (id) => {
    if (models.length) {
      return models.find((element) => element['id'] === id)
    }
    return ''
  }

  return (
    <Autocomplete
      options={models}
      getOptionLabel={option => option.name}
      onChange={onChange}
      value={getById(initialValue)}
      loading={loading}
      loadingText={translate('ra.page.loading')}
      renderInput={params => (
        <TextField {...params} label={translate('resources.crwl/links.fields.model')} variant="outlined"
                   fullWidth/>
      )}
    />
  )
}

ModelField.propTypes = {
  onChange: PropTypes.func,
  brand: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ModelField