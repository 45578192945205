import React, { useState, useEffect } from 'react'
import { useDataProvider, LoadingIndicator } from 'react-admin'
import Button from '@material-ui/core/Button'
import NewDimpleForm from './NewDimpleForm'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

const AddDimpleButton = ({ type, diagnostics, types, locations, onAdd }) => {

  const classes = useStyles()
  const dataProvider = useDataProvider()
  const [showForm, setShowForm] = useState(false)
  const [dimple, setDimple] = useState(null)
  const [loading, setLoading] = useState(false)

  const onCreate = () => {
    setShowForm(true)
    dataProvider.create('dimples', {
      data: {
        diagnostics: diagnostics.id
      }
    })
      .then(({ data }) => {
        setDimple(data)
      })
  }

  const onSave = ({ dimpleType, dimplePart, comment }) => {
    setShowForm(false)
    setLoading(true)
    dataProvider.update('dimples', {
      id: dimple.id,
      data: { ...dimple, type: dimpleType, location: dimplePart, comment }
    })
      .then(({ data }) => {
        setLoading(false)
        onAdd(data)
      })
  }

  const onClose = () => {
    if (dimple) {
      dataProvider.delete('dimples', { id: dimple.id })
    }
    setShowForm(false)
  }

  return (
    <div className={classes.root}>
      <Button variant="contained" color="primary" onClick={onCreate}>
        Добавить повреждение
      </Button>

      {
        showForm &&
        <NewDimpleForm
          type={type}
          onClose={onClose}
          onSave={onSave}
          types={types}
          locations={locations}
        />
      }
      {
        loading && <LoadingIndicator/>
      }

    </div>
  )

}

export default AddDimpleButton

