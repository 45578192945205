import React from 'react'
import {
  BooleanInput,
  Create,
  ReferenceInput,
  required,
  SelectInput,
  SelectArrayInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import { parse } from 'query-string'
import { rolesAvailable } from '../../config/roles'

const JobPositionCreate = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { company: company_id_string } = parse(props.location.search)

  const company = company_id_string
  const redirect = company ? `/companies/${encodeURIComponent(company)}/positions` : false

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ company }}
        redirect={redirect}
      >
        <ReferenceInput
          source="company"
          reference="companies"
          allowEmpty
          validate={required()}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" disabled/>
        </ReferenceInput>
        <ReferenceInput
          source="department"
          reference="departments"
          allowEmpty
          filter={{ company: company }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>


        <TextInput source="name"/>
        <SelectArrayInput
          source="roles"
          choices={
            Object
              .keys(rolesAvailable)
              .map(role => ({
                id: role,
                name: rolesAvailable[role].name
              }))
          }
        />
        <BooleanInput source="enabled"/>
      </SimpleForm>
    </Create>
  )
}

export default JobPositionCreate