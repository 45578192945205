import React from 'react'
import { Datagrid, List, ReferenceField, TextField, EditButton } from 'react-admin'

export const TypeList = props => {
  return (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={false} exporter={false}>
      <Datagrid rowClick="edit">
        <TextField source="name"/>
        <ReferenceField source="subjectType" reference="workflow/subject-types">
          <TextField source="name"/>
        </ReferenceField>
        <EditButton label={''}/>
      </Datagrid>
    </List>
  )
}