import React from 'react'
import { Datagrid, DeleteButton, Pagination, TextField, ReferenceField, ReferenceManyField } from 'react-admin'

export const ParticipantList = props => {
  return (
    <ReferenceManyField
      reference="workflow/participants"
      target="workflow"
      addLabel={false}
      pagination={<Pagination/>}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick="edit">
        <ReferenceField source="company" reference="companies" link={false}>
          <TextField source="name"/>
        </ReferenceField>
        <TextField source="role"/>
        <DeleteButton label="" redirect={false}/>
      </Datagrid>
    </ReferenceManyField>
  )
}