export default {
  'workflow/places': {
    add: 'Add state',
    name: 'State |||| States',
    fields: {
      workflow: 'Workflow',
      name: 'Title',
      code: 'Code',
      isInitial: ' Start',
      isSuccess: 'Successful',
      isFail: 'Failed',
      maxMinutesInPlace: 'Max minutes in place',
      overdueJobPositions: 'Notify on overdue'
    },
    timeleft: 'Time left',
    overdue: 'It was necessary to do further action until the specified time. The current state has a limit on the maximum time spent in it. You have not taken the next step in time.'
  },
}