import React, { useState } from 'react'
import { compose } from 'recompose'
import { connect, useDispatch } from 'react-redux'

import {
  Button,
  CREATE,
  fetchEnd,
  fetchStart,
  FormDataConsumer,
  ReferenceInput,
  SaveButton,
  SelectInput,
  showNotification,
  SimpleForm,
  useTranslate,
  useDataProvider,
  TextInput,
  useLocale,
  useNotify,
  Toolbar
} from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'
import PeriodInput from '../Inputs/DateInput/PeriodInput'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import CalendarView from './CalendarView'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const styles = theme => ({
  firstInput: {
    marginTop: 0,
  },
  first: {
    display: 'inline-block'
  },
  second: {
    display: 'inline-block', marginLeft: 32
  },
  heading: {
    marginTop: theme.spacing(2),
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  content: {
    padding: 0
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
})

function Transition (props) {
  return <Slide direction="up" {...props} />
}

const AddEventDialog = (props) => {

  const { open, onClose, onCreate, classes, fullScreen, resource, id } = props

  const [showCalendar, setShowCalendar] = useState(false)

  const translate = useTranslate()
  const locale = useLocale()
  const dataProvider = useDataProvider()
  const dispatch = useDispatch()
  const notify = useNotify()
  const [saving, setSaving] = useState(false)

  const handleSubmit = values => {
    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart())
    setSaving(true)

    dataProvider.create('calendar-events/request-create-events', {
      data: {
        resourceName: resource,
        resourceId: id,
        ...values
      }
    })
      .then(({ data }) => {
        onCreate(data)
        onClose()
      })
      .catch(error => {
        notify(error.message, 'error')
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        dispatch(fetchEnd())
        setSaving(false)
      })
  }

  const validate = (values) => {
    const errors = {}

    if (!values['period']) {
      errors.period = translate('resources.calendar-events/events.errors.periodRequired')
    } else {
      if ((new Date(values.period[0]) > new Date(values.period[1]))) {
        errors.period = translate('resources.calendar-events/events.errors.finishEarlieStart')
      }
    }

    if (!values['type']) {
      errors.type = translate('resources.calendar-events/events.errors.typeRequired')
    }

    return errors
  }

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-label="Create event"
      TransitionComponent={Transition}
    >
      <DialogTitle>{translate('resources.calendar-events/events.dialogs.create.title')}</DialogTitle>
      <DialogContent className={classes.content}>
        <SimpleForm
          resource="calendar-events/events"
          toolbar={<AddEventsDialogToolbar onClose={onClose} isSaving={saving}/>}
          undoable={false}
          redirect={false}
          save={handleSubmit}
          defaultValue={{ period: [(new Date).toISOString(), (new Date).toISOString()] }}
          validate={validate}
        >
          <ReferenceInput
            source="type"
            reference="calendar-events/event-types"
            filter={{ 'subjectType.resourceName': resource }}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name"/>
          </ReferenceInput>
          <TextInput multiline fullWidth source="description"/>
          <PeriodInput source="period"/>
          <Button
            label={`resources.calendar-events/events.buttons.${showCalendar ? 'hide' : 'show'}Calendar`}
            onClick={() => setShowCalendar(!showCalendar)}
          />
          {
            showCalendar &&
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  <Typography fullWidth>
                    <CalendarView
                      defaultView="day"
                      scrollToTime={formData['period'] ? new Date(formData['period'][0]) : new Date()}
                    />
                  </Typography>
                )
              }}
            </FormDataConsumer>
          }

        </SimpleForm>


      </DialogContent>
    </Dialog>
  )
}

const AddEventsDialogToolbar = (props) => {
  const { onClose, isSaving } = props
  return (
    <Toolbar {...props} >
      <SaveButton
        redirect={false}
        submitOnEnter={true}
        disabled={isSaving}
      />
      <Button label="ra.action.cancel" onClick={onClose} disabled={isSaving}>
        <IconCancel/>
      </Button>
    </Toolbar>
  )
}

export default compose(
  withStyles(styles),
  withMobileDialog(),
)(AddEventDialog)