import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import blue from '@material-ui/core/colors/blue'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import AssigneeList from './AssigneeList'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
}))

const AssigneeDialog = (props) => {

  const { open, onClose, onSubmit, transitionId, resourceId, resourceName, disabled } = props

  const translate = useTranslate()

  const [assignees, setAssignees] = useState([])
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleSelect = (id, name) => {
    if (assignees.includes({ id, name })) {
      setAssignees([])
    } else {
      setAssignees([{ id, name }])
    }
  }

  const handleSubmit = () => {
    onSubmit(assignees.map(({ id, name }) => id))
  }


  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="simple-dialog-title">
      <DialogTitle id="simple-dialog-title">
        {translate('resources.workflow/workflows.dialogs.assignee.title')}
      </DialogTitle>
      <div>
        {
          !showConfirmation ?
            <AssigneeList
              resourceId={resourceId}
              resourceName={resourceName}
              transitionId={transitionId}
              onSelect={handleSelect}
              selected={assignees.map(({ id, name }) => id)}
            /> :
            <DialogContent>
              <Typography variant={'h6'}>
                {translate('resources.workflow/workflows.dialogs.assignee.confirm')}
              </Typography>
              <List>
                {
                  assignees.map(({ id, name }) => {
                    return (
                      <ListItem>
                        <ListItemText primary={name}/>
                      </ListItem>
                    )
                  })
                }

              </List>
            </DialogContent>
        }

      </div>

      <DialogActions>
        <Button onClick={handleSubmit} color="primary" disabled={disabled}>
          {translate('resources.workflow/workflows.dialogs.assignee.assign')}
        </Button>
        {
          showConfirmation &&
          <Button onClick={() => setShowConfirmation(false)} color="primary" disabled={disabled}>
            {translate('ra.action.cancel')}
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

AssigneeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  transitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSubmit: PropTypes.func,
  resourceName: PropTypes.string,
  disabled: PropTypes.bool
}

AssigneeDialog.defaultProps = {
  onClose: () => {
  }
}

export default AssigneeDialog