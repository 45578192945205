import React, { useEffect, useState } from 'react'
import AppraisalFilter from './LinksListFilter/AppraisalFilter'
import { LoadingIndicator, useDataProvider, useLocale, useTranslate } from 'react-admin'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TitsPlot from './TitsPlot'
import SimilarLinksPlot from './SimilarLinksPlot'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'
import CreateFromAppraisalButton from '../WorksheetBuy/CreateFromCRWL/CreateFromAppraisalButton'
import StrictResolverContainer from './BeeCars2/StrictResolverContainer'
import { getCleanHydraId } from '../../utils/HydraUtils'
import FoundVersion from './BeeCars2/FoundVersion'
import CompareTable from './BeeCars2/CompareTable'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

const RequestAppraisalCreate = (props) => {

  const [filterValues, setFilterValues] = useState({})
  const [loading, setLoading] = useState(false)
  const [similarCars, setSimilarCars] = useState([])
  const [nextYearSimilarCars, setNextYearSimilarCars] = useState([])
  const [beeCarsVersionId, setBeeCarsVersionsId] = useState(null)
  const [versions, setVersions] = useState([])
  const [versionsLoading, setVersionsLoading] = useState(false)

  const { history } = props

  const classes = useStyles()
  const translate = useTranslate()
  const locale = useLocale()
  const dataProvider = useDataProvider()

  const requestAppraisal = () => {
    if (Object.keys(filterValues).length) {
      setLoading(true)
      dataProvider.create('crwl/request-appraisals', { data: filterValues })
        .then(({ data }) => {
          setSimilarCars(data.result)
        })
        .finally(() => {
          setLoading(false)
        })

      dataProvider.create('crwl/request-appraisals', {
        data: {
          ...filterValues,
          year: parseInt(filterValues.year) + 1
        }
      })
        .then(({ data }) => {
          setNextYearSimilarCars(data.result)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    setBeeCarsVersionsId(null)
    setVersions([])
    requestAppraisal()
  }, [filterValues])

  useEffect(() => {
    setVersionsLoading(true)
    dataProvider.create('commands/bc/versions-for-modification', { data: { modification: getCleanHydraId(filterValues.modification ?? ''), year: filterValues.year } })
      .then(({ data }) => {
        setVersions(data.result)
      })
      .finally(() => {
        setVersionsLoading(false)
      })

  }, [filterValues.modification, filterValues.year])

  const medianMileage = () => {
    const mileages = !!similarCars.links?.length ? similarCars.links.map(l => l.mileage) : [];
    const len = similarCars.links.length;
    const arrSort = mileages.sort();
    const mid = Math.ceil(len / 2);
    const median =
      len % 2 == 0 ? (arrSort[mid] + arrSort[mid - 1]) / 2 : arrSort[mid - 1];

    return median;
  }

  const avgMileage = () => {
    const mileages = !!similarCars.links?.length ? similarCars.links.map(l => l.mileage) : [];
    const len = mileages.length;
    const sum = mileages.reduce((acc, m) => acc + m, 0);
    return len ? sum / len : 0;
  }

  return (
    <React.Fragment>
      <AppraisalFilter
        setFilters={setFilterValues}
      />

      <Box component="span" m={1}>


        {
          loading ?
            <LoadingIndicator/> :

            !!Object.keys(filterValues).length &&
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CreateFromAppraisalButton history={history} filterValues={filterValues} beeCarsVersionId={beeCarsVersionId} />

                <Button variant="contained" color="primary" onClick={requestAppraisal} disabled={loading}>
                  {translate('ra.action.refresh')}
                </Button>
                {
                  !!similarCars.avgPrice &&
                  <Typography variant={'h5'}>
                    Средняя цена:&nbsp;
                    {new Intl.NumberFormat(locale, {
                      minimumFractionDigits: 0,
                      currency: 'RUB',
                      style: 'currency'
                    }).format(similarCars.avgPrice)}
                  </Typography>
                }
                {
                  similarCars.links?.length > 0 &&
                  <Typography variant={'h5'}>
                    Средний пробег:&nbsp;
                    {new Intl.NumberFormat(locale, {
                      maximumFractionDigits: 0
                    }).format(avgMileage())} км
                  </Typography>
                }
                {
                  similarCars.links?.length > 0 &&
                  <Typography variant={'h5'}>
                    Медианный пробег:&nbsp;
                    {new Intl.NumberFormat(locale, {
                      maximumFractionDigits: 0
                    }).format(medianMileage())} км
                  </Typography>
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper}>
                  {
                    !!similarCars.links &&
                    <SimilarLinksPlot data={similarCars.links} referencePrice={similarCars.avgPrice}
                                      basePath={'/crwl/links'}/>
                  }
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper}>
                  {
                    (!!nextYearSimilarCars.links && !!similarCars.links) &&
                    <TitsPlot data={{
                      year: {
                        year: filterValues.year,
                        data: similarCars.links
                      },
                      nextYear: {
                        year: parseInt(filterValues.year) + 1,
                        data: nextYearSimilarCars.links
                      }
                    }} referencePrice={similarCars.avgPrice} basePath={'/crwl/links'}/>
                  }
                </Paper>
              </Grid>
              {
                versionsLoading ?
                  <LoadingIndicator/> :
                  <Grid item xs={12}>
                    {
                      !beeCarsVersionId ?
                        <StrictResolverContainer
                          versions={versions}
                          onResolved={(id) => setBeeCarsVersionsId(id)}
                        /> :
                        <FoundVersion
                          versionName={versions.filter(v => v.id === beeCarsVersionId)[0]?.name}
                          onUnlink={() => setBeeCarsVersionsId(null)}
                        />
                    }
                  </Grid>
              }

              {
                beeCarsVersionId &&
                <Grid item xs={12}>
                  <CompareTable versions={versions} selectedVersion={beeCarsVersionId} year={filterValues.year} />
                </Grid>
              }
            </Grid>

        }


      </Box>

    </React.Fragment>
  )
}

export default RequestAppraisalCreate