import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import set from '@babel/runtime/helpers/esm/set'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

const VINEditor = ({ initialVIN, initialRegNumber = '', onSave }) => {
  const [open, setOpen] = useState(false)
  const [vin, setVIN] = useState(initialVIN)
  const [regNumber, setRegNumber] = useState(initialRegNumber)
  const [error, setError] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    setVIN(initialVIN)
  }, [initialVIN])

  useEffect(() => {
    setRegNumber(initialRegNumber)
  }, [initialRegNumber])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setVIN(initialVIN)
    setOpen(false)
    setError(null)
  }

  const save = () => {
    if (vin && -1 === vin.indexOf('*') && null === vin.match(/[A-HJ-NPR-Z0-9]{17}/)) {
      setError('Некорректный VIN')
    } else {
      onSave({ vin, regNumber })
      handleClose()
    }
  }

  const onChangeVin = (vin) => {
    setError(null)
    setVIN(vin)
  }

  const onChangeRegNumber = (regNumber) => {
    setRegNumber(regNumber)
  }

  return (
    <React.Fragment>
      <IconButton color="primary" onClick={handleClickOpen} size={'small'} className={classes.margin}>
        <EditIcon fontSize="small"/>
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Укажите VIN автомобиля</DialogTitle>
        <DialogContent>
          <TextField
            error={null !== error}
            helperText={error}
            margin="dense"
            id="name"
            label="VIN"
            type="text"
            value={vin}
            fullWidth
            onChange={(event) => onChangeVin(event.target.value.toUpperCase())}
            inputProps={{ maxLength: 17 }}
          />
          <TextField
            error={null !== error}
            helperText={error}
            margin="dense"
            id="name"
            label="Гос. номер"
            type="text"
            value={regNumber}
            fullWidth
            onChange={(event) => onChangeRegNumber(event.target.value.toUpperCase())}
            inputProps={{ maxLength: 9 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={save} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default VINEditor