export default {
  employees: {
    add: 'Добавить сотрудника',
    name: 'Сотрудник |||| Сотрудники',
    fields: {
      company: 'Дилер',
      companyBranches: 'Автосалоны',
      department: 'Отдел',
      jobPosition: 'Должность',
      person: 'Имя',
      'person.firstName': 'Имя',
      'person__firstName': 'Имя',
      'person.lastName': 'Фамилия',
      'person__lastName': 'Фамилия',
      personal_code: 'Табельный номер',
      cost_center: 'Центр затрат',
      account: 'Аккаунт',
      'account.email': 'Эл. почта',
      enabled: 'Активный',
      phone: 'Номер телефона',
      'person.gender': 'Пол',
      'person.dob': 'Дата рождения'
    },
    tabs: {
      summary: 'Данные сотрудника',
      stats: 'Статистика',
      performance: 'Эффективность',
      communication: 'Общение'
    },
    actions: {
      requestNewPassword: {
        button: 'Новый пароль',
        message: 'Новый пароль отправлен сотруднику на почту'
      }
    }
  },
}