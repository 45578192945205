import React, { useEffect, useState } from 'react'
import { LoadingIndicator, useDataProvider } from 'react-admin'
import Question from './Question'
import AnsweredQuestionsList from './AnsweredQuestionsList'

const QuestionsContainer = ({ onNullQuestion, versionsIds, onAnswer = () => {} }) => {

  const dataProvider = useDataProvider()
  const [questionLoading, setQuestionsLoading] = useState(true)
  const [questions, setQuestions] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(null)

  useEffect(() => {
    if (versionsIds.length) {
      requestNextQuestion()
    }
  }, [versionsIds])

  const requestNextQuestion = () => {
    dataProvider.create('b-c-request-questions', {
      data: { inVersions: versionsIds, prevQuestions: questions }
    }).then(({ data: { nextQuestion } }) => {
      //console.log("data", data);
      if (null === nextQuestion) {
        onNullQuestion()
      }
      setCurrentQuestion(nextQuestion)
      setQuestionsLoading(false)
    })
  }

  const answerQuestion = (answer) => {
    if (questions.find(q => q.id === currentQuestion.id)) {
      return
    }

    const newQuestions = [...questions]
    newQuestions.push({
      ...currentQuestion, answer: answer
    })

    setQuestions(newQuestions)
    onAnswer(newQuestions)
    setQuestionsLoading(true)
  }

  const removeQuestion = (questionId) => {
    const newQuestions = questions.filter(q => q.id !== questionId)
    setQuestions(newQuestions)
    onAnswer(newQuestions)
    setQuestionsLoading(true)
  }

  return (
    <div style={{ width: '100%' }}>
      {
        questionLoading ?
          <LoadingIndicator/> :
          <Question
            question={currentQuestion}
            onYes={() => answerQuestion(1)}
            onNo={() => answerQuestion(-1)}
            onDontKnow={() => answerQuestion(0)}
          />
      }
      <AnsweredQuestionsList questions={questions} onRemove={removeQuestion}/>
    </div>
  )

}

export default QuestionsContainer