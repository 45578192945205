import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Typography from '@material-ui/core/es/Typography'
import { Error, LoadingIndicator, useQuery, useTranslate, useNotify, useDataProvider } from 'react-admin'
import AssigneeDialog from './AssigneeDialog'
import MenuItem from '@material-ui/core/MenuItem'
import ConfirmDialog from '../ConfirmDialog'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const WorkflowActionsButtons = (props) => {

  const { resourceId, resourceName, onTransitionComplete } = props
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedTransitionId, setSelectedTransitionId] = useState(null)
  const [doingTransition, setDoingTransition] = useState(false)

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    dataProvider.create('workflow/request-allowed-transitions', { data: { resourceName, resourceId } })
      .then(({ data }) => {
        setData(data)
        setLoading(false)
      })
      .catch(error => {
        setError(error)
        setLoading(false)
      })
  }, [])

  if (loading) return <LoadingIndicator/>
  if (error) return <Error/>
  if (!data) return null

  const { transitions } = data

  const handleClick = event => {
    setMenuOpen(prev => !prev)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleClickAway = () => {
    setMenuOpen(false)
  }

  const handleClose = () => {
    setMenuOpen(false)
  }

  const onMenuItemClick = (transitionId) => () => {
    const transition = data.transitions.find(t => t.id === transitionId)
    setSelectedTransitionId(transitionId)

    if (transition.isFinal) {
      setConfirmDialogOpen(true)
    } else {
      setDialogOpen(true)
    }
  }

  const doTransition = (assignees) => {
    setDoingTransition(true)
    dataProvider.create('workflow/request-do-transitions', {
      data: {
        resourceName: resourceName,
        resourceId: resourceId,
        transitionId: selectedTransitionId,
        assigneeId: assignees.length > 0 ? assignees[0] : null
      }
    })
      .then(({ data }) => {
        //console.log("data", data);
        if (onTransitionComplete) {
          onTransitionComplete(data)
        }
      })
      .catch(e => {
        //console.log("e", e);
        notify(e.message, 'warning')
      })
      .finally(() => {
        setMenuOpen(false)
        setDialogOpen(false)
        setDoingTransition(false)
      })
  }

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        {
          transitions.length > 0 ?
            <div className={classes.root}>
              <Button
                onClick={handleClick}
                color={'primary'}
                className={classes.button}
                size={'small'}
              >
                {translate('resources.workflow/transitions.buttons.actionsWithObject')}
              </Button>

              <Menu
                id="simple-menu"
                keepMounted
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={handleClose}
              >
                {
                  transitions.map((t) =>
                    <MenuItem
                      key={t.id}
                      color="primary"
                      onClick={onMenuItemClick(t.id)}
                    >
                      {t.name}
                    </MenuItem>
                  )
                }
              </Menu>
            </div>
            :
            <Typography
              variant="caption">{translate('resources.workflow/transitions.buttons.noActionsAvailable')}</Typography>

        }
      </ClickAwayListener>

      {
        <AssigneeDialog
          open={dialogOpen}
          transitionId={selectedTransitionId}
          resourceId={resourceId}
          onClose={() => {setDialogOpen(false)}}
          onSubmit={doTransition}
          resourceName={resourceName}
          disabled={doingTransition}
        />
      }

      <ConfirmDialog
        open={confirmDialogOpen}
        title={translate('resources.workflow/transitions.confirmDialog.title')}
        body={translate('resources.workflow/transitions.confirmDialog.body')}
        onAgree={() => {
          setConfirmDialogOpen(false)
          setDialogOpen(true)
        }}
        onDisagree={() => setConfirmDialogOpen(false)}
      />

    </React.Fragment>
  )

}

WorkflowActionsButtons.propTypes = {
  resourceName: PropTypes.string,
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //id субъекта процесса
  onTransitionComplete: PropTypes.func,
}

export default WorkflowActionsButtons