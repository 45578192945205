import React, { useEffect, useState } from 'react'
import { useDataProvider, LoadingIndicator } from 'react-admin'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { grey } from '@material-ui/core/colors'
import PhotoThumbnail from './PhotoThumbnail'
import FullScreenPhoto from '../Photo/FullScreenPhoto'

import r1 from '../../assets/rakurs/1.png'
import r2 from '../../assets/rakurs/2.png'
import r3 from '../../assets/rakurs/3.png'
import r4 from '../../assets/rakurs/4.png'
import r5 from '../../assets/rakurs/5.png'
import r6 from '../../assets/rakurs/6.png'
import r7 from '../../assets/rakurs/7.png'
import r8 from '../../assets/rakurs/8.png'
import r9 from '../../assets/rakurs/9.png'
import r10 from '../../assets/rakurs/10.png'
import r11 from '../../assets/rakurs/11.png'
import r12 from '../../assets/rakurs/12.png'
import r13 from '../../assets/rakurs/13.png'
import r14 from '../../assets/rakurs/14.png'
import r15 from '../../assets/rakurs/15.png'
import r16 from '../../assets/rakurs/16.png'
import r17 from '../../assets/rakurs/17.png'
import r18 from '../../assets/rakurs/18.png'
import r19 from '../../assets/rakurs/19.png'
import r20 from '../../assets/rakurs/20.png'
import r21 from '../../assets/rakurs/21.png'
import r22 from '../../assets/rakurs/22.png'
import r23 from '../../assets/rakurs/23.png'
import r24 from '../../assets/rakurs/24.png'
import r25 from '../../assets/rakurs/25.png'
import r26 from '../../assets/rakurs/26.png'
import r27 from '../../assets/rakurs/27.png'
import r28 from '../../assets/rakurs/28.png'
import r29 from '../../assets/rakurs/29.png'
import r30 from '../../assets/rakurs/30.png'
import r31 from '../../assets/rakurs/31.png'
import r32 from '../../assets/rakurs/32.png'
import r33 from '../../assets/rakurs/33.png'
import r34 from '../../assets/rakurs/34.png'
import r35 from '../../assets/rakurs/35.png'
import r36 from '../../assets/rakurs/36.png'
import r37 from '../../assets/rakurs/37.png'
import r38 from '../../assets/rakurs/38.png'
import r39 from '../../assets/rakurs/39.png'
import r40 from '../../assets/rakurs/40.png'
import r41 from '../../assets/rakurs/41.png'
import r42 from '../../assets/rakurs/42.png'
import EmptyPhoto from '../../assets/no-photo.jpg'
import { getPhotoUri } from '../../utils/PhotoUtils'
import Thumbnail from '../Photo/Thumbnail'
import config from '../../config/config'
import { getAccessToken } from '../../utils/AuthUtils'

const photoTypes = {
  //	Ракурсы фоток
  1: '3/4 спереди - левым боком',
  2: 'вид спереди',
  3: '3/4 спереди - правым боком',
  4: 'правый бок',
  5: '3/4 сзади - правым боком',
  6: 'вид сзади',
  7: '3/4 сзади - левым боком',
  8: 'левый бок',
  9: 'интерьер - открытая водительская дверь',
  10: 'вся приборная панель - вид с заднего дивана',
  11: 'интерьер - открытая пассажирская дверь',
  12: 'интерьер открытая задняя дверь - вид на весь задний диван справа',
  13: 'интерьер открытая задняя дверь - вид на весь задний диван слева',
  14: 'вид с водительского места',
  15: 'панель приборов - вид с водительского места',
  16: 'левый передний блок фар',
  17: 'правый передний блок фар',
  18: 'левый задний блок фар',
  19: 'правый задний блок фар',
  20: 'парктроники/камера заднего вида',
  21: 'переднее правой колесо',
  22: 'переднее левое колесо',
  23: 'заднее левое колесо',
  24: 'заднее правое колесо',
  25: 'эмблема на решетке радиотора крупно',
  26: 'дверная карта водительской двери',
  27: 'дверная карта пассажирской двери',
  28: 'дверная карта задней левой двери',
  29: 'дверная карта задней правой двери',
  30: 'селектор КПП и блок между передними сиденьями',
  31: 'вид на водительское место со стороны переднего пассажира',
  32: 'багажник',
  33: 'дверная карта задней двери',
  34: 'третий ряд сидений',
  35: 'мультимедиа-система',
  36: 'мультимедиа система для задних пассажиров',
  37: 'кондиционер задних пассажиров',
  38: 'потолок / люк',
  39: 'подкапотное пространство',
  40: 'педальный узел',
  41: 'днище 1',
  42: 'днище 2',

  100: 'VIN 1',
  111: 'VIN 2',
  112: 'VIN 3',
  113: 'VIN 4',
  //
  101: 'ПТС 1',
  102: 'ПТС 2',
  103: 'ПТС 3',
  104: 'ПТС 4',
  //
  105: 'СТС 1',
  106: 'СТС 2',
  //
  107: 'ТО-1',
  117: 'ТО-2',
  118: 'ТО-3',
  119: 'ТО-4',
  //
  108: 'паспорт владельца 1',
  109: 'паспорт владельца 2',
  //
  110: 'ВУ владельца',

  200: 'Повреждение'
}

export const rakursList = [
  { type: 1, photo: r1, title: photoTypes[1] },
  { type: 2, photo: r2, title: photoTypes[2] },
  { type: 3, photo: r3, title: photoTypes[3] },
  { type: 4, photo: r4, title: photoTypes[4] },
  { type: 5, photo: r5, title: photoTypes[5] },
  { type: 6, photo: r6, title: photoTypes[6] },
  { type: 7, photo: r7, title: photoTypes[7] },
  { type: 8, photo: r8, title: photoTypes[8] },
  { type: 9, photo: r9, title: photoTypes[9] },
  { type: 10, photo: r10, title: photoTypes[10] },
  { type: 11, photo: r11, title: photoTypes[11] },
  { type: 12, photo: r12, title: photoTypes[12] },
  { type: 13, photo: r13, title: photoTypes[13] },
  { type: 14, photo: r14, title: photoTypes[14] },
  { type: 15, photo: r15, title: photoTypes[15] },
  { type: 16, photo: r16, title: photoTypes[16] },
  { type: 17, photo: r17, title: photoTypes[17] },
  { type: 18, photo: r18, title: photoTypes[18] },
  { type: 19, photo: r19, title: photoTypes[19] },
  { type: 20, photo: r20, title: photoTypes[20] },
  { type: 21, photo: r21, title: photoTypes[21] },
  { type: 22, photo: r22, title: photoTypes[22] },
  { type: 23, photo: r23, title: photoTypes[23] },
  { type: 24, photo: r24, title: photoTypes[24] },
  { type: 25, photo: r25, title: photoTypes[25] },
  { type: 26, photo: r26, title: photoTypes[26] },
  { type: 27, photo: r27, title: photoTypes[27] },
  { type: 28, photo: r28, title: photoTypes[28] },
  { type: 29, photo: r29, title: photoTypes[29] },
  { type: 30, photo: r30, title: photoTypes[30] },
  { type: 31, photo: r31, title: photoTypes[31] },
  { type: 32, photo: r32, title: photoTypes[32] },
  { type: 33, photo: r33, title: photoTypes[33] },
  { type: 34, photo: r34, title: photoTypes[34] },
  { type: 35, photo: r35, title: photoTypes[35] },
  { type: 36, photo: r36, title: photoTypes[36] },
  { type: 37, photo: r37, title: photoTypes[37] },
  { type: 38, photo: r38, title: photoTypes[38] },
  { type: 39, photo: r39, title: photoTypes[39] },
  { type: 40, photo: r40, title: photoTypes[40] },
  { type: 41, photo: r41, title: photoTypes[41] },
  { type: 42, photo: r42, title: photoTypes[42] },

  { type: 100, photo: EmptyPhoto, title: photoTypes[100] },

  { type: 200, photo: EmptyPhoto, title: photoTypes[200] },
]

const useStyles = makeStyles(theme => createStyles({
  root: {
    padding: theme.spacing(2)
  },
  bigAvatar: {
    border: `1px solid ${grey[100]}`
  },
}))

const PhotosContainer = (
  {
    carInstance,
    filter = (photos) => photos,
    types,
    extraAddType = null,
    photoUploadedCallback = (photo) => {},
    photoDeletedCallback = (photo) => {},
    showTitle=false
  }) => {

  const classes = useStyles()
  const dataProvider = useDataProvider()

  const [fullScreenOpen, setFullScreenOpen] = useState(false)
  const [fullScreenUrl, setFullScreenUrl] = useState(null)
  const [carPhotos, setCarPhotos] = useState([])
  const [uploadProgress, setUploadProgress] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (carInstance.hasOwnProperty('carPhotos')) {
      setIsLoading(true)
      fetchCarPhotos()
    }

  }, [carInstance])

  const fetchCarPhotos = () => {
    dataProvider.getList('car-photos', {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'type', order: 'ASC' },
      filter: { carInstance: carInstance.id }
    })
      .then(({ data }) => {
        setCarPhotos(filter(data))
      })
      .finally(() => {setIsLoading(false)})
  }

  const onPhotoDeleted = (photo) => {

    setCarPhotos(carPhotos.filter(p => p.id !== photo.id))
    dataProvider.delete(
      'car-photos',
      //если фотку загрузили и сразу удаляем, то в id содержится int
      { id: photo['@id'], previousData: photo },
      {
        onError: {
          notification: { body: 'Ошибка удаления', level: 'warning' }
        }
      })
      .then(data => photoDeletedCallback(photo))
  }

  const onPhotoUpload = (type) => {
    return (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', type)
        formData.append('car_instance_id', carInstance.originId)

        const request = new XMLHttpRequest()

        request.upload.onprogress = function (event) {
          setUploadProgress({ ...uploadProgress, [type]: 95 * (event.loaded / event.total) })
        }

        request.onload = function (ev) {
          if (request.status < 200 || request.status > 300) {
            setUploadProgress({ ...uploadProgress, [type]: null })
            reject(request.status)
          } else {
            const response = JSON.parse(request.response)
            setUploadProgress({ ...uploadProgress, [type]: null })
            resolve(response)
          }
        }
        request.open('POST', new URL('/car-photos', config.api))
        request.setRequestHeader('Authorization', 'Bearer ' + getAccessToken())
        request.setRequestHeader('Accept', 'application/ld+json')
        request.send(formData)
      })
        .then(photo => {
          //console.log("photo", transformJsonLdDocumentToReactAdminDocument);

          photoUploadedCallback(photo)
          setCarPhotos(carPhotos.concat(photo))
          //fetchCarPhotos();
        })
    }
  }

  if (isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <>
      <Grid container spacing={2} className={classes.root} alignItems="center">

        <Grid item xs={12}>

          <Grid container spacing={8}>
            {
              types.map(r => {
                const photos = carPhotos.filter(p => parseInt(p.type) === r.type && !p.deleted)

                if (photos.length > 0) {
                  return photos.map(p => {
                    return (
                      <Grid item xs={3} key={p.filename}>
                        <PhotoThumbnail
                          photo={p}
                          onClick={() => {
                            setFullScreenOpen(true)
                            setFullScreenUrl(getPhotoUri(p, 'big')())
                          }}
                          onDeleted={() => {
                            onPhotoDeleted(p)
                          }}
                          showTitle={showTitle}
                        />
                      </Grid>)
                  })
                } else {
                  return (
                    <Grid item xs={3} key={r.type}>
                      <Thumbnail
                        url={r.photo}
                        onClick={() => {
                          console.log(r.type)
                        }}
                        onDeleted={null}
                        uploadable={true}
                        uploadProgress={uploadProgress[r.type]}
                        onUpload={onPhotoUpload(r.type)}
                        showTitle={showTitle}
                        title={r.title}
                      />
                    </Grid>)
                }
              })
            }

            {
              (extraAddType && carPhotos.filter(p => parseInt(p.type) === extraAddType && !p.deleted).length > 0) &&
              <Grid item xs={3} key={`extra_btn_${carPhotos.length}`}>
                <Thumbnail
                  url={rakursList.find(r => r.type === extraAddType).photo}
                  onDeleted={null}
                  uploadable={true}
                  uploadProgress={uploadProgress[extraAddType]}
                  onUpload={onPhotoUpload(extraAddType)}
                  showTitle={showTitle}
                  title={rakursList.find(r => r.type === extraAddType).title}
                />
              </Grid>
            }
          </Grid>

        </Grid>
      </Grid>

      <FullScreenPhoto open={fullScreenOpen} url={fullScreenUrl} onClose={() => setFullScreenOpen(false)}/>
    </>

  )
}

export default PhotosContainer