import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SingleSelectField from './SingleSelectField'
import { useTranslate } from 'react-admin'
import { StorageFactory } from '../../../../utils/StorageFactory'

const DistanceField = ({ type, ...props }) => {
  const translate = useTranslate()

  const [radius, setRadius] = useState(StorageFactory().getItem('geo_radius'))

  const onChange = (value) => {
    setRadius(value)
    StorageFactory().setItem('geo_radius', value)
  }

  return (
    <SingleSelectField
      options={options.reduce((obj, item) => ({
        ...obj,
        [item]: `+${item} ${translate('resources.crwl/links.measure.mileage')}`
      }), {})}
      label={translate('resources.crwl/links.fields.radius')}
      {...props}
      initialValue={radius}
      onChange={onChange}
    />
  )
}

const options = [100, 200, 300, 400, 500, 1000]

DistanceField.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
}

export default DistanceField