import React from 'react'
import { BooleanInput, Edit, SimpleForm, TextInput, } from 'react-admin'

const DepartmentTitle = ({ record }) => {
  return <span>{record ? record.title : ''}</span>
}

const DepartmentEdit = props => {

  const redirect = (basePath, id, data) => `/companies/${encodeURIComponent(data.company)}/departments`

  return (
    <Edit {...props} title={<DepartmentTitle/>}>
      <SimpleForm
        redirect={redirect}
      >
        <TextInput source="name"/>
        <BooleanInput source="enabled"/>
      </SimpleForm>
    </Edit>
  )
}

export default DepartmentEdit