import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDataProvider, useTranslate, useNotify, useRefresh } from 'react-admin'
import AssigneeDialog from './AssigneeDialog'
import ConfirmDialog from '../ConfirmDialog'
import Button from '@material-ui/core/Button'

const WorkflowActionButton = (props) => {

  const { transition : {id, isFinal, name}, resourceName, resourceId } = props

  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const [doingTransition, setDoingTransition] = useState(false)
  const [assigneeDialogOpen, setAssigneeDialogOpen] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const onTransitionButtonClick = () => {
    if (isFinal) {
      setConfirmDialogOpen(true)
    } else {
      setAssigneeDialogOpen(true)
    }
  }

  const doTransition = (assignees) => {
    setDoingTransition(true)
    dataProvider.create('workflow/request-do-transitions', {
      data: {
        resourceName: resourceName,
        resourceId: resourceId,
        transitionId: id,
        assigneeId: assignees.length > 0 ? assignees[0] : null
      }
    })
      .then(({ data }) => {
        refresh()
      })
      .catch(e => {
        notify(e.message, 'warning')
      })
      .finally(() => {
        setAssigneeDialogOpen(false)
        setDoingTransition(false)
      })
  }

  return (
    <React.Fragment>
      <Button color="primary" variant="outlined" onClick={onTransitionButtonClick}>{name}</Button>

      {
        <AssigneeDialog
          open={assigneeDialogOpen}
          transitionId={id}
          resourceId={resourceId}
          onClose={() => {setAssigneeDialogOpen(false)}}
          onSubmit={doTransition}
          resourceName={resourceName}
          disabled={doingTransition}
        />
      }

      <ConfirmDialog
        open={confirmDialogOpen}
        title={translate('resources.workflow/transitions.confirmDialog.title')}
        body={translate('resources.workflow/transitions.confirmDialog.body')}
        onAgree={() => {
          setConfirmDialogOpen(false)
          setAssigneeDialogOpen(true)
        }}
        onDisagree={() => setConfirmDialogOpen(false)}
      />

    </React.Fragment>

  )

}

WorkflowActionButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  subject: PropTypes.string, //id субъекта процесса
  resource: PropTypes.string,
  is_final: PropTypes.bool,
  dataProvider: PropTypes.func,
  dispatch: PropTypes.func,
  onTransition: PropTypes.func
}

export default WorkflowActionButton