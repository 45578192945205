export default {
  menu: {
    dashboard: {
      name: 'Home'
    },
    settings: {
      name: 'Settings',
      menu: {
        countries: {
          name: 'Countries'
        },
        companies: {
          name: 'Companies'
        },
        employees: {
          name: 'Employees'
        },
        workflow_subject_types: {
          name: 'Workflow subjects'
        },
        workflow_types: {
          name: 'Types of processes'
        },
        workflows: {
          name: 'Processes'
        }
      }
    },
    tasks: {
      name: 'Tasks',
    },
    calendar: {
      name: 'Event calendar',
    },
    worksheets: {
      name: 'Worksheets'
    },
    questionnaires: {
      name: 'Questionnaires',
      menu: {
        settings: {
          name: 'Settings'
        },
        processing: {
          name: 'Questionnaire'
        },
        reports: {
          name: 'Results'
        },
      }
    },
    market: {
      name: 'Market',
      menu: {
        offers: {
          name: 'Offers'
        },
        favorites: {
          name: 'Favorites'
        },
        estimation: {
          name: 'Estimation'
        },
      }
    }
  }
}