export default {
  company: 'digidu',
  defaultTitle: 'digidu Dealer Platform',
  primary: '#1B3F8E',
  secondary: '#474b55',
  favicon: require('./favicon.ico'),
  manifest: '/theme/dgd/manifest.json',
  logo: '/theme/dgd/logo256.png',

  APP_DEFAULT_LOCALE: 'ru',

}