import React, { useState } from 'react'
import { List, Responsive, useTranslate, Pagination } from 'react-admin'

import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { StorageFactory } from '../../utils/StorageFactory'
import Button from '@material-ui/core/Button'
import ChevronFirst from '@material-ui/icons/FirstPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Toolbar from '@material-ui/core/Toolbar'
import Filter from './LinksListFilter/Filter'
import MobileGrid from './MobileGrid'
import LinkExpander from './LinkExpander'
import LinksDataGrid from './LinksDataGrid'

const styles = theme => ({
  listItemRoot: {
    paddingLeft: 0,
  },
  inline: {
    display: 'inline',
  },
  rightAligned: {
    textAlign: 'right'
  },
  avatar: {
    border: `1px solid ${theme.palette.grey['200']}`,
    width: '24px',
    height: '24px'
  },
  imgContainer: {
    width: 120,
    height: 90,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
})

export const color = (name) => {
  switch (name) {
    case 'Пурпурный' :
      return '#660099'
    case 'Розовый':
      return '#FFC0CB'
    case 'Серебряный':
      return '#CACECB'
    case 'Синий':
      return '#0000CC'
    case 'Бежевый':
      return '#C49648'
    case 'Золотой':
      return '#DEA522'
    case 'Голубой':
      return '#22A0F8'
    case 'Белый':
      return '#FAFBFB'
    case 'Красный':
      return '#EE1D19'
    case 'Фиолетовый':
      return '#4A2197'
    case 'Серый':
      return '#97948F'
    case 'Коричневый':
      return '#200204'
    case 'Оранжевый':
      return '#FF8649'
    case 'Черный':
      return '#040001'
    case 'Зеленый':
      return '#007F00'
    case 'Желтый':
      return '#FFD600'
  }
  return '#fff'
}

export const formatDate = (date, locale, translate) => {
  const d = new Date(date)
  if (d.toDateString() === new Date().toDateString()) {
    return `${translate('resources.crwl/links.measure.today')}, ${d.toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit'
    })}`
  } else {
    if (d.getFullYear() === (new Date()).getFullYear()) {
      return d.toLocaleDateString(locale, { day: '2-digit', month: 'long', hour: '2-digit', minute: '2-digit' })
    } else {
      return d.toLocaleDateString(locale, {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    }

  }
}

export const LinksPagination = props => {

  const { page, setPage } = props
  const translate = useTranslate()

  return (
    <Toolbar>
      {page > 1 &&
      <Button color="primary" key="first" startIcon={<ChevronFirst/>} onClick={() => setPage(1)}>
        {translate('navigation.first')}
      </Button>
      }
      {page > 1 &&
      <Button color="primary" key="prev" startIcon={<ChevronLeft/>} onClick={() => setPage(page - 1)}>
        {translate('ra.navigation.prev')}
      </Button>
      }
      <Button color="primary" key="next" endIcon={<ChevronRight/>} onClick={() => setPage(page + 1)}
              labelPosition="before">
        {translate('ra.navigation.next')}
      </Button>
    </Toolbar>
  )
}

const LinksList = (props) => {

  const [radius] = useState(StorageFactory().getItem('geo_radius'))
  const [lat] = useState(StorageFactory().getItem('geo_lat'))
  const [lon] = useState(StorageFactory().getItem('geo_lon'))

  const spatialFilter = 'null' !== lat ? { sellerPoint: JSON.stringify({ lat, lon, radius }) } : {}

  const { history } = props;
  
  return (
    <List
      bulkActionButtons={false}
      exporter={false}
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      filterDefaultValues={spatialFilter}
      filters={<Filter/>}
      pagination={<Pagination/>}
    >
      <Responsive

        small={
          /*<MobileGrid expand={<LinkExpander />} rowClick={"expand"} />*/
          <LinksDataGrid history={history}/>
        }

        medium={
          <LinksDataGrid history={history}/>
        }
      />
    </List>
  )
}

export default LinksList