import Group from './Group/ru'
import Options from './Options/ru'
import Question from './Question/ru'
import Questionnaire from './Questionnaire/ru'
import Survey from './Survey/ru'

export default {
  ...Group,
  ...Options,
  ...Question,
  ...Questionnaire,
  ...Survey,
}