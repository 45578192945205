import React from 'react'
import { translate } from 'react-admin'
import MuiPhoneInput from 'material-ui-phone-number'
import { withStyles } from '@material-ui/core/styles'
import { addField } from 'ra-core'

const styles = theme => ({
  inp: {
    minWidth: 256,
    marginTop: 8
  }
})

const PhoneInput = (props) => {

  const { classes, label, translate, resource, id, touched, value, error } = props

  const handleBlur = eventOrValue => {
    props.onBlur(eventOrValue)
    props.input.onBlur(eventOrValue)
  }

  const handleFocus = event => {
    props.onFocus(event)
    props.input.onFocus(event)
  }

  const handleChange = eventOrValue => {
    props.onChange(eventOrValue)
    props.input.onChange(eventOrValue)
  }

  return (

    <MuiPhoneInput
      label={label || translate(`resources.${resource}.fields.${id}`)}
      defaultCountry='ru'
      regions={'europe'}
      className={classes.inp}
      value={value}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      error={!!(touched && error)}
    />

  )

}

PhoneInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
}

export default addField(translate(withStyles(styles)(PhoneInput)))