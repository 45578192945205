import Actions from './Action/de'
import Participant from './Participant/de'
import Place from './Place/de'
import RequestToCopy from './RequestToCopy/de'
import SubjectType from './SubjectType/de'
import Transition from './Transition/de'
import Type from './Type/de'
import Workflow from './Workflow/de'

export default {
  ...Actions,
  ...Participant,
  ...Place,
  ...RequestToCopy,
  ...SubjectType,
  ...Transition,
  ...Type,
  ...Workflow,
}