import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import GeoSelectorContainer from './GeoSelectorContainer'
import { withStyles } from '@material-ui/core/styles'
import { StorageFactory } from '../../utils/StorageFactory'
import SimilarLinksContainer from './SimilarLinksContainer'
import TitsContainer from './TitsContainer'

const styles = theme => ({
  plot: {
    padding: theme.spacing(2)
  },
  suggest: {
    margin: theme.spacing(2)
  }
})

const MarketDataContainer = ({ record, classes }) => {

  const [geoParams, setGeoParams] = useState({
    lat: StorageFactory().getItem('geo_lat'),
    lon: StorageFactory().getItem('geo_lon'),
    radius: parseInt(StorageFactory().getItem('geo_radius'))
  })

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      style={{ width: '100%' }}
      spacing={2}
    >
      <Grid item xs={12}>
        { /*<GeoSelectorContainer handler={setGeoParams} />*/}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimilarLinksContainer {...record} {...geoParams} referencePrice={record.price}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TitsContainer {...record} {...geoParams} referencePrice={record.price}/>
      </Grid>
    </Grid>
  )

}

export default withStyles(styles)(MarketDataContainer)