export default {
  'crm/worksheets': {
    name: 'Arbeitsblatt |||| Arbeitsblätter',
    fields: {
      companyBranch: 'Unternehmenszweig',
      createdAt: 'Erstellt um',
      createdBy: 'Erstellt von',
      customerPhone: 'Telefon'
    },
    errors: {
      phoneOrEmailRequired: 'Telefon oder E-Mail erforderlich'
    },
    tabs: {
      summary: 'Eigenschaften',
      customer: 'Kunde',
    },
  },
}