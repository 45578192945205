import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

function TextMaskCustom (props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export default function PhoneInput ({ value = '', label = 'Номер телефона', onChange, error }) {

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="formatted-phone-mask-input" error={error}>{label}</InputLabel>
      <Input
        error={error}
        value={value}
        onChange={handleChange}
        id="formatted-phone-mask-input"
        inputComponent={TextMaskCustom}
      />
      {
        error && <FormHelperText error={error}>Некорретный номер телефона</FormHelperText>
      }
    </FormControl>
  )
}