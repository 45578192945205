import React, { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import TagList from '../Tags/TagList'

const LinksTags = (props) => {

  const { linkId } = props
  const dataProvider = useDataProvider()
  const [tags, setTags] = useState([])

  useEffect(() => {
    loadTagsList().then(tags => setTags(tags))
  }, [])

  const setTagSelected = (tagId, selected) => {
    setTags(tags.map(t => {
      if (tagId !== t.id) {
        return t
      } else {
        const newTag = t
        newTag.selected = selected
        return newTag
      }
    }))
  }

  const handleCreate = ({ title, color }) => {
    const promise = dataProvider.create('crwl/tags', { data: { title, color } })

    promise.then(({ data }) => {
      const newTags = tags
      newTags.push(data)
      setTags(newTags)
      handleLinkTag(data.id)
      return { ...data, selected: true }
    })

    return promise
  }

  const loadLinkedTags = () =>
    dataProvider.getList('crwl/tagged-ads', {
      pagination: { page: 1, perPage: 100 },
      sort: {
        field: 'tag.color', order: 'ASC'
      },
      filter: {
        adId: linkId
      }
    })
      .then(({ data }) => data)

  const loadTagsList = () => {
    const tags = dataProvider.getList('crwl/tags', {
      pagination: { page: 1, perPage: 100 },
      sort: {
        field: 'color', order: 'ASC'
      },
      filter: {}
    })
      .then(({ data }) => data)

    const linkedTags = loadLinkedTags()

    return Promise
      .all([tags, linkedTags])
      .then(data => {
        const tags = data[0]
        const links = data[1]

        return tags.map(tag => ({
          id: tag.id,
          title: tag.title,
          color: tag.color,
          selected: undefined !== links.find(l => l.tag === tag.id)
        }))
      })
  }

  const handleLinkTag = (tagId) => {
    dataProvider
      .create('crwl/tagged-ads', { data: { adId: linkId, tag: tagId } })
      .then(({ data }) => {
        setTagSelected(tagId, true)
      })

  }

  const handleUnlinkTag = (tagId) => {
    setTagSelected(tagId, false)
    dataProvider.getList('crwl/tagged-ads', {
      pagination: { page: 1, perPage: 10 }, sort: { field: 'id', order: 'ASC' }, filter: {
        adId: linkId,
        tag: tagId
      }
    })
      .then(({ data }) => {
        if (data.length) {
          dataProvider.delete('crwl/tagged-ads', { id: data[0].id, previousData: {} })
        }
      })
  }

  return (
    <TagList
      tags={tags}
      onCreate={handleCreate}
      onLinkTag={handleLinkTag}
      onUnlinkTag={handleUnlinkTag}
    />
  )
}

export default LinksTags