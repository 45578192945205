import authForm from './authForm/ru'
import menu from './menu/ru'
import resources from './resoures/ru'

export default {
  'app.loading': 'Загрузка',
  'app.welcome': 'Добро пожаловать',
  'app.form.required': 'Обязательно для заполнения',
  'app.form.submit': 'Отправить',
  'action.unselect': 'Снять отметку',
  'app.role.owner': 'Владелец процесса',
  show: 'Показать',
  hide: 'Скрыть',

  roles: {
    ROLE_ANONYMOUS: 'Анонимный пользователь',
    ROLE_ADMIN: 'Администратор',
    ROLE_EMPLOYEE: 'Рядовой сотрудник',
    ROLE_COMPANY_ADMIN: 'Администратор компании',
    ROLE_COMPANY_MANAGER: 'Менеджер компании',
    ROLE_BRANCH_MANAGER: 'Менеджер филиала',
    ROLE_DEALER: 'Дилер',
  },

  time: {
    days: '%{smart_count} День |||| %{smart_count} Дня |||| %{smart_count} Дней',
    hours: '%{smart_count} Час |||| %{smart_count} Часа |||| %{smart_count} Часов',
    minutes: '%{smart_count} Минута |||| %{smart_count} Минуты |||| %{smart_count} Минут',
    seconds: '%{smart_count} Секунда |||| %{smart_count} Секунды |||| %{smart_count} Секунд',
  },

  ...authForm,
  ...menu,
  ...resources

}