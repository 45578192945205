export default {
  'workflow/types': {
    name: 'Тип процесса |||| Типы процессов',
    fields: {
      name: 'Название',
      subjectType: 'Объект',
    },
    tabs: {
      summary: 'Свойства',
      actions: 'Действия'
    },
  },
}