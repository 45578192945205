import React from 'react'
import { Resource } from 'react-admin'

import WorksheetList from './WorksheetList'
import WorksheetEdit from './WorksheetEdit'
import WorksheetCreate from './WorksheetCreate'
import WorksheetShow from './WorksheetShow'
import WorksheetShowNew from './WorksheetShowNew'

export default permissions => [
  <Resource
    name="worksheet/worksheet-buys"
    list={WorksheetList}
    edit={WorksheetEdit}
    show={WorksheetShowNew}
    create={WorksheetCreate}
  />,

  <Resource
    name="worksheet/worksheet-buy-logs"
  />,

  <Resource
    name="worksheet/worksheet-buy-price-offers"
  />,

  <Resource
    name="worksheet/worksheet-buy-interfaces"
  />,
]