import Company from './Company/de'
import CompanyBranch from './CompanyBranch/de'
import CompanyFile from './CompanyFile/de'
import CompanyType from './CompanyType/de'
import Department from './Department/de'
import Employee from './Employee/de'
import JobGrade from './JobGrade/de'
import JobPosition from './JobPosition/de'

export default {
  ...Company,
  ...CompanyBranch,
  ...CompanyFile,
  ...CompanyType,
  ...Department,
  ...Employee,
  ...JobGrade,
  ...JobPosition
}