export default {
  departments: {
    add: 'Départements',
    name: 'Département |||| Département',
    fields: {
      name: 'Titre',
      company: 'Société',
      enabled: 'activé',
    }
  },
}