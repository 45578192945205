import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import PhoneInput from '../Inputs/PhoneInput'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

const ContactEditor = ({ initialPhone, initialName, onSave }) => {
  const [open, setOpen] = useState(false)
  const [phone, setPhone] = useState(initialPhone)
  const [name, setName] = useState(initialName)
  const [error, setError] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    setPhone(initialPhone)
  }, [initialPhone])

  useEffect(() => {
    setName(initialName)
  }, [initialName])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setPhone(initialPhone)
    setOpen(false)
    setError(null)
  }

  const save = () => {
    if (null === phone.match(/\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/)) {
      setError('Некорректный номер телефона')
    } else {
      onSave({ phone, name })
      handleClose()
    }
  }

  const onChangePhone = (phone) => {
    setError(null)
    setPhone(phone)
  }

  const onChangeName = (name) => {
    setName(name)
  }

  return (
    <React.Fragment>
      <IconButton color="primary" onClick={handleClickOpen} size={'small'} className={classes.margin}>
        <EditIcon fontSize="small"/>
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Укажите даные клиента</DialogTitle>
        <DialogContent>
          <TextField
            error={null !== error}
            helperText={error}
            margin="dense"
            id="name"
            label="Имя"
            type="text"
            value={name}
            fullWidth
            onChange={(event) => onChangeName(event.target.value)}
            inputProps={{ maxlength: 50 }}
          />
          <PhoneInput
            error={null !== error}
            helperText={error}
            margin="dense"
            id="name"
            label="Номер телефона"
            type="text"
            value={phone}
            fullWidth
            onChange={onChangePhone}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={save} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ContactEditor