export default {
  'company-files': {
    add: 'Dokumente hinzufügen',
    header: 'Dokumente',
    name: 'Dokument |||| Dokumente',
    fields: {
      createdAt: 'Hergestellt in',
      name: 'Titel',
      file: 'Datei',
      company: 'Firma'
    }
  },
}