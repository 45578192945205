export default {
  'questionnaire/groups': {
    add: 'Gruppe der Frage hinzufügen',
    name: 'Gruppe |||| Gruppen',
    fields: {
      name: 'Gruppentitel',
      questionnaire: 'Fragebogen',
      ord: 'Bestellung',
      enabled: 'Aktiviert',
      numberOfQuestions: 'Anzahl der Fragen',
    }
  }
}