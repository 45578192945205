export default {
  'workflow/transitions': {
    add: 'Ajouter transition',
    name: 'Transition |||| Transition',
    fields: {
      workflow: 'flux de travaux',
      code: 'Code',
      name: 'Titre',
      toPlace: 'à',
      jobPositions: 'Admis pour les postes',
      fromPlaces: 'de',
      participant: 'Contreparties',
      allowed_for_creator: 'autorisé pour le créateur',
      allowed_for_employee: 'permis pour employé en application',
      allowed_for_any_previous: 'admis pour tout destinataire précédent',
      actions: 'Actions',
      comment_required: 'Commentaire requis',
      document_required: 'Document requis'
    },
    alerts: {
      comment_required: 'Veuillez ajouter le commentaire requis à la demande avant de poursuivre.',
      document_required: 'Veuillez ajouter le document requis à la demande avant de poursuivre.',
    },
    buttons: {
      actionsWithObject: 'Actions',
      noActionsAvailable: 'Aucune action disponible'
    },
    confirmDialog: {
      title: 'Veuillez confirmer l\'action',
      body: 'Il s\'agit de la dernière étape du workflow. Veuillez confirmer votre action.'
    }
  },
}