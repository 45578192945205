import React, { useState } from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  email,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
  useLocale,
} from 'react-admin'

import { DateInput } from 'react-admin'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { getAccessToken, getTokenClaim, getTokenPayload } from '../../utils/AuthUtils'

export const styles = {
  department: { display: 'inline-block' },
  job_position: { display: 'inline-block', marginLeft: 32 },

  first_name: { display: 'inline-block' },
  last_name: { display: 'inline-block', marginLeft: 32 },

  email: { width: 544 },
}

const EmployeeCreate = props => {
  const { classes } = props

  const translate = useTranslate()
  const locale = useLocale()
  const employee = getTokenClaim(getAccessToken(), 'employee')

  const [company, setCompany] = useState(employee ? `/companies/${employee['company']}` : null)

  const defaultValue = {
    company: employee ? `/companies/${employee['company']}` : null,
    companyBranches: employee && employee['companyBranches'].length === 1 ? [`/company-branches/${employee['companyBranches'][0]}`] : null,
  }

  return (
    <Create {...props}>
      <SimpleForm defaultValue={defaultValue}>
        <ReferenceInput
          source="company"
          reference="companies"
          validate={required()}
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ name: searchText })}

        >
          <AutocompleteInput
            optionText="name"
            onChange={value => {
              console.log('value', value)
              setCompany(value)
            }}
          />
        </ReferenceInput>

        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              return (
                formData.company &&
                <ReferenceArrayInput
                  source="companyBranches"
                  reference="company-branches"
                  validate={required()}
                  filter={{ company: formData.company }}
                  perPage={500}
                  {...rest}
                >
                  <SelectArrayInput
                    optionText="name"
                  />
                </ReferenceArrayInput>
              )
            }
          }
        </FormDataConsumer>

        <FormDataConsumer
          formClassName={classes.department}
        >
          {
            ({ formData, ...rest }) => {
              return (
                formData.company &&
                <ReferenceInput
                  source="department"
                  reference="departments"
                  filter={{ company: formData.company }}
                  perPage={500}
                  {...rest}
                >
                  <SelectInput
                    optionText="name"
                  />
                </ReferenceInput>
              )
            }
          }
        </FormDataConsumer>

        <FormDataConsumer
          formClassName={classes.job_position}
        >
          {
            ({ formData, ...rest }) => {
              return (
                formData.department &&
                <ReferenceInput
                  source="jobPosition"
                  reference="job-positions"
                  validate={required()}
                  filter={{ department: formData.department }}
                  perPage={500}
                  {...rest}
                >
                  <SelectInput
                    optionText="name"
                  />
                </ReferenceInput>
              )
            }
          }
        </FormDataConsumer>

        <Typography/>

        <TextInput
          source="person.firstName"
          validate={required()}
          formClassName={classes.first_name}
        />
        <TextInput
          source="person.lastName"
          validate={required()}
          formClassName={classes.last_name}
        />
        <TextInput
          source="account.email"
          validate={[required(), email()]}
          type="email"
          fullWidth={true}
          formClassName={classes.email}
        />
        <TextInput
          source="phone"
          fullWidth={true}
          formClassName={classes.email}
        />

        <Typography/>

        <BooleanInput source="enabled"/>
      </SimpleForm>
    </Create>
  )
}

export default withStyles(styles)(EmployeeCreate)