import React from 'react'
import { translate, Edit, FormTab, ReferenceInput, required, SelectInput, TabbedForm, TextInput } from 'react-admin'
import { TransitionList } from './TransitionList'
import AddTransitionButton from './AddTransitionButton'
import AddActionButton from './AddActionButton'
import { ActionList } from './ActionList'

const Title = ({ record }) => {
  return <>{record ? record.name : ''}</>
}

const TypeEdit = props => (
  <Edit title={<Title/>} {...props}>
    <TabbedForm>
      <FormTab label={props.translate('resources.workflow/types.tabs.summary')}>
        <TextInput
          source="name"
          validate={required()}
        />
        <ReferenceInput source="subjectType" reference="workflow/subject-types">
          <SelectInput optionText="name"/>
        </ReferenceInput>
      </FormTab>
      <FormTab label={props.translate('resources.workflow/types.tabs.actions')} path="actions">
        <ActionList fullWidth/>
        <AddActionButton/>
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default translate(TypeEdit)