import React from 'react'
import {
  AutocompleteInput,
  Edit,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  ReferenceField,
  TextField
} from 'react-admin'

const WorksheetEdit = props => {
  
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput
          source="company"
          reference="companies"
          validate={required()}
          filter={{ isProvider: false }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText="name" allowEmpty={false}/>
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            <ReferenceInput
              source="companyBranch"
              reference="company-branches"
              validate={required()}
              filter={{ company: formData.company }}
              sort={{ field: 'name', order: 'ASC' }}
              perPage={500}
              {...rest}
            >
              <AutocompleteInput optionText="name" allowEmpty={false}/>
            </ReferenceInput>
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { workflow } = rest.record;

            return workflow ?
              <ReferenceField source="workflow" reference="workflow/workflows" link={false} {...rest}>
                <TextField source="name"/>
              </ReferenceField> :
              <ReferenceInput
                source="workflow"
                reference="workflow/workflows"
                validate={required()}
                filter={{
                  enabled: true,
                  company: formData.company,
                  'type.subjectType.resourceName': 'worksheet/worksheet-buys'
                }}
                sort={{ field: 'name', order: 'ASC' }}
                {...rest}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
          }}

        </FormDataConsumer>

      </SimpleForm>
    </Edit>
  )
}

export default WorksheetEdit