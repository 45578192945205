import React from 'react'
import PropTypes from 'prop-types'
import SingleSelectField from './SingleSelectField'

const startYear = new Date().getFullYear()
const options = new Array(20)
  .fill(1)
  .reduce((obj, item, index) => {
    obj[startYear - index] = startYear - index
    return obj
  }, {})

const YearField = ({ type, ...props }) =>
  <SingleSelectField
    options={props.years || options}
    label={'resources.crwl/links.fields.' + ('to' === type ? 'yearTo' : 'from' === type ? 'yearFrom' : 'year')}
    {...props}
  />

YearField.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  type: PropTypes.oneOf(['from', 'to']).isRequired
}

export default YearField