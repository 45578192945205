export default {
  'workflow/participants': {
    add: 'Add counterparty',
    name: 'Counterparty |||| Counterparties',
    fields: {
      workflow: 'Workflow',
      role: 'Role',
      company: 'Counterparty'
    }
  },
}