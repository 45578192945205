import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import FilledInput from '@material-ui/core/FilledInput'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'

const PriceInput = ({ onChange, initialValue, label, variant = 'input', ...rest }) => {

  const [value, setValue] = useState(initialValue)
  const [displayValue, setDisplayValue] = useState(0)

  const Component =
    'input' === variant ? Input :
      'filled' === variant ? FilledInput :
        'outlined' === variant ? OutlinedInput : <div/>

  const handleChange = () => event => {
    const val = event.target.value.replace(/[^\d]/g, '')
    const displayVal = new Intl.NumberFormat('ru-RU', {
      minimumFractionDigits: 0
    }).format(val)

    setValue(val)
    setDisplayValue(displayVal)
    onChange(val)
  }

  return (
    <FormControl {...rest} variant={variant}>
      <InputLabel htmlFor="adornment-amount">{label}</InputLabel>
      <Component
        id="adornment-amount"
        value={value > 0 ? displayValue : ''}
        onChange={handleChange()}
        endAdornment={<InputAdornment position="end">₽</InputAdornment>}
      />
    </FormControl>
  )

}

export default PriceInput