import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import Checkbox from '@material-ui/core/Checkbox'

import Avatar from '@material-ui/core/Avatar'

import grey from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'
import deepOrange from '@material-ui/core/colors/red'

const useStyles = makeStyles(theme => ({
  icon: {
    maxWidth: theme.spacing(3),
    height: 'auto'
  },

  greyAvatar: {
    backgroundColor: grey[500],
  },

  orangeAvatar: {
    backgroundColor: deepOrange[500],
  },

  greenAvatar: {
    backgroundColor: green[500],
  },
  root: {
    paddingLeft: 0
  },
  checkbox: {
    textAlign: 'center'
  }
}))

const CheckLamp = ({ icon, text, value, handler }) => {

  const classes = useStyles()
  const [className, setClassName] = useState(classes.greyAvatar)

  useEffect(() => {
    switch (value) {
      case 1:
      case 2:
        setClassName(classes.orangeAvatar)
        break
      case 3:
        setClassName(classes.greenAvatar)
        break

      default:
        setClassName(classes.greyAvatar)
    }
  }, [value])

  const handleValue = (value) => {
    return (event) => {
      const val = event.target.checked ? value : value - 1
      handler(val)
    }
  }

  return (
    <ListItem button>

      <Grid container spacing={0}>
        <Grid item xs={3}>
          <Avatar className={className}>
            <img src={icon} alt={text} className={classes.icon}/>
          </Avatar>
        </Grid>
        <Grid item xs={3} className={classes.checkbox}>
          <Checkbox
            onChange={handleValue(1)}
            checked={value > 0}

          />
        </Grid>
        <Grid item xs={3} className={classes.checkbox}>
          <Checkbox
            onChange={handleValue(2)}
            checked={value > 1}
          />
        </Grid>
        <Grid item xs={3} className={classes.checkbox}>
          <Checkbox
            onChange={handleValue(3)}
            checked={value === 3}
          />
        </Grid>
      </Grid>
    </ListItem>
  )
}

CheckLamp.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.number,
  handler: PropTypes.func
}

CheckLamp.defaultProps = {
  value: 0,
  handler: () => {}
}

export default CheckLamp