import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import grey from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'
import deepOrange from '@material-ui/core/colors/red'

const useStyles = makeStyles(theme => ({

  greyText: {
    color: grey[500],
  },

  orangeText: {
    color: deepOrange[500],
  },

  greenText: {
    color: green[500],
  },
  root: {
    paddingLeft: 0
  },
  checkbox: {
    textAlign: 'center'
  }
}))

const CheckPoint = ({ text, textNegative, value, handler }) => {

  const classes = useStyles()
  const [className, setClassName] = useState(classes.greyText)

  useEffect(() => {
    switch (value) {
      case 2:
        setClassName(classes.orangeText)
        break
      case 1:
        setClassName(classes.greenText)
        break
      default:
        setClassName(classes.greyText)
    }
  }, [value])

  const handleValue = (value) => {
    return (event) => {
      const val = event.target.checked ? value : value - 1
      handler(val)
    }
  }

  return (
    <ListItem button>

      <Grid container spacing={0}>
        <Grid item xs={3} md={5}>
          <Typography className={className}>
            {2 === value ?  textNegative : text}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.checkbox}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleValue(2)}
                checked={value > 0}
              />
            }
            label="Проверено"
          />
        </Grid>
        <Grid item xs={3} className={classes.checkbox}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleValue(1)}
                checked={value === 1}
              />
            }
            label="Исправно"
          />
        </Grid>
      </Grid>
    </ListItem>
  )
}

CheckPoint.propTypes = {
  text: PropTypes.string,
  textNegative: PropTypes.string,
  value: PropTypes.number,
  handler: PropTypes.func
}

CheckPoint.defaultProps = {
  value: 0,
  handler: () => {}
}

export default CheckPoint