import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useDataProvider } from 'react-admin'

import AddIcon from '@material-ui/icons/FavoriteBorder'
import DeleteIcon from '@material-ui/icons/Favorite'
import IconButton from '@material-ui/core/IconButton'
import { getCleanHydraId } from '../../utils/HydraUtils'

const styles = theme => ({
  container: {
    display: 'inline-block'
  },
  button: {
    marginRight: theme.spacing(),
  },
})

const FavoritesButton = (props) => {

  const { id, classes } = props

  const dataProvider = useDataProvider()

  const [favoritesId, setFavoritesId] = useState(null)

  useEffect(() => {
    dataProvider.getList('crwl/favorites', {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'DESC' },
      filter: { link: getCleanHydraId(id) }
    })
      .then(({ data, total }) => {
        if (total > 0) {
          setFavoritesId(data[0].id)
        }
      })
  }, [id])

  async function addToFavorites () {
    const fav = await dataProvider.create('crwl/favorites', { data: { link: parseInt(getCleanHydraId(id)) } })
    setFavoritesId(fav.data.id)
  }

  async function removeFromFavorites () {
    await dataProvider.delete('crwl/favorites', { id: favoritesId })
    setFavoritesId(null)
  }

  return (
    <div className={classes.container}>
      <IconButton
        className={classes.button}
        color={favoritesId ? 'primary' : 'default'}
        onClick={() => favoritesId ? removeFromFavorites() : addToFavorites()}
      >
        {
          favoritesId ? <DeleteIcon/> : <AddIcon/>
        }
      </IconButton>
    </div>
  )

}

FavoritesButton.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string,
  isSold: PropTypes.bool,
  soldAt: PropTypes.string
}

export default compose(
  withStyles(styles),
)(FavoritesButton)