import React from 'react'
import PropTypes from 'prop-types'
import MultipleSelectField from './MultipleSelectField'

const BodyTypeField = (props) =>
  <MultipleSelectField
    options={options.reduce((obj, item) => ({ ...obj, [item]: item }), {})}
    label={'resources.crwl/links.fields.bodyType'}
    {...props}
  />

const options = [
  'Седан',
  'Хетчбэк',
  'Внедорожник',
  'Универсал',
  'Кабриолет',
  'Компактвэн',
  'Купе',
  'Купе-хардтоп',
  'Лимузин',
  'Лифтбек',
  'Микроавтобус',
  'Микровэн',
  'Минивэн',
  'Пикап',
  'Родстер',
  'Тарга',
  'Фургон',
]

BodyTypeField.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
}

export default BodyTypeField