export default {
  departments: {
    add: 'Abteilung hinzufügen',
    name: 'Abteilung |||| Abteilungen',
    fields: {
      name: 'Titel',
      company: 'Firma',
      enabled: 'aktiviert',
    }
  },
}