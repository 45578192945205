import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useDataProvider, LoadingIndicator } from 'react-admin'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import QuestionsContainer from '../BeeCars/QuestionsContainer'

const VersionSelector = ({ onVersionFound, versions = [] }) => {

  const dataProvider = useDataProvider()

  const [reducedVersions, setReducedVersions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setReducedVersions(versions.map(v => v.id))
  }, []);

  const reduceVersions = (questions) => {
    dataProvider.create('b-c-request-versions', {
      data: { inVersions: versions.map(version => version.id), prevQuestions: questions }
    }).then(({ data: { outVersions = [] } }) => {
      if (outVersions.length === 1) {
        onVersionFound(outVersions[0])
      }
      setReducedVersions(outVersions)
    })
  }

  if (loading) {
    return <LoadingIndicator/>
  }

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12} spacing={3}>
        <List>
          <ListItem>
            <ListItemText primary={`Найдено ${reducedVersions.length} потенциальных комплектации`}/>
          </ListItem>
        </List>
      </Grid>
      {
        reducedVersions.length > 0 &&
        <Grid container item xs={12} md={4} spacing={3}>
          <List>
            {
              versions.filter(v => reducedVersions.includes(v.id)).map(version => {
                return (
                  <ListItem key={version.id}>
                    <ListItemText style={{cursor: 'pointer'}} secondary={version.name.replace(/\(\d{4}\)\s/, '')} onClick={() => onVersionFound(version.id)} />
                  </ListItem>
                )
              })
            }
          </List>
        </Grid>
      }
      {
        reducedVersions.length > 0 &&
        <Grid container item xs={12} md={8} spacing={3}>
          <QuestionsContainer
            onNullQuestion={() => onVersionFound(reducedVersions[0])}
            versionsIds={reducedVersions}
            onAnswer={reduceVersions}
          />
        </Grid>
      }

    </Grid>
  )
}

export default VersionSelector