export default {
  'questionnaire/questionnaires': {
    name: 'Fragebogen |||| Fragebögen',
    fields: {
      name: 'Titel',
      enabled: 'aktiviert',
      createdAt: 'Hergestellt in',
      numberOfQuestions: 'Anzahl der Fragen',
    },
    tabs: {
      summary: 'Allgemeine Daten',
      questions: 'Fragen',
    }
  },
}