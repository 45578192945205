export default {
  'workflow/workflows': {
    name: 'Workflow |||| Workflows',
    fields: {
      company: 'Company',
      title: 'Title',
      type: 'Type',
      initial_place: 'Initial state',
      enabled: 'Available'
    },
    tabs: {
      summary: 'Workflow data',
      participants: ' Counterparties',
      places: 'States',
      transitions: 'Transitions',
      dump: 'Dump'
    },
    buttons: {
      create: 'Add workflow',
      copy: 'Copy workflow'
    },
    dialogs: {
      assignee: {
        title: 'Assign',
        assign: 'Assign and submit',
        notSelected: 'Select employee',
        submit: 'Submit',
        noAssignees: 'Press submit button.',
        confirm: 'Confirm assignment'
      }
    }
  },
}