import React from 'react'
import PropTypes from 'prop-types'
import MultipleSelectField from './MultipleSelectField'

const options = {
  AUTOMATIC: 'resources.crwl/links.measure.gearbox.AUTOMATIC',
  ROBOT: 'resources.crwl/links.measure.gearbox.ROBOT',
  VARIATOR: 'resources.crwl/links.measure.gearbox.VARIATOR',
  MECHANICAL: 'resources.crwl/links.measure.gearbox.MECHANICAL'
}

const GearboxTypeField = (props) =>
  <MultipleSelectField
    options={options}
    label={'resources.crwl/links.fields.gearbox'}
    {...props}
  />

GearboxTypeField.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.array
}

export default GearboxTypeField