export default {
  'postal-addresses': {
    name: 'Адрес',
    fields: {
      addressCountry: 'Country',
      addressLocality: 'City',
      addressRegion: 'Region',
      postalCode: 'Postal code',
      streetAddress: 'Street address'
    }
  },
}