export default {
  'workflow/actions': {
    add: 'Ajouter une action',
    name: 'Action |||| Actions  ',
    fields: {
      workflowType: 'Type',
      name: 'Titre',
      commandName: 'Evénement généré',
      workflowEventName: 'Quel événement est déclenché'
    }
  },
}