import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDataProvider, useNotify, useTranslate, LoadingIndicator } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { getCleanHydraId } from '../../../utils/HydraUtils'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  container100: {
    width: 640,
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const AutoRuSelector = (props) => {

  const { model, mapping } = props

  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const translate = useTranslate()

  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedModel, setSelectedModel] = useState(null)
  const [selectedGen1, setSelectedGen1] = useState(null)
  const [selectedGen2, setSelectedGen2] = useState(null)
  const [selectedGen3, setSelectedGen3] = useState(null)
  const [selectedBody, setSelectedBody] = useState(null)

  const [brands, setBrands] = useState([])
  const [brandsLoading, setBrandsLoading] = useState(false)
  const [models, setModels] = useState([])
  const [modelsLoading, setModelsLoading] = useState([])
  const [generations, setGenerations] = useState([])
  const [generationsLoading, setGenerationsLoading] = useState([])
  const [bodiesLoading, setBodiesLoading] = useState([])
  const [bodies, setBodies] = useState([])

  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setSelectedBrand(null)
    setSelectedModel(null)
    setSelectedGen1(null)
    setSelectedGen2(null)
    setSelectedGen3(null)
    setSelectedBody(null)
  }, [model])

  useEffect(() => {
    setBrandsLoading(true)
    dataProvider.getList('reference/brands', {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'name', order: 'ASC' },
      filter: {}
    })
      .then(({ data }) => {
        setBrands(data)

        if (!!mapping.length) {
          const b = data.find(b => b.name === mapping[0].brand)

          if (b) {
            setSelectedBrand(b)
          }
        }
      })
      .finally(() => {
        setBrandsLoading(false)
      })
  }, [mapping])

  useEffect(() => {
    if (!selectedBrand) return
    setModelsLoading(true)
    setSelectedModel(null)
    setModels([])
    dataProvider.getList('reference/models', {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'name', order: 'ASC' },
      filter: { brand: selectedBrand.id }
    })
      .then(({ data }) => {
        if (!!mapping.length) {
          const m = data.find(m => m.name === mapping[0].model)

          if (m) {
            setSelectedModel(m)
          }
        }

        setModels(data)
      })
      .finally(() => {
        setModelsLoading(false)
      })
  }, [selectedBrand])

  useEffect(() => {
    if (!selectedModel) return
    setGenerationsLoading(true)
    setSelectedGen1(null)
    setGenerations([])
    dataProvider.getList('reference/generations', {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'name', order: 'ASC' },
      filter: { model: selectedModel.id } //без декода не работает для моделей с пробелами в названиях (например Citroen C3 Aircross)
    })
      .then(({ data }) => {
        if (!!mapping.length) {
          const g1 = data.find(g => g.name === mapping[0].generation)
          if (g1) {
            setSelectedGen1(g1)
          }

          const g2 = data.find(g => g.name === mapping[0].generation1)
          if (g2) {
            setSelectedGen2(g2)
          }

          const g3 = data.find(g => g.name === mapping[0].generation2)
          if (g3) {
            setSelectedGen3(g3)
          }
        }
        setGenerations(data.map(g => ({ id: g.id, name: `${g.name} [${g.yearFrom}-${g.yearTo}]` })))
      })
      .finally(() => {
        setGenerationsLoading(false)
      })
  }, [selectedModel])

  useEffect(() => {
    if (!selectedGen1) return
    setBodiesLoading(true)
    setSelectedBody(null)
    setBodies([])
    dataProvider.getList('reference/modifications', {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'name', order: 'ASC' },
      filter: { generation: selectedGen1.id }
    })
      .then(({ data }) => {
        const bodies = data.reduce(function (accumulator, currentValue) {
          if (!accumulator.find(b => b.name === currentValue.bodyType)) {
            accumulator.push({ id: currentValue.bodyType, name: currentValue.bodyType })
          }
          return accumulator
        }, []).sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })

        if (!!mapping.length) {
          const b = bodies.find(b => b.name === mapping[0].body)
          if (b) {
            setSelectedBody(b)
          }
        }

        setBodies(bodies)
      })
  }, [selectedGen1])

  const save = () => {
    if (saving) return

    setSaving(true)

    const b = selectedBrand
    const m = selectedModel
    const g1 = selectedGen1
    const g2 = selectedGen2
    const g3 = selectedGen3
    const body = selectedBody

    const data = {
      modelId: model.originId,
      brand: b.name,
      model: m.name,
      generation: g1.name.replace(/\s\[(.)*/i, ''),
      generation1: g2 ? g2.name.replace(/\s\[(.)*/i, '') : null,
      generation2: g3 ? g3.name.replace(/\s\[(.)*/i, '') : null,
      body: body.name

    }

    if (!!mapping.length) {
      dataProvider.update('b-c-auto-ru-mappings', { id: mapping[0].id, data: data })
        .then(() => {
          notify('Сохранено', 'info')
          setSaving(false)
        })
    } else {
      dataProvider.create('b-c-auto-ru-mappings', { data: data })
        .then(() => {
          notify('Сохранено', 'info')
          setSaving(false)
        })
    }
  }

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          onChange={(event, value) => setSelectedBrand(value)}
          classes={classes}
          value={selectedBrand}
          defaultValue={selectedBrand}
          label="Марка"
          options={brands.filter(b => '-' !== b.name)}
          getOptionLabel={option => option.name}
          getOptionSelected={option => option.id === selectedBrand}
          loading={brandsLoading}
          loadingText={translate('ra.page.loading')}
          renderInput={params => (
            <TextField
              {...params}
              label={translate('resources.crwl/links.fields.brand')}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Autocomplete
          onChange={(event, value) => setSelectedModel(value)}
          classes={classes}
          value={selectedModel}
          label="Модель"
          values={models}
          options={models.filter(m => '-' !== m.name)}
          getOptionLabel={option => option.name}
          loading={modelsLoading}
          loadingText={translate('ra.page.loading')}
          renderInput={params => (
            <TextField
              {...params}
              label={translate('resources.crwl/links.fields.model')}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid sm={4}/>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          onChange={(event, value) => setSelectedGen1(value)}
          classes={classes}
          value={selectedGen1}
          values={generations}
          options={generations.filter(m => '-' !== m.name)}
          getOptionLabel={option => option.name}
          loading={generationsLoading}
          loadingText={translate('ra.page.loading')}
          renderInput={params => (
            <TextField
              {...params}
              label="Поколение 1"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Autocomplete
          onChange={(event, value) => setSelectedGen2(value)}
          classes={classes}
          value={selectedGen2}
          values={generations}
          options={generations.filter(m => '-' !== m.name)}
          getOptionLabel={option => option.name}
          loading={generationsLoading}
          loadingText={translate('ra.page.loading')}
          renderInput={params => (
            <TextField
              {...params}
              label="Поколение 2"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid sm={4}/>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          onChange={(event, value) => setSelectedGen3(value)}
          classes={classes}
          value={selectedGen3}
          values={generations}
          options={generations.filter(m => '-' !== m.name)}
          getOptionLabel={option => option.name}
          loading={generationsLoading}
          loadingText={translate('ra.page.loading')}
          renderInput={params => (
            <TextField
              {...params}
              label="Поколение 3"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          onChange={(event, value) => setSelectedBody(value)}
          classes={classes}
          value={selectedBody}
          values={bodies}
          options={bodies.filter(b => '-' !== b.name)}
          getOptionLabel={option => option.name}
          loading={bodiesLoading}
          loadingText={translate('ra.page.loading')}
          renderInput={params => (
            <TextField
              {...params}
              label="Тип кузова"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        {
          selectedBody &&
          <Grid item>
            <Button color={'primary'} variant={'contained'} onClick={save}>
              {
                !saving ? 'Сохранить' : <LoadingIndicator/>
              }
            </Button>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default AutoRuSelector

