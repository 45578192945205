export default {
  'questionnaire/surveys': {
    add: 'Umfrage erstellen',
    name: 'Umfrage |||| Umfragen',
    fields: {
      createdAt: 'Hergestellt in',
      company: 'Händler',
      questionnaire: 'Fragebogen',
      isCompleted: 'Abgeschlossen',
      isOpen: 'öffnen',
      workflow: 'Arbeitsablauf',
    },
    tabs: {
      summary: 'Zusammenfassung',
      results: 'Ergebnisse',
      questions: 'Fragen',
    },
    header: 'Frage %{current_question} von %{questions_num}',
    buttons: {
      prev: 'Vorherige Frage',
      next: 'Nächste Frage',
      complete: 'Umfrage ausfüllen',
      save: 'Sparen',
      transitions: 'Umfrageaktionen'
    },
    inputs: {
      freeText: 'Deine Antwort',
      yes: 'Ja'
    },
    messages: {
      answerSaved: 'Antwort gespeichert',
      answerError: 'Beim Speichern der Antwort ist ein Fehler aufgetreten.'
    }
  }
}