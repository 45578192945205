export default {
  'workflow/request-copy-workflow': {
    name: 'Copy workflow',
    fields: {
      workflowToCopy: 'Процесс для копирования',
      newWorkflowCompany: 'Компания нового процесса',
      newWorkflowName: 'Новое имя процесса',
    },
    buttons: {
      create: 'Добавить процесс',
      copy: 'Скопировать процесс'
    }
  },
}