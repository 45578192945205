import React from 'react'
import { Create, TextInput, required, SimpleForm } from 'react-admin'

export const SubjectTypeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={required()}
      />
      <TextInput
        source="resourceName"
        validate={required()}
      />
      <TextInput
        multiline
        source="className"
      />
    </SimpleForm>
  </Create>
)