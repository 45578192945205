import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useDataProvider, useTranslate } from 'react-admin'

const BrandField = (props) => {

  const { initialValue } = props

  const [brands, setBrands] = useState([])
  const [loading, setLoading] = useState(false)

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  useEffect(() => {
    setLoading(true)
    dataProvider.getList('reference/brands', {
      filter: {},
      pagination: { perPage: 300 },
      sort: { field: 'name', order: 'ASC' }
    })
      .then(data => {
        setBrands(data.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const onChange = (event, value) => {
    const { onChange } = props
    onChange(value ? value['id'] : undefined)
  }

  const getById = (id) => {
    if (brands.length) {
      return brands.find((element) => element['id'] === id)
    }
    return ''
  }

  return (
    <Autocomplete
      value={getById(initialValue)}
      options={brands}
      getOptionLabel={option => option.name}
      onChange={onChange}
      loading={loading}
      loadingText={translate('ra.page.loading')}
      renderInput={params => (
        <TextField
          {...params}
          label={translate('resources.crwl/links.fields.brand')}
          variant="outlined"
          fullWidth
        />
      )}
    />
  )
}

BrandField.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string
}

export default BrandField