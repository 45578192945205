import React, { useEffect, useState } from 'react'
import { useDataProvider, useTranslate, useLocale, useNotify } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import AddCommentButton from './AddCommentButton'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 140,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  btn: {
    marginBottom: theme.spacing(1),
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}))

const CommentsCard = (props) => {

  const { resource, subjectId } = props

  const classes = useStyles()
  const translate = useTranslate()
  const locale = useLocale()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [comments, setComments] = useState([])

  useEffect(() => {
    dataProvider.getList('comments', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: {
        subjectId: subjectId,
        subjectResourceName: resource
      }
    })
      .then(({ data }) => {
        setComments(data)
      })
  }, [resource, subjectId])

  const onCreate = (comment) => {
    const newComments = [...comments]
    newComments.unshift(comment)
    setComments(newComments)
  }

  const deleteComment = (id) => {
    const newComments = comments.filter(comment => comment.id !== id)

    dataProvider.delete('comments', { id }, {
      undoable: true,
      onSuccess: ({ data }) => {
        notify(translate('resources.comments.notifications.deleted'), 'info', {}, true)
        setComments(newComments)
      },
      onFailure: (error) => notify(`${error.message}`, 'warning'),
    })
  }

  return (
    comments.length > 0 ?
      <Card className={classes.card} square={true} elevation={1}>
        <CardContent>
          <Typography gutterBottom variant="h5">
            {translate('resources.comments.name', { smart_count: 2 })}
          </Typography>

          <List className={classes.root}>
            {
              comments.map(comment => {
                return (
                  <React.Fragment>
                    <ListItem alignItems="flex-start" key={comment.id}>
                      <ListItemAvatar>
                        <Avatar alt={comment.author}/>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant="subtitle1"
                              color="textPrimary"
                            >
                              {comment.author}
                            </Typography>

                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography variant="body2">
                              {
                                new Intl.DateTimeFormat(locale, {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit'
                                }).format(new Date(comment.createdAt))
                              }
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textPrimary"
                            >
                              {comment.comment}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      {
                        comment.canBeDeleted &&
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => deleteComment(comment.id)}>
                            <DeleteIcon/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                  </React.Fragment>
                )
              })
            }

          </List>
        </CardContent>
        <CardActions>
          <AddCommentButton resource={resource} subjectId={subjectId} onCreate={onCreate}/>
        </CardActions>
      </Card> :
      <div className={classes.btn}>
        <AddCommentButton resource={resource} subjectId={subjectId} onCreate={onCreate}/>
      </div>
  )
}

export default CommentsCard