export default {
  'workflow/subject-types': {
    name: 'Workflow subject type |||| Workflow subject types',
    fields: {
      name: 'Title',
      className: 'Subject class name',
      resourceName: 'Resource name',
      eventTypes: 'Calendar event types',
    }
  },
}