export default {
  'workflow/types': {
    name: 'Workflow-Typ |||| Workflow-Typen',
    fields: {
      name: 'Titel',
      subjectType: 'Objekt',
    },
    tabs: {
      summary: 'Eigenschaften',
      actions: 'Aktionen'
    },
  },
}