import React, { useState } from 'react'
import {
  BooleanField,
  ChipField,
  EditButton,
  FunctionField,
  LoadingIndicator,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useDataProvider,
  useNotify,
  SelectField,
  DateField,
  useTranslate,
  Loading,
  Error,
  FileField
} from 'react-admin'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  department: { display: 'inline-block' },
  job_position: { display: 'inline-block', marginLeft: 32 },

  first_name: { display: 'inline-block' },
  last_name: { display: 'inline-block', marginLeft: 32 },

  first: { display: 'inline-block' },
  second: { display: 'inline-block', marginLeft: 32 },

  email: { width: 544 },
}))

const Title = ({ record, ...rest }) => {

  return <span>{record ? `${record.fullName}` : ''}</span>
}

const EmployeeShowActions = (props) => {

  const { basePath, data } = props

  const [isSending, setIsSending] = useState(false)

  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const requestNewPassword = () => {
    if (data) {
      setIsSending(true)
      dataProvider.create('request-new-passwords', { data: { employee: data.id } })
        .then(() => {
          notify(translate('resources.employees.actions.requestNewPassword.message'), 'info')
          setIsSending(false)
        })
        .catch((error) => {
          notify(translate(error.message), 'warning')
          setIsSending(false)
        })
    }
  }

  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data}/>
      {/* Add your custom actions */}
      {
        !isSending ?
          <Button size={'small'} color="primary"
                  onClick={requestNewPassword}>{translate('resources.employees.actions.requestNewPassword.button')}</Button> :
          <LoadingIndicator/>
      }
    </TopToolbar>
  )
}

const EmployeeShow = props => {

  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Show actions={<EmployeeShowActions/>} {...props} title={<Title/>}>
      <TabbedShowLayout>
        <Tab label={translate('resources.employees.tabs.summary')}>
          <FunctionField source="person" render={record => record.fullName}/>

          <TextField source="account.email"/>

          <ReferenceField
            source="company"
            reference="companies"
            linkType={false}
          >
            <TextField source="name"/>
          </ReferenceField>

          <ReferenceArrayField reference="company-branches" source="companyBranches" linkType={false}>
            <SingleFieldList>
              <ChipField source="name" linkType={false}/>
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceField
            source="department"
            reference="departments"
            linkType={false}
            className={classes.department}
          >
            <TextField source="name"/>
          </ReferenceField>

          <ReferenceField
            source="jobPosition"
            reference="job-positions"
            linkType={false}
            className={classes.job_position}
          >
            <TextField source="name"/>
          </ReferenceField>

          <BooleanField source="enabled"/>

        </Tab>

        {/*
                <Tab label={translate("resources.employees.tabs.stats")} />
                <Tab label={translate("resources.employees.tabs.performance")} />
                <Tab label={translate("resources.employees.tabs.communication")} />
                */}

      </TabbedShowLayout>
    </Show>
  )
}

export default EmployeeShow