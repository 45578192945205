import React from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'

export const WorkflowCreate = props => {

  return (
    <Create {...props}>
      <SimpleForm
        redirect={'edit'}
      >
        <ReferenceInput
          source="company"
          reference="companies"
          allowEmpty
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput
          source="type"
          reference="workflow/types"
          allowEmpty
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>

        <TextInput source="name" fullWidth validate={required()}/>

        <BooleanInput source="enabled"/>
      </SimpleForm>
    </Create>
  )
}