export default {
  'workflow/actions': {
    add: 'Add action',
    name: 'Action |||| Actions',
    fields: {
      workflowType: 'Type',
      name: 'Title',
      commandName: 'Generated command',
      workflowEventName: 'Workflow event name'
    }
  },
}