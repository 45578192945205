import {
  deleteToken,
  setToken,
  getAccessToken,
  isTokenValid,
  setRole,
  deleteRole,
  getRole, refreshToken
} from './utils/AuthUtils'

export default (history) => ({
  login: (params) => {
    setToken(params.token, params.refresh_token)
    setRole(params.token)
    return Promise.resolve()
  },
  logout: (params) => {
    deleteToken()
    deleteRole()
    return Promise.resolve()
  },
  checkError: error => {
    if (401 === error.status || error.response && 401 === error.response.status) {
      deleteToken()
      deleteRole()
      return Promise.reject()
    } else {
      return Promise.resolve()
    }
  },
  checkAuth: () => {
    const token = getAccessToken()
    return token && isTokenValid(token) ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => {
    const role = getRole()
    return role ? Promise.resolve(role) : Promise.reject()
  }
});