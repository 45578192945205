import React from 'react'
import {
  Show,
  SimpleShowLayout,
  UrlField,
  linkToRecord,
  useTranslate
} from 'react-admin'

import Button from '@material-ui/core/Button'
import CreateFromCRWLButton from '../WorksheetBuy/CreateFromCRWL/CreateFromCRWLButton'
import Grid from '@material-ui/core/Grid'

const LinkExpander = (props) => {

  const translate = useTranslate()

  const onClick = () => {
    const { id, basePath, record } = props
    window.open(`/#${linkToRecord(basePath, id, 'show')}`)
  }

  const { record, history } = props

  return (
    <Show {...props} title="">
      <SimpleShowLayout>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ width: '100%' }}
          spacing={2}
        >
          <Grid item md={6} xs={12}>
            <Button variant="contained" color="primary" onClick={onClick}>
              {translate('resources.crwl/links.actions.showButton')}
            </Button>
          </Grid>

          <Grid item md={6} xs={12}>
            <CreateFromCRWLButton link={record} history={history}/>
          </Grid>
        </Grid>
        <UrlField source={'url'} target="_blank"/>
      </SimpleShowLayout>
    </Show>
  )

}

export default LinkExpander