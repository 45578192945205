import React from 'react'
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  translate
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => {
  return ({
    headline: {
      marginTop: 16
    },
    addressCountry: { width: 256, display: 'inline-block' },
    postalCode: { width: 256, display: 'inline-block', marginLeft: 32 },
    addressLocality: { width: 256, display: 'inline-block' },
    addressRegion: { width: 256, display: 'inline-block', marginLeft: 32 },
  })
}

const AddressCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>

        <ReferenceInput
          source="addressCountry"
          reference="countries"
        >
          <SelectInput
            optionText="name"
          />
        </ReferenceInput>

        <TextInput
          source="postalCode"
          resettable={true}
        />

        <TextInput
          source="addressLocality"
          resettable={true}

        />

        <TextInput
          source="addressRegion"
          resettable={true}
        />

        <TextInput
          source="streetAddress"
          resettable={true}
          fullWidth={true}
        />
      </SimpleForm>
    </Create>
  )

}

export default translate(withStyles(styles)(AddressCreate))