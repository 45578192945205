export default {
  'crwl/links': {
    name: 'Объявление |||| Объявления',
    fields: {
      soldAt: 'Дата продажи',
      isSold: 'Продан',
      createdAt: 'Добавлен',
      src: 'Источник',
      url: 'Ссылка',
      srcId: 'ID источника',
      vin: 'VIN',
      year: 'Год выпуска',
      color: 'Цвет',
      brand: 'Марка',
      model: 'Модель',
      generation: 'Поколение',
      version: 'Версия',
      engineSize: 'Объем двигателя',
      engineType: 'Тип двигателя',
      gearbox: 'Тип КПП',
      doorsCount: 'Количество дверей',
      bodyTypeCode: 'Код типа кузова',
      bodyType: 'Тип кузова',
      driveType: 'Привод',
      hp: 'Мощность, л.с.',
      steeringWheel: 'Положение руля',
      srcCreateTs: 'Дата добавления',
      ownersNumber: 'Количество владельцев',
      ptsOriginal: 'ПТС оригинальный',
      price: 'Цена',
      mileage: 'Пробег',
      sellerType: 'Тип продавца',
      sellerName: 'Продавец',
      sellerLocationName: 'Нахождение продавца',
      sellerLocationLat: 'Широта',
      sellerLocationLon: 'Долгота',
      sellerPoint: 'Геоточка',
      photos: 'Фотографии',
    },
    measure: {
      mileage: 'km',
      sellerPrivate: 'Privé',
      sellerCompany: 'Marchand',
      currency: 'EUR',
      today: 'Aujourd\'hui',
    }
  },
}