import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Button,
  EditButton,
  Confirm,
  CRUD_UPDATE_SUCCESS,
  crudUpdate,
  Datagrid,
  DateField,
  FETCH_END,
  Filter,
  FunctionField,
  List,
  NullableBooleanInput,
  ReferenceField,
  setListSelectedIds,
  ShowButton,
  UPDATE,
  useDataProvider,
  useTranslate,
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import rowStyle from './RowStyle'
import taskableResources from './taskableResources'

import { getAccessToken, getTokenClaim } from '../../utils/AuthUtils'

const TaskFilter = (props) => (
  <Filter {...props}>
    <NullableBooleanInput source="isCompleted" label="Показать выполненные" alwaysOn />
  </Filter>
)

const TasksBulkActionButtons = connect(undefined, { crudUpdate, setListSelectedIds })(props => {
  const { basePath, crudUpdate, setListSelectedIds, resource, selectedIds } = props

  const translate = useTranslate()

  const markAsNew = () => {
    selectedIds.forEach(id => crudUpdate(resource, id, { isNew: true, isCompleted: false }, {}, basePath))
    setListSelectedIds(resource, [])
  }

  const markAsCompleted = () => {
    selectedIds.forEach(id => crudUpdate(resource, id, { isNew: false, isCompleted: true }, {}, basePath))
    setListSelectedIds(resource, [])
  }

  return (
    <React.Fragment>
      <Button label={translate('resources.tasks.buttons.markAsComplete')} {...props} onClick={markAsCompleted}/>
      <Button label={translate('resources.tasks.buttons.markAsNew')} {...props} onClick={markAsNew}/>
    </React.Fragment>
  )
})

const TaskShow = connect(null, { crudUpdate })(props => {
  const { id, record, crudUpdate, basePath } = props

  const [confirmCompleteOpen, setConfirmCompleteOpen] = useState(false)
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const dispatch = useDispatch()

  useEffect(() => {
    //не используем crudUpdate так как тогда раскрывашка схлопнется
    if (record.isNew) {
      dataProvider.update('tasks', { id: id, data: { isNew: false }, previousData: { record } })
        .then(({ data }) => {
          dispatch({
            type: CRUD_UPDATE_SUCCESS,
            payload: { data },
            meta: {
              resource: 'tasks',
              fetchResponse: UPDATE,
              fetchStatus: FETCH_END
            }
          })
        })
    }
  }, id)

  const setCompleted = () => {
    crudUpdate('tasks', id, { isNew: false, isCompleted: true }, {}, basePath)
    setConfirmCompleteOpen(false)
  }

  return (
    <React.Fragment>

      {
        taskableResources.find(r => r.name === record.subjectResourceName).link(record)
      }

      {
        !record.completed &&
        <React.Fragment>
          <Button label={translate('resources.tasks.buttons.markAsComplete')}
                  onClick={() => setConfirmCompleteOpen(true)}/>
          <Confirm
            isOpen={confirmCompleteOpen}
            title={translate('resources.tasks.dialogs.confirmComplete.title')}
            content={translate('resources.tasks.dialogs.confirmComplete.description')}
            onConfirm={setCompleted}
            onClose={() => setConfirmCompleteOpen(false)}
          />
        </React.Fragment>
      }
    </React.Fragment>
  )
})

export const TaskList = props => {
  const translate = useTranslate()

  return (
    <List {...props} filters={<TaskFilter/>}
          filterDefaultValues={{ isCompleted: false, assignee: getTokenClaim(getAccessToken(), 'id') }}
          sort={{ field: 'due', order: 'DESC' }} 
          bulkActionButtons={<TasksBulkActionButtons/>} 
          exporter={false}
    >
      <Datagrid rowClick="show" expand={<TaskShow/>} rowStyle={rowStyle}>
        <DateField source="createdAt" options={{ day: '2-digit', month: '2-digit', year: '2-digit' }}/>
        <ReferenceField source="creatorEmployee" reference="employees" linkType={false}>
          <FunctionField render={record => (
            <Typography variant="caption">{`${record.personData.lastName} ${record.personData.firstName}`}</Typography>
          )}/>
        </ReferenceField>
        <DateField source="due" showTime options={{
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        }}/>
        <FunctionField source="task" render={record => (
          <React.Fragment>
            <Typography>{record.title}</Typography>
            <Typography variant={'caption'}>{record.description}</Typography>
          </React.Fragment>
        )}/>
        <FunctionField source="subject" render={record => (
          <Typography>{translate(`resources.${record.subjectResourceName}.name`, { smart_count: 1 })}</Typography>
        )}/>

        <ShowButton label=""/>

        <EditButton label=""/>

      </Datagrid>
    </List>
  )
}