import React from 'react'
import { SelectField, Datagrid, DeleteButton, ReferenceManyField, TextField, Pagination } from 'react-admin'

export const ActionList = props => {
  return (
    <ReferenceManyField
      reference="workflow/actions"
      target="workflowType"
      addLabel={false}
      pagination={<Pagination/>}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="name"/>
        <SelectField source="workflowEventName" choices={[
          { id: 'workflow.guard', name: 'Guard event' },
          { id: 'workflow.leave', name: 'Leave event' },
          { id: 'workflow.transition', name: 'Transition event' },
          { id: 'workflow.enter', name: 'Enter event' },
          { id: 'workflow.entered', name: 'Entered event' },
          { id: 'workflow.completed', name: 'Completed event' },
        ]}/> <TextField source="commandName"/>
        <DeleteButton label="" redirect={false}/>
      </Datagrid>
    </ReferenceManyField>
  )
}