import React, { useState } from 'react'
import { compose } from 'recompose'
import { useDispatch } from 'react-redux'

import {
  BooleanInput,
  Button,
  fetchEnd,
  fetchStart,
  FormDataConsumer,
  TextInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  useNotify,
  SimpleForm,
  useTranslate,
  useDataProvider,
  useLocale,
  Toolbar
} from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core/styles'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { DateTimeInput } from '../Inputs/ra-admin-date-inputs'

const styles = theme => ({
  firstInput: {
    marginTop: 0,
  },
  first: {
    display: 'inline-block'
  },
  second: {
    display: 'inline-block', marginLeft: 32
  },
  heading: {
    marginTop: theme.spacing(2),
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  content: {
    padding: 0,
    width: '100%'
  }
})

const AddTaskDialog = (props) => {

  const { open, onClose, onCreate, classes, fullScreen, resource, id } = props

  const translate = useTranslate()
  const locale = useLocale()
  const dataProvider = useDataProvider()
  const dispatch = useDispatch()
  const notify = useNotify()
  const [saving, setSaving] = useState(false)

  const formName = 'task-quick-create'

  const handleSaveClick = () => {
    const { submit } = props
    submit(formName)
  }

  const handleSubmit = values => {

    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart())
    setSaving(true)

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider.create('tasks/request-create-tasks', { data: { resourceName: resource, resourceId: id, ...values } })
      .then(({ data }) => {
        onCreate(data)
        //reset(formName);
        onClose()
      })
      .catch(error => {
        notify(error.message, 'error')
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        dispatch(fetchEnd())
        setSaving(false)
      })
  }

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-label="Create task"
    >
      <DialogTitle>{translate('resources.tasks.dialogs.create.title')}</DialogTitle>
      <DialogContent className={classes.content}>
        <SimpleForm
          resource="tasks"
          toolbar={<AddTaskDialogToolbar onClose={onClose} isSaving={saving}/>}
          undoable={false}
          redirect={false}
          save={handleSubmit}

        >
          <TextInput fullWidth source="title" validate={required()} className={classes.firstInput}/>
          <TextInput multiline fullWidth source="description"/>
          <DateTimeInput source="due" locale={locale} options={{
            disablePast: true,
            ampm: false
          }}/>
          <Typography variant="subtitle1" className={classes.heading} color={'primary'}>
            {translate('resources.tasks.fields.assignment')}
          </Typography>

          <BooleanInput source="assignOnMe" defaultValue={true}/>
          <FormDataConsumer formClassName={classes.first}>
            {({ formData, ...rest }) =>
              !formData.assignOnMe &&
              <ReferenceInput
                source="company"
                reference="companies"
                formClassName={classes.first}
                {...rest}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
            }
          </FormDataConsumer>

          <FormDataConsumer formClassName={classes.second}>
            {({ formData, ...rest }) =>
              (!formData.assignOnMe && formData.company) &&
              <ReferenceInput
                source="companyBranch"
                reference="company-branches"
                formClassName={classes.second}
                filter={{ company: formData.company }}
                {...rest}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
            }
          </FormDataConsumer>
          <div/>
          <FormDataConsumer formClassName={classes.first}>
            {({ formData, ...rest }) =>
              (!formData.assignOnMe && formData.company) &&
              <ReferenceInput
                source="jobPosition"
                reference="job-positions"
                formClassName={classes.second}
                filter={{ company: formData.company }}
                {...rest}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
            }
          </FormDataConsumer>

          <FormDataConsumer formClassName={classes.second}>
            {({ formData, ...rest }) =>
              (!formData.assignOnMe && formData.companyBranch && formData.jobPosition) &&
              <ReferenceInput
                source="employee"
                reference="employees"
                formClassName={classes.second}
                filter={{ companyBranch: formData.companyBranch, jobPosition: formData.jobPosition }}
                {...rest}
              >
                <SelectInput optionText={r => {
                  return `${r.personData.lastName ? r.personData.lastName : ''} ${r.personData.firstName ? r.personData.firstName : ''} ${r.personData.thirdName ? r.personData.thirdName : ''}`
                }}/>
              </ReferenceInput>
            }
          </FormDataConsumer>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )
}

const AddTaskDialogToolbar = (props) => {
  const { onClose, isSaving } = props
  return (
    <Toolbar {...props} >
      <SaveButton
        redirect={false}
        submitOnEnter={true}
        disabled={isSaving}
      />
      <Button label="ra.action.cancel" onClick={onClose} disabled={isSaving}>
        <IconCancel/>
      </Button>
    </Toolbar>
  )

}

export default compose(
  withStyles(styles),
  withMobileDialog(),
)(AddTaskDialog)