import React, { useState } from 'react'
import {
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { useLocale } from 'react-admin'

const style = theme => ({
  header: {
    paddingLeft: theme.spacing(2.5)
  }
})

const SimilarLinksPlot = ({ data, classes, referencePrice = null }) => {

  const [plotData, setPlotData] = useState(data.map(l => ({
    x: l.price,
    y: l.price - referencePrice,
    id: l['id'],
    title: `${l.createdAt}`
  })))

  const locale = useLocale()

  const getLink = id => {
    return `/#/crwl/links/${encodeURIComponent(`/crwl/links/${id}`)}/show`
  }

  return data.length ? (
    <div>
      <div className={classes.header}>
        <Typography variant='h6'>Предложения на рынке</Typography>
      </div>
      <Typography variant='body1'>
        <ResponsiveContainer width="100%" height={400}>
          <ScatterChart
            margin={{ top: 20, right: 30, left: 10, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis type="number"
                   dataKey="x"
                   name="Цена"
                   domain={['dataMin', 'dataMax']}
                   tickFormatter={(val) => new Intl.NumberFormat(locale, {
                     minimumFractionDigits: 0
                   }).format(val)}
            />
            <YAxis type="number"
                   dataKey="y"
                   name="Разница"
                   tickFormatter={(val) => new Intl.NumberFormat(locale, {
                     minimumFractionDigits: 0
                   }).format(val)}
            />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              formatter={(value, name, props) => {
                return new Intl.NumberFormat(locale, {
                  minimumFractionDigits: 0,
                  currency: 'RUB',
                  style: 'currency'
                }).format(value)
              }}
            />
            <Scatter
              name="Предложения"
              data={plotData}
              fill="#8884d8"
              onClick={(param) => {
                window.open(getLink(param.id))
              }}
            />
            <ReferenceLine x={referencePrice} stroke="red"/>
            <ReferenceLine y={0} stroke="red"/>
          </ScatterChart>
        </ResponsiveContainer>
      </Typography>
    </div>
  ) : <Typography variant={'body1'}>Объявления не найдены</Typography>
}

export default withStyles(style)(SimilarLinksPlot)