export default {
  'workflow/participants': {
    add: 'Ajouter contreparties',
    name: 'Contreparties |||| Contreparties',
    fields: {
      workflow: 'flux de travaux',
      role: 'Rôle',
      company: 'Contreparties'
    }
  },
}