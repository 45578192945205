import React from 'react'
import {
  Show,
  SimpleShowLayout,
  UrlField,
  linkToRecord,
  useTranslate,
  ReferenceField
} from 'react-admin'

import Button from '@material-ui/core/Button'

const FavoritesExpander = (props) => {

  const translate = useTranslate()

  const onClick = () => {
    const { record } = props
    window.open(`/#/crwl/links/${encodeURIComponent(linkToRecord('/crwl/links', record.link))}/show`)
  }

  console.log('props', props)

  return (
    <Show {...props} title="">
      <SimpleShowLayout>
        <Button variant="contained" color="primary" onClick={onClick}>
          {translate('resources.crwl/links.actions.showButton')}
        </Button>
          <UrlField label="resources.crwl/links.fields.url" source={'linkData.url'} target="_blank"/>
      </SimpleShowLayout>
    </Show>
  )

}

export default FavoritesExpander