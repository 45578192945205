import React, { useEffect, useState } from 'react'
import {
  useDataProvider
} from 'react-admin'
import PriceOfferInput from './PriceOfferInput'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import PriceOfferList from './PriceOfferList'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 3,
  },
  input: {
    fontSize: theme.typography.fontSize * 2,
  },
  button: {
    marginLeft: theme.spacing.unit * 3,
  }
}))

const PriceOffers = ({ record, onChange }) => {

  const dataProvider = useDataProvider()

  const [lastOffer, setLastOffer] = useState('');
  const [offers, setOffers] = useState([])

  const onNewOffer = (offer) => {
    onChange(offer)
    setLastOffer(offer)
    dataProvider.create('worksheet/worksheet-buy-price-offers', {
      data: {
        worksheet: record.id,
        offer: offer
      }
    })
      .then(({data}) => {
        const newOffers = offers
        newOffers.unshift(data)
        setOffers(newOffers)
      })
  }

  const onDeleteOffer = (offer) => {
    console.log("ondelete", offer);
    dataProvider.delete('worksheet/worksheet-buy-price-offers', {
      id: offer, previousData: {}
    })
      .then(() => {
        const newOffers = offers.filter(o => o.id !== offer);
        setOffers(newOffers)
      })
  }

  useEffect(() => {
    dataProvider.getList('worksheet/worksheet-buy-price-offers', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: { worksheet: record.id },
    })
      .then(({ data }) => {
        setOffers(data)
        if (data.length) {
          onChange(`${data[0].offer}`)
          setLastOffer(`${data[0].offer}`)
        }
      })
  }, [])

  return (
    <Grid container spacing={0}>
      <Grid xs={12} item>
        <PriceOfferInput currentOffer={lastOffer} onChange={onNewOffer}/>
      </Grid>
      <Grid xs={12} item>
        {
          offers.length > 0 && <PriceOfferList offers={offers} onDelete={onDeleteOffer} />
        }
      </Grid>
    </Grid>
  )
}

export default PriceOffers