import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { useTranslate, useDataProvider, LoadingIndicator, linkToRecord } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 140,
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  btn: {
    marginBottom: theme.spacing(),
  }

}))

const CreateFromAppraisalButton = (props) => {

  const classes = useStyles()

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const [loading, setLoading] = useState(false)
  const { filterValues, history, beeCarsVersionId } = props


  const handleClick = () => {
    setLoading(true)
    dataProvider.create('car-instances', {
      data: {
        'revision': 0,
        'vin': '',
        'productionYear': parseInt(filterValues.year),
        'brand': filterValues.brand,
        'model': filterValues.model,
        'generation': filterValues.generation,
        'modification': filterValues.modification,
        'mileage': filterValues.mileage ? parseInt(filterValues.mileage) : 0,
        'bcId': beeCarsVersionId
      }
    })
      .then(response => {
        const record = response.data
        dataProvider.create('worksheet/worksheet-buys', {
          data: {
            carInstance: record.id
          }})
          .then(response => {
            const record = response.data
            history.push(linkToRecord('/worksheet/worksheet-buys', record.id, 'show'));
          })

      })
      .catch(error => {
        console.log('error', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className={classes.btn}>
      <React.Fragment>
        {
          loading ? <LoadingIndicator/> :
            <Button size="small" color="primary" onClick={handleClick}>
              <AddIcon className={classes.leftIcon}/>
              {translate('resources.worksheet/worksheet-buys.buttons.add')}
            </Button>
        }

      </React.Fragment>
    </div>
  )
}

export default CreateFromAppraisalButton