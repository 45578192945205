import React from 'react'
import {
  BooleanInput,
  Edit,
  FunctionField,
  ReferenceField,
  SimpleForm,
  TextInput,
  translate
} from 'react-admin'
import { isGranted, ROLE_ADMIN } from '../../utils/AuthUtils'

const CompanyBranchTitle = ({ record }) => {
  return <span>{record ? record.title : ''}</span>
}

const CompanyBranchEdit = props => {

  const redirect = (basePath, id, data) => `/companies/${encodeURIComponent(data.company)}/branches`

  const { translate } = props

  return (
    <Edit  {...props} title={<CompanyBranchTitle/>}>
      <SimpleForm
        redirect={redirect}
      >
        <TextInput source="name"/>
        <BooleanInput source="enabled"/>
        {
          isGranted(ROLE_ADMIN, props.permissions) &&
          <BooleanInput
            source="blocked"
          />
        }

      </SimpleForm>
    </Edit>
  )
}

export default translate(CompanyBranchEdit)