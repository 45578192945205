import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import SingleSelectField from '../../CRWL/LinksListFilter/Fields/SingleSelectField'
import TextField from '@material-ui/core/TextField'
import PriceInput from '../PriceInput'
import PhotosContainer, { rakursList } from '../PhotosContainer'
import { ConfirmableButton } from '../../Primitives/ConfirmableButton/ConfirmableButton'

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '24px'
  },
  media: {
    height: 140,
  },
})

const Dimple = ({ diagnostics, carInstance, dimple, locations, types, onSave, onDelete, type, onPhotoUploaded, onPhotoDeleted }) => {

  const classes = useStyles()

  const [dimpleType, setDimpleType] = useState(types.find(t => t.id === dimple.type)?.id)
  const [dimpleSide, setDimpleSide] = useState(locations.find(l => l.id === dimple.location)?.side)
  const [dimpleNode, setDimpleNode] = useState(locations.find(l => l.id === dimple.location)?.node)
  const [dimplePart, setDimplePart] = useState(locations.find(l => l.id === dimple.location)?.id)
  const [comment, setComment] = useState(dimple.comment)
  const [estimationCost, setEstimationCost] = useState(dimple.estimationCost)
  const [repairCost, setRepairCost] = useState(dimple.repairCost)

  useEffect(() => {
    setDimpleType(types.find(t => t.id === dimple.type)?.id)
    setDimpleSide(locations.find(l => l.id === dimple.location)?.side)
    setDimpleNode(locations.find(l => l.id === dimple.location)?.node)
    setDimplePart(locations.find(l => l.id === dimple.location)?.id)
    setEstimationCost(parseInt(dimple.estimationCost))
    setComment(dimple.comment)
  }, [dimple])

  const doSave = () => {
    onSave(dimple, { comment, type: dimpleType, location: dimplePart, estimationCost: parseInt(estimationCost) })
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Повреждение
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <SingleSelectField
              variant='filled'
              options={types.reduce((obj, item, index) => {
                obj[item.id] = item.type
                return obj
              }, {})}
              onChange={setDimpleType}
              label={'Тип повреждения'}
              initialValue={[dimpleType]}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SingleSelectField
              variant='filled'
              options={locations.reduce((obj, item, index) => {
                if (item.type === type) {
                  obj[item.side] = item.side
                }
                return obj
              }, {})}
              onChange={setDimpleSide}
              label={'Место повреждения'}
              initialValue={[dimpleSide]}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SingleSelectField
              variant='filled'
              options={locations.reduce((obj, item, index) => {
                if (item.type === type && item.side === dimpleSide) {
                  obj[item.node] = item.node
                }
                return obj
              }, {})}
              onChange={setDimpleNode}
              label={'Поврежденный узел'}
              initialValue={[dimpleNode]}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SingleSelectField
              variant='filled'
              options={locations.reduce((obj, item, index) => {
                if (item.type === type && item.side === dimpleSide && item.node === dimpleNode) {
                  obj[item.id] = item.part
                }
                return obj
              }, {})}
              onChange={setDimplePart}
              label={'Поврежденный элемент'}
              initialValue={[dimplePart]}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="Комментарий"
                multiline
                rowsMax="15"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                margin="normal"
                variant="filled"
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3}>
            <PriceInput
              variant="filled"
              onChange={setEstimationCost}
              label={'Оценка ремонта'}
              initialValue={estimationCost}
              style={{ width: '100%' }}
              margin="normal"
            />
          </Grid>

        </Grid>

        <PhotosContainer
          carInstance={carInstance}
          filter={(photos) => photos.filter(p => !p.deleted && p.type === '200' && dimple.photos.includes(p.id))}
          types={rakursList.filter(r => r.type === 200)}
          photoUploadedCallback={onPhotoUploaded}
          photoDeletedCallback={onPhotoDeleted}
          extraAddType={200}
        />
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={doSave}>
          Сохранить
        </Button>
        <ConfirmableButton
          size="small"
          color="primary"
          onClick={() => onDelete(dimple.id)}
          dialogTitle={'resources.dimples.dialogs.delete.title'}
          dialogMessage={'resources.dimples.dialogs.delete.message'}
        >
          Удалить
        </ConfirmableButton>
      </CardActions>
    </Card>
  )
}

export default Dimple