import authForm from './authForm/de'
import menu from './menu/de'
import resources from './resoures/de'

export default {
  'app.loading': 'Beladung',
  'app.welcome': 'Willkommen',
  'app.form.required': 'Erforderliches Feld',
  'app.form.submit': 'Übermitteln',
  show: 'Show',
  hide: 'Hide',

  roles: {
    ROLE_ANONYMOUS: 'Anonymer Nutzer',
    ROLE_ADMIN: 'Administrator',
    ROLE_EMPLOYEE: 'Mitarbeiter',
    ROLE_COMPANY_ADMIN: 'Firmen Administrator',
    ROLE_COMPANY_MANAGER: 'Firmen Manager',
    ROLE_BRANCH_MANAGER: 'Branch Manager',
    ROLE_DEALER: 'Händler',
  },

  ...authForm,
  ...menu,
  ...resources

}