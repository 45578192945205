import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { LoadingIndicator, useDataProvider, useLocale } from 'react-admin'
import { StorageFactory } from '../../utils/StorageFactory'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import SimilarLinksPlot from '../CRWL/SimilarLinksPlot'
import TitsPlot from '../CRWL/TitsPlot'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

const Appraisal = props => {

  const dataProvider = useDataProvider()
  const locale = useLocale()
  const classes = useStyles()

  const { priceOffer, record: { carInstance: id } } = props
  
  console.log("id", id);
  
  const [loading, setLoading] = useState(false)
  const [carInstance, setCarInstance] = useState({})
  const [referencePrice, setReferencePrice] = useState(priceOffer)

  const [similarCars, setSimilarCars] = useState([])
  const [nextYearSimilarCars, setNextYearSimilarCars] = useState([])

  const [showNoModificationMessage, setShowNoModificationMessage] = useState(false)

  const [geoParams, setGeoParams] = useState({
    lat: StorageFactory().getItem('geo_lat'),
    lon: StorageFactory().getItem('geo_lon'),
    radius: parseInt(StorageFactory().getItem('geo_radius'))
  })

  useEffect(() => {
    setLoading(true)
    dataProvider.getOne('car-instances', { id: id })
      .then(({ data }) => {
        setCarInstance({
          id: data.id,
          year: data.productionYear,
          mileage: data.mileage,
          modification: data.modification,
        })
      })
      .finally(() => setLoading(false))
  }, [id])

  useEffect(() => {
    if (Object.keys(carInstance).includes('id')) {
      if (!carInstance.modification) {
        setShowNoModificationMessage(true)
      } else {
        setLoading(true)
        dataProvider.create('crwl/request-appraisals', {
          data: { ...carInstance, ...geoParams }
        })
          .then(({ data }) => {
            setSimilarCars(data.result)
          })
          .finally(() => {
            setLoading(false)
          })

        dataProvider.create('crwl/request-appraisals', {
          data: { ...carInstance, ...geoParams, year: carInstance.year + 1 }
        })
          .then(({ data }) => {
            setNextYearSimilarCars(data.result)
          })
          .finally(() => {
            setLoading(false)
          })
      }

    }
  }, [carInstance])

  useEffect(() => {
    if (undefined !== similarCars.avgPrice && '' === referencePrice){
      setReferencePrice(similarCars.avgPrice);
    }
  }, [similarCars]);

  useEffect(() => {
    setReferencePrice(priceOffer);
  }, [priceOffer])

  return (
    loading ?
      <LoadingIndicator/> :

      showNoModificationMessage ?
        <Typography>Для отображения аналитики, укажите модификацию автомобиля</Typography> :

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {
              !!similarCars.avgPrice &&
              <Typography variant={'h4'}>
                {`Средняя цена: `}
                {new Intl.NumberFormat(locale, {
                  minimumFractionDigits: 0,
                  currency: 'RUB',
                  style: 'currency'
                }).format(similarCars.avgPrice)}
              </Typography>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              {
                !!similarCars.links &&
                <SimilarLinksPlot
                  data={similarCars.links}
                  referencePrice={referencePrice}
                  basePath={'/crwl/links'}
                />
              }
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              {
                (!!nextYearSimilarCars.links && !!similarCars.links) &&
                <TitsPlot data={{
                  year: {
                    year: carInstance.year,
                    data: similarCars.links
                  },
                  nextYear: {
                    year: parseInt(carInstance.year) + 1,
                    data: nextYearSimilarCars.links
                  }
                }}
                          referencePrice={referencePrice}
                          basePath={'/crwl/links'}
                />
              }
            </Paper>
          </Grid>
        </Grid>
  )
}

export default Appraisal