export default {
  'workflow/places': {
    add: 'Stadium hinzufügen',
    name: 'Stadium |||| Stadien',
    fields: {
      workflow: 'Arbeitsablauf',
      name: 'Titel',
      code: 'Code',
      isInitial: ' Start',
      isSuccess: 'Erfolgreich',
      isFail: 'Fehlgeschlagen',
      maxMinutesInPlace: 'Max Minuten an Ort und Stelle',
      overdueJobPositions: 'Bei Überfälligkeit benachrichtigen'
    },
    timeleft: 'Übrige Zeit',
    overdue: 'Bis zum angegebenen Zeitpunkt mussten weitere Maßnahmen ergriffen werden. Der aktuelle Status ist auf die maximal darin verbrachte Zeit begrenzt. Sie haben den nächsten Schritt nicht rechtzeitig getan.'
  },
}