import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useDataProvider, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'

const ModificationField = (props) => {

  const { brand, model, generation, initialValue, onOptionSelected = (generation) => {} } = props
  const [loading, setLoading] = useState(false)
  const [modifications, setModifications] = useState([])

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (generation) {
      setLoading(true)
      dataProvider.getList('reference/modifications', {
        filter: { generation: generation },
        pagination: { perPage: 300 },
        sort: { field: 'name', order: 'ASC' }
      })
        .then(data => {
          setModifications(data.data)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setModifications([])
    }

  }, [brand, model, generation])

  const onChange = (event, value) => {
    const { onChange } = props
    onChange(value ? value['id'] : undefined)
    onOptionSelected(value)
  }

  const getById = (id) => {
    if (modifications.length) {
      return modifications.find((element) => element['id'] === id)
    }
    return ''
  }

  return (
    <Autocomplete
      options={modifications}
      getOptionLabel={option => option ? `${option.name} [${option.yearFrom} - ${option.yearTo}] ${option.bodyType}` : ''}
      onChange={onChange}
      value={getById(initialValue)}
      loading={loading}
      loadingText={translate('ra.page.loading')}
      renderInput={params => (
        <TextField {...params} label={translate('resources.crwl/links.fields.version')} variant="outlined"
                   fullWidth/>
      )}
    />
  )
}

ModificationField.propTypes = {
  onChange: PropTypes.func,
  brand: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  model: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  generation: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ModificationField