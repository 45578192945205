import React from 'react'
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  ReferenceManyField,
  Pagination,
  TextField
} from 'react-admin'
import { isGranted, ROLE_COMPANY_ADMIN } from '../../utils/AuthUtils'

const DepartmentList = (props) => {

  return (
    <ReferenceManyField
      reference="departments"
      target="company"
      addLabel={false}
      sort={{ field: 'name', order: 'ASC' }}
      pagination={<Pagination/>}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="name"/>
        <BooleanField source="enabled"/>
        {
          isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
          <EditButton label=""/>
        }
        {
          isGranted(ROLE_COMPANY_ADMIN, props.permissions) &&
          <DeleteButton label="" redirect={false}/>
        }
      </Datagrid>
    </ReferenceManyField>
  )

}

export default DepartmentList