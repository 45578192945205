import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Tag from './Tag'
import AddTagButton from './AddTagButton'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing()
  },
  list: {
    marginTop: theme.spacing()
  }
}))

const TagList = ({ tags, onLinkTag, onUnlinkTag, onCreate }) => {

  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        {
          tags.map(tag => tag.selected && <Tag {...tag} onDelete={onUnlinkTag}/>)
        }

        <AddTagButton
          tags={tags}
          onLinkTag={onLinkTag}
          onUnlinkTag={onUnlinkTag}
          onCreate={onCreate}/>
      </div>
    </div>
  )
}

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
    title: PropTypes.string,
    selected: PropTypes.bool,
  })),
  onCreate: PropTypes.func.isRequired,
  loadTagsList: PropTypes.func.isRequired,
  loadLinkedTags: PropTypes.func.isRequired,
}

export default TagList