import React, { useState } from 'react'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useDataProvider, useTranslate, useLocale, LoadingIndicator } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'inline-block'
  },
  button: {
    marginRight: theme.spacing(),
  },
}))

const CarSoldButton = (props) => {

  const { id, isSold, soldAt } = props

  const [open, setOpen] = useState(false)

  const classes = useStyles()
  const dataProvider = useDataProvider()
  const translate = useTranslate()
  const locale = useLocale()

  function handleClickOpen () {
    setOpen(true)
  }

  function handleClose () {
    setOpen(false)
  }

  async function handleSold () {
    await dataProvider.update('crwl/links', { id: id, data: { isSold: true } })
    setOpen(false)
  }

  return (
    <div className={classes.container}>
      {!isSold ?
        <Button color='primary' className={classes.button} onClick={handleClickOpen}>
          {
            translate('resources.crwl/links.actions.carSold')
          }
        </Button> :
        <Typography variant={'body2'}>
          {translate('resources.crwl/links.actions.soldAt', { soldAt: new Intl.DateTimeFormat(locale).format(new Date(soldAt)) })}
        </Typography>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate('resources.crwl/links.dialog.sold.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              translate('resources.crwl/links.dialog.sold.description', { src: props.src })
            }
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleSold} color="primary">
            {translate('ra.boolean.true')}
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            {translate('ra.boolean.false')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )

}

CarSoldButton.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string,
  isSold: PropTypes.bool,
  soldAt: PropTypes.string
}

export default CarSoldButton