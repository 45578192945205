import React, { useState } from 'react'
import { MenuItemLink, UserMenu } from 'react-admin'
import UserIcon from '@material-ui/icons/PermIdentity'
import { getTokenPayload } from '../utils/JWTUtils'
import { getAccessToken } from '../utils/AuthUtils'
import { getSecuredFileURL } from '../utils/FilesUtils'
import Avatar from '@material-ui/core/Avatar'

const DefaultAvatar = (props) => <UserIcon/>

const CustomUserMenu = (props) => {

  const [avatar, setAvatar] = useState(null)
  const tokenData = getTokenPayload(getAccessToken())

  return (
    <UserMenu {...props}>

      <MenuItemLink
        to={'/'}
        primaryText={`${tokenData.email}`}
        leftIcon={
          avatar ?
            <Avatar src={getSecuredFileURL(avatar)} style={{ marginTop: '-12px', marginLeft: '-12px' }}/> :
            <DefaultAvatar/>
        }
        dense
      />

    </UserMenu>
  )
}

export default CustomUserMenu