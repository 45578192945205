import General from './General/resources'
import OrganizationResources from './Organization/resources'
//import QuestionnaireResources from './Questionnaire/resources';
import TasksResources from './Tasks/resources'
import CalendarEvents from './CalendarEvents/resources'
import WorkflowResources from './Workflow/resources'
import WorksheetBuyResources from './WorksheetBuy/resources'
import CRWL from './CRWL/resources'
import Tags from './Tags/resources'
import CarInstance from './CarInstance/resources'

export default permissions => [

  ...General(permissions),
  ...OrganizationResources(permissions),
  //...QuestionnaireResources(permissions),
  ...TasksResources(permissions),
  ...CalendarEvents(permissions),
  ...WorkflowResources(permissions),
  ...WorksheetBuyResources(permissions),
  ...CRWL(permissions),
  ...Tags(permissions),
  ...CarInstance(permissions)
]