import CRM from './CRM/de'
import General from './General/de'
import Organization from './Organization/de'
import Person from './Person/de'
import Questionnaire from './Questionnaire/de'
import CalendarEvents from './CalendarEvents/de'
import Tags from './Tags/de'
import Comments from './Comments/de'
import Tasks from './Tasks/de'
import Workflow from './Workflow/de'
import WorksheetsBuy from './WorksheetsBuy/de'
import CRWL from './CRWL/de'

export default {
  resources: {
    ...CRM,
    ...General,
    ...Organization,
    ...Person,
    ...Questionnaire,
    ...CalendarEvents,
    ...Tags,
    ...Comments,
    ...Tasks,
    ...Workflow,
    ...WorksheetsBuy,
    ...CRWL,
  },
  navigation: {
    first: 'First'
  }
}
