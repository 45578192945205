export default {
  'workflow/actions': {
    add: 'Aktion hinzufügen',
    name: 'Aktion |||| Aktionen ',
    fields: {
      workflowType: 'Typ',
      name: 'Titel',
      commandName: 'Generiertes Ereignis',
      workflowEventName: 'Welches Ereignis wird ausgelöst?'
    }
  },
}