import React from 'react'
import { Resource } from 'react-admin'

import CompanyIcon from '@material-ui/icons/Business'
import EmployeesIcon from '@material-ui/icons/People'

import { CompanyList } from './CompanyList'
import CompanyShow from './CompanyShow'
import CompanyEdit from './CompanyEdit'
import { CompanyCreate } from './CompanyCreate'
import CompanyBranchCreate from './CompanyBranchCreate'
import CompanyBranchEdit from './CompanyBranchEdit'
import DepartmentCreate from './DepartmentCreate'
import DepartmentEdit from './DepartmentEdit'
import JobPositionCreate from './JobPositionCreate'
import JobPositionEdit from './JobPositionEdit'

import EmployeesList from './EmployeesList'
import EmployeeCreate from './EmployeeCreate'
import EmployeeEdit from './EmployeeEdit'
import EmployeeShow from './EmployeeShow'

import JobGradeCreate from './JobGradeCreate'
import JobGradeEdit from './JobGradeEdit'
import CompanyFileCreate from './CompanyFileCreate'
import { CompanyTypeList } from './CompanyTypeList'
import CompanyTypeCreate from './CompanyTypeCreate'
import CompanyTypeEdit from './CompanyTypeEdit'

import { isGranted, ROLE_ADMIN, ROLE_BRANCH_MANAGER, ROLE_COMPANY_ADMIN } from '../../utils/AuthUtils'

export default permissions => [
  <Resource
    name="companies"
    list={CompanyList}
    edit={isGranted(ROLE_COMPANY_ADMIN) ? CompanyEdit : null}
    show={CompanyShow}
    create={isGranted(ROLE_ADMIN) ? CompanyCreate : null}
    icon={CompanyIcon}
  />,

  <Resource
    name="company-branches"
    create={isGranted(ROLE_COMPANY_ADMIN) ? CompanyBranchCreate : null}
    edit={isGranted(ROLE_COMPANY_ADMIN) ? CompanyBranchEdit : null}
  />,

  <Resource
    name="company-types"
    list={isGranted(ROLE_ADMIN) ? CompanyTypeList : null}
    create={isGranted(ROLE_ADMIN) ? CompanyTypeCreate : null}
    edit={isGranted(ROLE_ADMIN) ? CompanyTypeEdit : null}
  />,

  <Resource
    name="company-files"
    create={isGranted(ROLE_COMPANY_ADMIN) ? CompanyFileCreate : null}
  />,

  <Resource
    name="departments"
    create={isGranted(ROLE_COMPANY_ADMIN) ? DepartmentCreate : null}
    edit={isGranted(ROLE_COMPANY_ADMIN) ? DepartmentEdit : null}
  />,

  <Resource
    name="job-positions"
    create={isGranted(ROLE_COMPANY_ADMIN) ? JobPositionCreate : null}
    edit={isGranted(ROLE_COMPANY_ADMIN) ? JobPositionEdit : null}
  />,

  <Resource
    name="job-grades"
    create={isGranted(ROLE_COMPANY_ADMIN) ? JobGradeCreate : null}
    edit={isGranted(ROLE_COMPANY_ADMIN) ? JobGradeEdit : null}
  />,

  <Resource
    name="employees"
    icon={EmployeesIcon}
    list={EmployeesList}
    create={isGranted(ROLE_BRANCH_MANAGER) ? EmployeeCreate : null}
    edit={isGranted(ROLE_BRANCH_MANAGER) ? EmployeeEdit : null}
    show={EmployeeShow}
  />,

  <Resource
    name="users"
  />,
  <Resource
    name="user-avatars"
  />,
  <Resource
    name="logos"
  />,
  <Resource
    name="people"
  />,
  <Resource
    name="cities"
  />,
  <Resource
    name="addresses"
  />,

]