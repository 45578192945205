import React from 'react'
import { Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin'
import { parse } from 'query-string'

export const ParticipantCreate = props => {
  const { workflow: workflow_id_string } = parse(props.location.search)
  const workflow = workflow_id_string
  const redirect = workflow ? `/workflow/workflows/${encodeURIComponent(workflow)}/participants` : false

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ workflow }}
        redirect={redirect}
      >

        <ReferenceInput
          source="company"
          reference="companies"
          allowEmpty
          validate={required()}
          sort={{ field: 'name', order: 'ASC' }}
          filter={{ enabled: true }}
          perPage={1000}
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>

        <TextInput
          source="role"
        />
      </SimpleForm>
    </Create>
  )
}