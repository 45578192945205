export default {
  menu: {
    dashboard: {
      name: 'Startseite'
    },
    settings: {
      name: 'Einstellungen',
      menu: {
        countries: {
          name: 'Lände'
        },
        companies: {
          name: 'Händler'
        },
        employees: {
          name: 'Mitarbeiter'
        },
        workflow_subject_types: {
          name: 'Workflow-Themen'
        },
        workflow_types: {
          name: 'Arten von Prozessen '
        },
        workflows: {
          name: 'Arbeitsabläufe'
        }
      }
    },
    tasks: {
      name: 'Aufgaben',
    },
    calendar: {
      name: 'Veranstaltungskalender',
    },
    worksheets: {
      name: 'Arbeitsblätter'
    },
    questionnaires: {
      name: 'Fragebögen',
      menu: {
        settings: {
          name: 'Einstellungen'
        },
        processing: {
          name: 'Fragebogen'
        },
        reports: {
          name: 'Ergebnisse'
        },
      }
    }
  }
}