import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import colors from './colors'
import Chip from '@material-ui/core/Chip'

const styles = theme => ({
  ...colors(theme),
  margin: {
    marginRight: theme.spacing(),
  },
})

const Tag = ({ classes, color, title, onDelete, id }) => (
  <Chip
    label={title}
    onDelete={() => onDelete(id)}
    className={classNames(classes.chip, classes[color], classes.margin)}
  />
)

Tag.propTypes = {
  id: PropTypes.number,
  color: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onDelete: PropTypes.func
}

export default compose(
  withStyles(styles)
)(Tag)