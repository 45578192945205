import React from 'react'
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  Query,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'

const Title = ({ record }) => {
  return <span>{record ? record.name : ''}</span>
}

export const TransitionEdit = (props) => {
  const redirect = (basePath, id, data) => `/workflow/workflows/${encodeURIComponent(data.workflow)}/transitions`

  return (
    <Edit {...props} title={<Title/>}>
      <SimpleForm
        redirect={redirect}
      >
        <TextInput source="code" />
        <TextInput source="name" validate={required()} fullWidth={true}/>

        <FormDataConsumer fullWidth={true}>
          {
            ({ formData, ...rest }) => {
              return (
                <ReferenceArrayInput
                  source="fromPlaces"
                  reference="workflow/places"
                  filter={{ workflow: formData.workflow }}
                  sort={{ field: 'code', order: 'ASC' }}
                  perPage={300}
                  {...rest}
                >
                  <SelectArrayInput optionText={p => `${p.code}. ${p.name}`} validate={required()}/>
                </ReferenceArrayInput>
              )
            }
          }
        </FormDataConsumer>

        <FormDataConsumer fullWidth={true}>
          {
            ({ formData, ...rest }) => {

              return (
                <ReferenceInput
                  source="toPlace"
                  reference="workflow/places"
                  allowEmpty
                  validate={required()}
                  filter={{ workflow: formData.workflow }}
                  sort={{ field: 'code', order: 'ASC' }}
                  perPage={300}
                  {...rest}
                >
                  <SelectInput optionText={p => `${p.code}. ${p.name}`} />
                </ReferenceInput>
              )
            }
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {

              return (
                <ReferenceInput
                  source="participant"
                  reference="workflow/participants"
                  validate={required()}
                  sort={{ field: 'company.name', order: 'ASC' }}
                  filter={{ workflow: formData.workflow }}
                  {...rest}
                >
                  <SelectInput optionText="name" resettable/>
                </ReferenceInput>
              )
            }
          }
        </FormDataConsumer>

        <FormDataConsumer fullWidth={true}>
          {
            ({ formData, ...rest }) => {

              return (
                formData.participant &&
                <Query type="getOne" resource="workflow/participants" payload={{ id: formData.participant }} {...rest}>
                  {
                    ({ data, loading, error }) => {
                      if (loading) {
                        return ''
                      }
                      if (error) {
                        return ''
                      }
                      if (data) {
                        return (
                          <ReferenceArrayInput
                            source="jobPositions"
                            reference="job-positions"
                            filter={{ company: data.company }}
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={100}
                            {...rest}
                          >
                            <SelectArrayInput optionText="name"/>
                          </ReferenceArrayInput>
                        )
                      }
                    }
                  }
                </Query>
              )
            }
          }
        </FormDataConsumer>

        <BooleanInput source={'allowed_for_creator'}/>

        <FormDataConsumer fullWidth={true}>
          {
            ({ formData, ...rest }) => {
              return (
                <Query type="getOne" resource="workflow/workflow" payload={{ id: formData.workflow }} {...rest}>
                  {
                    ({ data, loading, error }) => {
                      if (loading) {
                        return ''
                      }
                      if (error) {
                        return ''
                      }
                      if (data) {
                        return (
                          <ReferenceArrayInput
                            source="actions"
                            reference="workflow/actions"
                            filter={{ workflowType: data.type }}
                            sort={{ field: 'name', order: 'ASC' }}
                            {...rest}
                          >
                            <SelectArrayInput optionText="name"/>
                          </ReferenceArrayInput>
                        )
                      }
                    }
                  }
                </Query>
              )
            }
          }
        </FormDataConsumer>


      </SimpleForm>
    </Edit>
  )
}