export default {
  'workflow/subject-types': {
    name: 'Type de workflow |||| Types de flux de travail',
    fields: {
      name: 'Titre',
      className: 'Nom de la classe de sujet',
      resourceName: 'Nom de la ressource',
      eventTypes: 'Calendar event types',
    }
  },
}