import config from '../../config/config'
import { getAccessToken } from '../../utils/AuthUtils'

const addUploadFeature = dataProvider => ({
  ...dataProvider,
  create: async (resource, params) => {
    if (resource === 'company-files') {
      console.log('CREATE company-files')
      const uploadRequest = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', params.data.file.rawFile, params.data.file.title)
        const request = new XMLHttpRequest()
        request.onload = function (ev) {
          if (request.status < 200 || request.status > 300) {
            reject()
          } else {
            //resolve(crudCreate(resource, {...params.data, policy: JSON.parse(request.response)['@id']}));
            const response = JSON.parse(request.response)
            resolve(response['@id'])
          }
        }
        request.open('POST', new URL('/company-files', config.api))
        request.setRequestHeader('Authorization', 'Bearer ' + getAccessToken())
        request.setRequestHeader('Accept', 'application/ld+json')
        request.send(formData)
      })

      try {
        const response = await uploadRequest
        const newParams = {
          id: response,
          data: {
            company: params.data.company,
            name: params.data.name
          }
        }
        console.log('response', response)
        console.log('resource', resource)
        console.log('newParams', newParams)
        return dataProvider.update(resource, newParams)
      } catch (e) {
        console.log('e', e)
        throw e
      }

    }

    return dataProvider.create(resource, params)
  },
  update: async (resource, params) => {
    if (resource === 'companies' && params.data.logo && typeof params.data.logo === 'object') {

      const uploadRequest = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', params.data.logo.rawFile)
        formData.append('company', params.data.originId)

        const request = new XMLHttpRequest()
        request.onload = function (ev) {
          if (request.status < 200 || request.status > 300) {
            reject()
          } else {
            const response = JSON.parse(request.response)
            resolve(response['@id'])
          }
        }
        request.open('POST', new URL('/logos', config.api))
        request.setRequestHeader('Authorization', 'Bearer ' + getAccessToken())
        request.setRequestHeader('Accept', 'application/ld+json')
        request.send(formData)
      })

      try {
        const logoUploadResult = await uploadRequest
      } catch (e) {
        throw e
      }
    }

    return dataProvider.update(resource, params)
  }

  /*
if (type === 'UPDATE' && resource === 'companies') {
  if (params.data.logo && typeof params.data.logo === 'object') {

      const logoUploadRequest =  new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append('file', params.data.logo.rawFile);
          formData.append('company', params.data.originId);

          const request = new XMLHttpRequest();
          request.onload = function (ev) {
              if (request.status < 200 || request.status > 300) {
                  reject();
              } else {
                  const response = JSON.parse(request.response);
                  resolve(response['@id']);
              }
          };
          request.open("POST", new URL('/logos', config.api));
          request.setRequestHeader("Authorization", "Bearer " + getAccessToken());
          request.setRequestHeader("Accept", "application/ld+json");
          request.send(formData);
      });

      try {
          const logoUploadResult = await logoUploadRequest;
      }
      catch(e) {
          throw e;
      }

  }
}

if (type === 'UPDATE' && resource === 'employees') {
  if (params.data.avatar && typeof params.data.avatar === 'object') {

      const user = await requestHandler(GET_ONE,"users",{id : params.data.account});

      const uploadRequest =  new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append('file', params.data.avatar.rawFile);
          formData.append('user', user.data.originId);

          const request = new XMLHttpRequest();
          request.onload = function (ev) {
              if (request.status < 200 || request.status > 300) {
                  reject();
              } else {
                  const response = JSON.parse(request.response);
                  resolve(response['@id']);
              }
          };
          request.open("POST", new URL('/user-avatars', config.api));
          request.setRequestHeader("Authorization", "Bearer " + getAccessToken());
          request.setRequestHeader("Accept", "application/ld+json");
          request.send(formData);
      });

      try {
          const uploadResult = await uploadRequest;
          console.log("uploadResult", uploadResult);
      }
      catch(e) {
          console.log("e", e);
          throw e;
      }

  }
}

if (type === 'CREATE' && resource === 'company-files') {
  console.log("CREATE company-files");
  const uploadRequest =  new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', params.data.file.rawFile, params.data.file.title);
      const request = new XMLHttpRequest();
      request.onload = function (ev) {
          if (request.status < 200 || request.status > 300) {
              reject();
          } else {
              //resolve(crudCreate(resource, {...params.data, policy: JSON.parse(request.response)['@id']}));
              const response = JSON.parse(request.response);
              resolve(response['@id']);
          }
      };
      request.open("POST", new URL('/company-files', config.api));
      request.setRequestHeader("Authorization", "Bearer " + getAccessToken());
      request.setRequestHeader("Accept", "application/ld+json");
      request.send(formData);
  });

  try {
      const response = await uploadRequest;
      const newParams = {
          id : response,
          data : {
              company : params.data.company,
              name : params.data.name
          }
      };
      console.log("response", response);

      console.log("resource", resource);
      console.log("newParams", newParams);
      return requestHandler('UPDATE',resource,newParams);
  }
  catch(e) {
      console.log("e", e);
      throw e;
  }

}

return requestHandler(type, resource, params);

   */
})

export default [
  addUploadFeature
]