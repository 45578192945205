import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import VINEditor from './VINEditor'
import ContactEditor from './ContactEditor'

const useStyles = makeStyles(theme => ({
  fieldRoot: {
    display: 'block',
    width: '100%'
  },
  fieldTitle: {
    display: 'inline-block',
    marginRight: theme.spacing(),
    minWidth: '160px'
  },
  fieldValue: {
    display: 'inline-block'
  },
  divider: {
    marginTop: '8px',
    marginBottom: '8px'
  }
}))

const DataField = ({ title, value, classes, editComponent = null }) =>
  <div className={classes.fieldRoot}>
    <Typography variant={'body1'} color={'textSecondary'} className={classes.fieldTitle}>{`${title} `}</Typography>
    <Typography variant={'body1'} color={'textPrimary'} className={classes.fieldValue}>{value}</Typography>
    {
      editComponent
    }
  </div>

const CarCommonData = ({
  date,
  brand,
  model,
  generation,
  year,
  version,
  engineType,
  gearbox,
  driveType,
  steeringWheel,
  vin,
  regNumber,
  doors,
  color,
  mileage,
  body,
  location,
  ptsOwners,
  sellerType,
  sellerName,
  saveVin,
  saveContact,
  contact
}) => {

  const classes = useStyles()

  return (
    <Grid
      container
    >
      <Grid item xs={12} className={classes.divider}/>

      <Grid item xs={12}>
        <DataField title="Дата размещения" value={date} classes={classes}/>
        <DataField title="Продавец" value={`${sellerName} (${sellerType})`} classes={classes}/>
        <DataField title="Место" value={location} classes={classes}/>
        <DataField title="Телефон" value={null === contact ? '' : contact.phone} classes={classes}
                   editComponent={<ContactEditor onSave={saveContact}
                                                 initialPhone={null === contact ? '' : contact.phone}
                                                 initialName={sellerName}/>}/>
      </Grid>

      <Grid item xs={12} className={classes.divider}><Divider/></Grid>

      <Grid item xs={12}>
        <DataField title="Марка" value={brand} classes={classes}/>
        <DataField title="Модель" value={model} classes={classes}/>
        <DataField title="Поколение" value={generation} classes={classes}/>
        <DataField title="Модификация" value={version} classes={classes}/>
        <DataField title="Тип кузова" value={body} classes={classes}/>
        <DataField title="Количество дверей" value={doors} classes={classes}/>
        <DataField title="Тип двигателя" value={engineType} classes={classes}/>
        <DataField title="Коробка передач" value={gearbox} classes={classes}/>
        <DataField title="Привод" value={driveType} classes={classes}/>
        <DataField title="Руль" value={steeringWheel} classes={classes}/>
        <DataField title="Цвет" value={color} classes={classes}/>
      </Grid>

      <Grid item xs={12} className={classes.divider}><Divider/></Grid>

      <Grid item xs={12}>
        <DataField title="Год выпуска" value={year} classes={classes}/>
        <DataField title="Пробег" value={mileage} classes={classes}/>
        <DataField title="Владельцев по ПТС" value={ptsOwners} classes={classes}/>
        <DataField title="VIN / Гос. номер" value={`${vin} / ${regNumber ? regNumber : ''}`} classes={classes}
                   editComponent={<VINEditor onSave={saveVin} initialVIN={vin} initialRegNumber={regNumber}/>}/>
      </Grid>

      <Grid item xs={12} className={classes.divider}><Divider/></Grid>

    </Grid>
  )

}

CarCommonData.propTypes = ({
  date: PropTypes.string,
  brand: PropTypes.string,
  model: PropTypes.string,
  generation: PropTypes.string,
  year: PropTypes.number,
  version: PropTypes.string,
  engineType: PropTypes.string,
  gearbox: PropTypes.string,
  driveType: PropTypes.string,
  steeringWheel: PropTypes.string,
  vin: PropTypes.string,
  doors: PropTypes.number,
  color: PropTypes.string,
  mileage: PropTypes.string,
  body: PropTypes.string,
  location: PropTypes.string,
  sellerType: PropTypes.string,
  sellerName: PropTypes.string,
  ptsOwners: PropTypes.number
})

export default CarCommonData