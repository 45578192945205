export default {
  'job-grades': {
    add: 'Добавить грейд',
    name: 'Грейд |||| Грейды',
    fields: {
      name: 'Название грейда',
      company: 'Дилер',
      enabled: 'Активный',
    }
  },
}