import Link from './Link/en'
import Filter from './Filter/en'
import Favorites from './Favorites/en'
import RequestAppraisal from './RequestAppraisal/en'

export default {
  ...Link,
  ...Filter,
  ...Favorites,
  ...RequestAppraisal,
}