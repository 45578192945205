export default {
  'worksheet/worksheet-buys': {
    name: 'Рабочий лист на выкуп автомобиля',
    fields: {
      originId: 'ID',
      createdAt: 'Дата создания',
      company: 'Дилер',
      companyBranch: 'Автосалон',
      carInstance: 'Автомобиль',
      workflow: 'Процесс',
      workflowPlace: 'Статус',
      isArchived: 'Архив',
      currentPlaceName: 'Статус',
      "creatorEmployee.fullName" : 'Автор',
      "assigneeEmployee.fullName" : 'Ответственный',
    },
    title: 'Рабочий лист на выкуп автомобиля №%{id}',
    tabs: {
      form: 'Свойства',
      summary: 'Данные автомобиля',
      log: 'История',
      check: 'Диагностика',
      photos: 'Фото',
      estimation: 'Оценка'
    },
    buttons: {
      add: 'Создать рабочий лист на выкуп'
    },
    filter : {
      created_after : 'Созданы начиная с',
      created_before : 'Созданы до',
      apps_label : 'Кому назначено',
      apps_all : 'Все',
      apps_assigned  : 'Назначены на меня',
      apps_employee : 'Я назначил',
      apps_user : 'Созданы мной',
    },
  },
}