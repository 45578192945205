import React from 'react'
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  useLocale,
  useTranslate,
  FunctionField,
  BooleanInput,
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const useFilterStyles = makeStyles(theme => ({
  dateInput: {
    marginBottom: theme.spacing(1)
  }
}))

const useListStyles = makeStyles(theme => ({
  listItemRoot: {
    paddingLeft: 0,
  },
}))

const WorksheetsFilter = (props) => {

  const locale = useLocale()
  const translate = useTranslate()
  const classes = useFilterStyles()
  const { filterValues } = props

  return (
    <Filter {...props}>
      <SelectInput
        source='assignmentFilter'
        label={translate('resources.worksheet/worksheet-buys.filter.apps_label')}
        choices={[
          {id: 'all', name: translate('resources.worksheet/worksheet-buys.filter.apps_all')},
          {id: 'assignedToMe', name: translate('resources.worksheet/worksheet-buys.filter.apps_assigned')},
          {id: 'assignedByMe', name: translate('resources.worksheet/worksheet-buys.filter.apps_employee')},
          {id: 'createdByMe', name: translate('resources.worksheet/worksheet-buys.filter.apps_user')},
        ]}
        alwaysOn
      />
      <ReferenceInput
        source="company"
        reference="companies"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ enabled: true, is_provider: false }}
        filterToQuery={searchText => ({ name: searchText })}
        alwaysOn
      >
        <AutocompleteInput helperText="" optionText="name" allowEmpty emptyValue={null}/>
      </ReferenceInput>

      {
        filterValues.hasOwnProperty('company') &&
        <ReferenceInput
          source="companyBranch"
          reference="company-branches"
          alwaysOn
          filter={{ company: filterValues['company'] }}
          sort={{ field: 'name', order: 'ASC' }}
          {...props}
        >
          <SelectInput helperText="" optionText="name" allowEmpty emptyValue={null}/>
        </ReferenceInput>
      }

      {
        filterValues.hasOwnProperty('company') &&
        <ReferenceInput
          source="workflow"
          reference="workflow/workflows"
          sort={{ field: 'name', order: 'ASC' }}
          filter={{ company: filterValues['company'], 'type.subjectType.resourceName': 'worksheet/worksheet-buys' }}
          alwaysOn
        >
          <SelectInput helperText="" optionText="name" allowEmpty emptyValue={null}/>
        </ReferenceInput>
      }

      {
        filterValues.hasOwnProperty('workflow') &&
        <ReferenceInput
          source="workflowPlace"
          reference="workflow/places"
          alwaysOn
          filter={{ workflow: filterValues['workflow'] }}
          filterToQuery={searchText => ({ state: searchText })}
          sort={{ field: 'code', order: 'ASC' }}
          perPage={100}
          {...props}
        >
          <SelectInput optionText={record => `${record.name}`} helperText="" allowEmpty
                       emptyValue={null}/>
        </ReferenceInput>
      }

      <BooleanInput source="isArchived" alwaysOn/>

    </Filter>
  )

}

const WorksheetList = props => {

  const classes = useListStyles()

  return (
    <List {...props}
          filters={<WorksheetsFilter/>}
          sort={{ field: 'createdAt', order: 'DESC' }}
          bulkActionButtons={false}
          exporter={false}
          actions={null}
    >
      <Datagrid rowClick="show">
        <TextField source="originId"/>
        <DateField source="createdAt" showTime/>
        <ReferenceField source="companyBranch" reference="company-branches" link={false}>
          <FunctionField render={record => {
            return (
              <React.Fragment>
                <Typography variant={'body2'}>{record.companyName}</Typography>
                <Typography variant={'caption'}>{record.name}</Typography>
              </React.Fragment>
            )
          }}/>
        </ReferenceField>

        <ReferenceField source="carInstance" reference="car-instances" link={false}>
          <FunctionField
            source="brand"
            render={record => (
              <ListItem alignItems="flex-start" className={classes.listItemRoot}>
                <ListItemText
                  primary={`${record.brandName} ${record.modelName}`}
                  secondary={`${record.modificationName || ''}`}
                  secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
                />
              </ListItem>
            )}
          />
        </ReferenceField>

        <FunctionField
          source="currentPlaceName"
          render={record => (
            <ListItem alignItems="flex-start" className={classes.listItemRoot}>
              <ListItemText
                primary={`${record.currentPlaceName || ''}`}
                secondary={`${record.currentWorkflowName}`}
                secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
              />
            </ListItem>
          )}
        />
      </Datagrid>
    </List>
  )
}

export default WorksheetList