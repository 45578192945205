import React from 'react'
import config from './config/config'
import { resolveBrowserLocale, fetchUtils } from 'react-admin'
import { createMuiTheme } from '@material-ui/core/styles'
import CustomLayout from './Layout/Layout'
import { StorageFactory } from './utils/StorageFactory'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import frenchCommonMessages from 'ra-language-french'
import englishCommonMessages from 'ra-language-english'
import germanCommonMessages from 'ra-language-german'
import russianCommonMessages from 'ra-language-russian'
import * as domainMessages from './i18n'
import AuthRoot from './components/Auth/AuthRoot'
import { createHashHistory } from 'history'
import authProvider from './authProvider'
import {Helmet} from 'react-helmet'
import { deleteRole, deleteToken, getAccessToken, refreshToken } from './utils/AuthUtils'
import Resources from './components/resources'
import dataProviderDecorators, { decorateDataProvider } from './components/dataProviderDecorators'
import { JssProvider } from 'react-jss'
import Dashboard from './Layout/Dashboard'
import customRoutes from './customRoutes'

import { Redirect, Route } from 'react-router-dom'
import {
  HydraAdmin,
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra
} from '@api-platform/admin'
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation'

const history = createHashHistory()

const theme = createMuiTheme({
  palette: {
    primary: {
      main: config.themeConfig.primary
    },
    secondary: {
      main: config.themeConfig.secondary
    }
  },
})

const messages = {
  fr: { ...frenchCommonMessages, ...domainMessages.default.fr },
  en: { ...englishCommonMessages, ...domainMessages.default.en },
  de: { ...germanCommonMessages, ...domainMessages.default.de },
  ru: { ...russianCommonMessages, ...domainMessages.default.ru }
}

const initLocale = StorageFactory().getItem('APP_LOCALE') || resolveBrowserLocale()
const i18nProvider = polyglotI18nProvider(locale => messages[locale], initLocale)

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT
const fetchHeaders = () => ({ Authorization: `Bearer ${getAccessToken()}` })
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  headers: new Headers(fetchHeaders()),
})
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders()) })
  .then(
    ({ api }) => ({ api }),
    (result) => {
      switch (result.status) {
        case 401:
          console.log('apiDocumentationParser')

          const redirect = Promise.resolve({
            api: result.api,
            customRoutes: [
              <Route path="/" render={() => {
                return getAccessToken() ? window.location.reload() : <Redirect to="/login"/>
              }}/>
            ],
          })

          return refreshToken()
            .then(() => {
              window.location.reload()
            })
            .catch(() => {
              deleteToken()
              deleteRole()
              return redirect
            })
        default:
          return Promise.reject(result)
      }
    },
  )
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true)

export default () =>
  <JssProvider>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{config.themeConfig.defaultTitle}</title>
      <link rel="shortcut icon" href={config.themeConfig.favicon.default} />
    </Helmet>

    <HydraAdmin
      entrypoint={process.env.REACT_APP_API_ENTRYPOINT}
      dataProvider={decorateDataProvider(dataProviderDecorators)(dataProvider)}
      theme={theme}
      layout={CustomLayout}
      i18nProvider={i18nProvider}
      loginPage={AuthRoot}
      authProvider={authProvider(history)}
      dashboard={Dashboard}
      customRoutes={customRoutes}
    >
      {
        permissions => Resources(permissions)
      }
    </HydraAdmin>

  </JssProvider>