import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { addField, FieldTitle } from 'ra-core'
import { ResettableTextField } from 'react-admin'
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

import moment from 'moment'
import MomentUtils from '@date-io/moment'
import 'moment/locale/fr'
import 'moment/locale/ru'
import 'moment/locale/de'
import 'moment/locale/en-gb'

export const localeMap = {
  en: 'en-gb',
  fr: 'fr',
  ru: 'ru',
  de: 'de',
}

const makePicker = (PickerComponent) => {

  class _makePicker extends Component {
    onChange (date) {
      this.props.input.onChange(date)
      this.props.input.onBlur()
    }

    render () {
      const {
        input,
        options,
        label,
        source,
        resource,
        isRequired,
        className,
        meta,
        locale
      } = this.props

      const { touched, error } = meta

      moment.locale(localeMap[locale])

      return (
        <div className="picker">
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={localeMap[locale]}>
            <PickerComponent
              {...options}
              label={<FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />}
              margin="dense"
              error={!!(touched && error)}
              helperText={touched && error}
              ref={(node) => { this.picker = node }}
              className={className}
              value={input.value ? input.value : null}
              onChange={date => this.onChange(date)}
              inputVariant="filled"
              inputProps={{
                size: 'small',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      )
    }
  }

  _makePicker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    locale: PropTypes.string,
    ampm: PropTypes.bool,
    format: PropTypes.string
  }

  _makePicker.defaultProps = {
    input: {},
    isRequired: 'false',
    meta: { touched: false, error: false },
    options: {
      ampm: false,
      format: 'DD.MM.YYYY HH:mm'
    },
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    locale: 'en',

  }
  return _makePicker
}

export const DateInput = addField(makePicker(DatePicker))
export const TimeInput = addField(makePicker(TimePicker))
export const DateTimeInput = addField(makePicker(DateTimePicker))
export const KeyboardDateInput = addField(makePicker(KeyboardDatePicker))