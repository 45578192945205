import React, { useEffect, useState } from 'react'
import { LoadingIndicator, useDataProvider } from 'react-admin'
import { getCleanHydraId } from '../../utils/HydraUtils'
import StrictResolverContainer from '../CRWL/BeeCars2/StrictResolverContainer'
import FoundVersion from '../CRWL/BeeCars2/FoundVersion'
import CompareTable from '../CRWL/BeeCars2/CompareTable'

const BeeCarsContainer = props => {

  const { carInstance } = props

  const [beeCarsVersionId, setBeeCarsVersionId] = useState(carInstance.bc_id)
  const [loadingModification, setLoadingModification] = useState(false)

  const [versions, setVersions] = useState([])
  const [versionsLoading, setVersionsLoading] = useState(false)

  const dataProvider = useDataProvider()

  useEffect(() => {
    setVersionsLoading(true)
    dataProvider.create('commands/bc/versions-for-modification', {
      data: {
        modification: getCleanHydraId(carInstance.modification ?? ''),
        year: carInstance.productionYear
      }
    })
      .then(({ data }) => {
        setVersions(data.result)
      })
      .finally(() => {
        setVersionsLoading(false)
      })
  }, [carInstance.modification])

  const linkBCVersion = (versionId) => {
    dataProvider.update('car-instances', { id: carInstance.id, data: { bcId: versionId }, previousData: carInstance })
      .then(({ data }) => {
        //console.log("linkBCVersion", data);
      })
    setBeeCarsVersionId(versionId)
  }

  const unlinkBCVersionId = () => {
    dataProvider.update('car-instances', { id: carInstance.id, data: { bcId: null }, previousData: carInstance })
      .then(({ data }) => {
        //console.log("linkBCVersion", data);
      })
    setBeeCarsVersionId(null)
  }

  if (loadingModification) {
    return <LoadingIndicator/>
  }

  return (
    <React.Fragment>
      {
        versionsLoading ?
          <LoadingIndicator/> :
          (!beeCarsVersionId ?
              <StrictResolverContainer
                versions={versions}
                onResolved={(id) => linkBCVersion(id)}
              /> :
              <React.Fragment>
                <FoundVersion ersionName={versions.filter(v => v.id === beeCarsVersionId)[0]?.name}
                              onUnlink={() => unlinkBCVersionId(null)}/>
                <CompareTable versions={versions} selectedVersion={beeCarsVersionId} year={carInstance.productionYear}/>
              </React.Fragment>
          )
      }
    </React.Fragment>
  )
}

export default BeeCarsContainer