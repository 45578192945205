export default {
  employees: {
    add: 'Add employee',
    name: 'Employee |||| Employees',
    fields: {
      company: 'Company',
      company_branch: 'Branch',
      department: 'Department',
      job_position: 'Position',
      first_name: 'First Name',
      last_name: 'Last Name',
      personal_code: 'Personal number',
      cost_center: 'Cost center',
      email_address: 'Email',
      enabled: 'enabled',
      phone: 'Phone number',
      gender: 'Gender',
      dob: 'Date of birth'
    },
    tabs: {
      summary: 'Summary',
      stats: 'Statistics',
      performance: 'Performance',
      communication: 'Communication'
    },
    actions: {
      requestNewPassword: {
        button: 'New password',
        message: 'New password has been sent to the employees\' email'
      }
    }
  },
}