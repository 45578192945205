import React, { useEffect, useState } from 'react'
import { StorageFactory } from '../../utils/StorageFactory'
import { useDataProvider, LoadingIndicator, Error, useTranslate, useLocale, linkToRecord } from 'react-admin'
import { color, formatDate, LinksPagination } from './LinksList'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { useStyles } from './LinksDataGrid'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

const SimilarLinksList = (props) => {

  const [geoParams, setGeoParams] = useState({
    lat: StorageFactory().getItem('geo_lat'),
    lon: StorageFactory().getItem('geo_lon'),
    radius: StorageFactory().getItem('geo_radius') ? StorageFactory().getItem('geo_radius') : 100
  })

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [links, setLinks] = useState([])
  const [page, setPage] = useState(1)
  const { record, modification } = props

  const classes = useStyles()
  const translate = useTranslate()
  const locale = useLocale()

  const dataProvider = useDataProvider()
  useEffect(() => {
    if (modification) {
      setLoading(true)
      dataProvider.getList(
        'crwl/links',
        {
          pagination: { page: page, perPage: 10 },
          sort: { field: 'createdAt', order: 'desc' },
          filter: {
            crwlFilter: JSON.stringify({
              brand: modification.brand,
              model: modification.model,
              generation: modification.generation,
              bodyTypeCode: [record.bodyTypeCode],
              gearbox: [record.gearbox],
              engineType: [record.engineType],
              driveType: [record.driveType],
              engineSizeFrom: record.engineSize,
              engineSizeTo: record.engineSize,
              yearFrom: record.year,
              yearTo: record.year,
              timePeriod: { month: 3 }
            }),
            spatialFilter: JSON.stringify('null' !== geoParams.lat ? {
              lat: geoParams.lat,
              lon: geoParams.lon,
              radius: geoParams.radius
            } : {})
          }
        })
        .then(({ data }) => {
          setError(null)
          setLinks(data)
        })
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    }
  }, [modification, page])

  const onClick = (id) => () => {
    window.open(`/#${linkToRecord('/crwl/links', id, 'show')}`)
  }

  if (loading) return <LoadingIndicator/>
  if (error) return <Error/>

  return (
    <div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            {
              links.filter(link => link.id !== record.id).map(link => {
                return (
                  <TableRow key={link.id} onClick={onClick(link.id)}>
                    <TableCell component="th" scope="row">
                      <div className={classes.imgContainer}
                           style={{ backgroundImage: `url(${link.photoUrls[0]})` }}
                      />
                    </TableCell>
                    <TableCell>
                      <ListItem alignItems="flex-start" className={classes.listItemRoot}>
                        <ListItemText
                          primary={`${link.src}`}
                          secondary={formatDate(link.createdAt, locale, translate)}
                          secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
                        />
                      </ListItem>
                    </TableCell>
                    <TableCell>
                      <ListItem alignItems="flex-start" className={classes.listItemRoot}>
                        <ListItemText
                          primary={`${link.brand} ${link.model}`}
                          secondary={`${link.generation || ''}`}
                          secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
                        />
                      </ListItem>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {
                          link.version
                        }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={link.color} placement="left">
                        <Avatar sizes={'s,a'}
                                className={classes.avatar}
                                style={{ backgroundColor: color(link.color) }}
                        >
                          <Typography/>
                        </Avatar>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {
                          link.year
                        }
                      </Typography>
                      <div/>
                      <Typography>
                        {
                          `${(new Intl.NumberFormat(locale).format(link.mileage))} ${translate('resources.crwl/links.measure.mileage')}`
                        }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ListItem alignItems="flex-start" className={classes.listItemRoot}>
                        <ListItemText
                          secondary={`${link.sellerType ? link.sellerType.toLowerCase() !== 'private' ?
                            translate('resources.crwl/links.measure.sellerCompany') :
                            translate('resources.crwl/links.measure.sellerPrivate') :
                            ''}`}
                          primary={`${link.sellerLocationName}`}
                          secondaryTypographyProps={{ variant: 'caption', display: 'block' }}
                        />
                      </ListItem>
                    </TableCell>
                    <TableCell>
                      <Typography variant='button'>
                        {
                          new Intl.NumberFormat(locale, {
                            minimumFractionDigits: 0,
                            style: 'currency',
                            currency: translate('resources.crwl/links.measure.currency'),
                            currencyDisplay: 'symbol'
                          }).format(link.price)
                        }
                      </Typography>
                      <div/>
                      <Typography variant='button'
                                  style={{ color: (link.price > record.price) ? '#EE1D19' : '#007F00' }}>
                        {
                          (link.price > record.price) ? '+' : ''
                        }
                        {
                          new Intl.NumberFormat(locale, {
                            minimumFractionDigits: 0,
                            style: 'currency',
                            currency: translate('resources.crwl/links.measure.currency'),
                            currencyDisplay: 'symbol'
                          }).format(link.price - record.price)
                        }
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>

      <LinksPagination page={page} setPage={setPage}/>
    </div>
  )

}

export default SimilarLinksList