export default {
  'app.auth.form.title.login': 'Войти в систему',
  'app.auth.form.title.register': 'Регистрация в системе',
  'app.auth.form.title.password-recovery': 'Восстановление пароля',
  'app.auth.form.description.password-recovery': 'Мы выслали код на ваш адрес. Введите его и новый пароль.',
  'app.auth.form.description.passwordless.code': 'Мы выслали код на ваш адрес. Введите его.',
  'app.auth.form.link.register': 'Зарегистрироваться',
  'app.auth.form.link.forgot-password': 'Забыли пароль?',
  'app.auth.form.link.email': 'Войти по одноразовому коду',
  'app.auth.form.link.login': 'Войти по паролю',
  'app.auth.form.link.logout': 'Выйти',
  'app.auth.form.email': 'Адрес электронной почты',
  'app.auth.form.code': 'Код из письма',
  'app.auth.form.firstName': 'Имя',
  'app.auth.form.lastName': 'Фамилия',
  'app.auth.form.password': 'Пароль',
  'app.auth.form.password.new': 'Новый пароль',
  'app.auth.form.password.confirm': 'Подтверждение пароля',
  'app.auth.form.password.changed': 'Пароль изменен',
  'app.auth.form.signin': 'Войти',
  'app.auth.form.validation.invalid.email': 'Неверный адрес',
  'app.auth.form.email.not.found': 'Адрес не найден. Хотите зарегистрироваться?',
  'app.auth.form.back': 'Назад',
  'app.auth.form.register': 'Зарегистрироваться',
  'app.auth.error.bad.credentials': 'Неверный адрес или пароль',
  'app.form.error.passwords.do.not.match': 'Пароли не совпадают',
  'app.form.error.passwords.empty': 'Пароль не может быть пустым',
  'app.form.error.code.empty': 'Код не может быть пустым',
  'app.form.error.code': 'Неверный код',
}