import config from '../config/config'
import React from 'react'

/**
 *
 * photoObject {
 *     id : serverID,
 *     fid : localID,
 *     type : number
 *     f : relativePathToFullImg
 *     p : relativePathToPreview
 * }
 *
 */

export const isPhotoTypeSecured = type => parseInt(type) >= 100 && parseInt(type) < 200
export const isPhotoSecured = photoObject => isPhotoTypeSecured(photoObject.type)

export const getPhotoUri = (photoObject, size = 'small') => () => {
  const carId = photoObject.carInstance.replace('/car-instances/', '')
  if (!isPhotoSecured(photoObject)) {
    return 'big' === size ?
      `${process.env.REACT_APP_IMAGES_ROOT}/cars/${carId}/${photoObject.filename}` :
      `${process.env.REACT_APP_IMAGES_ROOT}/cars/${carId}/${photoObject.previewFilename}`
  } else {
    return 'big' === size ?
      `${process.env.REACT_APP_IMAGES_SECURED_URL}${photoObject.id}` :
      `${process.env.REACT_APP_IMAGES_SECURED_URL}${photoObject.id}`
  }
}