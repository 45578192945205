export default {
  'questionnaire/surveys': {
    add: 'Создать анкету',
    name: 'Анкета |||| Анкеты',
    fields: {
      createdAt: 'Создан',
      company: 'Дилер',
      questionnaire: 'Опрос',
      isCompleted: 'Завершен',
      isOpen: 'Открыт для заполнения',
      workflow: 'Процесс',
    },
    tabs: {
      summary: 'Свойства',
      results: 'Результаты',
      questions: 'Вопросы',
    },
    header: 'Вопрос %{current_question} из %{questions_num}',
    buttons: {
      prev: 'Предыдущий вопрос',
      next: 'Следующий вопрос',
      complete: 'Завершить',
      save: 'Сохранить',
      transitions: 'Действия с анкетой'
    },
    inputs: {
      freeText: 'Ваш ответ',
      yes: 'Да'
    },
    messages: {
      answerSaved: 'Ответ сохранен',
      answerError: 'Ошибка при сохранении ответа'
    }
  }
}