export default {
  departments: {
    add: 'Add department',
    name: 'Department |||| Departments',
    fields: {
      title: 'Title',
      company: 'Company',
      enabled: 'Enabled',
    }
  },
}